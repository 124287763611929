.claseFondo {
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    font-weight: bold;
}
.lupa_clase_eleva{
    width:8vmin;
}

.claseFondo .container .info img {
    width: 13vw;
    margin:5%;
}

.claseFondo .container {
    height: 80%;
    display: flex;
    align-items: center;
    text-align: center;
}

.containerBGClase {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    position: absolute;
}

.containerComprobar {
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.377);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.container{
    margin-top:0px;
}

.containerComprobar .containerModal .title {
    font-size: calc(0.5em + 1vw);
}



.containerModal .iconTitle {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.containerModal .iconTitle img {
    width: 10%;
}



.containerModal .subtitle {
    color: #9DACBD;
    text-align: center;
    line-height: 1;
    font-size: calc(0.5rem + 0.5vw);
    margin:  auto;
}

.containerFinalizar .observaciones {
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-top: 1%;
    font-size: calc(0.5em + 1vw);
}


.containerFinalizar .observaciones textarea {
    max-height: 25vh;
    margin:2% auto 0% auto;
    border: 1px solid #E2E2E2;
    width: 70%;
    color:#9DACBD;
    border-radius:5px;
}
.containerFinalizar .observaciones textarea::placeholder {
    color:#9DACBD 
    }

.containerFinalizar .botones {
    text-align: center;
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 1%;
    font-size: calc(0.2em + 1vw);
}



.containerFinalizar .botones .sifin {
   
    background-color: #9EACED;
}

.containerFinalizar .botones .nofin {
    background-color: #CB3D82;
}

.containerModal .items .iconItem {
    width: 30%;
}

.containerModal .items {
    color: #969696;
    font-size: 3vmin !important;
}

.containerModal .items select {
    color: #969696;
    font-size: 3vmin !important;
}

.containerModal .items .row {
    justify-content: center;
    align-items: center;
}

.containerModal .items .row .icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerModal .items.scroll {
    padding:2%  5% 5% 5%; 
}

.containerModal .items.scroll .recomen {
    max-height: 40vh;
    overflow-x: scroll;
}

.containerModal .items.scroll .cancelar {
    margin-top: 5%;
    text-align: center;
    color: #394F65;
    font-weight: bold;
}
.containerModal .items.scroll .cancelarpero {
    margin-top: 5%;
    text-align: center;
    color: #394F65;
    font-weight: bold;
}

.containerModal .items.scroll .cancelar button {
    margin-top: 5%;
    color: white;
    background-color: #2A9DA5;
    border: none;
    border-radius: 30px;
    padding: 0 5%;
    
}
.containerModal .items.scroll .cancelarpero button {
    margin-top: 2%;
    color: white;
    z-index:888;
    background-color: #CB3D82;
    border: none;
    border-radius: 30px;
    padding: 1% 5%;
    
}

.containerModal .title.scroll {
    font-size: 3vmin;
}

.containerClase {
    width: 100vw;
    height: 100vh;
}

.containerClase .miVideo {
    width: 30vmin;
    height: 30vmin;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 5%;
    top: 95%;
    transform: translateY(-95%);
}
.containerClase .miVideoexpandir {
    width: 1.6rem;
    height: 1.6rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 5%;
    top: 95%;
    transform: translateY(-95%);
}

.containerClase .suVideo {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
}
.containerClase .expandirvideo {
    cursor: pointer;
    z-index: 3;
    width: 100%;
    height: 100%;
    max-width:3rem;
    max-height:3rem;
  
    
    position: absolute;
    left: 0;
    bottom: 0;
 
}

.myPanel .infoClas {
 
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
}

.myPanel .infoClas img {
    width:11%;
    align-self: center;
    margin-right: 5%;
}
.myPanel .infoClas_F img {
    width:9%;
    align-self: center;
    margin-right: 5%;
}





.miEntrenamiento {
    color: #394F65;
}

.miEntrenamiento .titulo {
    font-weight: bold;
    font-size: calc(0.4rem + 1vw);
    text-align: center;
    margin-top: 5%;
}

.miEntrenamiento .tiempo {
    font-weight: bold;
    font-size: 2.5vmin;
    line-height: 1;
    margin: 0 auto;
    justify-content: center;
    text-align:center;
}

.miEntrenamiento .tiempo span {
    font-size: 10vmin;
}



.miEntrenamiento .rutas {
    color: #9DACBD;
}

.miEntrenamiento .rutas .itemRutina {

    display: flex;
    font-size:1.5vw;
    align-items: center;
}



.miEntrenamiento .rutas span .circle {
    width: 4vmin;
    height: 4vmin;
    background-color: #F5BFB6;
}

.miEntrenamiento .rutas .circle.descanso {
    width: 2.5vmin;
    height: 2.5vmin;
   margin:0 0.7vmin;
    background-color: #F5BFB6;
}

.miEntrenamiento .rutas .itemRutina.active {
    font-weight: bold;
}

.miEntrenamiento .rutas .itemRutina.calentamiento.active {
    color: #9dacbd !important;
    font-weight: bold;
}

.miEntrenamiento .rutas .circle.calentamiento.active {
    background-color: #9EACED !important;
}

.miEntrenamiento .rutas .itemRutina.descanso.active {
    color: #9dacbd !important;
    font-weight: bold;
}

.miEntrenamiento .rutas .circle.descanso.active {
    background-color: #9EACED !important;
}

.miEntrenamiento .rutas .circle.estiramiento.active {
    background-color: #9EACED !important;
}

.miEntrenamiento .rutas .itemRutina.estiramiento.active {
    color: #9dacbd !important;
    font-weight: bold;
}

.miEntrenamiento .rutas .itemRutina.active {
    color: #9dacbd !important;
    font-weight: bold;
}

.miEntrenamiento .rutas .circle.active {
    background-color: #9EACED !important;
}

.myPanel .textFinalizar {
    font-size: calc(0.1rem + 1vw);

    text-align: center;
    width: 90%;
    line-height: 1;
}

.myPanel .textFinalizar div {
    font-weight: bold;
}

.myPanel .Finalizar button {
    background-color: #CB3D82;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 2.5vmin;
    border-radius: 30px;
    padding: 0.5rem 1.5rem;
}


.containerFinalizar {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.777);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.containerFinalizar .preguntas {
    height: 40vh;
}

.containerFinalizar .preguntas .pregunta {
    display: grid;
    justify-items: center;
    align-content: center;
    text-align: center;
    color: #9DACBD;
    font-weight: bold;
    font-size: calc(0.4em + 1vw);
}
.swiper-button-next, .swiper-button-prev{
    color:#9DACBD !important
}

.containerFinalizar .preguntas .pregunta div {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.containerFinalizar .preguntas .pregunta .sliderPregunta {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.containerFinalizar .preguntas .pregunta .estrella {
    width: 10%;
    cursor: pointer;
}

.containerFinalizar .preguntas .pregunta .estrellas {
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}

.containerFinalizar .PrivateValueLabel-circle-4 {
    width: 60px !important;
    height: 60px !important;
}

.containerFinalizar .PrivateValueLabel-circle-4 {
    width: 60px !important;
    height: 60px !important;
}

.containerFinalizar .PrivateValueLabel-offset-3 {
    top: -60px !important;
}

.containerFinalizar .MuiSlider-valueLabel {
    left: auto !important;
}

@media (min-width: 1441px) {
    .PrivateValueLabel-thumb-1.PrivateValueLabel-open-2 .PrivateValueLabel-offset-3 {
        transform: scale(1.5) translateY(-10px) !important;
    }
}
@media (min-width: 100px) and (max-width:  920px) {
    .miEntrenamiento .rutas span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 25px;
        min-height: 5vmin;
        margin-right: 0.4rem;
    }
    .containerModal .title {
        color: #394F65;
        font-weight: bold;
        text-align: center;
        display: flex;
        justify-content: center;
        line-height: 1;
        margin-top: 1%;
    }
    .letradeseascancelar{
        font-size:2vw !important
    }
    .containerFinalizar .botones button {
        width: 35%;
        border: none;
        border-radius: 30px;
        padding: 0.5rem 1rem;
        color: white;
        font-weight: bold;
        margin-top:20px !important;
    }
    .myPanel .infoClas .datos .valor {
       
        font-weight: bold;
    }
    .myPanel .infoClas .datos {
      
        text-align:center;
        font-size: 2.5vmin;
        font-weight: bold;
        line-height: 1;
    }
    .btnCancelNivel {
        max-width: 130px !important;
        font-size: 10px !important;
        padding:2%;
    }

    .containerModal {
        padding-bottom: 2%;
        padding-top: 2%;
        width: 65%;
        background-color: white;
        border-radius: 30px;
        max-height: 90%;
        /* overflow-y: scroll; */
    }
    .containerModal_eleva_class {
        padding: 3% 20%;
        width: 100vw;
        height: 100vh;
        background-image: url("../assets/img/tipos_ejercicios-min.png");
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -o-background-size: cover;
       
    }
    .containerModal_eleva_class .items .iconItem {
        width: 30%;
    }
    
    .containerModal_eleva_class .items {
        color: #9DACBD;
        font-size: 3vmin !important;
    }
    
    .containerModal_eleva_class .items select {
        color: #9DACBD;
        font-size: 3vmin !important;
    }
    
    .containerModal_eleva_class .items .row {
        justify-content: center;
        align-items: center;
    }
    
    .containerModal_eleva_class .items .row .icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .containerModal_eleva_class .items.scroll {
        padding:2%  5% 5% 5%; 
    }
    
    .containerModal_eleva_class .items.scroll .recomen {
        max-height: 40vh;
        overflow-x: scroll;
    }
    
    .containerModal_eleva_class .items.scroll .cancelar {
        margin-top: 5%;
        text-align: center;
        color: #CB3D82;
        font-weight: bold;
    }
    .containerModal_eleva_class .items.scroll .cancelarpero {
        margin-top: 5%;
        text-align: center;
        color: #394F65;
        font-weight: bold;
    }
    
    .containerModal_eleva_class .items.scroll .cancelar button {
        margin-top: 5%;
        color: white;
        background-color: #CB3D82;
        border: none;
        border-radius: 30px;
        padding: 0 5%;
        
    }
    .containerModal_eleva_class .items.scroll .cancelarpero button {
        margin-top: 2%;
        color: white;
        z-index:888;
        background-color: #CB3D82;
        border: none;
        border-radius: 30px;
        padding: 2% 5%;
        
    }
    
    .containerModal_eleva_class .title.scroll {
        font-size: 4vmin;
        color:#9DACBD;
    text-align:center    }
  
 
}
@media (min-width: 921px)  {
    .miEntrenamiento .rutas span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 25px;
        min-height: 4vmin;
        margin-right: 0.4rem;
    }
    .containerModal_eleva_class .items.scroll .cancelar {
        margin-top: 5%;
        text-align: center;
        color: #394F65;
        font-weight: bold;
    }
    .containerModal_eleva_class .items.scroll .cancelarpero {
        margin-top: 5%;
        text-align: center;
        color: #394F65;
        font-weight: bold;
    }
    
    .containerModal_eleva_class .items.scroll .cancelar button {
        margin-top: 5%;
        color: white;
        background-color: #CB3D82;
        border: none;
        border-radius: 30px;
        padding: 0 5%;
        
    }
    .containerModal_eleva_class .items.scroll .cancelarpero button {
        margin-top: 2%;
        color: white;
        z-index:888;
        background-color: #CB3D82;
        border: none;
        border-radius: 30px;
        padding: 2% 5%;
        
    }
    .containerModal_eleva_class .items.scroll .recomen {
        text-align:center;
        margin:2% auto;
        color: #9DACBD;
       
        
    }
    
    .containerModal_eleva_class .title.scroll {
        font-size: 3vmin;
        color:#394F65;
        font-weight:bold;
    text-align:center    }
  
 

    .containerModal_eleva_class {
        padding:6% 30%;
        width: 100vw;
        height: 100vh;
        font-size: 2.5vmin;
     
        background-image: url("../assets/img/tipos_ejercicios-min.png");
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -o-background-size: cover;
       
    }
    .containerModal .title {
        color: #394F65;
        font-size: calc(.1rem + 2vw);
        font-weight: bold;
        text-align: center;
        display: flex;
        justify-content: center;
        line-height: 1;
        margin-top: 1%;
    }
    .containerFinalizar .botones button {
        /* width: 35%; */
        border: none;
        border-radius: 30px;
        padding: 0.5rem 1rem;
        color: white;
        font-weight: bold;
        margin-top:20px !important;
    }
    .letradeseascancelar{
        font-size:1.5vw !important
    }
    .lineclas_clase{
        border-top:1px solid #BEBEBE;
        width:30%;
        opacity:0.5;
        padding:0 6vw;
        z-index:1;
    }
    .edadCliente{
        font-size:0.8vw;  
    }
    .nivelCliente{
        font-size:0.8vw;
    }
    .myPanel .infoClas .datos .valor {
   
        font-weight: bold;
    }
    .myPanel .infoClas .datos {
        color: #394F65;
        font-size: 2.0vmin;
        font-weight: bold;
        
        line-height: 1;
        text-align:center;
        margin-bottom:1vh;
    }
    .miEntrenamiento .rutaContainer {
        height: 50%;
        overflow-y: scroll ;
        margin: 4%;
        font-size: calc(0.5rem + 0.5vw);
    }
     .Finalizar1 button {
        background-color:#CB3D82;
        border: none;
        color: white;
        justify-content: center;
        display:flex;
        font-weight: bold;
        font-size: 2.5vmin;
        margin:auto;
        border-radius: 30px;
        padding: 0.5rem 1.5rem;
    }
     .Finalizar1 .btnFinalizar {
        background-color:#CB3D82;
        border: none;
        color: white;
        justify-content: center;
        display:flex;
        font-weight: bold;
        font-size: 2.5vmin;
        margin:auto;
        border-radius: 30px;
        padding: 0.5rem 1.5rem;
    }
     .Finalizar1 .btnFinalizar_clase_stream {
        background-color:#CB3D82;
        border: none;
        color: white;
        justify-content: center;
        display:initial;
        font-weight: bold;
        font-size: 2.5vmin;
        margin:auto;
        border-radius: 30px;
        padding: 0.5rem 1.5rem;
    }
    .containerModal {
        padding: 1%;
      
        width: 60%;
        background-color: white;
        border-radius: 30px;
        max-height: 85%;
      
       
        /* overflow-y: scroll; */
    }
    .btnCancelNivel {
        max-width: 190px !important;
        font-size: 16px !important;
    }
 
}



.miEntrenamiento .rutaContainer {
    height: 50%;
    overflow-y: scroll ;
    margin: 4%;
    font-size: calc(0.5rem + 0.5vw);
}
/* @media (min-width: 901px) {
    .miEntrenamiento .rutaContainer {
        height: 70%;
     
        margin: 4%;
        font-size: calc(0.5rem + 0.5vw);
    }
} */


.iconsParentClase {
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    margin: auto;
    align-items: flex-start;
}

.iconsAfterClase {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.iconsAfterClase img {
    width: 6vmin;
    margin-left: 5px;
}

.iconHeaderWait {
    width: 8vmin;
    margin: 0 auto 10px auto;
    display: flex;
    justify-content: center;
}



.btnCancelNivel.activo {
    background-color: #CB3D82 !important;
    color: white;
}

.containerFinalizar .swiper-button-next:after, .containerFinalizar .swiper-button-prev:after{
    font-size: 23px !important;
}

.containerFinalizar .swiper-button-next, .containerFinalizar .swiper-button-prev {
    height: 23px !important;
}