





.VerReserva .cabeceraContent .bg_img img {
    height: 100%;
    vertical-align: top;
}

@media (max-width: 920px) {
    .bulletsPublicidad .swiper-pagination-bullet{
    
        width: 3vmin;
        height: 3vmin;
    }
    .CancelarReserva .buttons .No {
        background-color: #9EACED;
     
      
    }
    
    .CancelarReserva .buttons .Si {
        background-color: #CB3D82;
      
        margin-top: 0.2rem;
        
    }
    .recomendacionreserva .elipse{
        height:10px;
      margin-right:10px;
      margin-left:0px;
        margin-top:4px
     }
    .VerReserva .contenedorInfo .descr .content {
        padding-right: 1%;
        font-size:3.2vw;
       color: #9DACBD
    }
    .CancelarReserva .buttons button {
        color: white;
        border: none;
        background-color: transparent;
        border-radius: 30px;
        padding: 0.5rem 1rem;
        height: 100%;
    }
    .CancelarReserva {
        padding: 10%;
    }
    .btnBackReservaschange{
        height:6vh !important;
        margin-left:13% !important;
      width:55vw !important;
        
        font-size: 3.5vw !important;
    }
    .VerReserva .cabeceraContent .btnBackReservas {
        position: relative;
        left: 40%;
        bottom: 55.5%;
        width: 50%;
        height:10%;
        transform: translateX(-50%);
        display: block;
        text-align: center;
    }
    .VerReserva .cabeceraContent button {
        background-color: #CB3D82 !important;
    }
    .VerReserva .contenedorInfo {
        height: 100%;
        width: 50%;
        margin-top: 10%;
        overflow: hidden;
    }
    .VerReserva .contenedorInfo .title {
        font-weight: bold;
        color: #394F65;
        font-size:5vw;
    }
    .VerReserva .cabeceraContent .btnBottom .colorbuttoniniciar{
        background-color:#9EACED !important
    }
    .VerReserva .cabeceraContent .btnBottom .colorbuttoniniciargray{
        background:#F6DCE8 !important;
        
        /* background:transparent linear-gradient(190deg,rgb(105,105,105)20%, rgba(220,220,220) 100%) 0% 0% no-repeat padding-box !important */
    }
    .VerReserva .cabeceraContent .btnBottom .colorbuttoniniciar2{
        background-color:#CB3D82 !important;
        z-index:1;
        
    }
    .VerReserva .cabeceraContent .bg_img {
        height: 60%;
        position: relative;
        top: 15%;
        z-index: 0;
    }
    .VerReserva {
        min-height: 595px;
        max-height: 100vh;
        max-width: 100vw;
        height: 100vh;
    }
    .VerReserva .cabeceraContent {
        height: 50.5%;
        overflow: hidden;
        background-color: transparent;
    }
    .VerReserva .cabeceraContent .bg_img img {
       
    }
    .VerReserva .contenedorInfo {
        display: flex !important;
        margin-top: 0 !important;
    }
    .VerReserva .contenedorInfo .descr {
        width: 65% !important;
    }
    .VerReserva .contenedorInfo {
        height: 30%;
        width: 90%;
        margin-top: -30px !important;
        overflow: hidden;
    }
    .VerReserva .cabeceraContent .info .name {
        font-size: 7vw;
        font-weight: bold;
        text-transform: capitalize;
        letter-spacing: 2px;
    }
    .VerReserva .cabeceraContent .info {
        position: relative;
        bottom: 47%;
        color: white !important;
        font-size: 1rem;
        line-height: 1;
        left: 5%;
        width: 90%
    }
    .VerReserva .cabeceraContent .btnBottom {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        position: relative;
        bottom: 9%;
        
    }
    .VerReserva .cabeceraContent .btnBottom * {
        font-size: calc(0.7rem + 0.6vw);
        line-height: 1;
        height:6vh;
        /* padding-bottom: 10px; */
        max-width: 160px;
    }
    .inforesevatuclase{
        font-size: 3vw !important;
        color:#9DACBD !important;
        max-width: 80vw !important;
        margin-top: 0vh;
        margin-left: 0vh;
    }
    .recomendacionreserva{
        display:flex;
        padding:0vh 2px;
        font-size:3.2vw;
    }
    .VerReserva .contenedorInfo .descr {
        color: #000;
        height: 20vh;
        width:100% !important;
  
        overflow-y: auto;
        overflow-x: hidden;
       
    }
    .VerReserva .contenedorInfo .titulo img {
        width: 3rem;
    }
    .VerReserva .contenedorInfo .titulo {
        display: flex;
        align-items: flex-start;
        color:#394F65;
    }
    .VerReserva .contenedorInfo .titulo span {
        font-weight: bold;
        margin-left: 1rem;
        display: flex;
        padding:1vh 0;
        align-items: center;
    }
}
/* mygeneralpo */

@media (min-width: 921px) {
    .bulletsPublicidad .swiper-pagination-bullet{
    
        width: 5vmin;
        height: 5vmin;
    }
    .VerReserva .cabeceraContent .btnBottom .colorbuttoniniciargray{
        background:#F6DCE8 !important
    }
    .CancelarReserva .buttons .No {
        background-color: #9EACED;
     
        width:48%;
    }
    
    .CancelarReserva .buttons .Si {
        background-color:#CB3D82;
      
        margin-left: 0.2rem;
        width:51%;
    }
    .recomendacionreserva .elipse{
        height:10px;
      margin-right:18px;
        margin-top:4px
     }
    .VerReserva .contenedorInfo .descr .content {
        padding-right: 1%;
    }
    .linedivisionrecomendaciones{
        margin: 2vh 0 ;
        border-bottom: 2px solid #BEBEBE;
        width:95%;
        opacity:0.2
    }
    .VerReserva .contenedorInfo .titulo span {
        font-weight: bold;
        margin: 0.7vh 1rem;
        display: flex;
        font-size:0.9vw;
        align-items: center;
    }
    .VerReserva .contenedorInfo .titulo {
        display: flex;
        margin: 1vh 0 !important;
        align-items: flex-start;
    }
    .VerReserva .contenedorInfo .titulo img {
        width: 2.5vw;
    }
    .VerReserva .contenedorInfo .descr {
        color: #000;
        height: 20vh;
        width:95%;
        padding:1.0vh 0;
        overflow-y: auto;
        overflow-x: hidden;
       
    }
    .VerReserva .contenedorInfo .descrgroup {
        color: #000;
        height: 50vh;
        width:95%;
        padding:1.0vh 0;
        overflow-y: auto;
        overflow-x: hidden;
       
    }
    .recomendacionreserva{
        display:flex;
        padding:2px;
        font-size:0.9vw;
    }
    .CancelarReserva .buttons button {
        color: white;
        border: none;
        font-weight:bold;
        background-color: transparent;
        border-radius: 30px;
        padding: 0.5rem 1rem;
        height: 100%;
    }
    .CancelarReserva {
        padding: 8%;
        max-width: 60vw;
    }
    .btnBackReservaschange{
        max-height:6vh !important;
        color:#394F65;
        margin-left:20% !important;
      width:100% !important;
        
        font-size: 1.2rem !important;
    }
    .VerReserva .cabeceraContent .btnBackReservas {
        position: relative;
        left: 40%;
        bottom: 78%;
        width: 50%;
        height:10%;
        transform: translateX(-50%);
        display: block;
        text-align: center;
    }
    .VerReserva .cabeceraContent button {
        background-color: transparent !important;
    }
    .VerReserva .contenedorInfo {
        height: 100%;
        width: 50%;
        margin-top: 5%;
        overflow: hidden;
    }
    .tituloreservaver{
   
        font-weight: bold;
        color: #9EACED;
        font-size:1.0vw;  
        border-bottom:4px solid  #9EACED;
        width:6.5vw;
        margin-bottom:2vh;
    }
    .inforesevatuclase{
        font-size: 0.9vw !important;
        color: black !important;
        max-width: 80vw !important;
        margin-top: 0vh;
        margin-left: 0vh;
    }
    .VerReserva .contenedorInfo .title {
        font-weight: bold;
        color: #394F65;
        font-size:1.0vw;
    }
    .VerReserva .cabeceraContent .btnBottom .colorbuttoniniciar{
        background-color:#9EACED !important
    }
    .VerReserva .cabeceraContent .btnBottom * {
        font-size: calc(0.7rem + 0.6vw);
        line-height: 1;
        height:4vh;
      
        max-width: 16vw;
    }
    .VerReserva .cabeceraContent .fgu * {
        font-size: calc(0.7rem + 0.6vw);
        line-height: 1;
        padding: 0.8vh 0;
        height:4vh;
      
        max-width: 16vw;
    }
    .VerReserva .cabeceraContent .btnBottom .colorbuttoniniciar2{
        background-color:#CB3D82 !important;
     
    }
    .VerReserva .cabeceraContent .btnBottom {
        display: flex;
        width:100%;
        align-items: flex-start;
        justify-content: space-evenly;
        position: relative;
        bottom: 13%;
        
    }
  
    .VerReserva .cabeceraContent .info {
        position: relative;
        bottom: 40%;
        color: white !important;
        font-size: 1rem;
        line-height: 1;
        left: 0%;
        width: 90%
    }
    .VerReserva .cabeceraContent .bg_img {
        height: 80%;
        position: relative;
        top: 10%;
        z-index: 0;
        background-size:5vmin !important;
        background-position:left;
        width: 100%;
      ;
    }
 
    .VerReserva .cabeceraContent .bg_imggrup {
        height: 80%;
        position: relative;
        top: 2%;
        z-index: 0;
        background-size:5vmin !important;
        background-position:left;
        width: 100%;
      ;
    }
    .lineop1 {
        position: absolute;
        top: 0vh !important;
        width: 85% !important;
        margin-left: 1vw !important;
        border-top: 3px solid white;
        opacity: 1;
        width: 100%;
        margin-top: 1vh;
    }
    .VerReserva .cabeceraContent .info .name {
        font-size: 3rem;
        font-weight: bold;
        text-transform: capitalize;
        letter-spacing: 2px;
    }
    .VerReserva {
        display:flex;
        position:relative;
        left:10%;
        min-height: 595px;
        max-height: 100vh;
        width: 90vw;
        height: 100vh;
    }

    .VerReserva .cabeceraContent {
        height: 90%;
        overflow: hidden;
        width:40%;
        background-color: transparent;
    }
    .VerReserva .cabeceraContent .bg_img img {
      height:100%;  
    width:100%;
    }
    .VerReserva .cabeceraContent .bg_imggrup img {
      height:100%;  
    
    }
    .verReservaWeb{
        display:none
    }
}

.cabeceraContent .btnMOB {
    font-size: calc(0.4rem + 1vw);
}

.VerReserva .cabeceraContent .bg_img:after {
    content: '\A';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0.3;
    transition: all 1s;
    -webkit-transition: all 1s;
}
.VerReserva .cabeceraContent .bg_imggrup:after {
    content: '\A';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0.3;
    transition: all 1s;
    -webkit-transition: all 1s;
}

.VerReserva .cabeceraContent .bg_img:hover:after {
    opacity: 0;
}
.VerReserva .cabeceraContent .bg_imggrup:hover:after {
    opacity: 0;
}














.VerReserva .cabeceraContent .info .line {
    position: absolute;
    width: 50vw;
    height: 1px;
    background: #CB3D82;
    margin-top: 5px;
    right: 0;
}

.bulletsPublicidadgrupal .swiper-pagination-bullet{
    
    display:none;
}












.VerReserva .contenedorInfo .info {
    color: #000;
}





.CancelarReserva .title {
    color: #394F65;
    font-weight: bold;
}



@media (max-width: 481px) {
    .CancelarReserva .buttons button {
        /*width: 50%;*/
        /* padding: unset !important; */
        width: 100%;
    }
    .CancelarReserva {
        padding: 1rem;
    }
    .CancelarReserva .buttons {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }
}

.CancelarReserva .buttons {
    display: flex;
    line-height: 1;
}

.CancelarReserva .buttons .confirmfin {
    background-color: #CB3D82;
    margin: 1vh 14vw !important
}
.CancelarReserva .buttons .confirmfin1 {
    background-color: #CB3D82;
    margin: 1px 10vw !important
}




.btnIniciarReserva {
    font-size: calc(1rem + 0.1vw);
}