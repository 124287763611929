
@media (min-width: 100px) and (max-width: 920px) {
  .custom-file-input::-webkit-file-upload-button {
    display:none;
  }
    
    .custom-file-input{
      color:gray !important;
      display:flex;
      margin:1vh 0 ;
      font-weight:400;
      border-top: none !important;
      border-left :none !important;
      border-right:none !important;
    }
  
    .custom-file-input::before {
      background-image: url('../../assets/img/Auth_images/filesd.png');
      background-size: 6vw 6vw;
      display: inline-block;
      float:right;
      width: 6vw; 
      height: 6vw;
      content:"";
    
   
   
      cursor: pointer;
  
    }
  
}

@media (min-width: 921px)  {
  .marginforbotton{
    margin:3vh 0;
  }
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}
    .custom-file-input{
      color:gray !important;
      font-family:WorkSans-Bold
    }
    .custom-file-input::before {
      content: 'Subir documento';
      display: inline-block;
      background: -webkit-linear-gradient(top, #2A9DA5, #2A9DA5);
      color:white !important;
      font-size:1vw !important;
      border-radius: 10px;
      padding: 5px 8px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
   
      font-weight: 700;
      font-size: 10pt;
    }
    .custom-file-input:hover::before {
      background:#268E95
    }
    .custom-file-input:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }
  }