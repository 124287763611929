#root {

  height:100%;
}
a {
  text-decoration: none !important;
}

.contenido {
  margin-top: 72px !important;
}
.allproyect{
  height:100%;
}
.allproyect > .contenido_proyect{
  height:100%;
}
#chatWidget {
  width: 45px;
  height: 45px;
  position: fixed;
  cursor: pointer;
  right: 20px;
  bottom: 75px;
  z-index: 99998;
}
