.lineagruesa{
    border:1vh solid #D6D6D6 !important;   
}
.bom{
    position:absolute !important;
    margin-top:-10vh !important;
}


@media (min-width: 100px) and (max-width:  920px) {
    .creditoPlan input[type='radio'] {
        -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
margin:auto 0;
  width: 1rem;
  height: 1rem;
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: inline-block;
  padding:2px;
  background-clip: content-box;
        background-color: transparent!important; 
    }
    .creditoPlan input[type='radio']:checked  {
        
      
        background-color: #CB3D82 !important;
    
    }
    .containerResumemtrans{
padding: 1vh 0
    }
    .containerResumemtrans .container{
        
        height:auto;
        padding:0vh 8vw;
    }
.containerPagos{
 
    position:absolute;
    z-index: 999 !important;
    height:100vh;
    top:20%;
    bottom:0%;
    left:5%;
    right:0;
}
    .btnRedPag {
        display:flex;
        background-color: #CB3D82;
        color: white;
        font-weight: bold;
       
        width: 45%;
        padding: 0.3rem;
        border-radius: 30px;
        text-align: center;
        margin-left: 1rem;
        margin-top: 1rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    .btnCreditos {
        display: flex;
        justify-content: flex-start;
        margin-left: 0rem;
    }
    .btnRedPag {
        width: 13rem;
        right: 0;
        
    }
    .infoValor .info {
   
        text-align: right;
        color: #7C7C7C;
        font-size: 0.8rem;
        
    }
    
    .infoValor {
        margin-top: 2vh;
    }
    
    .currency {
        margin-left: 3px;
    }
    
    .infoValor .value {
        padding-left: 1vh;
        text-align: left;
        color: #9DACBD;
        font-weight: bold;
        font-size: 1.1rem
    }
    #input_container {
   
        position: relative;
        width: 50%;
    }
    .btnCupon {
    
        margin-top: 3vh;
        padding: 1vh 3vw 1vh 3vw;
        background-color: #7B86FA;
        display: flex;
        margin-left: 5vw;
        margin-right: 5vw;
        color: white;
        font-weight: bold;
        border-radius: 15px;
       
       
    }
    .btnPagar2 {
        padding:5px;
        font-size: 4vw;
    }
    .ContainerCreditos .description .vigencia {
        font-weight: bold;
        color: #394F65;
       
      
    }
    .ContainerCreditos .description .vigencia1 {
        font-weight: bold;
        color: #2A9DA5;
        margin-left:22vw
        
      
    }
    .btnCupon input {
        display:flex;
       
        border: none;
        padding:2px;
        margin:1px;
        border-bottom: 1px dotted #D6D6D6 !important;
        
        background-color: #7B86FA;
        color: white;
        width: 33vw;
     
    }
    .info12{
    
        font-weight:400;
        font-size: 3vw !important;
        color: #9DACBD !important;
        max-width: 80vw !important;
     
        margin-left: 6vh;
        text-align: right;
    }
    .tienescodigo{
        padding:3px;
        margin:1px;
        font-size:4vw;
        display:flex;
    }
    .ContainerCreditos .subtitle {
        text-align: center;
        color: #7B86FA;
        font-weight: bold;
        font-size: 6vw;
        padding-bottom: 2vh;
        border-bottom: 5px solid #D6D6D6;
        width: 100%;
    }
    .ContainerCreditos .subtitlepasarela {
        text-align: center;
        color: #333333;
        font-weight: bold;
        font-size: 5vw;
        padding-bottom: 2vh;
       
        width: 100%;
    }
    .ContainerCreditos .title {
        
        text-align: center;
        color: #394F65;
        font-weight: bold;
        font-size: 7vw;
        line-height: 1;
    }
    .ContainerCreditos {
 
        height: 100vh;
        overflow: hidden;
    }
    
    .ContainerCreditos .listPlans {

       
        padding: 1vh 10%;
        font-size: 3vw;
    }
    
    .ContainerCreditos .listPlans .creditoPlan {
        padding-top: 2vh;
        display:flex;
    }
    
    .ContainerCreditos .listPlans .creditoPlan .name {
        padding-left: 3vw;
    }
    
    .ContainerCreditos .listPlans .creditoPlan .name span {
        font-weight: bold;
        color: #9DACBD;
    }
    
    .ContainerCreditos .description {
        font-weight: 600;
     
        padding-top: 4vh;
        font-size: 3vw;
        color: #9DACBD;
      
    }
    .btnCupon input::placeholder {
        display:flex;
        color: white;
        
       font-size:4vw;
       
    }
    .tienescodigo .input_container input::placeholder{
        font-size: calc(0.2rem + 1vw);
}
.btnPagar {
    font-size: 1.3rem;
}
.imagentarjeta{
    background-image:url("../assets/img/creditos/imagenes/tarjetaverde.png") ;
    background-repeat: no-repeat;
    background-size: 15% 90%;
    background-position-x: right;
 
 
 
  
  
}

}

@media (min-width: 921px) {
    .creditoPlan input[type='radio'] {
        -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
margin:auto 0;
  width: 2vmin;
  height: 2vmin;
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: inline-block;
  padding:2px;
  background-clip: content-box;
        background-color: transparent!important; 
    }
    .creditoPlan input[type='radio']:checked  {
        
      
        background-color: #CB3D82 !important;
    
    }

    #input_containerregalo{
        padding:1vw !important;
    }
    .btnRedPag {
        display:flex;
        background-color: #9EACED;
        color: white;
        font-weight: bold;
       
        width: 70% !important;
        padding: 0.3rem;
        border-radius: 30px;
        text-align: center;
      margin:auto;
        /* margin-top: 3rem; */
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .imgarribacreditos{
        position: absolute;
        margin-top:-23vh;
        margin-left:-10vh;
         width:40%;
         z-index:-1 !important
     }
     .imgabajocreditos{
        position: fixed;

  right:0;
        
         width:40% !important;
      
         z-index:-1 !important
     }
    .btnCreditos {
        display: flex;
       position:absolute;
       right:8vw;
       top:300px;
        justify-content: flex-end;
        margin-right: 2rem;
    }
    .btnCreditosmit {
        display: flex;
     text-align:center;
   
      
    
        justify-content: center;
       
    }
    .tienescodigomitad{
        border-top:none;
        border-left:none;
        border-right:none;
        border-bottom:1px solid #9DACBD
    }
    .btnCuponmitad{
        margin-top: 1vh;
        padding: 1vh 2vw 1vh 0vw;
      
        display: flex;
    
      
      
        color: white;
        font-weight: bold;
        border-radius: 15px;
    }
    .descuentobtn0{
        width: 85%;
        /* margin-top:5vh; */
        border:1px solid white ;
        /* border-radius: 20px; */
        /* box-shadow: -3px 1px 10px -3px #707070; */
    }
    .descuentobtn01{
        width:60%;
        border:1px solid white ;
        border-radius: 20px;
        box-shadow: -3px 1px 10px -3px #394F65;
    }
    .descuentobtn019{
        width:100%;
      
        border:1px solid white ;
        border-radius: 20px;
        box-shadow: -3px 1px 10px -3px #394F65;
    }
    .ContainerCreditos {
        width:100%;
        position: relative;
        top:0px;
        left:6vw;;
       
      min-width:90vw;
        height: 110vh !important;
        overflow: hidden;
    }
    .ContainerCreditos_eleva_response {
        width:100%;
        position: relative;
        top:0px;
     padding:10%;
      min-width:90vw;
        height: 110vh !important;
        overflow: hidden;
    }
    .muestramecreditos{
        position:fixed;
      margin-left:77%;
        top:15%;
    }
    .totalcresub{
 padding:0px;
position:relative;
left:10%;
color:#394F65 ;
font-weight:bold;
font-size: calc(3rem );
line-height: 1;  
    }
    .totalcrename{
        padding:5px;
        text-align: left;
        color:#9DACBD ;
        font-weight:400;
        font-size: calc(0.7rem + 0.6vw);
        line-height: 1;  
    }
    .infoValor .info {
   
        text-align: right;
        color: #7C7C7C;
        font-size: calc(0.2rem + 1vw);
        
    }
    .infoValormedio .info {
   
        text-align: right;
        color: #7C7C7C;
        font-size: calc(0.2rem + 1vw);
        
    }
    
    .infoValor {
        position:relative;
        top:80px;
        left:-140px;
    }
    .infoValormedio {
        position:relative;
        /* top:80px; */
        left:0px;
    }
    
    .currency {
        margin-left: 3px;
    }
    
    .infoValor .value {
        padding-left: 1vh;
        text-align: left;
        color: #9DACBD;
        font-weight: bold;
        font-size: 1.1rem
    }
    .infoValormedio .value {
        padding-left: 1vh;
        text-align: left;
        color: #9DACBD;
        font-weight: bold;
        font-size: 1.1rem
    }
    .tienescodigo .input_container input::placeholder{
        font-size: calc(0.2rem + 1vw);
}
    #input_container {
   
   
     
    }
    .columna2creditos{
        width:60%;
        border-right:1px solid #D1D1D1;
    }
.subtitlewebcreditosdown{
    padding:0 1vw;
    text-align: left;
    color:#9DACBD ;
    font-weight:400;
    font-size: calc(0.3rem + 0.7vw);
    line-height: 1;  
}

.subtitlewebcreditosdowndelmediao{
    width:85%;
    padding:1px;
    text-align: left;
    color:#9DACBD ;
    font-weight:400;
    font-size: calc(0.5rem + 0.5vw);
    line-height: 1;  
}
.subtitlewebcreditoesteplan{
    width:85%;
    padding:2vh 0;;
    text-align: left;
    color:#9DACBD ;
    font-weight:bold;
    font-size: calc(0.5rem + 0.5vw);
    line-height: 1;  
    
}
.subtitlewebcreditosdown1{
    padding:1px;
    text-align: left;
    color:#2A9DA5 ;
    font-weight:bold;
    font-size: calc(0.2rem + 1vw);
    line-height: 1;  
}
.subtitlewebcreditosdown2{
    padding:9px;
    text-align: left;
    color:#9DACBD ;
    font-weight:bold;
    font-size: calc(0.2rem + 1vw);
    line-height: 1;  
  
   
}
.btnCreditostercer{
    padding:2vw;
}
.subtitlewebcreditosdown3{
   
    padding:1px;
    text-align: left;
    color:#9DACBD ;
    font-weight:bold;
    font-size: calc(0.2rem + 1vw);
    line-height: 1;  
   margin-top:30px;
}
.subtitlewebcreditos{
    width:85%;
    padding:0vh 1.0vw 2vh 0;

    margin-top:20px;
    margin-bottom:10px;
    text-align: left;
    color: #394F65;
    font-weight: bold;
    font-size: calc(0.7rem + 0.9vw);
    line-height: 1;  
}
.subtitlewebcreditos1{
    width:100%;
    padding:1vw;

    margin-bottom:10px;
    text-align: left;
    color: #394F65;
    font-weight: bold;
    font-size: calc(0.7rem + 1vw);
    line-height: 1;  
}

    .btnCupon {
    
        margin-top: 1vh;
        padding: 1vh 3vw 1vh 3vw;
        background-color: #2A9DA5;
        display: flex;
      width:14vw;
      
      
        color: white;
        font-weight: bold;
        border-radius: 15px;
       
       
    }
    .ContainerCreditos .description .vigencia {
        font-weight: bold;
        color: #2A9DA5;
       
      
    }
    .ContainerCreditos .description  {
 width:25vw;
       
      
    }
    .ContainerCreditos .description .vigencia1 {
        font-weight: bold;
        color: #2A9DA5;

        
      
    }
    .btnCupon input::placeholder {
        display:flex;
        color: white;
        
        font-size: calc(0.2rem + 1vw);
       
    }
    .btnCupon input {
        display:flex;
       
        border: none;
        padding:2px;
        margin:1px;
        border-bottom: 1px dotted #D6D6D6 !important;
        
        background-color: #2A9DA5;
        color: white;
        width: 10vw;
     
    }
 
    .info12{
    
        font-weight:400;
        font-size: 1.1rem;
        color: #394F65 !important;
        
     
        margin-left:1vh;
        text-align: right;
    }
    .tienescodigo{
        padding:3px;
        margin:1px;
        font-size: calc(0.2rem + 1vw);
        display:flex;
    }

 .twocolumna {
    position:relative;
    width:70%;
    left: 33px;
 }
 .onecolumna {

     position:relative;


     width:100%;
     margin-top: 6vh
     ;
 }
 .threcolumna {
     margin:0 1vw 0 0;;
     position:relative;
    
 }
    .ContainerCreditos .title {
       position:relative;
       width:100%;
        text-align: left;
        color: #394F65;
        font-weight: bold;
        font-size: calc(5.0vmin + 0.2vw);
        line-height: 1;
     left:5.5vw;
        margin-top:2vh;
     
    }
    .ContainerCreditos_eleva_response .title_eleva_creditos {
        position:relative;
        width:100%;
         text-align: center;
         color: #394F65;
         font-weight: bold;
         font-size: calc(5.0vmin + 0.2vw);
         line-height: 1;
         margin-top:2vh;
     
      
     }
    .ContainerCreditos .listPlans {
        margin: 0 0px 0 6vw;
        border-right:1px solid #D1D1D1;
     width:35vw;
        font-size: calc(0.2rem + 1vw);
    }
    
    .ContainerCreditos .listPlans .creditoPlan {
        padding-top: 2vh;
        display:flex;
        width:20vw
    }
    
    .ContainerCreditos .listPlans .creditoPlan .name {
        padding-left: 1vw;
        font-size: calc(0.3rem + 0.7vw);
    }
    
    .ContainerCreditos .listPlans .creditoPlan .name span {
        font-weight: bold;
        color: #394F65;
        font-size: calc(0.3rem + 0.7vw);
    }
    
 
    .btnRedPag {
        width: 15rem;
        right: 0;
        
    }
  
    .btnPagar2 {
        padding:2px;
        font-size: 0.9rem;
    }
    
}




.btnRedPag img {
    margin-left: 2vw;
    width: 10vw;
    max-width: 30px;
}

.comprobarBtn {
    background-color: transparent;
    color: white;
    border: 1px solid;
    border-radius: 15px;
}

.ContainerCreditos .bg_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 50vh;
    margin-top: 1vh;
}
.creditoPlan input,
{
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;

}
/* .creditoPlan [type=radio]{display:none} */
/* .creditoPlan i,
.creditoPlan i:before{
	transition: all 0.25s ease;
    border: solid 0.1vw #DDDDDD;
  
} */
/* .creditoPlan input[type=radio] + i{
  
  display:block;
  top:0.3vh;
left:-1vw;
width:2.7vw;
height:1.6vh;
   position:relative;
	border-radius: 100%;

   
} */
/* .creditoPlan input[type=radio] + i:before{
	content: '';
	display: block;
	height: 3.4vh;
	width: 5.5vw;
	border-radius: 100%;
	position: absolute;
	z-index: 1;
	top: -0.95vh;
	left: -1.52vw;
	background: #2A9DA5;
	transition: all 0.25s ease;
	transform: scale(0);
	opacity: 0; /* Lo ocultamos*/
/* }  */
/* .creditoPlan input[type=radio]:checked+i:before{
	transform: scale(0.4);
	opacity: 1;
} */


#input_container input {
    margin-right: 20px;
}

#input_img {
    position: absolute;
    right: 0%;
    top: 50%;
    transform: translateY(-50%);
}