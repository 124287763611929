
.playervideoIndividual img {
    border-radius:25px  !important;
    object-fit: initial !important;
    border:1px solid rgba(0,0,0,0.40);
    box-shadow:0px 0px 10px -2px rgba(0,0,0,0.50);
    
    }
    .playervideoIndividual video {
        border-radius:25px  !important;
        object-fit: initial !important;
   
        /* border:1px solid rgba(0,0,0,0.40);
        box-shadow:0px 0px 10px -2px rgba(0,0,0,0.50); */
        
        }
.playervideoIndividual {
padding:2%;

}
.buttonIndividualTypes{
    border:2px solid #CB3D82;
    background:transparent;
   border-radius:20px;
   font-size:1.3vmin;
   padding:1vmin 3%;
   margin:auto 2%;
   
}
.buttonIndividualTypesverahora{
    border:2px solid #CB3D82;
    background:#CB3D82;
   border-radius:20px;
   font-weight:bold;
   color:#ffff;
   font-size:1.3vmin;
   padding:1vmin 3%;
   margin:auto 2%;
   
}