



@media (min-width: 100px) and (max-width:  920px) {
    .infotext_see{
        font-size: 0.8rem !important;
        color: #9DACBD !important;
      max-width:80vw !important;
      
       margin-top:0vh;
        margin-left: 6vh;
        text-align: flex-end
    }
    .login_logo_new {
        position: absolute;
        right: 10%;
    
        top: 6vh;
    width:24vw;
        z-index: 0;
    }
    .info{
        font-size: 3.5vw !important;
        color: #9DACBD !important;
      max-width:85vw !important;
       margin-top:0vh;
     
        text-align: flex-end
    }
    .list_sesiones_info .info{
        font-size: 3.5vw !important;
        color: #9DACBD !important;
      max-width:100vw !important;
       margin-top:0vh;
     
        text-align: flex-end
    }
    .olvido_pass {
        padding-top:3vh;
        padding-bottom:4vh;
    justify-content: center;
    font-weight: 400;
        color: #CB3D82 !important;
        font-size: 3.5vw;
        cursor: pointer;
    }
    .registro_nuevo a {
        color: #CB3D82 !important;
    }
    .Botonentrar{
        margin:auto;
        font-size: calc(.75rem + 1vw) !important;
    }
    .registro_nuevo {
        font-size: 3.5vw ;
        font-weight: 400;
        color:#394F65;
    }
    .position-absolutecrear{
        display: block;
        margin-left: 7vw !important;
        margin-top: -39px !important;
        padding-bottom:20px !important;
        max-height:1vh !important;
        width: 18vw;
        z-index: 0   
    }
    .infocontraseñarecu{
        color:#7C7C7C;
        font-size: calc(.6rem + 1vw) !important;
    
        margin-left:6%;
       
        text-align:flex-end
    }
    #position-absolutecontraseña{
        display: block;
        color:#BEBEBE;
        font-weight:bold;
        margin-left: 7vw !important;
        margin-top: -60px !important;
        padding-bottom:-20px !important;
        max-height:1vh !important;
        width: 48vw;
        z-index: 0   
    }
    .position-absolute{
            display: block;
    margin-left: 1vw !important;
    margin-top: 15px !important;
    max-height:1vh !important;
    width: 18vw;
    z-index: 0;
        
    }
    .login_logo_new1 {
        display:flex;
        margin-left: 68vw !important;
        margin-top: -90px !important;
       
        width: 24vw;
        z-index: 0;
    }
 
    .LoginFormContainer4  {
       margin-top:35%;
            width: 100%;
            height: 100%;
           
           
    }
    .LoginForm {
        margin-left: 5vh;
        margin-right: 5vh;
        margin-bottom: 1%;
        margin-top: 5vh;
        z-index: 2;
  
        position: relative;
    }
    
    .LoginForm label {
        color: #394F65;
        font-weight: 400;
     
        margin-top: 2vh;
    }
    
    .Registro label {
        margin-top: 2vh !important;
    }
    
    .marco{
        display: none !important;
    }
    .imgarriba{
       position: absolute;
       margin-top:-23vh;
       margin-left:-10vh;
        width:100%;
        z-index:-1 !important
    }
    .imgabajo{
       position: absolute;
       margin-top:37vh;
       padding-right:6vw !important;
        width:100%;
        z-index:-1 !important
    }
    .textodebajo12{
        font-size:calc(.4rem + 1.7vw);
        margin-left:9vw;
        max-width:80vw;
        font-weight: 500;
        padding-top:9vh;
        color: #9DACBD;
    }
    .LoginFormContainer5 .info {
        color: #9DACBD !important;
        text-align:center;
        margin-top:9vh !important;
        font-weight: 400;
        margin:auto;
        font-size: calc(.4rem + 1.5vw) !important;
        width: 80%;
    }
    .TyC {
        font-size: calc(.4rem + 1.7vw);
        color: #394F65;
        font-weight: 400;
    }
    .titleBienve{
      
        font-size: 9vw !important;
        color: #394F65 !important;
        margin-top:0vh !important;
      
        margin-left: 9vw !important;  
    }
    .SubTitle8mobil {
        position:relative;
        white-space:pre;
        font-size: calc(.4rem + 1.7vw);
        font-weight: 500;
        color: #9DACBD;
      
       
        
        margin-left: 9% !important;
    
    }
    .SubTitle8mobil1 {
        position:relative;
        white-space:pre;
        font-size: 3.5vw;
        font-weight: 500;
        color: #9DACBD;
      margin-top:8vh;
     
        margin-left: 9% !important;
    
    }
    .SubTitle8 {
        position:relative;
        white-space:pre;
        font-size: 4vw;
        color: #969696;
        padding-top: 6rem;
       
        margin-left: 3% !important;
        margin-top:3vh
    }
    .subtitleobj{
        display:none
    }
    .textodebajoss{
        display:none 
    }
    .textodebajoregis{
        display:none
    }
  
  
        .title1crear{
            font-weight: bold !important;
            font-size: calc(2rem + 1vw) ;
            color: #394F65;
            margin-top:0vh;
            margin-bottom:-3vh;
            margin-left: 5vh;    
    }
        .title1{
            font-weight: bold !important;
            font-size: calc(2rem + 1vw) ;
            color: #2A9DA5;
            margin-top:3vh;
            margin-bottom:-6vh;
            margin-left: 5vh;    
    }
   
    #position-absolute1{
        display:none
    }
    .login_area_logo7{
       display:none
    }
    .titlerecu2{
        display:flex;
       
        font-size: 7vw;
        color: #394F65;
        font-weight: 700;
        margin-top:20vh;
       margin-bottom:0vh !important;
        margin-left: 8vw;   
    }
    .titlerecucontra{
        
        display:flex;
        font-family: WorkSans-Bold !important;
        font-size: 7vw;
        color: #2A9DA5;
        margin-top:10vh;
       margin-bottom:0vh !important;
   margin-left:6%;
   margin-right:0%;
    }
 
    .flexusuario{
        display:none
    }
    .textodebajo{
        font-size: calc(.4rem + 1.7vw) !important;
        max-width:80vw;
        margin-left:9vw !important;
        font-weight: 400;
        padding-top:1vh;
        color:#9DACBD
    }

    /* .textodebajo12{
        display:none
    } */
    #position-absolute10{
        display:none !important
    }
  
    #position-absolute7{
        position: relative ;
        display:flex !important;
      font-weight:700;
      color:#c2c2c2;
      top:0px;
      left:1vw;
      padding-top:2vh !important;
       
    }
    .CompletarPerfil label{
        margin-left:1.5vh ;  
       }
  
   
}



@media (min-width: 921px)  {
    .infotext_see{
        font-size: 1rem !important;
        color: #9DACBD !important;
      max-width:80vw !important;
      
       margin-top:0vh;
        margin-left: 6vh;
        text-align: flex-end
    }
    .LoginForm input::placeholder{
        font-size:1rem;
    }
    .title1crear{
      font-weight:bold;
        font-size: calc(2rem + 1vw) ;
        color: #394F65;
        margin-top:14vh;
        margin-bottom:-5vh;
        margin-left: 2vw;    
}
    .LoginForm label {
        color: #394F65;     font-size: calc(.1rem + 1vw)}
    .login_logo_new {
        position: absolute;
        right: 20%;
        top: 4vh;
        width: 15vw;
        z-index: 0;
    }
    .flexusuariomobil{
        display:none
    }
    .flexusuariomobil1{
        display:none
    }
    .meroproblem{
        display:none 
    }
    .info{
        font-size: 0.8vw !important;
        color: #9DACBD !important;
      max-width:80vw !important;
      
       margin-top:0vh;
        margin-left: 6vh;
        text-align: flex-end
    }
    
    .TyC {
        font-size: calc(.1rem + 0.8vw) ;
    }
    .LoginForm {
        margin-left: 5vh;
        margin-right: 5vh;
        margin-bottom: 2vh;
        margin-top: 2vh;
        z-index: 2;
        position: relative;
    }
    .crearcuenta  label{
        color: #394F65;
        font-size: calc(.1rem + 1vw) !important;
        margin-top: 2vh; 
    }
    

    
    .Registro label {
        margin-top: 2vh !important;
       font-size: calc(.1rem + 1vw)
    }
    
    .textodebajoregis{
        font-size: calc(.3rem + 0.7vw) !important;
        max-width:70vw;
        margin-left:2.4vw !important;
        margin-right:2vw !important;
        padding-top:1vh;
        margin-top:5vh;
        color:#9DACBD ;
        z-index:3 !important
    }
    .formSteps{
        display:flex;
        position: relative;
  
    }
    .acomodarfoto{
        position:relative !important;
        padding-top:0px;
       left:0px !important;
       
        
    }
 

    .subtitleobj{
        font-family: WorkSans-Bold !important;
        font-size: 3vmin;
        color: #2A9DA5;
        padding-top:1px;
        padding-bottom:40px;
        margin-bottom:-32px;
        margin-left: 5vh; 
    }
   
    .CompletarPerfil .Separador {display:none;}
    .flexusuario{
        position:relative !important
    }
    .SubTitle9 {
        position:absolute;
        white-space:pre;
        margin-top: -50px;
        font-size: 15px;
        color: #BEBEBE;
        padding-top: 10vh;
        font-weight:700;
        margin-left: 5vh;
    }

    .logo-imgsolis{
        padding-left:20px
          }
          .containerReservas.title{ font-family: WorkSans-Bold !important;
            font-size: 9vmin;
            color: #2A9DA5;
            margin-top:60px;
            margin-left: 5vh;
           position:relative;
           text-align:left;
          }
    
    .flexcontraseñas{
       display:flex;
        width:100% !important;
    }
    .form-label{
        float:left
    }
    .textodebajo{
        font-size: calc(.4rem + 0.7vw) !important;
        margin-left:40px;
        padding-top:30px;
        color:#9DACBD
    }
    .textodebajo12{
        font-size:1.0vw;
        margin-left:6%;
        margin-right:1%;
        padding-top:8vh;
        margin-top:2vh;
        margin-bottom:2vh;
        color:#9DACBD
    }
    

    #position-absolute7{
        position: absolute;
      font-weight:700;
      color:#c2c2c2;
      top:0px;
      right:-190px;
      padding-bottom:230px !important;
       
    }

    #position-absolute10{
        position: absolute ;
      font-weight:700;
      color:#7B86FA;
      top:30px;
      right:30px;
   font-size:1vw;
      z-index:1
       
    }
.lineflexI{
    position:relative;
    margin-top: 12vh;
    margin-left:15px;
    padding-right:100px;
    
    width:800px;
    height:1px;
  
}
.position-absolutecrear{
    display:none
}
#position-absolutecontraseña{
    display: block;
    color:#BEBEBE;
    font-weight:bold;
    padding-left: 29vw !important;
    margin-top: 30px !important;
    padding-bottom:-20px !important;
    max-height:1vh !important;
    width: 48vw;
    z-index: 0   
}
  .position-absolutecrear1{
    display: block;
    position:absolute;
    font-size: 1vw !important;
    margin-left: 24vw !important;
    margin-top: 10px !important;
    padding-bottom:20px !important;
    max-height:1vh !important;
    width: 18vw;
    z-index: 1  
}
.lineflex{
    border-bottom: 1px solid #BEBEBE;
    opacity: 0.5;
    width: 33.5vw;
  margin-bottom:-1vh;
    margin-left:1vw; 
}
.lineflextriner{
    border-bottom: 1px solid #BEBEBE;
    opacity: 0.5;
    width: 31.8vw;
  margin-bottom:-1vh;
    margin-left:1vw; 
}
.lineflex9{
    display:flex;
    position:relative;
    margin-top: 16vh;
    margin-left:2%;
 
    height:0.1vh;  
}
.infocontraseñarecu{
    color:#7C7C7C;
    font-size: calc(.3rem + 1vw) !important;
    
    margin-left:6%;
   
    text-align:flex-end
}
    .flexusuario{
        margin-top: 5vh;
        display:flex;
         position: absolute;
        ;
    }
    .flexusuariocrear{
        margin-top: 2vh;
        display:flex;
         position: absolute;
        ;
    }
    .SubTitle8 {
        position:relative;
        white-space:pre;
        margin-top: 5px;
        font-size: calc(.1rem + 0.8vw) !important;
        color: #9DACBD;
        padding-top: 10vh;
    
        margin-left: 5%;
        margin-bottom:-2vh;
    }
    .SubTitle9 {
        position:relative;
        white-space:pre;
        margin-top: 40px;
        font-size: calc(.1rem + 1vw) !important;
        color: #BEBEBE;
        padding-top: 10vh;
        font-weight:700;
        margin-left: 6%;
    }
    .login_area_logo1{
        display:flex;
        width:8vw;
        margin-left:50px ;
        padding-top:55px
    }

    .imgarriba{
        display:none
    }
    .imgabajo{
        display:none;
    }
    .LoginFormContainer14_Usuario_ingreso {

        background-image: url("../assets/img/fondo/login_bg0.jpg");
        background-size:cover;
        width:100%;
       
        min-width:100vw;
    
       height:100vh;
      
       display:flex
    }
    .LoginFormContainer14_Entrenador_ingreso {

        background-image: url("../assets/img/fondo/login_bg-B.jpg");
        background-size:cover;
        width:100%;
       
        min-width:100vw;
    
       height:100vh;
      
       display:flex
    }
    .LoginFormContainer14_Entrenador_crearcuenta {

        background-image: url("../assets/img/fondo/login_bg-A.jpg");
        background-size:cover;
        width:100%;
       
        min-width:100vw;
    
       height:100vh;
      
       display:flex
    }
    .LoginFormContainer14_Usuario_crearcuenta {

        background-image: url("../assets/img/fondo/login_bg-c.jpg");
        background-size:cover;
        width:100%;
       
        min-width:100vw;
    
       height:100vh;
      
       display:flex
    }
    .marco{
        position:relative;
   
     margin-left:2%;
     margin-right:-11%;
     margin-top:3%;
      left:4%;
      height:90vh;
        width: 80vw;
        border:7px solid white;
        z-index:1;
      
    }
    .LoginFormContainer  {
      display:none
                 
    }
    .LoginFormContainer5{
        position: relative;
     margin-top:26px;
      background-color:white;
      margin-bottom:136px;
      width: 40vw;
      height:70vh;
            z-index:1;
    }
    .titlerecucontra{
        font-size: calc(1.4rem + 1.5vw) !important;
        display:flex;
        font-family: WorkSans-Bold !important;
        white-space: nowrap;
        color: #2A9DA5;
        margin-top:10vh;
       margin-bottom:0vh !important;
   margin-left:6%;
   margin-right:0%;
    }
    .LoginFormContainer4 .info {
        color: #BEBEBE !important;
        text-align:center;
        margin-top:-4vh !important;
        margin:auto;
        font-size: calc(.4rem + 1.5vw) !important;
        width: 100%;
    }
    .LoginFormContainer5 .LoginForm {
        margin-top:8vh
    }
    .LoginFormContainer5 .info {
        color: #394F65 !important;
        text-align:center;
        margin-top:5vh !important;
        margin:auto;
        font-size: calc(.2rem + 0.7vw) !important;
        width: 100%;
    }
    .LoginFormContainer4  {
        position: relative;
        /* bottom:90px !important; */
     /* bottom:-30px !important; */
     
     margin-top:3%;
      background-color:white;
        /*padding-bottom:21px !important; */
         margin-right:6vw;
         margin-left:10vw;
      margin-bottom:136px;
      width: 65vw;
      height:90vh;
            z-index:1;
    }
    .LoginFormContainer6  {
        position: relative;
        /* bottom:90px !important; */
     /* bottom:-30px !important; */
     
     margin-top:3%;
      background-color:white;
        /*padding-bottom:21px !important; */
         margin-right:6vw;
         margin-left:10vw;
      margin-bottom:136px;
      width: 65vw;
      height:90vh;
            z-index:1;
    }
    .Botonentrar{
        margin-right:15% !important;
        margin-left:15% !important;
        font-size: calc(.3rem + 1vw) !important;
        
    }

    .login_logo_new{
        display:none
    }
    .olvido_pass {
        padding-top:3vh;
        padding-bottom:4vh;
        font-size: calc(.1rem + 0.8vw) !important;
        color: #CB3D82 !important;
        font-size: 1.1vw;
        margin-left:5px;
        white-space: nowrap;
        cursor: pointer;
    }
    #position-absolute1 {
      position: absolute ;
      font-weight:700;
      color:#c2c2c2;
      top:50px;
      right:70px;
      padding-bottom:230px !important;
    }

    /* #position-absolute7 {
        position: absolute ;
        font-weight:700;
        color:#c2c2c2;
        top:30px;
        right:-200px;
        padding-bottom:230px !important;
      } */
 
      .registro_nuevo a {
        color: #CB3D82 !important;
    }
    .registro_nuevo {
        font-size: calc(.2rem + 0.8vw) !important;
    }
    .titlerecu2{
        display:flex;
        
        font-size: 2.5vw !important;
        white-space: nowrap;
        color: #394F65;
        margin-top:13vh;
        font-weight:700;
      margin-left:34px;
       margin-bottom:0vh !important;
      
    }
    .login_logo_new1{
        display:none
    }
}

.form-controlanexo{
    padding-bottom:10px !important;
}
/* .form-control {
    line-height:1.2 !important
} */
.PhoneInputInput::placeholder{
    color:#9DACBD !important
}
.form-control::placeholder{
  
    color:#9DACBD !important
}

.PhoneInputCountrySelectArrow{
    color:#7B86FA;
}

.LoginForm input {
   
    border: hidden;
    background: transparent;
    border-bottom: 1px solid #BEBEBE;
    
}

.SubTitle {
    margin-top: -90px;
    font-size: 15px;
    color: #BEBEBE;
    padding-top: 10vh;
    font-weight:700;
    margin-left: 5vh;
}


.titleBienve{
    font-weight: 700;
    font-size: calc(2rem + 1vw) !important;
    color: #394F65;
    margin-top:2%;
    margin-bottom:-8vh;
    margin-left: 2.5vw;  
}

.titlerecu{
    font-family: WorkSans-Bold !important;
    font-size: 7vmin;
    color: #394F65;
    margin-top:130px;
    margin-bottom:-32px;
    margin-left: 5vh;  
}

.LoginForm input {
    color: #9DACBD !important;
    border: hidden;
    background: transparent;
    border-bottom: 1px solid #BEBEBE;
}

.SubTitle {
    font-size: 18px;
    font-family: WorkSans !important;
    color:#6c757d;
       padding-top: 15vh;
}

.LoginFormContainer.title {
    margin-left: 5vh;
    font-family: WorkSans-Bold !important;
    font-size: 11vmin;
    color: #2A9DA5;
}

.LoginFormContainer .info {
    color: #7C7C7C;
    font-size: calc(.5rem + 1vw);
    width: 50%;
}





#passmail_correct {
    width: 100%;
    text-align: right;
   
    font-size: 15px;
    -webkit-transition-timing-function: ease-in-out;
    -webkit-transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
    -moz-transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
    -o-transition: wiwidth 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
    transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
}


#passmail_error {
    width: 100%;
    text-align: right;
   
    font-size: 15px;
    -webkit-transition-timing-function: ease-in-out;
    -webkit-transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
    -moz-transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
    -o-transition: wiwidth 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
    transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
}





.TyC a {
    color: #CB3D82 !important;
}

