.Reservando.confirmado .recomendacion img {
    height: 60px;
    margin: auto auto;
}

.Reservando.confirmado .recomendacion {
    text-align: center;
    color: #9DACBD;
    font-size: calc(0.6rem + 1vw);
    margin: auto 1rem;
}
.créditos_actuales{
color:#9DACBD;
font-weight:bold;
text-align:center;
}
.costo_reserva{
    color:#9DACBD;
    font-weight:400;
    text-align:center;
}
@media (min-width: 921px) {

    .variasclases .swiper-button-next{
        color:white
    }
    .variasclases .swiper-button-prev{
        color:white
    }
}