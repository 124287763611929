

@media (min-width: 100px) and (max-width: 920px) {

.Botonentrar{
  margin-left:10% !important;
  margin-right:10% !important;
  height:5.5vh;
  border-radius:13px !important ;
}
  .LoginBGLeft::before, .loginBGRight::before {
    content: "";
    position: absolute;
    ;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.2;
  }
  .LoginBGLeft.cambio::before{
    opacity: 0.4;
    
  }
  .loginBGRight.cambio::before{
    opacity: 0.4;
    
  }
  .loginBGRight:hover::before {
    opacity: 0.0;
  }
  .LoginBGLeft:hover::before {
    opacity: 0.7;
  }

  
  .LoginBGLeft {
    background-image: url("../assets/img/fondo/login_bg2.jpg");
    height: 100vh;
    width: 50vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   
  }
  
  
  
  a {
    color: white !important;

  }
  
  .LoginTitle {
    padding-bottom: 5px;
    display: inherit;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
  
    font-size: calc(1.795rem + 1.795vw);
  }
  
  .LoginLogo::before {
    opacity: 1;
  }
  
  
  
  .LoginSelect {
    padding-top: 45px;
    flex-direction: row-reverse;
  }
  .trazado4{
    width:25vw;
    height:0.1 !important;
  }

  .loginBGRight {
    background-image: url("../assets/img/fondo/login_picture.jpg");
    height: 100vh;
    width: 100vw;
    /* position: absolute;
    top: 0;
    bottom: 0;
    right: 0; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   
  }
 
 

  .LoginBtn1 {
   
    width: 65vw !important;
    height:7vh !important;
    max-width:230px;
    max-height: 47px;
    min-width: 100px;
    background: #F8DCD1;
    font-weight: bold;

    border-radius: 20px !important;
  color:#8D665F;
    opacity: 1;
    text-align:center;
    cursor: pointer;
    font-size: 3.5vw;
    padding:2vh !important;
    margin-bottom:2rem !important;
  }

  .LoginContainer{
    width:100% 
  }
 .LoginBtn {

  display:block;
  width: 65vw !important;
  max-width: 230px;
  max-height: 47px;
  left:50% !important;
  top:50% !important;
  min-width: 100px;
  height:7vh !important;
  justify-content: center;
  background: #CB3D82;
  vertical-align: middle !important;
  font-weight: bold;
  
  border-radius: 20px !important;
  opacity: 1;

  padding:2vh !important;
  text-align: center;
margin-top:0rem !important;
margin-bottom:0.7rem !important;
  cursor: pointer;
  font-size: 3.5vw;
  }

  .fechaatras{
    margin-right:0.4rem;
    margin-left:0.4rem;
   width:1.4rem;
   height:1.4rem;
   justify-content: center;
   
    }
  .botonesdesktop{
    display:none
  }
  .appingreso1{
    display: none;
  }
  .trazado{
    position: relative;
    width:100%;
    max-width:100vw;
opacity:0.4;
    height:0vh;
text-align: center;
margin-bottom:0vh;
    margin-left:auto;
    margin-right:auto ;

  }
  .centradotexto{
    display:none;
   
   
  }
  .LoginLogo {
    display: inherit;
    position: relative;
    top: 0%;
    left: 50%;
    margin-bottom: 0.5rem;
    transform: translateX(-50%);
    width: 100%;
    max-width:40vw;
    
    /* padding: 20px;
    border-radius: 30px; */
  }
  .LoginLogo1{
    display:none
  }
  .trazado1{
    display:none;
  }
  .LoginTitulos {
    color: #fff;
    font-family: WorkSans-Regular;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
  }
 

  .rectangulo{
    background-origin: padding-box, content-box !important;
    width:94% !important;
    position:absolute;
    opacity:0.9;
    height:96%  !important;
   border:2px solid white !important;
  margin-left:3vw !important;
  margin-top:1.7vh !important;
 
 
  }
  .BackStart {
    display:flex;
    position:relative;
    padding-top:3rem;
  width:100%;
padding-left:1rem;

  }


  .LoginTitle1 {
    display:flex;
    position:absolute;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
   
    font-size: 4vw;
   
  }
  .LoginTitle {
    display: absolute;
    width: 100%;
    text-align: center;
    color: #FFFFFF;

    font-size: 4.5vw !important;
    
  }
    .botonesmobile{
      margin-top:0vh;
    display:row !important
  }

}

 @media (min-width: 921px)  {
  .Botonentrar{
   
    height:5.5vh;
  }
  .botonesdesktop{
    width:100%;
    

    margin-bottom:1vw;
    margin-top:-2vw
  }
  .LoginTitulos {
    color: #fff;
    font-family: WorkSans-Regular;
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
  }
   .rectangulogrande{
    background-origin: padding-box, content-box !important;
    width:94% !important;
    position:absolute;
    opacity:0.9;
    height:96%  !important;
   border:0.5vw solid white !important;
  margin-left:3vw !important;
  margin-top:1.7vh !important;
   }
   .rectangulo{
     display:none !important
   }


  .LoginBGLeft::before, .loginBGRight::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.4;
  }
  .LoginBGLeft.cambio::before{
    opacity: 0.2;
    
  }
  .loginBGRight.cambio::before{
    opacity: 0.2;
    
  }
  .loginBGRight:hover::before {
    opacity: 0.2;
  }
 
  .LoginBGLeft:hover::before {
    opacity: 0.4;
  }
  
  .LoginBGLeft {
    background-image: url("../assets/img/fondo/login_bg1.jpg");
    height: 100vh;
    width: 50vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   
  }
  .loginBGRight {
    background-image: url("../assets/img/fondo/login_bg2.jpg");
    height: 100vh;
    width: 50vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   
  }
  a {
    color: white !important;
  }
  

  .LoginTitle {
    padding-bottom: 5px;
    display: inherit;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    font-weight:700;
    font-size: calc(1.795rem + 1.795vw);
  }
  
  .LoginLogo::before {
    opacity: 1;
  }

  
  
  .LoginSelectgrande {
    padding-top: 45px;
    padding-left: -35vw !important;
    padding-right:-35vw !important;

    flex-direction: row-reverse;
  }

  .loginBGRight {
    width: 50vw !important;
    right: 0 !important;
  }
  a>.LoginBtn.lf {
    position: relative;
  }
  a>.LoginBtn.rg {
    position: relative;
  }

  .botonesmobile{
    display:none !important
  }
  .LoginBtn1des {

    display:block;
    width: 50%;
    max-width: 40vw;
    min-width: 5vw;
    background: #CB3D82;
    font-weight:700;
    border-radius: 25px;
    font-size:1.2vw !important;
    opacity: 1;
    text-align: center;
  
    cursor: pointer;
    font-size: 1.3vw;
  }
  
   .botonusuario {
    display:row;
    padding-right:7vw !important
  }
   .botonusuario1 {
    display:row;
    padding-left:15vw !important
  }
  

 
  
  .LoginBtndes {

    display:block;
    width: 40%;
    font-size:1.2vw !important;
    max-width: 40vw;
    min-width: 5vw;
    margin:0 10vw;

    background: #CB3D82;
     font-weight:700;
    border-radius: 25px;
    opacity: 1;
    text-align: center;
 
    
    cursor: pointer;
    font-size: 1.3vw;
  }

  .appingreso1{
position: relative;
    width:100%;
    display:flex;
    justify-content: center;
    margin-bottom:30px;
  }

  .trazado5{
    position: relative;
    width:60%;
    height:0.3vh;
    justify-content: center;
    z-index:2;
text-align: center;
padding:auto;
margin:0.7vw;

  }

  .LoginTitle6{
 position: relative;
    font-size:1.2vw;
    height:12px;
   white-space:pre;
  
  }
  .appingreso{
    display:none
  }

  .centradotexto{
    cursor: default;
    text-align:center;
    margin-left:17%;
    margin-right:17%;
    font-weight:700;
    font-size: 4vw;
    font-weight: bolder;
    z-index:0
  }

  .LoginLogo{
    display:none;
  }
  .LoginLogo1{
    display: inherit;
    position: relative;
    top: 0%;
    left: 50%;
    margin-bottom: 60px;
    transform: translateX(-50%);
   
    width: calc(8rem + 7vw);
   
    
  }

  .trazado{
  display:none
  }
  .trazado4{
  display:none
  }
  .trazado1{

    width:100%;
    height:1px;
text-align: center;
margin-left:none;
margin-right:none;
padding-left:none;
padding-right:none;;
  }
 
  .LoginTitle3 {
    display: absolute;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    font-weight:700;
    font-size: 1vw;
  }
  .LoginTitle1 {
    display:none
  }
  .BackStart2 {
    position: absolute;
    top: 7%;
    bottom: 10px;
    left: -39%;
    padding-left:20%;
   
    z-index:1
    
 
  }
  .BackStart{
    display:none
  }
  .loginBGRight {
    width: 50vw !important;
    right: 0 !important;
    z-index:auto
  }
 
  a>.LoginBtn.lf {
    position: relative;
  }
  a>.LoginBtn.rg {
    position: relative;
  }
} 

/*
@media (min-width: 481px) {
  .LoginContainer {
    background-image: url("../assets/img/fondo/login_bg1.jpg"), url("../assets/img/fondo/login_bg2.jpg") !important;
    background-position: left top, right top !important;
    background-repeat: no-repeat, no-repeat !important;
    background-size: 51% 100%, 50% 100% !important;
  }
}

.LoginContainer {
  background: linear-gradient( rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5)100%), url("../assets/img/fondo/login_bg2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}*/


