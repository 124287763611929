.UpdateProfile {
    min-height: 85vh;
}

.rc-slider-track{
    background-color:#CB3D82 !important;
}
.rc-slider-handle{
    border: solid 2px #CB3D82 !important;   
}
.rc-slider-dot-active{
    border-color: white !important;
}
.rc-slider-dot {
    border: 2px solid white!important;
}

.cuadrorangos .rc-slider-mark-text{
    font-size:10px;
    margin-left:-12% !important;

}
.cuadrorangoschange .rc-slider-mark-text{
    font-size:10px;
    margin-left:-24% !important;

}

.kilogramos{
    margin-left:25%;
    padding-top:1%
}

.cajafotos{
    margin-left:-4vw;
}

.UpdateProfile.perfil {
    min-height: 80vh;
    overflow: hidden;
    margin-bottom: 75px; /*Tawk*/
}

.cabeceraPerfil .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2A9DA5;
    text-align: center;
}
.roma .rv-xy-plot__inner {
 
    width: 90vw;
}
.roma_web .rv-xy-plot__inner {
 
    width: 38vw;

   
}

.rv-xy-plot__grid-lines__line{
    stroke:none !important
}
.jazmin .rv-xy-plot__axis__line {

    stroke-width: 2px;
    stroke:#707070  ;
}
.jazmin .rv-xy-plot__axis__tick__line {
    stroke: #707070;
}
.jazmin .rv-xy-plot__axis--horizontal  .rv-xy-plot__axis__tick__line {
    stroke: none !important;   
}


.borderlineiconosvisceras{
    display: flex;
 

    width: 90%;
    border-bottom: 1px solid #959595 !important;
    opacity:0.5;
}

.borderlineiconoscreditos{
    display: flex;
   

    
    margin-top: 0.7rem;
    margin-bottom:0.6rem;
    width: 100%;
    border-bottom: 1px solid #D6D6D6 !important;
    
}
.borderlineiconoscreditospasarela{
    display: flex;
   
    margin-top: 0.7rem;
    margin-bottom:0.6rem;
    width: 90%;
    border-bottom: 1px solid #D6D6D6 !important;
    
}

.cabeceraPerfil .Iconos .icono img {
    width: 6vmin;
    height: 6vmin;
}






.letrasvisceras2{
    text-align: center;
    color: #FF0000;
    font-weight:bold;
    font-size:3.5vw   
}





/* .letrasbrazoderecho1imgiza{
    position: absolute;
    top:94%;
    width:2.8vw;
    right:0%;
} */




.UpdateProfile input, select {
    border: hidden !important;
    background: transparent;
    border-bottom: 1.3px solid #EAEAEA !important;
}
.tipoperson .UpdateProfile input,select {
    border: hidden !important;
    background: transparent;
    border-bottom: 1.3px solid #EAEAEA !important;
 
}
.tipoperson select {
    border-bottom: 1.3px solid white !important;
}
.tipopersonP select {
 
    border: 1px solid #9DACBD !important;
}



.UpdateProfile .containerCuerpo .dolor {
    position: absolute;
}



.UpdateProfile .subtitle_text {
    color: rgba(124, 124, 124, 1);
    width: 70vw;
    font-size: 3.5vmin;
    margin-bottom: 5%;
    text-align: center;
}



.UpdateProfile .modalInfoContenido .icon img {
    height: 5rem;
}






.bitacora .SwipSlide .bitacoraSlider .bitacoraTitle .spanimg {
    background-color: #9EACED;
    padding: 0%;
    border-radius: 50%!important;
    width: 11vmin;
    height: 11vmin;
  
}
.bitacora .SwipSlide .bitacoraSlider .bitacoraTitle .spanimg img {
  display:inline-flex;
padding:6%;
  width: 11vmin;
    height: 11vmin;
 
   


 
    
    
    
}



.bitacora .SwipSlide .bitacoraSlider .bitacoraForm .bitacoraInput {
    display: grid;
   
 
    width: 40%;
    justify-items: center;
}

.bitacora .SwipSlide .bitacoraSlider .bitacoraForm .bitacoraInput label {
    font-weight: bold;
    color: #9DACBD;
    padding: 1.0rem 0;
    
    
}

.bitacora .SwipSlide .bitacoraSlider .bitacoraForm .bitacoraInput .bitInputNum {
    font-weight: bolder;
    color: #9DACBD;
    width: 60%;
    padding:0px;
    text-align: center;
    font-size: 1.5rem;
}

.UpdateProfile .perfil label {}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

.stepsBitacora {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
}
.changestepsBitacora {
    display: flex  !important;
    justify-content: space-evenly  !important;
    margin-top:0vh;
    width: 30% !important;
}

.stepsBitacora .steps {
    margin-top: 1rem;
    width: 4vmin;
    height: 4vmin;
    background-color: #9EACED;
    opacity: 0.5;
}
.stepsBitacora .steps1 {
    margin-top: 1rem;
    width: 4vmin;
    height: 4vmin;
    background-color: #9EACED;
    opacity: 0.5;
}

.stepsBitacora .steps.active {
    opacity: 1;
}



.estadisticas .SwipSlide .staticDesc {
    font-size: 0.8rem;
    text-align: center;
    color: #262626;
}

.estadisticas .SwipSlide .estadisticaSlider .title {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.estadisticas .SwipSlide .estadisticaSlider .title span {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2A9DA5;
    text-align: center;
}

.estadisticas .SwipSlide .estadisticaSlider .title span.Nivel {
    font-size: 2rem;
    margin-top: 2.5rem;
    color: #363636;
}

.estadisticaSlider .icon {
    width: 2.5rem;
}

.estadisticaSlider .containerImg {
    display: flex;
    justify-content: center;
    align-items: flex-start;

}







 .caloriasfrecuencia {
    margin:auto;
 margin-left:3vw;

 margin-right:9vw;
     font-weight:bold;
    color: #2A9DA5;
    font-size: 3vw;
    
}
.textotiempo{
    font-weight:bold;
    position: absolute;
    font-size:3vw;
    color: #2A9DA5;
    bottom:28%;
    margin-top:2vh;
    margin-bottom:0vh;
    left:47%;
}

.cajalegendsnames{
 position:relative;
 margin-right:0%;

    font-size:4vw;
    color:#919191;
    font-family:WorkSans-Medium;
}
.textoverti{
  font-size:3vw;
    position: absolute;
    font-weight:bold;
    color: #2A9DA5;
     transform:rotate(-90deg);
   bottom:50%;
  
  left:3vw;
 margin-left:0%;
    margin-right:70%;
 }
 .cajalegends{
     margin-left:30vw;;
     margin-right:30vw;
     
     display:flex;
     position:absolute;
 }
 .legends{
     display:flex;
     position:relative;
 }
 .strokebom{
     height:1vh;
     width:1vw;
     border-radius:15px;
     color:red;
 }

.divderecha{
    margin:auto;
    margin-left:1vw;
}
.divderechaDIAS{
    margin:auto;
    margin-left:2vw;  
}
.divderechaFREC{
    margin:auto;
    
}
.divderechaNIV{
    margin:auto;
    margin-left:5vw;  
}
.divderechaMOB{
    margin:auto;
    margin-left:14vw;  
}


.containerCalorias{
   align-self: flex-end;
   text-transform: uppercase;
}
.cal1{
    text-align:center;
 
    margin:auto;
  
   
}
.cal2{
    margin:auto;
    width:5% !important;
    text-align:center;
  
 
    ;
 
}

.contentEstadistica .containerCalorias {
    text-align: center;
    line-height: 1;
}

.contentEstadistica .containerCalorias .cal {
    color: #6E6E6E;
    font-weight: bold;
    text-align:center;
    justify-content: center;
    
    font-size: 2.5vw;
    margin-bottom: 0.3rem;
}

.contentEstadistica .containerCalorias .inf {
    color: #919191;
    font-weight: bold;
    font-size: 0.8rem;
    text-transform: uppercase;
}

.contentEstadistica .containerTiempo {
    text-align: center;
    line-height: 1;
}

.contentEstadistica .containerTiempo .time {
    color: #6E6E6E;
    font-weight: bold;
    font-size: 3.3rem;
    margin-bottom: 0.4rem;
}

.contentEstadistica .containerTiempo .time div {
    color: #919191;
    font-weight: bold;
    font-size: 0.6rem;
    margin-top: -0.2rem;
    display: flex;
    justify-content: space-evenly;
    width: 60%;
}

.contentEstadistica .containerTiempo .inf {
    color: #919191;
    font-weight: bold;
    font-size: 0.8rem;
    text-transform: uppercase;
}

.contentEstadistica .containerTotal {
    text-align: center;
    line-height: 1;
}

.contentEstadistica .containerTotal .time {
    color: #6E6E6E;
    font-weight: bold;
    font-size: 3.3rem;
    margin-bottom: 0.4rem;
}

.contentEstadistica .containerTotal .time div {
    color: #919191;
    font-weight: bold;
    font-size: 0.6rem;
    margin-top: -0.2rem;
    display: flex;
    justify-content: space-evenly;
    width: 60%;
}



.containerGraficas .semana {
    display: inline-flex;
    justify-content: space-evenly;
    width: 80%;
}

@media (min-width: 481px) {
    /* .containerGraficas .semana .dia .icon {
        width: 3vmin !important;
        height: 3vmin !important;
    }
    .containerGraficas .semana .dia .icon.yes svg {
        width: 3vmin !important;
        height: 3vmin !important;
    } */
}
@media (min-width: 100px) and (max-width:  600px) {
    .progressvideogame .progress-bar{
        background-color:#9EACED;
        height:2.5vh;
        border-radius:1rem;
    }
    .progressvideogame1 .progress-bar{
        background-color:#9EACED;
        height:1.25rem;
        width:100%;
        border-radius:7px;
    }
  
    .cuadrorangos{
     
                margin-top:-6%;
        margin-bottom:4vh;
        text-align:left;
    }
    .cuadrorangoschange{
     
                margin-top:-6%;
        margin-bottom:4vh;
        margin-left:13vw;
        margin-right:13vw;
        text-align:left;
    }
 

}
@media (min-width: 601px)and (max-width:  920px){
    
    .cuadrorangos{
        margin-top:0%;
        padding:0 9vw;
        text-align:left;
    }
    .cuadrorangoschange{
        margin-top:0%;

        text-align:left;
    }
    .modalInfoContenidograficagiro{
        width: 70vw !important;
        height:80%;
        padding:10px;
        }
}
@media (min-width: 100px) and (max-width:  920px) {
    .progress-bar {
        font-family:WorkSans-Bold
       }
    .letrasbrazoderecho1{
        display:row;
        position: absolute;
        top:90%;
        margin:7%;
        line-height:60%;
       
        left:0%;
      white-space: nowrap;
       
    }
    .bitacoraForm.Medida .containerCuerpo {
        width: 236px !important;
        height: 285px !important;
    }
    .bitacoraForm.Medida {
        display: flex;
        justify-content: center;
    }
 
    .UpdateProfile .containerCuerpo {
        width: 300px;
        height: 390px;
        position: relative;
        top: 0%;
        left: 0%;
    }
    .borderlineiconospruebas{
        display: flex;
       
    
        
        margin-top: 0.7rem;
        margin-bottom:0.6rem;
        width: 100%;
        border-bottom: 1px solid #959595 !important;
        opacity:0.5;
    }
    .bitacora .SwipSlide {
      
        /* border-top: 1px solid gray; */
        padding-top: 1rem;
    }
  
    .labelPhotocamera1 {
        box-sizing: border-box;
        height: 30vw;
        width: 30vw;
        border: 1px solid  rgba(158,172,237,0.6);
    justify-content: center;
    margin-top:0.8vh;
  
        background-position: center 60%;
        background-repeat: no-repeat;
        background-size: 100%  !important;
     
        position: absolute;
    }
    .contetfototoalusuario{
        width: 37vw;
        justify-content: center;
        display:flex;
 
        height: 37vw;
        border-radius: 100%;
        border: 7px solid #9EACED;
        }
    .containerGraficas .semana .dia .icon.yes svg {
        width: 10vmin;
        height: 10vmin;
    }
    .cuerpol{
        min-height: 206vh !important;
    }
    .pasarela .accordion-button {
        color:#2A9DA5;
        font-weight:bold;
        font-size:3.5vw;
        font-style: italic;
        padding: 1rem 5.5rem 1rem 5.5rem !important; 
    }
    .accordion-button {
        padding: 1rem 1.25rem 1rem 1.7rem !important; 
    }
    .containerGraficas .semana .dia .icon {
        margin-top: 1vh;
        border-radius: 50%;
        width: 10vmin;
        height: 10vmin;
        display: inline-flex;
        justify-content: center;
    }
    .contentEstadistica .containerTotal .cal {
        color: #9DACBD;
        font-weight: bold;
        font-size: 3vw;
        
    }
    .contentEstadistica .containerTotal .calestasemana {
        margin:auto;
        width:33% !important;
        text-align:center;
    }
    .contentEstadistica .containerTotal .calestasemana1 {
          margin:auto;
      width:0% !important;
      text-align:center;
    }
    
    .contentEstadistica .containerTotal div {
        color: #919191;
        font-weight: bold;
        font-size: 0.8rem;
        text-transform: uppercase;
    }
    
    .contentEstadistica .containerGraficas {
        text-align: center;
        line-height: 1;
    }
    .selectnuevofelcha{
       
        background-image:url("../assets/img/UserIcons/iconos/Icon material-arrow-drop-down.png") !important
    }
    .letrasviscerasadentro{
        
        margin:auto 10px;
        text-align: center;
        color: #9DACBD;
        font-weight:bold;
        font-size:15px; 
    }
    .flechakilogramos{
        display: flex;
        width:15px;
        height:15px;
        margin-left:1vw
    }
    .imagenexpanded{
        display:flex;
        padding-left:3vw;
        width:100%;;
        max-width:40px;
        margin-left:auto
    }
    .imagenizquierdacolumns{
        display:flex;
        width:100%;
        max-width:9vw;
        margin-left:0vw;
        padding-right:3vw;
        padding-top:-2vh
    }
    .brazo_web{
        position:absolute;
        display:inline-flex;
        width:41vw;
        top:-4vh;
        height:10vh;
        left:0.0vw;
      

    }
    .brazoderecho_web{
        position:absolute;
        display:inline-flex;
        width:41vw;
        top:-4vh;
        height:10vh;
    
        transform:scaleX(-1);
        right:-8.5vw;
    }
    .piernaizquierda_web{
        position:absolute;
        display:inline-flex;
        width:42.8vw;
        top:-4vh;
        height:4vh;
        right:-7.3vw; 
        transform:scaleX(-1);
        
    }
    .brazoderecho{
        display:row;
        position:absolute;
        top:16%;
        left:5vw
    }
    .brazoizquierdo{
        display:row;
        position:absolute;
        top:16%;
        
        right:14vw
    }
    .piernaizquierda{
        display:row;
        position:absolute;
        top:70%;
        left:5vw
    }
    .piernaderecha{
        display:row;
        position:absolute;
        top:70%;
        right:13vw
    }
    .abdomen_web{
        position:absolute;
        display:inline-flex;
        width:46vw;
        top:-4vh;
        height:4vh;
        left:0.0vw;
    }
    .piernadercha_web{

        position:absolute;
        display:inline-flex;
        width:43.5vw;
        top:-4vh;
        height:4vh;
        left:0.0vw; 
        
    }
    .abdomen{
        display:row;
        position:absolute;
       ;
        top:43%;
        left:5vw 
    }
    .letrasbrazoderecho1img{
        position: absolute;
        top:94%;
        width:2.8vw;
        left:5%;
    }
    .siluetaMedidas {
        width: 28%;
        height:100%;
        margin-top:4vh;
        margin-bottom:4vh;
    }
    
.UpdateProfile .titulo4 {

    color: white;
    font-weight:bold;
  margin-left:-1rem;
    font-size: 2.5vw;
    
}
    .UpdateProfile .titulo1 {
        text-align: center;
        color: black;
        font-weight: bold;
        font-size: 1.3rem;
        margin-top: 0vh;
        margin-bottom: 1vh;
    }
    
    .UpdateProfile .titulo2 {
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 1.3rem;
        margin-top: 0vh;
    
        margin-bottom: 1vh;
    }
    .ayudadlo_titulo3{
        white-space: nowrap;
        margin-left:25% !important;
    }
 
    .UpdateProfile .titulo3 {
        text-align: center;
        color: white;
        font-weight:bold;
        font-size: 3vw;
        margin-top: 0vh;
    
        margin-bottom: 1vh;
    }
    .UpdateProfile .titulopeso {
        text-align: center;
        color: #394F65;
        font-weight: bold;
        font-size: 1.2rem;
        margin-top: 0vh;
        margin-bottom: 1vh;
    }
    .bitacora .SwipSlide .bitacoraSlider .bitacoraTitle span {
        font-size: 4.0vw;
        font-weight: bold;
        display: flex;
        text-align:center;
        color: #394F65;
     margin:2vw;
    }
    
.bitacora .SwipSlide .staticDesc {
    font-size: 0.8rem;
    width: 70%;
    margin:auto;
    
    text-align: center;
    color: #394F65;
}
    .textareatamaño{
        width:60vw !important;
        height:22vh
    }
    .estadisticas .SwipSlide {
        width: 90%;
     
        padding-top: 1rem;
        /* height: 50vh; */
    }
    .letrasvisceras{
        text-align: center;
        color: #CB3D82;
        font-weight:bold;
        font-size:15px ;
        margin-top:0.5vh;
      
    }
    .iconCalorias{
        margin:auto;
   
        width:100%;
        height:100%;
        max-width: 1.3rem;
        max-height: 1.3rem;
        display: flex;
        justify-content: center;  
    }
    .withespaciowhite{
        width:10% !important;
    }
    .iconCalorias1foto{
        margin-left:3vw;
        margin-right:0vw;
      
        width:100%;
        height:100%;
        max-width: 10vw;
        height: 3vh;
        display: flex;
        justify-content: center;  
    }
    .flechaverde1{
        margin:1.5vw;
     padding: 1px;;
    width:3vw;
    }
    .flechaverde2{
        margin:auto;
        padding-bottom:0.3vh;
       
      padding-left: 2vw; 
     height:5vw;
    width:5vw;
    }
    .letrasvisceras1{
        margin-top:0.5vh;
        text-align: center;
        color: #9DACBD;
        font-weight:bold;
        font-size:3.5vw  ;
        margin-right:2vw; 
    }
    .letrasvisceras1auch{
        margin-top:0.5vh;
        text-align: center;
        color: #9DACBD;
        font-weight:bold;
        font-size:15px  ;
        margin-right:2vw; 
    }
    .letrasvisceras1RED{
        margin-top:0.5vh;
        text-align: center;
        color: #CB3D82;
        font-weight:bold;
        font-size:3.5vw  ;
        margin-right:2vw; 
    }
    .fivecolumnas{
        margin-top:1vh;
    width:100%;
    }
    .calorias1 {
        margin:auto;
     margin-left:2vw;
     text-transform: none !important;
     width:35% !important;
    text-align:left;
         font-weight:bold;
        color: #394F65 !important;
        font-size: 3vw !important;
        
    }
    .infoestasemana{
        font-weight:bold;
        font-size:3vw;
        padding:2vw;
    color:#000000;
    
    }
    .infoobjetivo{
        padding:2vw;
        font-weight:bold;
        font-size:3vw;
        color:#000000;
    
    }
    .contentEstadistica .estrellas .estrella {
        width: 7vw;
    }
    .contentEstadistica .estrellas {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-left:30vw;
        margin-right:30vw;
    
    }
 
    .borderlineiconos{
        display: flex;
        justify-content: space-evenly;
    
        margin-top: 0.7rem;
        margin-bottom:0.6rem;
        width: 90%;
        border-bottom: 1px solid #959595 !important;
        opacity:0.5;
    }
    .UpdateProfile .titulo {
        text-align: center;
        color: #394F65;
        font-weight: bold;
        font-size: 1.3rem;
        margin-top: 0vh;
        margin-bottom: 1vh;
    }

    .UpdateProfile .btnActualizar {
        border: none;
        background-color: #CB3D82;
        color: white;
        font-weight: bold;
        font-size: 1rem;
        border-radius: 20px;
        width: 100%;
        padding: 0.6rem 0;
        margin: 1rem 0;
    }
    .perfil label {
        color: #394F65;
        padding-top: 2.5vh;
    }
    .UpdateProfile .perfil label {
        color: #394F65;
        padding-top: 2vh;
    }
    .cabeceraPerfil .Iconos {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 0.5rem;
      
        margin-bottom: 0.6rem;
        margin-top: 0.6rem;
        cursor: pointer;
        width: 80%;
       
    }
    .cabeceraPerfil .Iconos .icono {
        background-color: #E1E5F9;
        padding: 3%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10vmin;
        height: 10vmin;
    }
    .cabeceraPerfil .Iconos .icono.active {
        background-color: #9EACED;
    }
    .colornuevo1{
        color:#394F65 !important
    }
    .colornuevo{
        color:#9DACBD  !important;
       
    }
    .contentEstadistica .nivel {
        display: flex;
        margin: 1vh auto;
        justify-content: center;
        font-size: 5vw;
        font-weight: bold;
        color: #394F65;
    }
    .totalclases{
        color:black;
        text-align:center;
        font-size:6vw;
       margin-left:30vw;
    }
    .bitacora .SwipSlide .bitacoraSlider .bitacoraTitle {
        margin-left:13vw;
        margin-right:13vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .relativegramera{
    display:none
     
    }
    .mitadesdefoto{
        width:60%;
      color:#394F65;
      font-weight:bold;
        font-size:3.0vw;
       text-align:left;
    }
    .mitadesdefoto1{
        width:48%;
        font-size:3.5vw;
     
    }
}


@media (min-width: 921px) {
    .progress-bar {
        font-family:WorkSans-Bold
       }
    .letrasbrazoderecho1{
        display:row;
        position: absolute;
        top:90%;
        margin:7%;
        line-height:60%;
       
        left:0%;
      white-space: nowrap;
       
    }
  
    .siluetaMedidas {
        width: 22%;
        height: 90%;
        margin-top: 0.5vh;
        margin-bottom: 0.5vh;
    }
    .UpdateProfile .containerCuerpo {
        width: 300px;
        height: 390px;
        position: relative;
        top: 0%;
     left:0%;
    }
    .borderlineiconospruebas{
        display: flex;
       
    
        
        margin-top: 0.7rem;
        margin-bottom:0.6rem;
        width: 80%;
        border-bottom: 1px solid #959595 !important;
        opacity:0.5;
    }
    .bitacora .SwipSlide {
        width: 100%;
        /* border-top: 1px solid gray; */
        padding-top: 1rem;
    }
    .progressvideogame{
        max-width:40vw;
        margin: 1vh auto
    }
    .progressvideogame .progress-bar{
        background-color:#9EACED;
        height:2.6vh;
        border-radius:1rem;
    }
    .progressvideogame1 .progress-bar{
        background-color:#9EACED;
        height:1.25rem;
        width:100%;
        border-radius:7px;
    }
       .contentEstadistica_web .nivel {
        display: flex;
        margin: 1vh auto;
        justify-content: left;
        font-size:  4vmin;
        font-weight: bold;
        color: #9EACED;
    }
    .cuadrorangos{
        margin-top:0%;
        margin-bottom:2vh;
        text-align:left;
        position:relative;
        top:60%;
    }

.cabeceraPerfil{
    margin-top:-3vh;
}

    .btnActualizarnue_boton .btnActualizar {
        border: none;
        background-color: #2A9DA5;
        color: white;
        font-weight: bold;
        font-size: 1rem;
        border-radius: 20px;
        width: 100%;
        padding: 0.6rem 0;
        margin: 1rem 0
    }
    .btnActualizarnue_boton{
        position: absolute;
        left:50%;
       margin-top:-4rem;
        width:50vw;
        
    }
    .containerGraficas .semana .dia .icon.yes svg {
        width: 6vmin;
        height: 6vmin;
    }
    .containerGraficas .semana .dia .icon {
        margin-top: 1vh;
        border-radius: 50%;
        width: 6vmin;
        height: 6vmin;
        display: inline-flex;
        justify-content: center;
    }
    .contentEstadistica .containerTotal .cal {
        color: #6E6E6E;
        font-weight: bold;
        font-size: 3.3rem;
        margin-bottom: 0.4rem;
    }
    
    .contentEstadistica .containerTotal div {
        color: #919191;
        font-weight: bold;
        font-size: 0.8rem;
        text-transform: uppercase;
    }
    
    .contentEstadistica .containerGraficas {
        text-align: center;
        line-height: 1;
    }
 
    #nacimiento1 input[type="date"]::-webkit-calendar-picker-indicator {
        background: none !important;
      }

#nacimiento1 input[type=date]{
;
    position: relative ;
    background: URL("../assets/img/UserIcons/iconos/calendar.png")  no-repeat   16.5vw 6px    ;
    background-size:1vw;

    ;
 ;
     
      border-bottom:-2px solid #BEBEBE!important   
}

  
    .selectnuevofelcha{
        background-image:url("../assets/img/UserIcons/iconos/Icon material-arrow-drop-down.png") !important;
        background-size:1vw !important;
    }
    .labelPhotocamera1 {
        box-sizing: border-box;
        height: 25vmin;
        margin-top:5% !important;
        margin-left:5% ;
        margin-bottom:0vh !important;
        width: 25vmin;
        border: 1px solid gray;
      
        background-position: center 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        position: relative;
    }
    .contetfototoalusuario{
        width: 30vmin;
        height: 30vmin;
   
        border-radius: 100%;
        border: 10px solid #9EACED;
        }
    .marginparapruebas{
        margin-left:10% !important;
    }
    .widthtrainer{
        width:13vw;
     
    }
    .flechakilogramos{
        display: flex;
        width:1rem;
        margin:7px;
       
    }
    .imagenexpanded{
        display:flex;
       
        width:100%;;
        max-width:2rem;
        max-height:2rem;
        margin-left:auto
    }
    .positionitemscrol{
        position:fixed;
        width:100vw;
    }
    .letrasvisceras1RED{
        margin: auto 0px;
        text-align: center;
        color: #CB3D82;
        font-weight:700;
        font-size: 1rem;
    }
    .mitadesdefoto{
        width:50%;
    }
    .letrasviscerasplum{
        
        margin:auto 25px;
        text-align: center;
        color: #394F65;
        font-weight:bold;
        font-size:1rem; 
    }
    .imagenizquierdacolumns{
        display:flex;
     
      
        padding: 10px;
       
    }
    .mitadesdefoto{
        width:50%;
        font-size:1rem;
       text-align:center;
       justify-content:center;
    }
    .mitadesdefoto1{
        width:30%;
        font-size:1rem;
     
    }
    .brazo_web{
        position:inherit;
        display:inline-flex;
        width:14vw;
        top:-200%;
        height:13vh;
        left:0.1vw;
       
    }
    .brazoderecho_web{
        position:inherit;
        display:inline-flex;
        width:14vw;
        top:-200%;
        height:13vh;
        transform:scaleX(-1);
        right:-2.7vw;
    }
    .brazoderecho{
        display:row;
        position:absolute;
        top:16%;
        left:9%
    }
    .brazoizquierdo{
        display:row;
        position:absolute;
        top:16%;
        right:16.3%
    }
    .piernaizquierda{
        display:row;
        position:absolute;
        top:79%;
        left:3.5vw
    }
    .piernaderecha{
        /* position: absolute;
    top: 80%;
    right: 4vw; */
        display:row;
        position:absolute;
        top:79%;
        
        right:4.0vw;
    }
    .abdomen{
        display:row;
        position:absolute;
       ;
        top:48%;
        left:9%
    }
    .piernadercha_web{
        position:absolute;
        display:inline-flex;
        width:15.0vw;
       
        top:-5vh;
        height:5vh;
        left:0%;  
    }
    .ayudadlo_titulo3{
        white-space: nowrap;
        margin-left:55%;
    }
    .ayudadlo_titulo6{
        white-space: nowrap;
        margin-left:5%;
    }
    .ayudadlo_titulo4{
        white-space: nowrap;
        margin-left:-45%;
    }
    .piernaizquierda_web{
        position:inherit;
        display:inline-flex;
        width:14.5vw;
        transform:scaleX(-1);
        top:-5vh;
   
        height:5vh;
        right:0vw;  
    }
    /* .piernaizquierda_web{
        position:absolute;
        display:inline-flex;
        width:14vw;
        top:0vh;
        height:5vh;
        right:-8.5vw; 
        transform:scaleX(-1);
        
    } */
    .piernaizquierda_web_lol{
        position:absolute;
        display:inline-flex;
        width:14vw;
        top:4vh;
        height:4vh;
        right:-16.5vw; 
     
        
    }
    .abdomen_web{
        position:inherit;
        display:inline-flex;
        width:16vw;
        top:-5vh;
        height:5vh;
        left:0vw;
    }
    .acordeonbum{
        display: grid;
height:47vh;
overflow-y: scroll;
padding-right: 10%;
      width:100%;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0 3rem;
        margin: 0 0% 0 0%;
        grid-auto-rows: 100px;
        position:relative;

    }
    .quitarcuadro{
        border-bottom :1px solid #959595 !important;
      
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }
    .letrasbrazoderecho1img{
        position: absolute;
        top:94%;
        width:0.7rem;
        left:0%
    }
  
    .flexiohnar{
        display:flex;
    }
  

    
.UpdateProfile .titulo4 {

    color: white;
    font-weight:bold;
  
    font-size: 0.8rem;
    
}
    .UpdateProfile .titulo1 {
        text-align: center;
        color: black;
        font-weight: bold;
        font-size: 1.3rem;
        margin-top: 0vh;
        margin-bottom: 1vh;
    }
    
 
    .UpdateProfile .titulo3 {
        text-align: center;
        color: white;
        font-weight:bold;
        font-size: 0.8rem;
        margin-top: 0vh;
    
        margin-bottom: 1vh;
    }
    .UpdateProfile .titulopeso {
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 0.8vw;
        margin:auto 0;
    }
    .UpdateProfile .titulopeso1 {
        text-align: center;
        color: white;
        font-weight: bold;
        font-size:  0.8vw;
        margin-top: 0vh;
        margin-bottom: 1vh;
    }
    .ubicarpesoencuerpo{
        position:absolute;;
        left:0vw;
        width:15vw;
        top:20%;

    }
    .ubicarpesoencuerpo1{
        width:15vw;
        position:absolute;
        left:0vw;
        top:49%;
    }

    .bitacora .SwipSlide .staticDescpru1 {
        font-size: 1.3vw;
        font-weight: bold;
        width: 100%;
        text-align: left;
        color: #394F65;
    }
    .bitacora .SwipSlide .bitacoraSlider .bitacoraTitle {
       
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
.bitacora .SwipSlide .staticDesc {
    font-size: 0.9rem;
    width: 70%;
    margin: 2.0vh 13vw;
    
    text-align: left;
    color: #9DACBD;
}
.bitacora .SwipSlide .staticDescpru {
    font-size: 0.8vw;
    width: 100%;
  
    
    text-align: left;
    color: #9DACBD;
}
    .textareatamaño{
        margin: 1vh 13.5vw;
    height:14vh;
    border:1px solid #9DACBD !important; 
    opacity:0.5 !important;
    border-radius:20px;
        width:50vw !important;
    }
    .contenedortareas{
        width:80%;
        margin: 3vh 10% 0vh 10%;
        
    }
    .withcontainer{
        width:80%;
        margin: 0 5%;
    }
    .perfilgraf{
        width:50%;
    }
    .totalclases{
        color:black;
        margin:auto 0px;
        text-align:center;
        font-weight:bold;
        font-size:2rem;
        text-align: center;
       
    }
    .estadisticas .SwipSlide {
        width: 100%;
     
     
        /* height: 50vh; */
    }
    .haciaizquierpruebas{
        cursor: pointer;
        margin: 20px ;
    }
 
    .scrollturesumen{
        overflow-y: scroll;
        height: calc(87.9vh - 290px + 2.5rem);
     
        overflow-x:hidden !important;
    }
    .scrollturesumenc{
        overflow-y: scroll;
        height: calc(87.2vh - 16.5rem + 0.7vmin );
     
        overflow-x:hidden !important;
    }
    .letrasviscerasinfo{
        text-align: center;
        color: #6E6E6E;
        font-weight:bold;
        font-size:0.8vw ;
        margin:0.5rem 0px;
        
        padding: 0.6rem 0;
      
    }
    .letrasviscerasinfoquemadas{
        text-align: center;
        color: #6E6E6E;
        font-weight:bold;
        font-size:0.8vw ;
        margin:0.5rem 0px;
        
        padding: 1rem 0;
      
    }
    .letrasviscerasinfo11{
        text-align: center;
        color: #CB3D82;
        font-weight:bold;
        font-size:0.9vw ;
        margin:0.5rem 0px;
      
    }
    .letrasvisceras{
        text-align: center;
        color: #2A9DA5;
        font-weight:bold;
        font-size:1rem ;
        margin:auto 0px;
      
    }
    .firecambiartamaño{
        margin:auto 0;
     
   
   width:4.4% !important;  
   height:4.4% !important;
    }
    .iconCalorias{
      
      
        margin:auto 0;
      
     
   
   width:5% !important;  
   height:5% !important;  
    }
    .iconCaloriasHEART{
      
        margin:auto 0;
     
   
   width:5% !important;  
   height:5% !important;  
    }
 
    .flechaverde1{
        margin:auto;
        padding: 0.3vw ;

   
    max-width:0.2vw !important;
    }
    .flechaverde1caloriasq{
        margin:1.4vh auto;
        padding: 0.5vw ;

   
    max-width:0.2vw !important;
    }
    .flechaverde1caloriasq1{
        margin:2vh auto;
        padding: 0.3vw ;

   
    max-width:0.2vw !important;
    }
    .flechaverde2caloriasq{
        margin: 0.7rem auto;
        padding: 0.4rem 0;
     
   
    width:0.8vw !important;
    }
    .flechaverde2caloriasq img{
        width:1vw !important;
      }
 
    .flechaverde2{
        margin: 0.5rem auto;
        padding: 0.2rem 0;
     
   
    width:0.8vw !important;
    }
    .flechaverde1caloriasq img{
      width:0.7vw !important;
    }
    .flechaverde1caloriasq1 img{
      width:0.7vw !important;
    }
    .flechaverde1 img{
      width:0.7vw !important;
    }
    .flechaverde2 img{
      width:1vw !important;
    }
    .letrasvisceras1cuerpo{
        margin:auto 0px;
        text-align: center;
        color: #9DACBD;
        font-weight:bold;
        font-size:1rem; 
    }
    .letrasvisceras1{
        
        margin:0.5rem 0px;
        text-align: center;
        color: #9DACBD;
        font-weight:bold;
        font-size:1rem; 
    }
    .letrasviscerasadentro{
        
        margin:auto 15px;
        text-align: center;
        color: #9DACBD;
        font-weight:bold;
        font-size:1rem; 
    }
    .fivecolumnas{
        margin:0vh 0% 0 18%;;
    width:100%;
    
    }
    .fivecolumnascuerpo{
        margin:0vh 0% 0 10%;;
    width:100%;
    
    }
    .calorias1 {
        width:30% !important;
        margin:auto 3%;
        padding:auto 1rem;
        text-align: left;
         font-weight:bold;
        color: #394F65;
        font-size: 0.8vw;
        
    }
    .calorias1calorias {
        width:30% !important;
        margin:auto 3%;
        padding:auto 1rem;
        text-align: left;
         font-weight:bold;
        color: #394F65;
        font-size: 0.8vw;
        
    }
  
    .infoobjetivo{
        padding:1rem;
        font-weight:bold;
        font-size:1rem;
        color:#000000;
        margin: auto 3vw;
    
    }
    .infoestasemana{
        font-weight:bold;
        font-size:1rem;
        padding:1rem;
        margin: auto 3vw;
    color:#000000;
    
    }
    .contentEstadistica  {
 width:100%
       
    }
    .contentEstadistica .estrellas .estrella {
        width: 3.0vw;
    }
    .msg1 .estrellas .estrella {
        width: 2.0vw;
    }
    .contentEstadistica .estrellas {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin:0% 4% 0 15%
      
    
    }
    .flexstarts{display:flex}
    .contentEstadistica .nivel {
        display: initial;
        padding:0.4rem 0 0 0 ;
        margin: 2vh auto ;
        text-align:center;
     
        font-size: 1.5vw;
        font-weight: bold;
        color: #394F65;
    }
 
    .borderlineiconos{

      
        width: 100%;
        border-bottom: 1px solid #959595 !important;
        opacity:0.5;
    }
    .UpdateProfile .titulo {
        text-align: left;
        color: #394F65;
        font-weight: bold;
        font-size: 1.5vw;
     margin-left:43%;
    }
    .UpdateProfile .titulodiasreser {
        text-align: left;
        color: #394F65;
        font-weight: bold;
        font-size: 1.5vw;
     margin:0vh 10vw
    }
    .UpdateProfile .titulobita {
        text-align: left;
        color: #394F65;
        font-weight: bold;
        font-size: 2rem;
     margin:0vh 12.5vw
    }
    .colortextvista{
        color:white;
    }
    .siluetaperfil{
        width:60%;
    }
    .siluetaperfil1{
        width:61%;
    
        margin-top:2.0rem;
    }
    .siluetaperfil2{
        width:61%;
        margin-top:2.0rem;
    }
    .UpdateProfile .btnActualizar {
        border: none;
        background-color: #CB3D82;
        color: white;
        font-weight: bold;
        font-size: 1rem;
        border-radius: 20px;
        width: 30%;
        padding: 0.6rem 0;
        margin: 1rem 0
    }
    .colornuevo{
        font-size: 0.8vw !important;
        color:#BEBEBE  !important;
       
    }
    #equipos::placeholder{
        font-size:0.8vw !important;
    }
    .perfil label {
        color: #394F65 ;
        /* padding-top: 1.5vh; */
        font-size:1vw;
    }
    .letra_ce{font-size:1vw}
    .direccionwhata{
        margin-top:-10px !important;
        width:100%;
    }
    .telefonoroto{
        width:100%;
       
    }
    .UpdateProfile .perfil label {
        color: #7B7B7B;
      
    }
    .containerweb{
        width:100% !important;
    }
    .containerweb1{
        width:100% !important;
    }
    .containerweb2{
        width:40% !important;
    }
    .containerweb3{
        width:100% !important;
       
    }
    .containerweb4{
        width:66.5% !important;
    }
    .containerweb5{
        width:47% !important;
        margin-left: 0.5rem;
    }
    .cabeceraPerfil .Iconos {
        display: flex;
        justify-content: space-evenly;
     
        cursor: pointer;
        margin: 0.5rem 3vw 0.5vw 12vw !important;
     
       
       
    }
    .cabeceraPerfil .Iconos .icono {
        background-color: transparent;
      
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 10vmin;
    }
    .cabeceraPerfil .Iconos .icono.active {
        color: #9EACED !important;
        font-weight:bold;
        width: 100%;
        /* border-bottom:7px solid #2A9DA5; */
    }
    .bordealo.active{
     
         border-bottom:7px solid #9EACED;
    }
    .letrascolorchange{
     
        color: #9DACBD;
        text-align:center !important;
        font-size:1.3vw;
        font-weight:400;
    }
    .perfilcorregirparam{
left:0% !important
    }
    .mxfecha{
        margin-right:0.9rem !important;
   
    }
    .mxfecha1{
        margin-right:2rem !important;
   
    }
    .colornuevo1{
 font-size :0.8vw;
        display:row;
        color:#394F65 !important
    }
    .scrollperfil{
        overflow-y: auto;
        overflow-x: hidden;
     
        height:52vh;
        
    }
    .estadisticas .SwipSlide {
        height: auto !important;
    }
    .estadisticas .SwipSlide .estadisticaSlider {
        margin-bottom: 7rem;
    }
    .estadisticas .SwipSlide .estadisticaSlider, .bitacoraSlider {
        margin-bottom: 7rem;
    }
}

.containerGraficas .semana .dia .name {
    color: #9DACBD;
    font-weight: 400;
    font-size: 0.8rem;
}


.containerGraficas .semana .dia .icon .no .active  {
background-image:url("./iconosinfo/chulito.png") ;
background-repeat: no-repeat;
background-size: cover;  
}
.containerGraficas .semana .dia .icon.no {
    border: 1px solid #E3E3E3;
}



.containerGraficas_web .msg {
    margin-top: 2vh;
    color: #919191;
    font-size: 0.8rem;
    line-height: 1.4;
    text-align:center;
    margin:0 29.5vw 0 16vw;
}
.containerGraficas .msg {
    margin-top: 2vh;
    color: #9DACBD;
    font-size: 0.8rem;
    line-height: 1.4;
}
.containerGraficas .msginfo_genre {
    margin-top: 2vh;
    color: #919191;
    font-size: 0.8rem;
    line-height: 1.4;
}
.modalInfoContenido .msg {
    margin-top: 2vh;
    color: #9DACBD;
    font-size: 1rem;
    line-height: 1.4;
}

.containerGraficas .msg span {
    width: 70%;
    display: inline-flex;
    justify-content: center;
}
  
  
.bitacoraForm.Medida {
    display: flex;
    justify-content: center;
}



/* mob.fitness.wellness@gmail.com
Appmob12345 */
/* https://developers.google.com/tag-manager/enhanced-ecommerce ecommerce */
/* https://www.digitalmarketer.com/blog/customer-value-optimization/                  preciosenganche */