.rw-widget-input {
    border-radius:20px !important;
    min-width:45vmin !important
}

.rw-multiselect-tag:first-child {
    border-top-left-radius:15px !important; 
    border-bottom-left-radius:15px !important; 
}
.searchprueba-now .container {
    position: absolute;
    margin: auto 0% auto auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    z-index: 999999999999999;
    height: 100px;  
}
.searchprueba-now .container .search {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 50px;
    background: #7B86FA;
    border-radius: 50%;
    transition: all 1s;
    z-index: 4;
    
}
.searchprueba-now .container input {
 
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 50px;
    outline: none;
    border: none;
    background: #7B86FA;
    color: white;

    padding: 0 80px 0 20px;
    border-radius: 30px;


    transition: all 1s;
    opacity: 0;
    z-index: 5;
    
    letter-spacing: 0.1em;
}
.searchprueba-now .container input:hover {
    cursor: pointer;
  }
  
  .searchprueba-now .container input:focus ~ .search {
    right: -200px;
    background: #CB3D82;
    z-index: 6;
}

    .searchprueba-now .container input:focus ~ .search::before {
        top: 0;
        left: 0;
        width: 0; 
     height: 0; 
    
      }
      .searchprueba-now .container input:focus ~ .search::after {
        top: 0px;
        width: 0; 
        height: 0; 
        left: 8px;
        border-left: 25px solid #ffff;
        border-top: 12px solid #CB3D82;
        border-bottom: 12px solid #CB3D82; 
        transform: rotate(0deg);
       
      }
      .searchprueba-now .container input::placeholder{
        color: white !important;
       
    
      }
  .searchprueba-now .container input:focus {
    width: 300px;
    left:-80px;
    opacity: 1;
    cursor: text;
  }
.searchprueba-now .container .search:hover {
    cursor: pointer;
  }
  .searchprueba-now .container .search::before {
    content: "";
    position: absolute;
    margin: auto;
    top: -3px;
    right: 0;
    bottom: 0;
    left: 0px;
    width: 25px;
    height: 25px;
    background: white;
    border-radius:20px;
  
    
  }
  .searchprueba-now .container .search::after {
    
    content: "";
    position: absolute;
    margin: auto;
    top: 18px;
    right: 0px;
    bottom: 0;
    left: 4px;
    border-left: 10px solid white;
    border-top: 10px solid transparent;
    width: 6px;
    height: 10px;
    transform: rotate(35deg);
   
  
   
  }
.btnMOB {
    font-weight: bold;
    font-size: calc(.4rem + 1.5vw) !important;
    color: #FFFFFF;
    background: #7B86FA 0% 0% no-repeat padding-box;
    border-radius: 32px;
    padding: 5px;
    border: hidden;
    width: 70vw;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:1rem auto;
}
.commentsClientsOn{
    display:flex
}
.commentsClientsOff{
    display:none;
}

.btnMOB.invertido {
    color: white;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

/* .btnMOB.disabled {
    opacity: 0.9;
} */

.btnMOB:focus {
    text-decoration: none;
    color: #ffffff72;
}

.modalInfo {
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 1s;
    opacity: 1;
    z-index: 9999999999;
}

#containerIcon {
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    width: calc(calc(1vw + 2rem)*4 + 15px);
    justify-content: space-between;
    display: flex;
}
#containerIconMusic {
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    width: calc(calc(2vw + 2rem)*4 + 15px);
    justify-content: space-between;
    display: flex;
}
#containerIconStream {
    position: absolute;
    bottom: 8.5vmin;
    right: 0;
    
    margin-right:0%;
   
    z-index: 10000000000000000;

    justify-content: space-between;
    display: flex;
}

#containerIcon img {
    width: calc(1vw + 2rem);
}
#containerIconMusic img {
    width: calc(1vw + 2rem);
}
/* @media (max-width: 575px) {
    .modalInfo .modalInfoContenidoordenar .modalMsgReserva {
      
        overflow-y: hidden;
         
    }
} */
.fontawesome_icones{
    width:2.5rem !important;
    height: 2.5rem!important;
        }
#buttonSendMessage{
    border:none;
}

@media (max-width:  920px) {
    .btnActionCancelar {
        width: 35vw !important;
        max-width: 50% !important;
        color:#CB3D82 !important;
        border:1px solid #CB3D82;
        background-color: #FFFF !important;
    }
    .btnAction {
        max-width: 50%;
        width: 35vw;
   
        display: inline-flex;
        justify-content: center;
    }
    .btnAction-only {
        max-width: 50%;
        width: 35vw;
        background-color: #CB3D82 !important;
        display: inline-flex;
        justify-content: center;
    }
    .stargolden{
        margin: auto 0;
         width: 20px;
          height: 20px
    }
  
.imagenload{
    background-image:url("../assets/img/logosconvierte/MOB-LOADING.gif");
    background-size: cover;
    background-repeat: no-repeat;
}
    .infoPeer .name {
        font-weight: bold;
        margin:auto;
      white-space:nowrap;  
        font-size: 2.5vmin;
    }
 
    .PanelContent .calificacionEntrenador {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    .PanelContent .calificacionEntrenador img {
        width: 10px;
        height: 10px;
    }
    .PanelContent .logo img {
        width: 23%;
        min-width: 30px;
        margin: auto;
    }
    .PanelTab {
        position: absolute;
        top: 30%;
        z-index: 9999999999;
        background-color: #262626;
        color: white;
        /* height: 25%;
        width: 5%; */
        transform: rotate(
    180deg);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        cursor: pointer;
        transition: 0.5s;
        /* 0.5 second transition effect to slide in the sidenav */
        padding: 1rem 0.3rem;
    }
    .PanelTabgroup {
        position: absolute;
    
        z-index: 9999999999;
        background-color: #262626;
        color: white;
        /* height: 25%;
        width: 5%; */
        transform: rotate(
    180deg);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        cursor: pointer;
        transition: 0.5s;
        /* 0.5 second transition effect to slide in the sidenav */
        padding: 1rem 0.3rem;
    }
    .PanelContent .imgEntrenador {
       
    
        padding: 0 20%;
        justify-content: center;
    
    }
    .modalInfo .modalInfoContenidografica {
        background-color: white;
        display:flex;
        width: 80% !important;
       
        text-align: center;
       /* transform:rotate(-90deg); */
        position: relative;
        border-radius: 10px;
       
    }
    .modalInfoContenidograficagiro{
        width: 100% !important;
        height:320px;
        padding:10px;
        }
        .circulorojolesion{
            background-color:#CB3D82;
            border:1px solid #CB3D82;
            border-radius:100%;
            width: 20vw;
            height: 20vw;
            max-width: 3vmin;
            max-height: 3vmin;
              width: 10vw;
            height: 10vw;
            max-width: 3vmin;
            max-height: 3vmin;
            margin: auto 2%  auto 2% ;
        }
        .colornuevaletra{
            color:#505050 !important
        }
        .scrolldolor{
            overflow: scroll;
            height:15vh;
        }
        .scrolldolornull{
            display:none
        }
        .PanelContent .imgEntrenador1 img {
            width: calc(1rem + 4vw);
            height: calc(1rem + 4vw);
     
            margin: 0 auto;
        }
        .modalInfoContenido .title_pago{
            font-size: 4vw;
font-weight:bold;
            width: 100%;
            margin: 8% auto 3% auto;
            line-height: 100%;
            
            color: #394F65; 
        }
    }
    @media (min-width:  921px) {
        .btnActionCancelar {
            max-width: 45%  !important;
            width: 35vw !important;
            margin:0 2.5% !important;
            display: inline-flex !important;
            justify-content: center !important;
            color:#CB3D82 !important;
            border:1px solid #CB3D82 !important;
            background-color: #ffff !important;
        }
        .btnAction {
            max-width: 45%  !important;
            width: 35vw !important;
            margin:0 2.5% !important;
            display: inline-flex !important;
            justify-content: center !important;
            /* background:#CB3D82 !important; */
        }
        .btnAction-only {
            max-width: 45%  !important;
            width: 35vw !important;
            margin:0 2.5% !important;
            display: inline-flex !important;
            justify-content: center !important;
             background:#CB3D82 !important; 
        }
        .stargolden{
            margin: 1vh 1vw;
             width: 24px;
             height: 24px
    
         
        }
        .imagenload{
            background-image:url("../assets/img/logosconvierte/MOB-LOADING.gif");
            background-size: cover;
            background-repeat: no-repeat;
        }
        .letrasconviertemosbter{
            color:#394F65;
            font-size:1rem;
            font-weight:bold;
        
        }
        
        .letrasconviertemosbtertextreservas{
            color:black;
            font-size:3.2vw;
            padding:2vh 1vw;
            
            text-align:left;
            font-weight:400;
        
        }
        .letrasconviertemosbter1{
            color:#394F65;
            padding:0 3rem 3vh 3rem !important;
         
            width:100%;
            font-size:1rem;
            font-weight:bold;
        
        }
        .letrasconviertemosbtertext{
            color:#394F65;
            font-size:0.8rem;
            padding:2vh 1vw;
            
            text-align:left;
            font-weight:400;
        
        }
        .infoPeer .name {
            font-weight: bold;
            margin:auto;
          white-space:nowrap;  
            font-size: 1vw;
        }
        .modalInfoContenido .title_pago{
            font-size: 2vw;

            width: 100%;
            margin: auto;
            line-height: 100%;
            font-weight:bold;
            color: #394F65; 
        }
        .circulorojolesion_clase{
            width:15px;
            height:15px;
            background-color:#CB3D82;
            border:1px solid #CB3D82;
            border-radius:100%;
           
        }

        .modalInfo .modalInfoContenido_clase .text {
            font-size: calc(0.5rem + 0.5vw);
            color: #7B7B7B;
            
            margin-top: 3vh;
            line-height: 110%;
        }
    .PanelContent .logo img {
        width: 17%;
        min-width: 13vmin;
        margin: auto;
    }
    .PanelContent .imgEntrenador1 img {
        width: 15vmin;
        height: 15vmin;
        object-fit: cover;
    
        margin: 0 auto;
    }
    .PanelContent .imgEntrenador1_f img {
        width: 7.5vmin;
   margin-right:0.7vw;
        height: 7.5vmin;
        object-fit: cover;
    
       
    }
    .PanelContent .calificacionEntrenador {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    .PanelContent .calificacionEntrenador img {
        width: 10px;
        height: 10px;
    }
  
   
    .PanelTab {
        position: absolute;
        top: 40%;
        z-index: 9999999999;
        background-color: #262626;
        color: white;
        /* height: 25%;
        width: 5%; */
        transform: rotate(
    180deg);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        cursor: pointer;
        transition: 0.5s;
        /* 0.5 second transition effect to slide in the sidenav */
        padding: 1rem 0.3rem;
    }
    .PanelTabgroup {
        position: absolute;
      bottom:0;
        right:10rem;
    
        z-index: 9999999999;
        background-color: #262626;
        color: white;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        border-bottom-right-radius: -25px;
        border-bottom-left-radius: -25px;
        /* height: 25%;
        width: 5%; */
       
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    
      
        cursor: pointer;
        transition: 0.5s;
        /* 0.5 second transition effect to slide in the sidenav */
        padding: 0.0rem 3rem;
    }
    .PanelContent .imgEntrenador {
        margin-top: -4vh;
       
        padding: 0vw 20%;
        justify-content: center;
    
    }
    .scrolldolor{
        overflow: scroll;
        overflow-x:hidden;
        height:20vh;
    }
    .scrolldolorcoments{
        overflow: scroll;
        overflow-x:hidden;
        height:25vh !important;
        width:21vw !important;
    
    }
 
    .scrolldolornull{
        display:none
    }
    .colornuevaletra{
        font-size:0.8vw;
        color:#505050 !important;
        margin:auto 2.5vw !important;
    }
    .colornuevaletra_entre{
        font-size:0.8vw;
        color:#505050 !important;
        margin:auto 0.5vw !important;
    }
    .colornuevaletra1{
        font-size:0.9vw !important ;
        margin:auto 1vw !important;
       
    }
    .circulorojolesion{
        width:2vmin;
        height:2vmin;
        background-color:#CB3D82;
        border:1px solid #CB3D82;
        border-radius:100%;
        margin:auto 0vw auto 2.4vw !important;
    }
    .circulorojolesionperfil{
        width:2vmin;
        height:2vmin;
        background-color:#CB3D82;
        border:1px solid #CB3D82;
        border-radius:100%;
        margin:auto 0vw auto 0 !important;
    }
    .modalInfo .modalInfoContenidografica {
        background-color: white;
        display:flex;
        width: 65% !important;
 
        text-align: center;
       /* transform:rotate(-90deg); */
        position: relative;
        border-radius: 10px;
       
    }
    .modalInfoContenidograficagiro{
        width: 65vw !important;
        padding:1vw;
        height:65vh
        }
        .scrolldolorcoments_eleva{
            overflow-y: auto !important;
            overflow-x:hidden !important;
            width:100% !important;
            height:27vh !important;
          
        
        }
}
@media (min-width: 100px) and (max-width:  920px) {
    .scrolldolorcoments_eleva{
        overflow-y: scroll !important;
        overflow-x:hidden !important;
        height:100% !important;
      
    
    }
    .letrasconviertemosbter{
        color:#394F65;
        font-size:0.8rem;
        font-weight:bold;
    
    }
    
    .letrasconviertemosbtertextreservas{
        color:black;
        font-size:3.2vw;
        padding:2vh 5vw;
        
        text-align:left;
        font-weight:400;
    
    }
    .letrasconviertemosbter1{
        color:#394F65;
        padding:0 10vw 1vh 10vw !important;
     
        width:100%;
        font-size:0.7rem;
        font-weight:bold;
    
    }
    .letrasconviertemosbtertext{
        color:#394F65;
        font-size:0.75rem !important;
        padding:2vh 5vw;
        
        text-align:left;
        font-weight:400;
    
    }
    .btnActionCONVIERTE1 {
        max-width: 50%;
        width: 250px;
        margin-bottom:1vh;
        display: inline-flex;
        justify-content: center;
        background-color:#CB3D82;
        margin-top:-1vh !important
    }

    .btnActionCONVIERTE {
        margin:0vh 0 0 0  !important;
        max-width: 12%;
        font-weight:400;
       font-size:1.3rem !important;
        left:85%;
        top:0%;
        font-weight:600;
        color:#9DACBD;
        background:#FFFFFF;
    position: absolute;
        justify-content: center;
    }
    .modalInfo .modalInfoContenidomonster {
        background-color: white;
        max-width: 80vw;
        padding: 3vw;
        position: relative;
        border-radius: 10px;
        text-align: center;
    }

    .react-confirm-alert {
    
      
        border-radius: 15px;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.33);
        margin: auto 5%;
    }
    .iconcolor{
        display:flex !important;
       
        background: #9EACED;
       max-width:4rem;;
       margin:1rem auto;
       margin-bottom:1vh !important;
        border-radius:30px !important;
    }
    .modalInfo .modalInfoContenido .title {
        font-size: 5vw;
        padding:1vh 5vw 1vh 1vw;
        width: 100%;
        margin: auto;
        line-height: 100%;
        font-weight: 700 !important;
        color: #394F65;
    }
    .maspequeñoordenar{
        font-size:4vw !important;

        width:150px !important;
    }
}

@media (min-width: 921px) {
    
    .react-confirm-alert {
        min-width: 15vw;
        min-height: 20vh;
      
        border-radius: 15px;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.33);
        margin: auto 5%;
    }
    .corridoreservatuclase{
        margin-left:15px;
    }
    .corridoreservatuclase1{
        margin-right:15px;
    }
    .iconcolor{
        display:flex !important;
        background-color: #2A9DA5;
     
       margin:auto;
       margin-bottom:1vh !important;
        border-radius:30px !important;
    }
    .modalInfo .modalInfoContenido .title {
        font-size: 1.5vw;
        width: 100%;
        text-align:center;
        margin: auto;
        font-weight:bold;
        line-height: 100%;
      
        color: #394F65;
    }
    .modalInfo .modalInfoContenido .titleleft {
        font-size: 1.5vw;
        width: 100%;
        text-align:center;
        margin: auto;
        line-height: 100%;
        left:0!important;
    
        color: #9DACBD;
    }
    .modalInfo .modalInfoContenido_clase .title {
        font-size: 1.0vw;
        width: 100%;
        margin: auto;
        padding:auto;
        line-height: 100%;
       
        color: #9DACBD;
    }
    .modalInfo .modalInfoContenido .titleclasedolor {
        font-size: 3vmin;
        width: 100%;
      
        margin: auto;
        text-align:center;
        line-height: 100%;
    
        color: #9DACBD;
    }
    .btnMOB {
        font-weight:700;
        font-size: calc(.4rem + 0.6vw) !important;
        color: #FFFFFF;
        background: #CB3D82 0% 0% no-repeat padding-box;
        border-radius: 32px;
        padding: 5px;
        border: hidden;
        width: 70vw;
        max-width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:3vh !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .maspequeñoordenar{
        font-size:1rem !important;
        width:150px !important;
    }
    .modalinfoordenarpor{
        padding-top:30px ;
        z-index: 999 !important; 
    }
    .modalInfo .modalInfoContenidoordenar .text {
      
        line-height:none !important;
    }
    .modalInfo .modalInfoContenidoordenar .modalMsgReserva {
      
        overflow-y: hidden;
         
    }
    .modalInfo .modalInfoContenidoordenar {
        padding-top:120px !important;
        background-color: white;
      z-index:0 !important;
        padding: 20px 30px;
        position: absolute !important;
        min-height: 90vh;
        top:72px !important;
        left:7vw !important;
     
        
        border-radius: 0px !important;
        text-align: center;
    } ;
    }

/* @media (min-width: 1000px) {
    #containerIcon {
        bottom: 25px;
    }
} */

@media (min-width: 921px) {
   
       
        .btnActionCONVIERTE1 {
            max-width: 60% !important;
            width: 250px;
            display: inline-flex;
            justify-content: center;
            background-color:#CB3D82 !important;
            margin-top:0vh !important
        }
    
    .btnActionCONVIERTE {
        width: 10px !important;
        height: 10px !important;
        max-width: 20px !important;
        margin:1vh 0 0 0  !important;
       color: #9DACBD!important;
        font-weight:400;
       font-size:2vw !important;
        left:90%;
        top:0%;
        font-weight:400 !important;
        background: white !important;
    position: absolute;
        justify-content: center;
    }
    .iconcolor{
        display:flex !important;
        background-color: #9EACED;
        width:6vw !important;
       max-width:6vw;;
       margin:auto;
       margin-bottom:1vh !important;
        border-radius:30px !important;
    }
    .modalInfo .modalInfoContenido {
        max-width: 40vw !important;
    }
    .modalInfo .modalInfoContenido_clase {
        max-width: 15vw !important;
    }
    /* .modalInfo .modalInfoContenido .text {
        font-size: 2vmin !important;
    } */
    .modalInfo .modalInfoContenidomonster {
        background-color: white;
        margin:auto !important;
        max-width: 25rem;
     
        position: relative;
        border-radius: 10px;
        text-align: center;
    }
}

.modalInfo .modalInfoContenido {
    background-color: white;
    max-width: 80vw;
    padding: 3vw;
    position: relative;
    border-radius: 10px;
    text-align: center;
}

.modalInfo .modalInfoContenido_clase {
    background-color: white;
    max-width: 80vw;
    padding: 1vw;
    position: relative;
    border-radius: 10px;
    text-align: center;
}




.modalInfo .modalInfoContenido .title1 {
    font-size: 5vmin !important;
    width: 100%;
    margin-top: auto !important;
    margin-bottom:auto !important;
     display:flex;
     margin-left:0px !important;
   
    line-height: 100%;
    font-weight:700 !important;
    color: #394F65;
}

.modalInfo .modalInfoContenido_clase .title1 {
    font-size: 5vmin !important;
    width: 100%;
    margin-top: auto !important;
    margin-bottom:auto !important;
     display:flex;
     margin-left:0px !important;
   
    line-height: 100%;
  
    color: #9DACBD;
}

.modalInfo .modalInfoContenido .text {
    font-size: calc(10px + 1.5vmin);
    color: #9DACBD;
    padding:2vw;

    line-height: 100%;
}



.btnAction_pruebas {
    max-width: 80%;
    width: 250px;
    display: inline-flex;
    justify-content: center;
}
.btnAction_pruebasactu {
    max-width: 35%;
    width: 200px;
    display: inline-flex;
    justify-content: center;
}


.btnActionreser {
    max-width: 80%;
    width: 390px;
    display: inline-flex;
    justify-content: center;
}
.btnAction1 {
    max-width: 90%;
    width: 60vw;
    height:5vh;
    font-size:3.5vw;
    display: inline-flex;
    justify-content: center;
}



.botonesModalInfo {
    font-size: calc(0.8rem + 1vw);
    display: flex;
    justify-content: space-evenly;
}



/* Panel para la clase */



.PanelTabgroup.left {

    border-radius: 10px 0px 0px 10px;
    margin-left: 0px;
    left: 0;
}
.PanelTab.left {

    border-radius: 10px 0px 0px 10px;
    margin-left: 0px;
    left: 0;
}

.PanelTab.right {
    border-radius: 0px 10px 10px 0px;
    margin-right: 0px;
    right: 0;
}
.PanelTab.right.otro {
    border-radius: 0px 10px 10px 0px;
    margin-right: 0px;
    right: 0;
    top:20% !important;
}

.PanelTab img {
    width: 15px;
    height: 15px;
}
.PanelTabgroup img {
    width: 15px;
    height: 15px;
}

.PanelTab .text {
    font-size: calc(0.5em + 1vw);
    margin-bottom: 10px;
}
.PanelTabgroup .text {
    font-size: calc(0.5em + 1vw);
    margin: 0.5rem;
}

/* The side navigation menu */

.PanelContent {
    height: 100%;
    /* 100% Full-height */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    top: 0;
    /* Stay at the top */
    background-color: #FDF1E8;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */

    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
    max-width: 350px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
   
    padding-bottom: 15px;
 
    z-index: 9999999999;
    color: #394F65;
}
.PanelContentgroup {
    border-top:3px #CB3D82 solid ;
    height: 0%;
    /* 100% Full-height */
    width: 100%;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    bottom: 0%;
    /* Stay at the top */
    background-color: #FDF1E8;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
   
    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
    
    display: flex;
    justify-content: space-between;
    flex-direction: unset;
    
    z-index: 9999999999;
    color: white;
}

.PanelContent.right {
    right: 0;
    border-left: 2px solid #CB3D82;
}
.PanelContent.right1 {
    right: 0%;
    border-left: 2px solid #CB3D82;
}

.PanelContent.left {
    left: 0;
    border-right: 2px solid #CB3D82;
    /* display: grid;
    justify-items: center;
    align-content: space-evenly; */
    align-items: center;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */



.PanelContent .logo {
    width: 100%;
    display: flex;
}







.PanelContent .imgEntrenador .infoPeer {
 
    display: flex;
    flex-direction: column;
    justify-content: center;
}







/*Reloj y mensaje de espera clase*/

.clock {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 5px solid #CB3D82;
    border-radius: 50%;
    left: 50%;
    top: 20%;
    background: #fefbf2;
    transform: translate(-50%, -50%);
}

.clock::before {
    position: absolute;
    content: '';
    height: 36px;
    width: 5px;
    background: #CB3D82;
    top: 50%;
    left: calc(50% - 2.5px);
    border-radius: 5px;
    animation: spin 6s linear infinite;
    transform-origin: top;
}

.clock::after {
    position: absolute;
    content: '';
    height: 26px;
    width: 5px;
    background: #CB3D82;
    top: 50%;
    left: calc(50% - 2.5px);
    border-radius: 5px;
    animation: spin 72s linear infinite;
    transform-origin: top;
}

@keyframes spin {
    0% {
        transform: rotate(-180deg)
    }
    100% {
        transform: rotate(180deg)
    }
}

#containerClock::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #CB3D82;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

#containerClock::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #394F65;
    left: 50%;
    top: calc(20% - 44px);
    z-index: 2;
    transform: translateX(-50%);
    box-shadow: 40px 43px 0 0 #394F65, -42px 43px 0 0 #394F65, 0 82px 0 0 #394F65;
}



.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.6) !important;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-4-29 12:11:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation hidding-blurred
 * ----------------------------------------
 */
.hidding-blurred {
    animation: hidding-blurred 1s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

@keyframes hidding-blurred {
    0% {
        filter: blur(0);
        opacity: 1;
    }
    100% {
        filter: blur(40px);
        opacity: 0;
    }
}