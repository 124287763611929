@media (min-width: 921px)  {
    .letraspopfi1{
        position:relative;
        display:inline-flex;
padding:0.5vw;
    
        height:10vh;
      
    }
    .letraspopfi2{
        position:relative;
        display:inline-flex;
        padding:0.6vw;
        margin-top: 16px;
        
        height:8vh;
        max-width:35vw !important;
    }
    .letraspopfi2actualizar{
        position:relative;
        display:inline-flex;
        padding:0.6vw;
        margin-top: 16px;
        
        height:4.5vh;
   
    }
    .grafico2{
        margin-left:5vw !important;
        margin-right:0vw !important;
       
     }
    .tamañoestadisticsres{
        margin-left:11vw !important;
        margin-right:20vw !important;   
    }
    .tamañoestadisticsres_tear{
        margin-left:13% !important;
        margin-right:20vw !important;   
    }
    .tamañoestadisticsres_tearCompanie{
        margin-left:5% !important;
        margin-right:20vw !important;   
    }
    .Hr .letrasestadis{
    font-weight:bold;
    font-size:2.3vw;
    text-align:center;
    color:#394F65
}

.Hr .letrasestadis1{
    font-weight:400;
    font-size:1vw;
    color:#394F65;
    text-align:center
}

 .borderkm{
     margin-top:3vh;
    border-right:1px solid #394F65;
    height:7vh !important;
    margin-bottom:0.8vh;
   
    opacity: 0.4;
}
.Hr .imagesestadisfire{
    text-align:center;
    width:100%;
    max-width:2.5vw;
    margin: 0 4vw;
    display:flex;
    justify-content: center;
}
.Hr .imagesestadiskm{
    width:100%;
    max-width:4vw;
    margin: 0 4vw;
align-items:center;
   
    justify-content: center;
}
.Hr .imagesestadiskmelevapoint{
    width:100%;
    max-width:6vmin;
    margin: 0 4vw;
align-items:center;
   
    justify-content: center;
}
.Hr .tamañoestadistics{
    margin-top:0vh
}

.Hr .bitacoraSlider{
    height: 100vh;;
}
.letraspopfi{
    display: flex !important;
     font-size:1vw !important;
     margin-top:0 !important;
    
 }
}
@media (min-width: 100px) and (max-width:  920px) {
    .letraspopfi1{

        width:50vw !important;
      
        max-width:100vw !important;
    }
    .letraspopfi2{

        width:50vw !important;
      
        max-width:100vw !important;
    }
    .letraspopfi{
        display: flex !important;
         font-size:3vw !important;
         margin-top:0 !important;
         padding:2vh 0;
        
     }
    .grafico2{
        margin-left:10vw !important;
        margin-right:0vw !important;
       
     }
    .tamañoestadisticsres{
        margin:0 !important;  
    }
    .Hr .letrasestadis{
        text-align:center;
    font-weight:bold;
    font-size:1.5rem;
    color:#9DACBD
}

.Hr .letrasestadis1{
    font-weight:400;
    font-size:3vw;
    color:#9DACBD;
    text-align:center
}

.Hr .imagesestadiscardio{
    width:5vw;
    display:flex;
    margin:0 auto;
    justify-content: center;
}
.Hr .imagesestadiscardio_disciplinas{
    width:8vw;
    display:flex;
    margin:0 auto;
    justify-content: center;
}
 .borderkm{
     margin-top:3vh;
    border-right:1px solid #959595;
    height:13vh !important;
    margin-bottom:0.8vh;
    opacity: 0.4;
}
.Hr .imagesestadisfire{
    width:5vw;
    margin-left:35%;
    display:flex;
    justify-content: center;
}
.Hr .imagesestadiskm{
    width:9vw;
    margin:auto;
    display:flex;
    justify-content: center;
}
.Hr .tamañoestadistics{
    margin-top:0vh
}

.Hr .bitacoraSlider{
    height: 100vh;;
}}

.bitacoraSlider1{
    height: 100vh;;
}
.stopwatch{
    text-align:center
}
.Hr .bitacoraSlider .bitacoraTitle{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #394F65;
    text-align: center;
}
.bitacoraTitle{
    margin-top: 1rem;
    margin-left:4rem;
    margin-right:4rem;
    padding-bottom:0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #2A9DA5;
    text-align: center;
}
.botonstart{
    width:20vw !important;
    background-color:#2A9DA5;
    border-radius:18px;
    border:#2A9DA5;
    height:7vh;
    margin:1vw;
}
.cronometro{
    display:flex;
    margin-top:3vh !important
}
.relojtime{
    display:flex;
    width:18vw;
    margin-left:26%
}
.botonstop{
    width:20vw !important;
    height:7vh;
    background-color:#FF0000;
    border-radius:18px;
    border:#FF0000;
    margin:1vw;
}
.Hr .bitacoraSlider .bitacoraTitle1{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #394F65;
    text-align: center;
    border-bottom:1px solid #BEBEBE
}
.bitacoraTitle1{
    margin-top: 1rem;
    margin-left:3rem;
    margin-right:3rem;
    padding-bottom:1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    color: #2A9DA5;
  
    text-align: center;
    border-bottom:1px solid #BEBEBE
}
.Hr .bitacoraSlider .bitacoraTitle2{
    
    margin-top: 1rem;
    margin-left:2rem;
    margin-right:2rem;
   
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: #9DACBD;
    text-align: center;
    
}
.Hr .bitacoraSlider .bitacoraTitle5{
    margin-top: 2rem;
    margin-left:35%;
    display: flex;
    justify-content: center !important;
    align-items: center;
    width:30vw;
    text-align: center;
    
}
.letrabotonemep{
    font-size:4vw !important;

}
.stepsBitacoraz {
    display: flex;
    justify-content: space-evenly;
    width: 30%;
}
.stepsBitacoraz .steps {
    margin-top: 1rem;
    width: 4vmin;
    height: 4vmin;
    background-color: #9EACED;
    opacity: 0.5;
}

.stepsBitacoraz .steps.active {
    opacity: 1;
}
.paused {
    height: 17px;
    border-width: 8px 8px 8px 18px;
    cursor: pointer;
    box-sizing: border-box;
    margin-left: 2vw;
    border-color: transparent transparent transparent #fff;
    transition: 100ms all ease;
    will-change: border-width;
    border-style: solid;
}

.btn-danger{
    
    background-color: #ffff !important;
    color: #CB3D82 !important;
    border:1px solid #CB3D82 !important;
}
.btn-confirmar-new{
    
    background-color: #CB3D82 !important;
    color: #ffff !important;
   
}



.running {
    padding-right: 2.8vw;
    padding-left: 2.8vw;
    border-style: double;
    border-width: 0 0 0 14px;
}

.stop {
    background-color: #fff;
    height: 15px;
    width: 15px;
    cursor: pointer;
}