 .container {
    width:70px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:3vh;
    margin-top:4vh
  }
  
  .slider {
    position: relative;
    width: 400px;
  }
  
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }
  
  .slider__track,
  .slider__range {
    border-radius: 17px;
    height: 23px;
  }
  
  .slider__track {
    background-color: white;
    width: 100%;
    border:2px solid #bebebe;
    z-index: 2;
  }
  
  .slider__range {
    background-color: #9EACED;
    z-index: 3;
  }
  
  .slider__left-value,
  .slider__right-value {
    color: black;
    font-size: 12px;
    margin-top: 2vh;
  }
  
  .slider__left-value {
    left: 6px;
  }
  
  .slider__right-value {
    right: -4px;
  }
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 29px;
    width: 180px;
    outline: none;
  
  }
  
  .thumb--left {
    z-index: 4;
    margin-bottom:-19px;
  }
  
  .thumb--right {
    z-index: 5;
    margin-bottom:-19px;
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #9EACED;
    border: none;
    border-radius: 50%;
   
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-top: 1.3vh;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */

 
  /* https://codesandbox.io/s/multi-range-slider-react-js-ecwcr?from-embed=&file=/src/component/multiRangeSlider/multiRangeSlider.css:0-1518 */