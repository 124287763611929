@media (min-width: 100px) and (max-width:  920px) {
    .imagenx{
        height:5vh !important;
        width:10vw
    }
    .imagenx1{
        height:16vw !important;
        width:16vw
    }
    .imagenx2{
    position:absolute;
    right:6%;
        height:2.5vh !important;
        width:5vw
    }
    
.mySwiper3{
    display:flex; 
    position:relative;
    width:90vw;
    margin-left:5vw;
    padding-right:2vw !important; 
}
    .mySwiper3 .imagenbit .imagenw{
        display:inline-flex;
        width:100% !important;
      
    max-width:12vw;
    

     margin:auto ;
     padding: auto ;
            justify-content: center;
            align-items:center;
    
    }
    
    
    
    .mySwiper3 .letras{
        
        position: absolute;
        top:5vh;
        left:25vw;
        z-index:1;
        color:#363636;
        font-size:3.5vw;
        font-weight:bold;
        display:flex;
    }
    .mySwiper3 .cuadrogrande1 .letrasdisciplinas{
        
        position: absolute;
        width:90vw;
        display:flex;
        top:0vh;
        left:1vw;
        z-index:1;
        color:#CB3D82;
       
        
    }
    .mySwiper3 .imagenbit{
        display:inline-flex;
        width:18% !important; 
        height:7vh;
        position: absolute;
        background-color:#CB3D82;
        border-radius:13px; 
        top:25%;

        left:5%;
       
        
    }
    
.mySwiper2 .imagen1{
  

    left:2.8vw;
    z-index:1;
    
}
    .imagencaneca{
        width:6vw;
     
        
        margin:0 3vw;
    }
    .letraspeque13{
        font-size:3vw;
        position: absolute;
        top:6vh;
        padding:1vw;
        left:17.5vw;
        z-index:1;
        color:#9DACBD;
        font-family:WorkSans-Regular
    }
    .mySwiper .letras{
    width:70%;
        position: absolute;
        top:1.5vh;
        left:18vw;
        z-index:1;
        color:#394F65;
       font-weight:600;
        
    }
    .mySwiper .borrar{
        width:20% !important; 
        height:10vh;
        font-size:3.5vw;
        color:white;
       margin-left:4vw;
       font-weight:bold;
       padding-top:2vh;
       padding-left:2.3vw;
        background-color:#CB3D82; 
        border-radius:15px;
        margin-right:20vw;
    }
    .borrar.letraspequeborrar{
        padding-left:10vw;
     
      
    }
    .mySwiper{
        display:flex; 
        width:90vw;
       
        padding-right:2vw !important; 
    }
    .cuadrogrande{
    display:flex;
    position: relative;
    width:100%;
height:10vh;
    background-color:#FBEDEA;
    opacity:0.5;
    margin:auto;
    border-radius:15px; 
   margin-bottom:2vh;
}
.mySwiper3 .cuadrogrande1{
    display:flex;
    position: relative;
    width:100%;
    top:3vw;
    max-width:100vw;
height:10vh;
    background-color:#FBEDEA;
    opacity:0.5;
    MARGIN:AUTO;
    border-radius:15px; 
   margin-bottom:2vh;
}
.mySwiper2 {
    width:100% !important
    }
    .mySwiper .imagen{
        width:14vw !important; 
        height:7vh;
        display:inline-flex;
        position: absolute;
        background-color:#9EACED;
        border-radius:15px; 
        top:1.5vh;
    
        left:2vw;
        z-index:1;
        
    }
    .letraspeque12{
        font-size:3vw;
        position: absolute;
        top:4.1vh;
        padding:1vw;
        left:17.5vw;
        z-index:1;
        color:#9DACBD;
     
    }
    .letraspeque14{
        font-size:3vw;
        position: absolute;
        top:7vh;
        right:6vw;
        z-index:1;
        color:#CB3D82;
        font-weight:bold
        
    }
    .letraspeque15{
        font-size:3vw;
        position: absolute;
        top:4vh;
        right:6vw;
        z-index:1;
        color:#CB3D82;
        font-weight:bold
        
    }
    
.mySwiper .imagen .imagen1{
  
    width:100% !important;
    
padding:0.2vw ;
    justify-content: center;
    align-items:center;
 
}


}
@media  (min-width:  921px) {
    .imagenx{
        height:6vw !important;
        width:6vw
    }
    .imagenx1{
        height:6vw !important;
        width:6vw
    }
    .imagenx2{
        float:right;
        height:1.5vw !important;
        width:1.5vw
    }
    .imagenx{
        height:7vh !important;
        width:4vw
    }
    .cuboizquier{position: relative;
width:10%;
margin: 2vh 12vw;
  }
    
.mySwiper3{
  
   

}
    .mySwiper3 .imagenbit .imagenw{
        width:100%;
     padding:6%;
        position: relative;
       

   
    
    } 
    .mySwiper3 .imagenbit .imagenw1{
        width:100%;
        max-width:2rem;
    
       top:0%;
    margin:auto 5vw;
    padding:calc(14px - 0.3vw) !important;
    display:none;
    } 
    .mySwiper3 .letras{
        
        position: relative;
    
     white-space:nowrap;
       padding-left:70%;
        z-index:1;
        color:#394F65;
        font-size:1vw;
        margin:auto;
        font-weight:bold;
        display:flex;
    }
  
    .mySwiper3 .imagenbit{
        display:inline-flex;
        position: relative;;
        left: 0px;
        top: 0px;
        font-size: 30px;
        color: #fff;
    
        width: 100%;
        height:3rem;
        max-width:3rem;
        border-radius: 100px;
      
        padding: 1px;
 
    background-color:#9EACED; 
padding:auto !important;
margin:auto !important;   
}
    .btnActualizarequipos {
        border: none;
        background-color:#CB3D82;
        color: white;
        font-weight: bold;
        font-size: 1vw;
        border-radius: 20px;
        width: 100%;
     position:relative;
margin: 0.5vh 13vw !important;        

    }
    .gridtareas{
        margin: 0 7%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0 3rem
    }
        
.mySwiper2 .imagen1{
    z-index:1; 
}
    
.mySwiper .imagen .imagen1{
    display:inline-flex;
    width:100% !important;
   max-width:3.5vw !important;
    justify-content: center;
    align-items:center;
    
   

 
}
    

    .contenedortareasall{
        display:flex;
        width:100%;
        margin:1vw;
        position:relative !important;
    }

    .letraspeque14{
        font-size:1vw;
        position: absolute;
        top:80%;
        left:75%;
        z-index:1;
        color:#CB3D82;
        font-weight:bold
        
    }
    .letraspeque15{
        font-size:1vw;
        position: absolute;
        top:60%;
        left:75%;
        z-index:1;
        color:#CB3D82;
     font-weight:bold
        
    }
    .letraspeque12{
        font-size:1vw;
        position: absolute;
        top:43%;
        margin:1vw;
        left:30%;
        z-index:1;
        color:#9DACBD;
        
    }
    .letraspeque13{
        font-size:1vw;
        position: absolute;;
        top:63%;
        margin:1vw;
        left:30%;
        z-index:1;
        color:#9DACBD;
     
    }
    .mySwiper .letras{
        margin:1vw;
        font-size:1vw;
        position: absolute;
        top:19%;
        left:30%;
        z-index:1;
        color:#394F65;
      font-weight:bold;
        
    }
    .mySwiper .imagen{
        width:9vh !important; 
        justify-content: center;
       display: inline-flex;
        
        text-align:center;
        height:9vh;
        top:25%;
        left:9%;
        position: absolute;
        background-color:#9EACED;
        border-radius:15px; 
    margin:auto 1rem !important;
    padding: 2.0vh 0.99vw !important;
    
        
        z-index:1;
        
    }
    .imagencaneca{
        max-width:1rem;
            width:100%;
            justify-content: center;
       display: inline-flex;
   
           margin:auto;
         
        }
    .mySwiper .borrar{
        cursor:pointer;
        width:100% !important; 
        max-width:3rem;
        height:45%;
        display:flex;
        left:96%;
        top:24%;
        position: relative;
        background-color:#2A9DA5;
        border-radius:5vw; 
    margin:1rem;
        z-index:1;
        color:white;

     
       
    }
    .borrar.letraspequeborrar{
       
     
      
    }
    .cuadrogrande{
        display:flex;
        position: absolute;;;
        width:100%;

    height:100% !important;
        background-color:#FDF8F7;
    
        MARGIN:1rem;
        max-width:40vw;
        border-radius:15px; 
   
    }
    .mySwiper3 .cuadrogrande1{
        display:flex;
        position: absolute;;
        width:100%;
       
        max-width:20vw;
    height:10vh;
        background-color:#D8D8D8;
        opacity:0.5;
        MARGIN:AUTO;
        border-radius:15px; 
       margin-bottom:2vh;
    }
    .mySwiper2 {
        height:7rem;
        width:100% !important;
      
        }
    .mySwiper{
        display:flex; 
        width:100%;;
        margin-left:2vw;
        padding-right:2vw !important; 
    }
 
}












.mySwiper .swiper-slide{
    overflow: hidden;
    -webkit-overflow-scrolling:touch;
}
.mySwiper .swiper-slide:first-child:active{
    width:430px !important
}
.mySwiper .swiper-slide:last-child:active{
    width:300px !important
}








