@media (min-width: 100px) and (max-width:  920px) {
    .nosvemos{
        display:none
    }
    
.entrenadorInicioContainer {
    min-height: 100vh;
    overflow-x: hidden;
    
    text-align: center;
}
}
@media (min-width: 921px){
   
    .listaReservasentre{
        margin:0 4vw  !important;
        position:relative;
      
        width:none !important
    }
    .entrenadorInicioContainer {
      
        overflow-x: hidden;
        overflow-y: auto;
        
        width:100vw;
        height: calc(100vh - 70px);
  
        text-align: center;
    }
 
}



.entrenadorInicioContainer .sliderReservas .swiper-slide {
    text-align: left;
  font-size:4vw;

   
}