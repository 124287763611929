@media (min-width: 921px) and (max-width:  1370px) {
    .entrenadorInicioContainer .titlec {
        color: #394F65;
        font-weight: bolder;
        letter-spacing: -1px;
        font-size: 2vw;
        margin-left: 6.5vw !important;
     
    }
    .entrenadorVerReserva .info .item {
        margin: 2vh auto;
    }
}
@media (min-width: 1371px){
    .entrenadorInicioContainer .titlec {
        color: #394F65;
        font-weight: bolder;
        letter-spacing: -1px;
        font-size: 3vw;
        margin-left: 6.5vw !important;
     
    }
    .entrenadorVerReserva .info .item {
        margin: 3vh auto;
    }
}


@media (min-width: 921px){
 
    .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnReserva {
        padding: 0.4rem 2rem;
        margin-top:0 !important;
        background:#6EE1FB !important;
        width: auto;
        white-space: nowrap;
        font-size:0.8vw !important;
    }
    .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnReservacost1 {
        padding: 0.4rem 2rem;
        background:#FFB6A4 !important;
        margin-top:0 !important;
        width: auto;
        white-space: nowrap;
        font-size:0.8vw !important;
    }
    .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnReservacost2 {
        padding: 0.4rem 2rem;
        background:#B381BE !important;
        margin-top:0 !important;
        width: auto;
        font-size:0.8vw !important;
    }
    .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnReservacost3 {
        padding: 0.4rem 2rem !important;
        margin-top:0 !important;
        white-space: nowrap;
        background:#CB3D82 !important;
      text-align:center;
          font-size:0.8vw !important;
        width: 11vw;
    }
    .borderkm_clases{
    
            margin-top: 3vh
        ;
            border-right: 1px
         solid #959595;
            height: 14vh
         !important;
            margin-bottom: 0.8vh
        ;
            opacity: 0.4;
        
    }
    .entrenadorInicioContainer .sliderReservas .itemReserva .bg {
        background-color: black;
        opacity: 0.2;
        height: 100%;
        position: absolute;
        width: 90%;
    }
    .corregr_botin{
        margin-top:0vh !important
    }
    .entrenadorInicioContainer .sliderReservas .itemReserva {
      
        height: 40vh !important;
        min-height: 180px;
        background-size: contain;
        
        color: white;
        font-size: 3.3vmin;
        line-height: 1;

    
     
        max-width: 100vw;
    
     
     
        background-repeat: no-repeat;
        position: relative;
       
    }
  
    .entrenadorInicioContainer  .NoClase1 {
        color:#394F65;
        text-align: center;
        font-weight: bold;
        font-size:1.5vw;
        margin-top: 45px;
    }
    .dalepower{
        width: 15% !important;
        left: 5.6% !important;
    }
    .dalepowerl{
        width: 65% !important;
        left: 5.6% !important;
    }
    .textocentradop{
        margin-left: 12% !important;
        text-align:left;
    }
    .nuevovacio{
        width:16%;
    }
    .nuevovacio1{
        width:13%;
    }
    .nuevovacio2{
        width:13%;
    }
    .nuevovacio4{
        width:10%;
    }
    .nuevovacio3{
        width:35%;
    }
    .nuevovacio8{
        width:26%;
    }
    .nuevovacio7{
        width:13%;
    }
    .colorvacios{
        color: #394F65 !important;
        font-weight: bolder;
        letter-spacing: -1px;
        font-size: 1.3rem;
    }
    

  
    .entrenadorInicioContainer .listClasesDisponibles .acomodar {
       display:flex;
       background-color:#F7F7F7;
       justify-content: center;
       width:100%;
       margin: auto 10vw 0 3%;
    }
    .entrenadorInicioContainer .listClasesDisponibles .acomodartitulo {
       display:flex;
       background-color:#F7F7F7;
       justify-content: center;
       width:100%;
       margin: auto 5% 0 5%;
    }
   
    .entrenadorInicioContainer .title {
        color: #394F65;
        font-weight: bolder;
        letter-spacing: -1px;
        font-size: 2rem;
        margin: 1rem auto;
        margin: 2rem auto  0rem auto;
    }
    .textodebajotex {
        color: #262626;
      font-weight:400;
       
        font-size: 1rem;
        margin: 1rem auto;
       ;
    }
  
    .entrenadorInicioContainer .titlec1 {
        color: #394F65;
        font-weight: bolder;
        letter-spacing: -1px;
        font-size: 3rem;
        margin-left:1vw;
        
     
    }
    .cabeceraPerfil .titlec {
        color: #394F65;
        margin-left:1vw;
        font-weight: bolder;
        letter-spacing: -1px;
        font-size: 3rem;
     
     
    }
    .entrenadorInicioContainer .listClasesDisponibles .botonReservar {
        margin-bottom: 0rem;
        padding:0 8vw;
    }
    
    .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnReserva {
        padding: 0.4rem 2rem;
     justify-content: center;
     display:inline-flex;
        vertical-align:middle;
    }
    
    .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnCalendar {
        width: 1.5rem;
        margin-left: 0.5rem;
    }
  
  

    .centrarbotonentre{margin-left:80% !important;
    }
    .entrenadorInicioContainer .sliderReservas .itemReserva .bot {
        position: absolute;
        top: 0%;
        left: 70%;
        height: 100% !important;
        width: 30%;
    }
 
    .entrenadorInicioContainer .calendar-title {
        width: 1.5rem;
        margin-left: 0.5rem;
    }
    .text1 {
        position: absolute;
     top:60%;
        font-size:1vw;
        padding-left:3%;
        text-transform:lowercase !important;
    }
    .textmodi {
        position: absolute !important;
   
      left:5% !important;
        font-size:1vw;
      
        text-transform:lowercase !important;
    }

    .text1 .linere{
        width:9vw;
        margin:1vh 1vw 1vh 1vw;
        border-bottom:0.1vh solid white;
    }
    .text1 div:first-letter { text-transform: capitalize }
    .text2:first-letter { text-transform: capitalize }
    .text2{
        position: absolute !important;
  left:3%;
        text-transform:lowercase !important;
    }
}
    @media (min-width: 100px) and (max-width:  920px) {
        .entrenadorInicioContainer .sliderReservas .itemReserva .bg {
            background-color: black;
            opacity: 0.0;
            height: 100%;
            position: absolute;
            width: 100%;
        }
        .entrenadorInicioContainer .sliderReservas .itemReserva {


    
            min-height: 25vh;
            max-width: 100vw;
            margin: auto;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            top: 0;
        }
        .marginbotonreservaentrenadors{
            margin:auto 15vw !important
        }
        .entrenadorInicioContainer .calendar-title {
            width: 2rem;
            margin-left: 1rem;
        }
        .cositaver{
            background-color: #7B86FA !important;
        }
        .text1{
            position: absolute !important;
            padding:10%;
         color:#394F65;
            text-transform:lowercase !important;
        }
        .text1_usuario{
            position: absolute !important;
            top:0%;
            padding:5%;
         
            text-transform:capitalize !important;
        }
   
        .text1 div:first-letter { text-transform: capitalize }
        .text2{
            position: absolute !important;
      
            text-transform:lowercase !important;
        }
        .letricaregu{
            font-weight:400;
        }
        .entrenadorInicioContainer .sliderReservas .NoClase {
            color: black;
           
            text-align: center;
            font-weight: bold;
            margin-top: 20px;
        }
        .entrenadorInicioContainer .sliderReservas .itemReserva .bot {
            position: absolute;
           bottom: 0;
           width: 100%;
           height: 35%;
       }
        .entrenadorInicioContainer .listClasesDisponibles .botonReservar {
            margin-bottom: 1rem;
        }
     
        .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnReserva {
            padding: 0.4rem 2rem;
            margin-top:0 !important;
            background:#6EE1FB !important;
            width: auto;
        }
     
        .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnReservacost1 {
            padding: 0.4rem 2rem;
            background:#FFB6A4 !important;
            margin-top:0 !important;
            width: auto;
        }
        .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnReservacost2 {
            padding: 0.4rem 2rem;
            background:#B381BE !important;
            margin-top:0 !important;
            width: auto;
        }
        .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnReservacost3 {
            padding: 0.4rem 2rem;
            margin-top:0 !important;
            background:#CB3D82 !important;
            width: auto;
        }
        
        .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnCalendar {
            width: 4.5vw;
            margin-left: 0.5rem;
        }
        .entrenadorInicioContainer .listClasesDisponibles .botonReservar .btnCalendarequis {
            width: 3.5vw;
            margin-left: 0.5rem;
        }
        .semanalinea{
            border-bottom:1px solid #bebebe;
            opacity:0.4;
            margin: 10px 10px;
        }
        .centrarbotonentre{justify-content: center;
        }
        .entrenadorInicioContainer .title {
            color: #394F65;
            font-weight: bolder;
            letter-spacing: -1px;
            font-size: 4.5vw;
            margin: 1rem auto;
        }
    }


.entrenadorInicioContainer {
    text-align: center;

 margin:auto;
    min-height: 80vh;
}
.entrenadorInicioContainersa {
    text-align: center;
    width:95vw;
 margin:auto;
    min-height: 80vh;
}


.entrenadorInicioContainer .subtitle {
    color: #000;
    font-size: 1rem;
}









.entrenadorInicioContainer .sliderReservas .itemReserva .bg .text {
    position: absolute;
    top: 30%;
    transform: translateY(-30%);
}



.entrenadorInicioContainer .bulletsSlider .active {
    opacity: 1 !important;
}

.entrenadorInicioContainer .bulletsSlider .swiper-pagination-bullet {
    height: 13px;
    width: 13px;
    background-color: #9EACED;
    opacity: 0.5;
}

.entrenadorInicioContainer .verReservasEntrenador {
    font-size: 1rem;
    padding: 0.1rem 0.7rem;
   
}

.entrenadorInicioContainer .selectFilterClases {
    margin:2vh  15vw !important ;
    max-width: 250px;
    width: 100%;
}

.entrenadorInicioContainer .selectFilterClases option {
    color: #9DACBD;
}



.entrenadorInicioContainer .listClasesDisponibles .tipoClase {
    color: #394F65;
    font-weight: bold;
}

.entrenadorInicioContainer .listClasesDisponibles .horaClase {
    color:  #394F65;
}

.modalContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1021;
    background-color: rgba(0, 0, 0, 0.562);
}

.modalContainer .modalContent {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 330px;
    min-height: 310px;
    background-color: white;
    z-index: 1022;
    width: 50%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:1.5rem;
    line-height: 1;
}

.Penalizado {
    color: red;
}
