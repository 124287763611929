.lineareservatuclasemis{
        border-bottom: 1px solid #BEBEBE;
        opacity:0.5;
        position:absolute !important;
     margin-left:8vw !important;
     padding:5.59vh 0;
        width:calc(100% - 21vw);
 
    
}
.informacionmisclases{width: 40%;
    height: 10vh !important;
  position:absolute !important;
    width:54% !important;
    top: 67.6% !important;
    left: 46% !important;
  
    margin-right: -1vw;}
    .informacionmisclases .tamañosver{
        font-size:1rem !important;
    }
    .aero img {
        width: 100% !important;
        max-width:2.5vw;
      padding:0.3vw;
    }
    .informacionmisclases .tamañosaerobico{
        font-size:0.6rem !important;
      
    }
    .informacionmisclases .calorias{
        background-color: #9EACED !important;
    }
.grupales{

    overflow-y: auto;
    overflow-x: hidden; 
    width:100vw;
     height:calc(91vh - 225px);
}
.listaReservasmisclases .itemReservagrupal {
   
    height: 60%;
    position: relative;
    background-repeat:no-repeat;
    
    z-index: 1;
    
}
.botonesPestañas{
    BACKGROUND-color:#1E7980;
    COLOR:WHITE;
    height:50px;
    border:#2A9DA5;
    width:15vw;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: -30px;
    border-bottom-left-radius: -30px;
}
.botonesPestañas1{
    BACKGROUND-color:#2A9DA5;
    COLOR:WHITE;
  width:15vw;
    height:50px;
    border:#2A9DA5;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: -30px;
    border-bottom-left-radius: -30px;
}
.Entrenamientomisclases .reserva1 {
    position:relative;
    margin-top: 0 !important;
    height: 30vh !important;
    background-size:cover;
    background-position:center;
    transform: scaleX(-1) !important;

   
}
.botonespestañasall{
position:relative;
height:99px;
    cursor:pointer;
    display:flex;
    justify-content: center;
    
}
.botonespestañasall .letrasbotones{
    color: white;
    font-weight:bold;
    position:relative;
   
    text-align:center;
    top:49%;
    margin: 0 5rem;
    display:inline-flex;
   
z-index:1;
    
    font-size: calc(0.7em + 0.3vw);
    
}
.botonespestañasall .letrasbotones1{
    color: white;
    font-weight:bold;
    position:relative;
    text-align:center;
    display:inline-flex;
   top:49%;
   margin: 0 4rem;
   
z-index:1;
    
    font-size: calc(0.7em + 0.3vw);
    
}
.botonespestañasall  img{
  
  height:55px;
    position:relative;
   
    
}
.botonespestañasall  .botonespestañas1{
margin-left:100px;
position:fixed;

   
}
.botonespestañasall  .botonespestañas2{
    margin-left:190px;
   
    
}
.letra_cabeza{
    font-size:1vw !important
}

.reservamisclases {
    position: relative;
    top: 4% !important;
    color: white;
    font-size: calc(1em + 1vw);
    margin-top:1vh !important;
    left: 45% !important;
    width: 100%
}

    .Entrenamientomisclases {
       
        background-repeat: no-repeat;
        background-size: cover;
        height: 30.9vh !important;
        position: relative;
        width:100% !important;
        margin-right:0vw !important;
        top: 0;
        
    }
    .listaReservasmisclases {
        position: relative;
        left: 3%;
        width:77%;
    }
    .listaReservasmisclasesCardif {
        position: relative;
       
        width:80%;
    }

    .Entrenamientomisclases .reserva *{
        transform: scaleX(-1);
        /* rever the text*/
    }
    .Entrenamientomisclases .lineop {
        position:absolute;
        top:0% !important;
        width:65% !important;
      left:44%; 
    border-top:3px solid #9EACED;
        opacity:1;
      
        margin-top: 1vh;
    
    }