/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"); */


@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css");
@keyframes touch {
	to {
		width: 600px;
		height: 600px;
		border-radius: 600px;
		opacity: 0;
		margin: -300px
	}
}

@keyframes box-animate {
	0% {
		width: 1px;
		height: 1px;
		border-radius: 240px;
		opacity: 1
	}
	50% {
		opacity: .8
	}
	90% {
		width: 2rem;
		height: 2rem;
		opacity: 0
	}
	to {
		opacity: 0
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg)
	}
	to {
		transform: rotate(360deg)
	}
}

@keyframes bar {
	0% {
		width: 0
	}
	to {
		width: 100%
	}
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, main, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
	display: block
}

[hidden] {
	display: none
}

body {
	line-height: 1.5;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	display: flex;
	flex-direction: column
}

menu, ol, ul {
	list-style: none
}

blockquote, q {
	quotes: none
}

blockquote:after, blockquote:before, q:after, q:before {
	content: none
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

hr {
	border-top: 1px solid #f2f2f2
}

/* body, h1, h2, h3, h4, h5, h6 {
	font-family: "WorkSans", sans-serif
} */

/* @font-face {
	font-family: 'mob';
	src: url("../fonts/mob.eot?uk8w17");
	src: url("../fonts/mob.eot?uk8w17#iefix") format("embedded-opentype"), url("../fonts/mob.woff?uk8w17") format("woff"), url("../fonts/mob.svg?uk8w17#ds") format("svg");
	font-weight: 400;
	font-style: normal;
	font-display: block
} */

[class*=" ico-"], [class^=ico-] {
	margin-left: 0px;
	color: #fff;
	font-size: 30px;
	font-family: 'mob'!important;
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.ico-facebook:before {
	content: "\e91d"
}

.ico-instragram:before {
	content: "\e91e"
}

.ico-youtube:before {
	content: "\e91f"
}

*, :after, :before {
	box-sizing: border-box
}

body, html {
	height: 100%
}

a, button {
	color: #CB3D82;
	outline: 0;
	transition: color .1s ease-out
}

a, a.button:hover, button, button.button:hover {
	text-decoration: none
}

a:focus, a:hover, button:focus, button:hover {
	/* color: #228389;
	text-decoration: underline */
}

a.text-link, button.text-link {
	font-family: "WorkSans", sans-serif;
	font-size: 1rem;
	text-transform: uppercase;
	border: 0;
	font-weight: 700;
	line-height: 1;
	outline: 0;
	background-color: transparent;
	overflow: hidden;
	display: inline-flex;
	align-items: center;
	padding: 0;
	cursor: pointer
}

a.text-link.text-primary-base, button.text-link.text-primary-base {
	color: #2a9da5
}

a.text-link.text-primary-base:hover, button.text-link.text-primary-base:hover {
	color: #228389
}

.cards hr, .m-none {
	margin: 0
}

.m-tiny {
	margin: .5rem
}

.m-xsmall {
	margin: .75rem
}

.m-small {
	margin: .875rem
}

.m-base {
	margin: 1rem
}

.m-thumb {
	margin: 1.125rem
}

.m-mid {
	margin: 1.25rem
}

.m-half {
	margin: 1.5rem
}

.m-medium {
	margin: 2rem
}

.m-heavy {
	margin: 2.5rem
}

.m-large {
	margin: 3rem
}

.m-xlarge {
	margin: 3.5rem
}

.m-huge {
	margin: 4rem
}

.mt-none {
	margin-top: 0
}

.mt-tiny {
	margin-top: .5rem
}

.mt-xsmall {
	margin-top: .75rem
}

.mt-small {
	margin-top: .875rem
}

.mt-base {
	margin-top: 1rem
}

.mt-thumb {
	margin-top: 1.125rem
}

.mt-mid {
	margin-top: 1.25rem
}

.mt-half {
	margin-top: 1.5rem
}

.mt-medium {
	margin-top: 2rem
}

.mt-heavy {
	margin-top: 2.5rem
}

.mt-large {
	margin-top: 3rem
}

.mt-xlarge {
	margin-top: 3.5rem
}

.mt-huge {
	margin-top: 4rem
}

.mb-none {
	margin-bottom: 0
}

.mb-tiny {
	margin-bottom: .5rem
}

.mb-xsmall {
	margin-bottom: .75rem
}

.mb-small {
	margin-bottom: .875rem
}

.mb-base {
	margin-bottom: 1rem
}

.mb-thumb {
	margin-bottom: 1.125rem
}

.mb-mid {
	margin-bottom: 1.25rem
}

.mb-half {
	margin-bottom: 1.5rem
}

.mb-medium {
	margin-bottom: 2rem
}

.mb-heavy {
	margin-bottom: 2.5rem
}

.mb-large {
	margin-bottom: 3rem
}

.mb-xlarge {
	margin-bottom: 3.5rem
}

.mb-huge {
	margin-bottom: 4rem
}

.mr-none {
	margin-right: 0
}

.mr-tiny {
	margin-right: .5rem
}

.mr-xsmall {
	margin-right: .75rem
}

.mr-small {
	margin-right: .875rem
}

.header__nav li, .mr-base {
	margin-right: 1rem
}

.mr-thumb {
	margin-right: 1.125rem
}

.mr-mid {
	margin-right: 1.25rem
}

.mr-half {
	margin-right: 1.5rem
}

.mr-medium {
	margin-right: 2rem
}

.mr-heavy {
	margin-right: 2.5rem
}

.mr-large {
	margin-right: 3rem
}

.mr-xlarge {
	margin-right: 3.5rem
}

.mr-huge {
	margin-right: 4rem
}

.ml-none {
	margin-left: 0
}

.ml-tiny {
	margin-left: .5rem
}

.ml-xsmall {
	margin-left: .75rem
}

.ml-small {
	margin-left: .875rem
}

.ml-base {
	margin-left: 1rem
}

.ml-thumb {
	margin-left: 1.125rem
}

.ml-mid {
	margin-left: 1.25rem
}

.ml-half {
	margin-left: 1.5rem
}

.ml-medium {
	margin-left: 2rem
}

.ml-heavy {
	margin-left: 2.5rem
}

.ml-large {
	margin-left: 3rem
}

.ml-xlarge {
	margin-left: 3.5rem
}

.ml-huge {
	margin-left: 4rem
}

.my-none {
	margin-top: 0;
	margin-bottom: 0
}

.my-tiny {
	margin-top: .5rem;
	margin-bottom: .5rem
}

.my-xsmall {
	margin-top: .75rem;
	margin-bottom: .75rem
}

.my-small {
	margin-top: .875rem;
	margin-bottom: .875rem
}

.my-base {
	margin-top: 1rem;
	margin-bottom: 1rem
}

.my-thumb {
	margin-top: 1.125rem;
	margin-bottom: 1.125rem
}

.my-mid {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem
}

.my-half {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem
}

.my-medium {
	margin-top: 2rem;
	margin-bottom: 2rem
}

.my-heavy {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem
}

.my-large {
	margin-top: 3rem;
	margin-bottom: 3rem
}

.my-xlarge {
	margin-top: 3.5rem;
	margin-bottom: 3.5rem
}

.my-huge {
	margin-top: 4rem;
	margin-bottom: 4rem
}

.mx-none {
	margin-left: 0;
	margin-right: 0
}

.mx-tiny {
	margin-left: .5rem;
	margin-right: .5rem
}

.mx-xsmall {
	margin-left: .75rem;
	margin-right: .75rem
}

.mx-small {
	margin-left: .875rem;
	margin-right: .875rem
}

.mx-base {
	margin-left: 1rem;
	margin-right: 1rem
}

.mx-thumb {
	margin-left: 1.125rem;
	margin-right: 1.125rem
}

.mx-mid {
	margin-left: 1.25rem;
	margin-right: 1.25rem
}

.mx-half {
	margin-left: 1.5rem;
	margin-right: 1.5rem
}

.mx-medium {
	margin-left: 2rem;
	margin-right: 2rem
}

.mx-heavy {
	margin-left: 2.5rem;
	margin-right: 2.5rem
}

.mx-large {
	margin-left: 3rem;
	margin-right: 3rem
}

.mx-xlarge {
	margin-left: 3.5rem;
	margin-right: 3.5rem
}

.mx-huge {
	margin-left: 4rem;
	margin-right: 4rem
}

.p-none {
	padding: 0
}

.p-tiny {
	padding: .5rem
}

.p-xsmall {
	padding: .75rem
}

.p-small {
	padding: .875rem
}

.p-base {
	padding: 1rem
}

.p-thumb {
	padding: 1.125rem
}

.p-mid {
	padding: 1.25rem
}

.p-half {
	padding: 1.5rem
}

.p-medium {
	padding: 2rem
}

.p-heavy {
	padding: 2.5rem
}

.p-large {
	padding: 3rem
}

.p-xlarge {
	padding: 3.5rem
}

.p-huge {
	padding: 4rem
}

.pt-none {
	padding-top: 0
}

.pt-tiny {
	padding-top: .5rem
}

.pt-xsmall {
	padding-top: .75rem
}

.pt-small {
	padding-top: .875rem
}

.pt-base {
	padding-top: 1rem
}

.pt-thumb {
	padding-top: 1.125rem
}

.pt-mid {
	padding-top: 1.25rem
}

.pt-half {
	padding-top: 1.5rem
}

.pt-medium {
	padding-top: 2rem
}

.pt-heavy {
	padding-top: 2.5rem
}

.pt-large {
	padding-top: 3rem
}

.pt-xlarge {
	padding-top: 3.5rem
}

.pt-huge {
	padding-top: 4rem
}

.pb-none {
	padding-bottom: 0
}

.pb-tiny {
	padding-bottom: .5rem
}

.pb-xsmall {
	padding-bottom: .75rem
}

.pb-small {
	padding-bottom: .875rem
}

.pb-base {
	padding-bottom: 1rem
}

.pb-thumb {
	padding-bottom: 1.125rem
}

.pb-mid {
	padding-bottom: 1.25rem
}

.pb-half {
	padding-bottom: 1.5rem
}

.pb-medium {
	padding-bottom: 2rem
}

.pb-heavy {
	padding-bottom: 2.5rem
}

.pb-large {
	padding-bottom: 3rem
}

.pb-xlarge {
	padding-bottom: 3.5rem
}

.pb-huge {
	padding-bottom: 4rem
}

.pr-none {
	padding-right: 0
}

.pr-tiny {
	padding-right: .5rem
}

.pr-xsmall {
	padding-right: .75rem
}

.pr-small {
	padding-right: .875rem
}

.pr-base {
	padding-right: 1rem
}

.pr-thumb {
	padding-right: 1.125rem
}

.pr-mid {
	padding-right: 1.25rem
}

.pr-half {
	padding-right: 1.5rem
}

.pr-medium {
	padding-right: 2rem
}

.pr-heavy {
	padding-right: 2.5rem
}

.pr-large {
	padding-right: 3rem
}

.pr-xlarge {
	padding-right: 3.5rem
}

.pr-huge {
	padding-right: 4rem
}

.pl-none {
	padding-left: 0
}

.pl-tiny {
	padding-left: .5rem
}

.pl-xsmall {
	padding-left: .75rem
}

.pl-small {
	padding-left: .875rem
}

.pl-base {
	padding-left: 1rem
}

.pl-thumb {
	padding-left: 1.125rem
}

.pl-mid {
	padding-left: 1.25rem
}

.pl-half {
	padding-left: 1.5rem
}

.pl-medium {
	padding-left: 2rem
}

.pl-heavy {
	padding-left: 2.5rem
}

.pl-large {
	padding-left: 3rem
}

.pl-xlarge {
	padding-left: 3.5rem
}

.pl-huge {
	padding-left: 4rem
}

.py-none {
	padding-top: 0;
	padding-bottom: 0
}

.py-tiny {
	padding-top: .5rem;
	padding-bottom: .5rem
}

.py-xsmall {
	padding-top: .75rem;
	padding-bottom: .75rem
}

.py-small {
	padding-top: .875rem;
	padding-bottom: .875rem
}

.py-base {
	padding-top: 1rem;
	padding-bottom: 1rem
}

.py-thumb {
	padding-top: 1.125rem;
	padding-bottom: 1.125rem
}

.py-mid {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem
}

.py-half {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem
}

.py-medium {
	padding-top: 2rem;
	padding-bottom: 2rem
}

.py-heavy {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem
}

.py-large {
	padding-top: 3rem;
	padding-bottom: 3rem
}

.py-xlarge {
	padding-top: 3.5rem;
	padding-bottom: 3.5rem
}

.py-huge {
	padding-top: 4rem;
	padding-bottom: 4rem
}

.px-none {
	padding-left: 0;
	padding-right: 0
}

.px-tiny {
	padding-left: .5rem;
	padding-right: .5rem
}

.px-xsmall {
	padding-left: .75rem;
	padding-right: .75rem
}

.px-small {
	padding-left: .875rem;
	padding-right: .875rem
}

.px-base {
	padding-left: 1rem;
	padding-right: 1rem
}

.px-thumb {
	padding-left: 1.125rem;
	padding-right: 1.125rem
}

.px-mid {
	padding-left: 1.25rem;
	padding-right: 1.25rem
}

.px-half {
	padding-left: 1.5rem;
	padding-right: 1.5rem
}

.px-medium {
	padding-left: 2rem;
	padding-right: 2rem
}

.px-heavy {
	padding-left: 2.5rem;
	padding-right: 2.5rem
}

.px-large {
	padding-left: 3rem;
	padding-right: 3rem
}

.px-xlarge {
	padding-left: 3.5rem;
	padding-right: 3.5rem
}

.px-huge {
	padding-left: 4rem;
	padding-right: 4rem
}

.elevation-1 {
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, .05), 0 2px 2px 0 rgba(0, 0, 0, .05), 0 1px 3px 0 rgba(0, 0, 0, .05)
}

.elevation-2 {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, .05), 0 3px 4px 0 rgba(0, 0, 0, .05), 0 1px 5px 0 rgba(0, 0, 0, .05)
}

.elevation-3 {
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .05), 0 3px 4px 0 rgba(0, 0, 0, .05), 0 1px 7px 0 rgba(0, 0, 0, .05)
}

.elevation-4 {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05), 0 4px 5px 0 rgba(0, 0, 0, .05), 0 1px 8px 0 rgba(0, 0, 0, .05)
}

.elevation-5 {
	box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .05), 0 4px 5px 0 rgba(0, 0, 0, .05), 0 1px 10px 0 rgba(0, 0, 0, .05)
}

.elevation-6 {
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .05), 0 1px 18px 0 rgba(0, 0, 0, .05), 0 3px 5px 0 rgba(0, 0, 0, .05)
}

.elevation-7 {
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .05), 0 2px 18px 1px rgba(0, 0, 0, .05), 0 3px 8px 0 rgba(0, 0, 0, .05)
}

.elevation-8 {
	box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .05), 0 3px 14px 2px rgba(0, 0, 0, .05), 0 4px 15px 0 rgba(0, 0, 0, .05)
}

.elevation-9 {
	box-shadow: 0 9px 12px 2px rgba(0, 0, 0, .05), 0 3px 18px 3px rgba(0, 0, 0, .05), 0 5px 11px 0 rgba(0, 0, 0, .05)
}

.elevation-10 {
	box-shadow: 0 10px 13px 2px rgba(0, 0, 0, .05), 0 4px 16px 3px rgba(0, 0, 0, .05), 0 5px 14px 0 rgba(0, 0, 0, .05)
}

.elevation-11 {
	box-shadow: 0 11px 14px 2px rgba(0, 0, 0, .05), 0 4px 18px 3px rgba(0, 0, 0, .05), 0 6px 10px 0 rgba(0, 0, 0, .05)
}

.elevation-12 {
	box-shadow: 0 12px 16px 2px rgba(0, 0, 0, .05), 0 5px 22px 4px rgba(0, 0, 0, .05), 0 6px 13px 0 rgba(0, 0, 0, .05)
}

.elevation-13 {
	box-shadow: 0 13px 18px 2px rgba(0, 0, 0, .05), 0 5px 26px 4px rgba(0, 0, 0, .05), 0 7px 10px 0 rgba(0, 0, 0, .05)
}

.elevation-14 {
	box-shadow: 0 14px 21px 2px rgba(0, 0, 0, .05), 0 5px 29px 4px rgba(0, 0, 0, .05), 0 7px 12px 0 rgba(0, 0, 0, .05)
}

.elevation-15 {
	box-shadow: 0 15px 22px 2px rgba(0, 0, 0, .05), 0 6px 34px 5px rgba(0, 0, 0, .05), 0 7px 14px 0 rgba(0, 0, 0, .05)
}

.elevation-16 {
	box-shadow: 0 16px 23px 2px rgba(0, 0, 0, .05), 0 6px 30px 5px rgba(0, 0, 0, .05), 0 8px 13px 0 rgba(0, 0, 0, .05)
}

.elevation-17 {
	box-shadow: 0 17px 24px 2px rgba(0, 0, 0, .05), 0 6px 36px 5px rgba(0, 0, 0, .05), 0 8px 15px 0 rgba(0, 0, 0, .05)
}

.elevation-18 {
	box-shadow: 0 18px 25px 3px rgba(0, 0, 0, .05), 0 7px 33px 6px rgba(0, 0, 0, .05), 0 9px 11px 0 rgba(0, 0, 0, .05)
}

.elevation-19 {
	box-shadow: 0 19px 26px 3px rgba(0, 0, 0, .05), 0 7px 37px 6px rgba(0, 0, 0, .05), 0 9px 13px 0 rgba(0, 0, 0, .05)
}

.d-flex {
	display: flex
}

.d-inline-flex {
	display: inline-flex
}

.d-block {
	display: block
}

.d-inline-block {
	display: inline-block
}

.d-grid {
	display: grid
}

.d-none {
	display: none
}

@media (min-width:600px) {
	.d-sm-flex {
		display: flex
	}
	.d-sm-block {
		display: block
	}
	.d-sm-grid {
		display: grid
	}
	.d-sm-none {
		display: none
	}
}

@media (min-width:960px) {
	.d-md-flex {
		display: flex
	}
	.d-md-block {
		display: block
	}
	.d-md-grid {
		display: grid
	}
	.d-md-none {
		display: none
	}
}

@media (min-width:1280px) {
	.d-lg-flex {
		display: flex
	}
	.d-lg-block {
		display: block
	}
	.d-lg-grid {
		display: grid
	}
	.d-lg-none {
		display: none
	}
}

@media (min-width:1920px) {
	.d-xl-flex {
		display: flex
	}
	.d-xl-block {
		display: block
	}
	.d-xl-grid {
		display: grid
	}
	.d-xl-none {
		display: none
	}
}


.d-row {
	display: row
}

.d-row-reverse {
	display: row-reverse
}

.d-column {
	display: column
}

.d-column-reverse {
	display: column-reverse
}

.jc-flex-start {
	justify-content: flex-start
}

.jc-flex-end {
	justify-content: flex-end
}

.jc-center {
	justify-content: center
}

.jc-space-between {
	justify-content: space-between
}

.jc-space-around {
	justify-content: space-around
}

.jc-space-evenly {
	justify-content: space-evenly
}

.ac-flex-start {
	align-content: flex-start
}

.ac-flex-end {
	align-content: flex-end
}

.ac-center {
	align-content: center
}

.ac-space-between {
	align-content: space-between
}

.ac-space-around {
	align-content: space-around
}

.ac-stretch {
	align-content: stretch
}

.ai-flex-start {
	align-items: flex-start
}

.ai-flex-end {
	align-items: flex-end
}

.ai-center {
	align-items: center
}

.ai-baseline {
	align-items: baseline
}

.ai-stretch {
	align-items: stretch
}

.as-flex-start {
	align-self: flex-start
}

.as-flex-end {
	align-self: flex-end
}

.as-center {
	align-self: center
}

.as-baseline {
	align-self: baseline
}

.as-stretch {
	align-self: stretch
}

.text-primary-base {
	color: #2a9da5
}

.text-primary-light {
	color: #2bb7c1
}

.text-primary-dark {
	color: #228389
}

.header__nav li a.active, .text-grayscale-white {
	color: #fff
}

.text-grayscale-gray-1 {
	color: #f2f2f2
}

.text-grayscale-gray-2 {
	color: #eee
}

.text-grayscale-gray-3 {
	color: #ccc
}

.text-grayscale-gray-4 {
	color: #999
}

.text-grayscale-gray-5 {
	color: #666
}

.text-grayscale-gray-6 {
	color: #333
}

.text-grayscale-gray-7 {
	color: #262626
}

.text-grayscale-base {
	color: #1b1b1b
}

.text-grayscale-black {
	color: #000
}

.text-none {
	font-size: 0;
	transition: .3s ease
}

.text-tiny, .text-xsmall {
	font-size: .5rem;
	transition: .3s ease
}

.text-xsmall {
	font-size: .75rem
}

.text-base, .text-small {
	font-size: .875rem;
	transition: .3s ease
}

.text-base {
	font-size: 1rem
}

.text-mid, .text-thumb {
	font-size: 1.125rem;
	transition: .3s ease
}

.text-mid {
	font-size: 1.25rem
}

.text-half, .text-medium {
	font-size: 1.5rem;
	transition: .3s ease
}

.text-medium {
	font-size: 2rem
}

.text-heavy, .text-large {
	font-size: 2.5rem;
	transition: .3s ease
}

.text-large {
	font-size: 3rem
}

.text-huge, .text-xlarge {
	font-size: 3.5rem;
	transition: .3s ease
}

.text-huge {
	font-size: 4rem
}

.thin {
	font-weight: 100
}

.black.italic, .bold.italic, .light.italic, .medium.italic, .regular.italic, .semibold.italic, .thin.italic, .xbold.italic, .xlight.italic {
	font-style: italic
}

.xlight {
	font-weight: 200
}

.light {
	font-weight: 300
}

.regular {
	font-weight: 400
}

.medium {
	font-weight: 500
}

.semibold {
	font-weight: 600
}

.bold {
	font-weight: 700
}

.xbold {
	font-weight: 800
}

.black {
	font-weight: 900
}

@media (max-width:599px) {
	.text-medium {
		font-size: 1.5rem
	}
	.text-large {
		font-size: 2.25rem
	}
	.text-xlarge {
		font-size: 2.625rem
	}
	.text-huge {
		font-size: 3rem
	}
}

.text-left {
	text-align: left
}

.text-center {
	text-align: center
}

.text-right {
	text-align: right
}

.text-justify {
	text-align: justify
}

.bg-primary-base {
	background: #2a9da5
}

.bg-primary-light {
	background: #2bb7c1
}

.bg-primary-dark {
	background: #228389
}

.bg-grayscale-white {
	background: #fff
}

.bg-grayscale-gray-1 {
	background: #f2f2f2
}

.bg-grayscale-gray-2 {
	background: #eee
}

.bg-grayscale-gray-3 {
	background: #ccc
}

.bg-grayscale-gray-4 {
	background: #999
}

.bg-grayscale-gray-5 {
	background: #666
}

.bg-grayscale-gray-6 {
	background: #333
}

.bg-grayscale-gray-7 {
	background: #262626
}

.bg-grayscale-base {
	background: #1b1b1b
}

.bg-grayscale-black {
	background: #000
}

.color {
	border-radius: 100%;
	width: 6rem;
	height: 6rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-right: .4rem
}

@media (max-width:599px) {
	.color {
		width: 4.5rem;
		height: 4.5rem;
		font-size: .9rem;
		margin-bottom: .5rem
	}
}

.opacity-0 {
	opacity: 0
}

.opacity-1 {
	opacity: .1
}

.opacity-2 {
	opacity: .2
}

.opacity-3 {
	opacity: .3
}

.opacity-4 {
	opacity: .4
}

.opacity-5 {
	opacity: .5
}

.opacity-6 {
	opacity: .6
}

.opacity-7 {
	opacity: .7
}

.opacity-8 {
	opacity: .8
}

.opacity-9 {
	opacity: .9
}

.opacity-10 {
	opacity: 1
}

.ico-dumbbell:before {
	content: "\e916"
}

.ico-healthcare:before {
	content: "\e917"
}

.ico-medical:before {
	content: "\e918"
}

.ico-salad:before {
	content: "\e919"
}

.ico-exercise:before {
	content: "\e91a"
}

.ico-gym:before {
	content: "\e91b"
}

.ico-reserve:before {
	content: "\e91c"
}

.ico-share:before {
	content: "\e905"
}

.ico-favorite-black:before {
	content: "\e915"
}

.ico-error_outline:before {
	content: "\e912"
}

.ico-report_problem:before {
	content: "\e913"
}

.ico-check_circle_outline:before {
	content: "\e914"
}

.ico-build:before {
	content: "\e910"
}

.ico-check_box:before {
	content: "\e911"
}

.ico-schedule:before {
	content: "\e90f"
}

.ico-today:before {
	content: "\e90e"
}

.ico-grid:before {
	content: "\e909"
}

.ico-invert_colors:before {
	content: "\e90a"
}

.ico-layout:before {
	content: "\e90b"
}

.ico-text_format:before {
	content: "\e90c"
}

.ico-touch:before {
	content: "\e90d"
}

.ico-account_circle:before {
	content: "\e908"
}

.ico-arrow_down:before {
	content: "\e900"
}

.ico-arrow_up:before {
	content: "\e901"
}

.ico-btn-close:before {
	content: "\e902"
}

.ico-close:before {
	content: "\e903"
}

.ico-menu:before {
	content: "\e904"
}

.ico-opacity:before {
	content: "\e906"
}

.ico-search:before {
	content: "\e907"
}

.container {
	width: 100%;
	padding-left: 1rem;
	padding-right: 1rem;
	margin-left: auto;
	margin-right: auto
}

@media (min-width:960px) {
	.container {
		max-width: 930px
	}
}

@media (min-width:1280px) {
	.container {
		max-width: 1140px
	}
}

.grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 1rem
}

.grid-1 {
	grid-column: span 1
}

.grid-2 {
	grid-column: span 2
}

.grid-3 {
	grid-column: span 3
}

.grid-4 {
	grid-column: span 4
}

.grid-5 {
	grid-column: span 5
}

.grid-6 {
	grid-column: span 6
}

.grid-7 {
	grid-column: span 7
}

.grid-8 {
	grid-column: span 8
}

.grid-9 {
	grid-column: span 9
}

.grid-10 {
	grid-column: span 10
}

.grid-11 {
	grid-column: span 11
}

.grid-12 {
	grid-column: span 12
}

@media (min-width:600px) {
	.grid-sm-1 {
		grid-column: span 1
	}
	.grid-sm-2 {
		grid-column: span 2
	}
	.grid-sm-3 {
		grid-column: span 3
	}
	.grid-sm-4 {
		grid-column: span 4
	}
	.grid-sm-5 {
		grid-column: span 5
	}
	.grid-sm-6 {
		grid-column: span 6
	}
	.grid-sm-7 {
		grid-column: span 7
	}
	.grid-sm-8 {
		grid-column: span 8
	}
	.grid-sm-9 {
		grid-column: span 9
	}
	.grid-sm-10 {
		grid-column: span 10
	}
	.grid-sm-11 {
		grid-column: span 11
	}
	.grid-sm-12 {
		grid-column: span 12
	}
}

@media (min-width:960px) {
	.grid-md-1 {
		grid-column: span 1
	}
	.grid-md-2 {
		grid-column: span 2
	}
	.grid-md-3 {
		grid-column: span 3
	}
	.grid-md-4 {
		grid-column: span 4
	}
	.grid-md-5 {
		grid-column: span 5
	}
	.grid-md-6 {
		grid-column: span 6
	}
	.grid-md-7 {
		grid-column: span 7
	}
	.grid-md-8 {
		grid-column: span 8
	}
	.grid-md-9 {
		grid-column: span 9
	}
	.grid-md-10 {
		grid-column: span 10
	}
	.grid-md-11 {
		grid-column: span 11
	}
	.grid-md-12 {
		grid-column: span 12
	}
}

@media (min-width:1280px) {
	.grid-lg-1 {
		grid-column: span 1
	}
	.grid-lg-2 {
		grid-column: span 2
	}
	.grid-lg-3 {
		grid-column: span 3
	}
	.grid-lg-4 {
		grid-column: span 4
	}
	.grid-lg-5 {
		grid-column: span 5
	}
	.grid-lg-6 {
		grid-column: span 6
	}
	.grid-lg-7 {
		grid-column: span 7
	}
	.grid-lg-8 {
		grid-column: span 8
	}
	.grid-lg-9 {
		grid-column: span 9
	}
	.grid-lg-10 {
		grid-column: span 10
	}
	.grid-lg-11 {
		grid-column: span 11
	}
	.grid-lg-12 {
		grid-column: span 12
	}
	.grid-xl-1 {
		grid-column: span 1
	}
	.grid-xl-2 {
		grid-column: span 2
	}
	.grid-xl-3 {
		grid-column: span 3
	}
	.grid-xl-4 {
		grid-column: span 4
	}
	.grid-xl-5 {
		grid-column: span 5
	}
	.grid-xl-6 {
		grid-column: span 6
	}
	.grid-xl-7 {
		grid-column: span 7
	}
	.grid-xl-8 {
		grid-column: span 8
	}
	.grid-xl-9 {
		grid-column: span 9
	}
	.grid-xl-10 {
		grid-column: span 10
	}
	.grid-xl-11 {
		grid-column: span 11
	}
	.grid-xl-12 {
		grid-column: span 12
	}
}

@media (min-width:600px) {
	.grid-start-sm-1.grid-sm-1 {
		grid-column: 1/span 1
	}
	.grid-start-sm-2.grid-sm-1 {
		grid-column: 2/span 1
	}
	.grid-start-sm-3.grid-sm-1 {
		grid-column: 3/span 1
	}
	.grid-start-sm-4.grid-sm-1 {
		grid-column: 4/span 1
	}
	.grid-start-sm-5.grid-sm-1 {
		grid-column: 5/span 1
	}
	.grid-start-sm-6.grid-sm-1 {
		grid-column: 6/span 1
	}
	.grid-start-sm-7.grid-sm-1 {
		grid-column: 7/span 1
	}
	.grid-start-sm-8.grid-sm-1 {
		grid-column: 8/span 1
	}
	.grid-start-sm-9.grid-sm-1 {
		grid-column: 9/span 1
	}
	.grid-start-sm-10.grid-sm-1 {
		grid-column: 10/span 1
	}
	.grid-start-sm-11.grid-sm-1 {
		grid-column: 11/span 1
	}
	.grid-start-sm-12.grid-sm-1 {
		grid-column: 12/span 1
	}
	.grid-sm-1 {
		grid-column-start: span 1
	}
	.grid-start-sm-1.grid-sm-2 {
		grid-column: 1/span 2
	}
	.grid-start-sm-2.grid-sm-2 {
		grid-column: 2/span 2
	}
	.grid-start-sm-3.grid-sm-2 {
		grid-column: 3/span 2
	}
	.grid-start-sm-4.grid-sm-2 {
		grid-column: 4/span 2
	}
	.grid-start-sm-5.grid-sm-2 {
		grid-column: 5/span 2
	}
	.grid-start-sm-6.grid-sm-2 {
		grid-column: 6/span 2
	}
	.grid-start-sm-7.grid-sm-2 {
		grid-column: 7/span 2
	}
	.grid-start-sm-8.grid-sm-2 {
		grid-column: 8/span 2
	}
	.grid-start-sm-9.grid-sm-2 {
		grid-column: 9/span 2
	}
	.grid-start-sm-10.grid-sm-2 {
		grid-column: 10/span 2
	}
	.grid-start-sm-11.grid-sm-2 {
		grid-column: 11/span 2
	}
	.grid-start-sm-12.grid-sm-2 {
		grid-column: 12/span 2
	}
	.grid-sm-2 {
		grid-column-start: span 2
	}
	.grid-start-sm-1.grid-sm-3 {
		grid-column: 1/span 3
	}
	.grid-start-sm-2.grid-sm-3 {
		grid-column: 2/span 3
	}
	.grid-start-sm-3.grid-sm-3 {
		grid-column: 3/span 3
	}
	.grid-start-sm-4.grid-sm-3 {
		grid-column: 4/span 3
	}
	.grid-start-sm-5.grid-sm-3 {
		grid-column: 5/span 3
	}
	.grid-start-sm-6.grid-sm-3 {
		grid-column: 6/span 3
	}
	.grid-start-sm-7.grid-sm-3 {
		grid-column: 7/span 3
	}
	.grid-start-sm-8.grid-sm-3 {
		grid-column: 8/span 3
	}
	.grid-start-sm-9.grid-sm-3 {
		grid-column: 9/span 3
	}
	.grid-start-sm-10.grid-sm-3 {
		grid-column: 10/span 3
	}
	.grid-start-sm-11.grid-sm-3 {
		grid-column: 11/span 3
	}
	.grid-start-sm-12.grid-sm-3 {
		grid-column: 12/span 3
	}
	.grid-sm-3 {
		grid-column-start: span 3
	}
	.grid-start-sm-1.grid-sm-4 {
		grid-column: 1/span 4
	}
	.grid-start-sm-2.grid-sm-4 {
		grid-column: 2/span 4
	}
	.grid-start-sm-3.grid-sm-4 {
		grid-column: 3/span 4
	}
	.grid-start-sm-4.grid-sm-4 {
		grid-column: 4/span 4
	}
	.grid-start-sm-5.grid-sm-4 {
		grid-column: 5/span 4
	}
	.grid-start-sm-6.grid-sm-4 {
		grid-column: 6/span 4
	}
	.grid-start-sm-7.grid-sm-4 {
		grid-column: 7/span 4
	}
	.grid-start-sm-8.grid-sm-4 {
		grid-column: 8/span 4
	}
	.grid-start-sm-9.grid-sm-4 {
		grid-column: 9/span 4
	}
	.grid-start-sm-10.grid-sm-4 {
		grid-column: 10/span 4
	}
	.grid-start-sm-11.grid-sm-4 {
		grid-column: 11/span 4
	}
	.grid-start-sm-12.grid-sm-4 {
		grid-column: 12/span 4
	}
	.grid-sm-4 {
		grid-column-start: span 4
	}
	.grid-start-sm-1.grid-sm-5 {
		grid-column: 1/span 5
	}
	.grid-start-sm-2.grid-sm-5 {
		grid-column: 2/span 5
	}
	.grid-start-sm-3.grid-sm-5 {
		grid-column: 3/span 5
	}
	.grid-start-sm-4.grid-sm-5 {
		grid-column: 4/span 5
	}
	.grid-start-sm-5.grid-sm-5 {
		grid-column: 5/span 5
	}
	.grid-start-sm-6.grid-sm-5 {
		grid-column: 6/span 5
	}
	.grid-start-sm-7.grid-sm-5 {
		grid-column: 7/span 5
	}
	.grid-start-sm-8.grid-sm-5 {
		grid-column: 8/span 5
	}
	.grid-start-sm-9.grid-sm-5 {
		grid-column: 9/span 5
	}
	.grid-start-sm-10.grid-sm-5 {
		grid-column: 10/span 5
	}
	.grid-start-sm-11.grid-sm-5 {
		grid-column: 11/span 5
	}
	.grid-start-sm-12.grid-sm-5 {
		grid-column: 12/span 5
	}
	.grid-sm-5 {
		grid-column-start: span 5
	}
	.grid-start-sm-1.grid-sm-6 {
		grid-column: 1/span 6
	}
	.grid-start-sm-2.grid-sm-6 {
		grid-column: 2/span 6
	}
	.grid-start-sm-3.grid-sm-6 {
		grid-column: 3/span 6
	}
	.grid-start-sm-4.grid-sm-6 {
		grid-column: 4/span 6
	}
	.grid-start-sm-5.grid-sm-6 {
		grid-column: 5/span 6
	}
	.grid-start-sm-6.grid-sm-6 {
		grid-column: 6/span 6
	}
	.grid-start-sm-7.grid-sm-6 {
		grid-column: 7/span 6
	}
	.grid-start-sm-8.grid-sm-6 {
		grid-column: 8/span 6
	}
	.grid-start-sm-9.grid-sm-6 {
		grid-column: 9/span 6
	}
	.grid-start-sm-10.grid-sm-6 {
		grid-column: 10/span 6
	}
	.grid-start-sm-11.grid-sm-6 {
		grid-column: 11/span 6
	}
	.grid-start-sm-12.grid-sm-6 {
		grid-column: 12/span 6
	}
	.grid-sm-6 {
		grid-column-start: span 6
	}
	.grid-start-sm-1.grid-sm-7 {
		grid-column: 1/span 7
	}
	.grid-start-sm-2.grid-sm-7 {
		grid-column: 2/span 7
	}
	.grid-start-sm-3.grid-sm-7 {
		grid-column: 3/span 7
	}
	.grid-start-sm-4.grid-sm-7 {
		grid-column: 4/span 7
	}
	.grid-start-sm-5.grid-sm-7 {
		grid-column: 5/span 7
	}
	.grid-start-sm-6.grid-sm-7 {
		grid-column: 6/span 7
	}
	.grid-start-sm-7.grid-sm-7 {
		grid-column: 7/span 7
	}
	.grid-start-sm-8.grid-sm-7 {
		grid-column: 8/span 7
	}
	.grid-start-sm-9.grid-sm-7 {
		grid-column: 9/span 7
	}
	.grid-start-sm-10.grid-sm-7 {
		grid-column: 10/span 7
	}
	.grid-start-sm-11.grid-sm-7 {
		grid-column: 11/span 7
	}
	.grid-start-sm-12.grid-sm-7 {
		grid-column: 12/span 7
	}
	.grid-sm-7 {
		grid-column-start: span 7
	}
	.grid-start-sm-1.grid-sm-8 {
		grid-column: 1/span 8
	}
	.grid-start-sm-2.grid-sm-8 {
		grid-column: 2/span 8
	}
	.grid-start-sm-3.grid-sm-8 {
		grid-column: 3/span 8
	}
	.grid-start-sm-4.grid-sm-8 {
		grid-column: 4/span 8
	}
	.grid-start-sm-5.grid-sm-8 {
		grid-column: 5/span 8
	}
	.grid-start-sm-6.grid-sm-8 {
		grid-column: 6/span 8
	}
	.grid-start-sm-7.grid-sm-8 {
		grid-column: 7/span 8
	}
	.grid-start-sm-8.grid-sm-8 {
		grid-column: 8/span 8
	}
	.grid-start-sm-9.grid-sm-8 {
		grid-column: 9/span 8
	}
	.grid-start-sm-10.grid-sm-8 {
		grid-column: 10/span 8
	}
	.grid-start-sm-11.grid-sm-8 {
		grid-column: 11/span 8
	}
	.grid-start-sm-12.grid-sm-8 {
		grid-column: 12/span 8
	}
	.grid-sm-8 {
		grid-column-start: span 8
	}
	.grid-start-sm-1.grid-sm-9 {
		grid-column: 1/span 9
	}
	.grid-start-sm-2.grid-sm-9 {
		grid-column: 2/span 9
	}
	.grid-start-sm-3.grid-sm-9 {
		grid-column: 3/span 9
	}
	.grid-start-sm-4.grid-sm-9 {
		grid-column: 4/span 9
	}
	.grid-start-sm-5.grid-sm-9 {
		grid-column: 5/span 9
	}
	.grid-start-sm-6.grid-sm-9 {
		grid-column: 6/span 9
	}
	.grid-start-sm-7.grid-sm-9 {
		grid-column: 7/span 9
	}
	.grid-start-sm-8.grid-sm-9 {
		grid-column: 8/span 9
	}
	.grid-start-sm-9.grid-sm-9 {
		grid-column: 9/span 9
	}
	.grid-start-sm-10.grid-sm-9 {
		grid-column: 10/span 9
	}
	.grid-start-sm-11.grid-sm-9 {
		grid-column: 11/span 9
	}
	.grid-start-sm-12.grid-sm-9 {
		grid-column: 12/span 9
	}
	.grid-sm-9 {
		grid-column-start: span 9
	}
	.grid-start-sm-1.grid-sm-10 {
		grid-column: 1/span 10
	}
	.grid-start-sm-2.grid-sm-10 {
		grid-column: 2/span 10
	}
	.grid-start-sm-3.grid-sm-10 {
		grid-column: 3/span 10
	}
	.grid-start-sm-4.grid-sm-10 {
		grid-column: 4/span 10
	}
	.grid-start-sm-5.grid-sm-10 {
		grid-column: 5/span 10
	}
	.grid-start-sm-6.grid-sm-10 {
		grid-column: 6/span 10
	}
	.grid-start-sm-7.grid-sm-10 {
		grid-column: 7/span 10
	}
	.grid-start-sm-8.grid-sm-10 {
		grid-column: 8/span 10
	}
	.grid-start-sm-9.grid-sm-10 {
		grid-column: 9/span 10
	}
	.grid-start-sm-10.grid-sm-10 {
		grid-column: 10/span 10
	}
	.grid-start-sm-11.grid-sm-10 {
		grid-column: 11/span 10
	}
	.grid-start-sm-12.grid-sm-10 {
		grid-column: 12/span 10
	}
	.grid-sm-10 {
		grid-column-start: span 10
	}
	.grid-start-sm-1.grid-sm-11 {
		grid-column: 1/span 11
	}
	.grid-start-sm-2.grid-sm-11 {
		grid-column: 2/span 11
	}
	.grid-start-sm-3.grid-sm-11 {
		grid-column: 3/span 11
	}
	.grid-start-sm-4.grid-sm-11 {
		grid-column: 4/span 11
	}
	.grid-start-sm-5.grid-sm-11 {
		grid-column: 5/span 11
	}
	.grid-start-sm-6.grid-sm-11 {
		grid-column: 6/span 11
	}
	.grid-start-sm-7.grid-sm-11 {
		grid-column: 7/span 11
	}
	.grid-start-sm-8.grid-sm-11 {
		grid-column: 8/span 11
	}
	.grid-start-sm-9.grid-sm-11 {
		grid-column: 9/span 11
	}
	.grid-start-sm-10.grid-sm-11 {
		grid-column: 10/span 11
	}
	.grid-start-sm-11.grid-sm-11 {
		grid-column: 11/span 11
	}
	.grid-start-sm-12.grid-sm-11 {
		grid-column: 12/span 11
	}
	.grid-sm-11 {
		grid-column-start: span 11
	}
	.grid-start-sm-1.grid-sm-12 {
		grid-column: 1/span 12
	}
	.grid-start-sm-2.grid-sm-12 {
		grid-column: 2/span 12
	}
	.grid-start-sm-3.grid-sm-12 {
		grid-column: 3/span 12
	}
	.grid-start-sm-4.grid-sm-12 {
		grid-column: 4/span 12
	}
	.grid-start-sm-5.grid-sm-12 {
		grid-column: 5/span 12
	}
	.grid-start-sm-6.grid-sm-12 {
		grid-column: 6/span 12
	}
	.grid-start-sm-7.grid-sm-12 {
		grid-column: 7/span 12
	}
	.grid-start-sm-8.grid-sm-12 {
		grid-column: 8/span 12
	}
	.grid-start-sm-9.grid-sm-12 {
		grid-column: 9/span 12
	}
	.grid-start-sm-10.grid-sm-12 {
		grid-column: 10/span 12
	}
	.grid-start-sm-11.grid-sm-12 {
		grid-column: 11/span 12
	}
	.grid-start-sm-12.grid-sm-12 {
		grid-column: 12/span 12
	}
	.grid-sm-12 {
		grid-column-start: span 12
	}
}

@media (min-width:960px) {
	.grid-start-md-1.grid-md-1 {
		grid-column: 1/span 1
	}
	.grid-start-md-2.grid-md-1 {
		grid-column: 2/span 1
	}
	.grid-start-md-3.grid-md-1 {
		grid-column: 3/span 1
	}
	.grid-start-md-4.grid-md-1 {
		grid-column: 4/span 1
	}
	.grid-start-md-5.grid-md-1 {
		grid-column: 5/span 1
	}
	.grid-start-md-6.grid-md-1 {
		grid-column: 6/span 1
	}
	.grid-start-md-7.grid-md-1 {
		grid-column: 7/span 1
	}
	.grid-start-md-8.grid-md-1 {
		grid-column: 8/span 1
	}
	.grid-start-md-9.grid-md-1 {
		grid-column: 9/span 1
	}
	.grid-start-md-10.grid-md-1 {
		grid-column: 10/span 1
	}
	.grid-start-md-11.grid-md-1 {
		grid-column: 11/span 1
	}
	.grid-start-md-12.grid-md-1 {
		grid-column: 12/span 1
	}
	.grid-md-1 {
		grid-column-start: span 1
	}
	.grid-start-md-1.grid-md-2 {
		grid-column: 1/span 2
	}
	.grid-start-md-2.grid-md-2 {
		grid-column: 2/span 2
	}
	.grid-start-md-3.grid-md-2 {
		grid-column: 3/span 2
	}
	.grid-start-md-4.grid-md-2 {
		grid-column: 4/span 2
	}
	.grid-start-md-5.grid-md-2 {
		grid-column: 5/span 2
	}
	.grid-start-md-6.grid-md-2 {
		grid-column: 6/span 2
	}
	.grid-start-md-7.grid-md-2 {
		grid-column: 7/span 2
	}
	.grid-start-md-8.grid-md-2 {
		grid-column: 8/span 2
	}
	.grid-start-md-9.grid-md-2 {
		grid-column: 9/span 2
	}
	.grid-start-md-10.grid-md-2 {
		grid-column: 10/span 2
	}
	.grid-start-md-11.grid-md-2 {
		grid-column: 11/span 2
	}
	.grid-start-md-12.grid-md-2 {
		grid-column: 12/span 2
	}
	.grid-md-2 {
		grid-column-start: span 2
	}
	.grid-start-md-1.grid-md-3 {
		grid-column: 1/span 3
	}
	.grid-start-md-2.grid-md-3 {
		grid-column: 2/span 3
	}
	.grid-start-md-3.grid-md-3 {
		grid-column: 3/span 3
	}
	.grid-start-md-4.grid-md-3 {
		grid-column: 4/span 3
	}
	.grid-start-md-5.grid-md-3 {
		grid-column: 5/span 3
	}
	.grid-start-md-6.grid-md-3 {
		grid-column: 6/span 3
	}
	.grid-start-md-7.grid-md-3 {
		grid-column: 7/span 3
	}
	.grid-start-md-8.grid-md-3 {
		grid-column: 8/span 3
	}
	.grid-start-md-9.grid-md-3 {
		grid-column: 9/span 3
	}
	.grid-start-md-10.grid-md-3 {
		grid-column: 10/span 3
	}
	.grid-start-md-11.grid-md-3 {
		grid-column: 11/span 3
	}
	.grid-start-md-12.grid-md-3 {
		grid-column: 12/span 3
	}
	.grid-md-3 {
		grid-column-start: span 3
	}
	.grid-start-md-1.grid-md-4 {
		grid-column: 1/span 4
	}
	.grid-start-md-2.grid-md-4 {
		grid-column: 2/span 4
	}
	.grid-start-md-3.grid-md-4 {
		grid-column: 3/span 4
	}
	.grid-start-md-4.grid-md-4 {
		grid-column: 4/span 4
	}
	.grid-start-md-5.grid-md-4 {
		grid-column: 5/span 4
	}
	.grid-start-md-6.grid-md-4 {
		grid-column: 6/span 4
	}
	.grid-start-md-7.grid-md-4 {
		grid-column: 7/span 4
	}
	.grid-start-md-8.grid-md-4 {
		grid-column: 8/span 4
	}
	.grid-start-md-9.grid-md-4 {
		grid-column: 9/span 4
	}
	.grid-start-md-10.grid-md-4 {
		grid-column: 10/span 4
	}
	.grid-start-md-11.grid-md-4 {
		grid-column: 11/span 4
	}
	.grid-start-md-12.grid-md-4 {
		grid-column: 12/span 4
	}
	.grid-md-4 {
		grid-column-start: span 4
	}
	.grid-start-md-1.grid-md-5 {
		grid-column: 1/span 5
	}
	.grid-start-md-2.grid-md-5 {
		grid-column: 2/span 5
	}
	.grid-start-md-3.grid-md-5 {
		grid-column: 3/span 5
	}
	.grid-start-md-4.grid-md-5 {
		grid-column: 4/span 5
	}
	.grid-start-md-5.grid-md-5 {
		grid-column: 5/span 5
	}
	.grid-start-md-6.grid-md-5 {
		grid-column: 6/span 5
	}
	.grid-start-md-7.grid-md-5 {
		grid-column: 7/span 5
	}
	.grid-start-md-8.grid-md-5 {
		grid-column: 8/span 5
	}
	.grid-start-md-9.grid-md-5 {
		grid-column: 9/span 5
	}
	.grid-start-md-10.grid-md-5 {
		grid-column: 10/span 5
	}
	.grid-start-md-11.grid-md-5 {
		grid-column: 11/span 5
	}
	.grid-start-md-12.grid-md-5 {
		grid-column: 12/span 5
	}
	.grid-md-5 {
		grid-column-start: span 5
	}
	.grid-start-md-1.grid-md-6 {
		grid-column: 1/span 6
	}
	.grid-start-md-2.grid-md-6 {
		grid-column: 2/span 6
	}
	.grid-start-md-3.grid-md-6 {
		grid-column: 3/span 6
	}
	.grid-start-md-4.grid-md-6 {
		grid-column: 4/span 6
	}
	.grid-start-md-5.grid-md-6 {
		grid-column: 5/span 6
	}
	.grid-start-md-6.grid-md-6 {
		grid-column: 6/span 6
	}
	.grid-start-md-7.grid-md-6 {
		grid-column: 7/span 6
	}
	.grid-start-md-8.grid-md-6 {
		grid-column: 8/span 6
	}
	.grid-start-md-9.grid-md-6 {
		grid-column: 9/span 6
	}
	.grid-start-md-10.grid-md-6 {
		grid-column: 10/span 6
	}
	.grid-start-md-11.grid-md-6 {
		grid-column: 11/span 6
	}
	.grid-start-md-12.grid-md-6 {
		grid-column: 12/span 6
	}
	.grid-md-6 {
		grid-column-start: span 6
	}
	.grid-start-md-1.grid-md-7 {
		grid-column: 1/span 7
	}
	.grid-start-md-2.grid-md-7 {
		grid-column: 2/span 7
	}
	.grid-start-md-3.grid-md-7 {
		grid-column: 3/span 7
	}
	.grid-start-md-4.grid-md-7 {
		grid-column: 4/span 7
	}
	.grid-start-md-5.grid-md-7 {
		grid-column: 5/span 7
	}
	.grid-start-md-6.grid-md-7 {
		grid-column: 6/span 7
	}
	.grid-start-md-7.grid-md-7 {
		grid-column: 7/span 7
	}
	.grid-start-md-8.grid-md-7 {
		grid-column: 8/span 7
	}
	.grid-start-md-9.grid-md-7 {
		grid-column: 9/span 7
	}
	.grid-start-md-10.grid-md-7 {
		grid-column: 10/span 7
	}
	.grid-start-md-11.grid-md-7 {
		grid-column: 11/span 7
	}
	.grid-start-md-12.grid-md-7 {
		grid-column: 12/span 7
	}
	.grid-md-7 {
		grid-column-start: span 7
	}
	.grid-start-md-1.grid-md-8 {
		grid-column: 1/span 8
	}
	.grid-start-md-2.grid-md-8 {
		grid-column: 2/span 8
	}
	.grid-start-md-3.grid-md-8 {
		grid-column: 3/span 8
	}
	.grid-start-md-4.grid-md-8 {
		grid-column: 4/span 8
	}
	.grid-start-md-5.grid-md-8 {
		grid-column: 5/span 8
	}
	.grid-start-md-6.grid-md-8 {
		grid-column: 6/span 8
	}
	.grid-start-md-7.grid-md-8 {
		grid-column: 7/span 8
	}
	.grid-start-md-8.grid-md-8 {
		grid-column: 8/span 8
	}
	.grid-start-md-9.grid-md-8 {
		grid-column: 9/span 8
	}
	.grid-start-md-10.grid-md-8 {
		grid-column: 10/span 8
	}
	.grid-start-md-11.grid-md-8 {
		grid-column: 11/span 8
	}
	.grid-start-md-12.grid-md-8 {
		grid-column: 12/span 8
	}
	.grid-md-8 {
		grid-column-start: span 8
	}
	.grid-start-md-1.grid-md-9 {
		grid-column: 1/span 9
	}
	.grid-start-md-2.grid-md-9 {
		grid-column: 2/span 9
	}
	.grid-start-md-3.grid-md-9 {
		grid-column: 3/span 9
	}
	.grid-start-md-4.grid-md-9 {
		grid-column: 4/span 9
	}
	.grid-start-md-5.grid-md-9 {
		grid-column: 5/span 9
	}
	.grid-start-md-6.grid-md-9 {
		grid-column: 6/span 9
	}
	.grid-start-md-7.grid-md-9 {
		grid-column: 7/span 9
	}
	.grid-start-md-8.grid-md-9 {
		grid-column: 8/span 9
	}
	.grid-start-md-9.grid-md-9 {
		grid-column: 9/span 9
	}
	.grid-start-md-10.grid-md-9 {
		grid-column: 10/span 9
	}
	.grid-start-md-11.grid-md-9 {
		grid-column: 11/span 9
	}
	.grid-start-md-12.grid-md-9 {
		grid-column: 12/span 9
	}
	.grid-md-9 {
		grid-column-start: span 9
	}
	.grid-start-md-1.grid-md-10 {
		grid-column: 1/span 10
	}
	.grid-start-md-2.grid-md-10 {
		grid-column: 2/span 10
	}
	.grid-start-md-3.grid-md-10 {
		grid-column: 3/span 10
	}
	.grid-start-md-4.grid-md-10 {
		grid-column: 4/span 10
	}
	.grid-start-md-5.grid-md-10 {
		grid-column: 5/span 10
	}
	.grid-start-md-6.grid-md-10 {
		grid-column: 6/span 10
	}
	.grid-start-md-7.grid-md-10 {
		grid-column: 7/span 10
	}
	.grid-start-md-8.grid-md-10 {
		grid-column: 8/span 10
	}
	.grid-start-md-9.grid-md-10 {
		grid-column: 9/span 10
	}
	.grid-start-md-10.grid-md-10 {
		grid-column: 10/span 10
	}
	.grid-start-md-11.grid-md-10 {
		grid-column: 11/span 10
	}
	.grid-start-md-12.grid-md-10 {
		grid-column: 12/span 10
	}
	.grid-md-10 {
		grid-column-start: span 10
	}
	.grid-start-md-1.grid-md-11 {
		grid-column: 1/span 11
	}
	.grid-start-md-2.grid-md-11 {
		grid-column: 2/span 11
	}
	.grid-start-md-3.grid-md-11 {
		grid-column: 3/span 11
	}
	.grid-start-md-4.grid-md-11 {
		grid-column: 4/span 11
	}
	.grid-start-md-5.grid-md-11 {
		grid-column: 5/span 11
	}
	.grid-start-md-6.grid-md-11 {
		grid-column: 6/span 11
	}
	.grid-start-md-7.grid-md-11 {
		grid-column: 7/span 11
	}
	.grid-start-md-8.grid-md-11 {
		grid-column: 8/span 11
	}
	.grid-start-md-9.grid-md-11 {
		grid-column: 9/span 11
	}
	.grid-start-md-10.grid-md-11 {
		grid-column: 10/span 11
	}
	.grid-start-md-11.grid-md-11 {
		grid-column: 11/span 11
	}
	.grid-start-md-12.grid-md-11 {
		grid-column: 12/span 11
	}
	.grid-md-11 {
		grid-column-start: span 11
	}
	.grid-start-md-1.grid-md-12 {
		grid-column: 1/span 12
	}
	.grid-start-md-2.grid-md-12 {
		grid-column: 2/span 12
	}
	.grid-start-md-3.grid-md-12 {
		grid-column: 3/span 12
	}
	.grid-start-md-4.grid-md-12 {
		grid-column: 4/span 12
	}
	.grid-start-md-5.grid-md-12 {
		grid-column: 5/span 12
	}
	.grid-start-md-6.grid-md-12 {
		grid-column: 6/span 12
	}
	.grid-start-md-7.grid-md-12 {
		grid-column: 7/span 12
	}
	.grid-start-md-8.grid-md-12 {
		grid-column: 8/span 12
	}
	.grid-start-md-9.grid-md-12 {
		grid-column: 9/span 12
	}
	.grid-start-md-10.grid-md-12 {
		grid-column: 10/span 12
	}
	.grid-start-md-11.grid-md-12 {
		grid-column: 11/span 12
	}
	.grid-start-md-12.grid-md-12 {
		grid-column: 12/span 12
	}
	.grid-md-12 {
		grid-column-start: span 12
	}
}

@media (min-width:1280px) {
	.grid-start-lg-1.grid-lg-1 {
		grid-column: 1/span 1
	}
	.grid-start-lg-2.grid-lg-1 {
		grid-column: 2/span 1
	}
	.grid-start-lg-3.grid-lg-1 {
		grid-column: 3/span 1
	}
	.grid-start-lg-4.grid-lg-1 {
		grid-column: 4/span 1
	}
	.grid-start-lg-5.grid-lg-1 {
		grid-column: 5/span 1
	}
	.grid-start-lg-6.grid-lg-1 {
		grid-column: 6/span 1
	}
	.grid-start-lg-7.grid-lg-1 {
		grid-column: 7/span 1
	}
	.grid-start-lg-8.grid-lg-1 {
		grid-column: 8/span 1
	}
	.grid-start-lg-9.grid-lg-1 {
		grid-column: 9/span 1
	}
	.grid-start-lg-10.grid-lg-1 {
		grid-column: 10/span 1
	}
	.grid-start-lg-11.grid-lg-1 {
		grid-column: 11/span 1
	}
	.grid-start-lg-12.grid-lg-1 {
		grid-column: 12/span 1
	}
	.grid-lg-1 {
		grid-column-start: span 1
	}
	.grid-start-lg-1.grid-lg-2 {
		grid-column: 1/span 2
	}
	.grid-start-lg-2.grid-lg-2 {
		grid-column: 2/span 2
	}
	.grid-start-lg-3.grid-lg-2 {
		grid-column: 3/span 2
	}
	.grid-start-lg-4.grid-lg-2 {
		grid-column: 4/span 2
	}
	.grid-start-lg-5.grid-lg-2 {
		grid-column: 5/span 2
	}
	.grid-start-lg-6.grid-lg-2 {
		grid-column: 6/span 2
	}
	.grid-start-lg-7.grid-lg-2 {
		grid-column: 7/span 2
	}
	.grid-start-lg-8.grid-lg-2 {
		grid-column: 8/span 2
	}
	.grid-start-lg-9.grid-lg-2 {
		grid-column: 9/span 2
	}
	.grid-start-lg-10.grid-lg-2 {
		grid-column: 10/span 2
	}
	.grid-start-lg-11.grid-lg-2 {
		grid-column: 11/span 2
	}
	.grid-start-lg-12.grid-lg-2 {
		grid-column: 12/span 2
	}
	.grid-lg-2 {
		grid-column-start: span 2
	}
	.grid-start-lg-1.grid-lg-3 {
		grid-column: 1/span 3
	}
	.grid-start-lg-2.grid-lg-3 {
		grid-column: 2/span 3
	}
	.grid-start-lg-3.grid-lg-3 {
		grid-column: 3/span 3
	}
	.grid-start-lg-4.grid-lg-3 {
		grid-column: 4/span 3
	}
	.grid-start-lg-5.grid-lg-3 {
		grid-column: 5/span 3
	}
	.grid-start-lg-6.grid-lg-3 {
		grid-column: 6/span 3
	}
	.grid-start-lg-7.grid-lg-3 {
		grid-column: 7/span 3
	}
	.grid-start-lg-8.grid-lg-3 {
		grid-column: 8/span 3
	}
	.grid-start-lg-9.grid-lg-3 {
		grid-column: 9/span 3
	}
	.grid-start-lg-10.grid-lg-3 {
		grid-column: 10/span 3
	}
	.grid-start-lg-11.grid-lg-3 {
		grid-column: 11/span 3
	}
	.grid-start-lg-12.grid-lg-3 {
		grid-column: 12/span 3
	}
	.grid-lg-3 {
		grid-column-start: span 3
	}
	.grid-start-lg-1.grid-lg-4 {
		grid-column: 1/span 4
	}
	.grid-start-lg-2.grid-lg-4 {
		grid-column: 2/span 4
	}
	.grid-start-lg-3.grid-lg-4 {
		grid-column: 3/span 4
	}
	.grid-start-lg-4.grid-lg-4 {
		grid-column: 4/span 4
	}
	.grid-start-lg-5.grid-lg-4 {
		grid-column: 5/span 4
	}
	.grid-start-lg-6.grid-lg-4 {
		grid-column: 6/span 4
	}
	.grid-start-lg-7.grid-lg-4 {
		grid-column: 7/span 4
	}
	.grid-start-lg-8.grid-lg-4 {
		grid-column: 8/span 4
	}
	.grid-start-lg-9.grid-lg-4 {
		grid-column: 9/span 4
	}
	.grid-start-lg-10.grid-lg-4 {
		grid-column: 10/span 4
	}
	.grid-start-lg-11.grid-lg-4 {
		grid-column: 11/span 4
	}
	.grid-start-lg-12.grid-lg-4 {
		grid-column: 12/span 4
	}
	.grid-lg-4 {
		grid-column-start: span 4
	}
	.grid-start-lg-1.grid-lg-5 {
		grid-column: 1/span 5
	}
	.grid-start-lg-2.grid-lg-5 {
		grid-column: 2/span 5
	}
	.grid-start-lg-3.grid-lg-5 {
		grid-column: 3/span 5
	}
	.grid-start-lg-4.grid-lg-5 {
		grid-column: 4/span 5
	}
	.grid-start-lg-5.grid-lg-5 {
		grid-column: 5/span 5
	}
	.grid-start-lg-6.grid-lg-5 {
		grid-column: 6/span 5
	}
	.grid-start-lg-7.grid-lg-5 {
		grid-column: 7/span 5
	}
	.grid-start-lg-8.grid-lg-5 {
		grid-column: 8/span 5
	}
	.grid-start-lg-9.grid-lg-5 {
		grid-column: 9/span 5
	}
	.grid-start-lg-10.grid-lg-5 {
		grid-column: 10/span 5
	}
	.grid-start-lg-11.grid-lg-5 {
		grid-column: 11/span 5
	}
	.grid-start-lg-12.grid-lg-5 {
		grid-column: 12/span 5
	}
	.grid-lg-5 {
		grid-column-start: span 5
	}
	.grid-start-lg-1.grid-lg-6 {
		grid-column: 1/span 6
	}
	.grid-start-lg-2.grid-lg-6 {
		grid-column: 2/span 6
	}
	.grid-start-lg-3.grid-lg-6 {
		grid-column: 3/span 6
	}
	.grid-start-lg-4.grid-lg-6 {
		grid-column: 4/span 6
	}
	.grid-start-lg-5.grid-lg-6 {
		grid-column: 5/span 6
	}
	.grid-start-lg-6.grid-lg-6 {
		grid-column: 6/span 6
	}
	.grid-start-lg-7.grid-lg-6 {
		grid-column: 7/span 6
	}
	.grid-start-lg-8.grid-lg-6 {
		grid-column: 8/span 6
	}
	.grid-start-lg-9.grid-lg-6 {
		grid-column: 9/span 6
	}
	.grid-start-lg-10.grid-lg-6 {
		grid-column: 10/span 6
	}
	.grid-start-lg-11.grid-lg-6 {
		grid-column: 11/span 6
	}
	.grid-start-lg-12.grid-lg-6 {
		grid-column: 12/span 6
	}
	.grid-lg-6 {
		grid-column-start: span 6
	}
	.grid-start-lg-1.grid-lg-7 {
		grid-column: 1/span 7
	}
	.grid-start-lg-2.grid-lg-7 {
		grid-column: 2/span 7
	}
	.grid-start-lg-3.grid-lg-7 {
		grid-column: 3/span 7
	}
	.grid-start-lg-4.grid-lg-7 {
		grid-column: 4/span 7
	}
	.grid-start-lg-5.grid-lg-7 {
		grid-column: 5/span 7
	}
	.grid-start-lg-6.grid-lg-7 {
		grid-column: 6/span 7
	}
	.grid-start-lg-7.grid-lg-7 {
		grid-column: 7/span 7
	}
	.grid-start-lg-8.grid-lg-7 {
		grid-column: 8/span 7
	}
	.grid-start-lg-9.grid-lg-7 {
		grid-column: 9/span 7
	}
	.grid-start-lg-10.grid-lg-7 {
		grid-column: 10/span 7
	}
	.grid-start-lg-11.grid-lg-7 {
		grid-column: 11/span 7
	}
	.grid-start-lg-12.grid-lg-7 {
		grid-column: 12/span 7
	}
	.grid-lg-7 {
		grid-column-start: span 7
	}
	.grid-start-lg-1.grid-lg-8 {
		grid-column: 1/span 8
	}
	.grid-start-lg-2.grid-lg-8 {
		grid-column: 2/span 8
	}
	.grid-start-lg-3.grid-lg-8 {
		grid-column: 3/span 8
	}
	.grid-start-lg-4.grid-lg-8 {
		grid-column: 4/span 8
	}
	.grid-start-lg-5.grid-lg-8 {
		grid-column: 5/span 8
	}
	.grid-start-lg-6.grid-lg-8 {
		grid-column: 6/span 8
	}
	.grid-start-lg-7.grid-lg-8 {
		grid-column: 7/span 8
	}
	.grid-start-lg-8.grid-lg-8 {
		grid-column: 8/span 8
	}
	.grid-start-lg-9.grid-lg-8 {
		grid-column: 9/span 8
	}
	.grid-start-lg-10.grid-lg-8 {
		grid-column: 10/span 8
	}
	.grid-start-lg-11.grid-lg-8 {
		grid-column: 11/span 8
	}
	.grid-start-lg-12.grid-lg-8 {
		grid-column: 12/span 8
	}
	.grid-lg-8 {
		grid-column-start: span 8
	}
	.grid-start-lg-1.grid-lg-9 {
		grid-column: 1/span 9
	}
	.grid-start-lg-2.grid-lg-9 {
		grid-column: 2/span 9
	}
	.grid-start-lg-3.grid-lg-9 {
		grid-column: 3/span 9
	}
	.grid-start-lg-4.grid-lg-9 {
		grid-column: 4/span 9
	}
	.grid-start-lg-5.grid-lg-9 {
		grid-column: 5/span 9
	}
	.grid-start-lg-6.grid-lg-9 {
		grid-column: 6/span 9
	}
	.grid-start-lg-7.grid-lg-9 {
		grid-column: 7/span 9
	}
	.grid-start-lg-8.grid-lg-9 {
		grid-column: 8/span 9
	}
	.grid-start-lg-9.grid-lg-9 {
		grid-column: 9/span 9
	}
	.grid-start-lg-10.grid-lg-9 {
		grid-column: 10/span 9
	}
	.grid-start-lg-11.grid-lg-9 {
		grid-column: 11/span 9
	}
	.grid-start-lg-12.grid-lg-9 {
		grid-column: 12/span 9
	}
	.grid-lg-9 {
		grid-column-start: span 9
	}
	.grid-start-lg-1.grid-lg-10 {
		grid-column: 1/span 10
	}
	.grid-start-lg-2.grid-lg-10 {
		grid-column: 2/span 10
	}
	.grid-start-lg-3.grid-lg-10 {
		grid-column: 3/span 10
	}
	.grid-start-lg-4.grid-lg-10 {
		grid-column: 4/span 10
	}
	.grid-start-lg-5.grid-lg-10 {
		grid-column: 5/span 10
	}
	.grid-start-lg-6.grid-lg-10 {
		grid-column: 6/span 10
	}
	.grid-start-lg-7.grid-lg-10 {
		grid-column: 7/span 10
	}
	.grid-start-lg-8.grid-lg-10 {
		grid-column: 8/span 10
	}
	.grid-start-lg-9.grid-lg-10 {
		grid-column: 9/span 10
	}
	.grid-start-lg-10.grid-lg-10 {
		grid-column: 10/span 10
	}
	.grid-start-lg-11.grid-lg-10 {
		grid-column: 11/span 10
	}
	.grid-start-lg-12.grid-lg-10 {
		grid-column: 12/span 10
	}
	.grid-lg-10 {
		grid-column-start: span 10
	}
	.grid-start-lg-1.grid-lg-11 {
		grid-column: 1/span 11
	}
	.grid-start-lg-2.grid-lg-11 {
		grid-column: 2/span 11
	}
	.grid-start-lg-3.grid-lg-11 {
		grid-column: 3/span 11
	}
	.grid-start-lg-4.grid-lg-11 {
		grid-column: 4/span 11
	}
	.grid-start-lg-5.grid-lg-11 {
		grid-column: 5/span 11
	}
	.grid-start-lg-6.grid-lg-11 {
		grid-column: 6/span 11
	}
	.grid-start-lg-7.grid-lg-11 {
		grid-column: 7/span 11
	}
	.grid-start-lg-8.grid-lg-11 {
		grid-column: 8/span 11
	}
	.grid-start-lg-9.grid-lg-11 {
		grid-column: 9/span 11
	}
	.grid-start-lg-10.grid-lg-11 {
		grid-column: 10/span 11
	}
	.grid-start-lg-11.grid-lg-11 {
		grid-column: 11/span 11
	}
	.grid-start-lg-12.grid-lg-11 {
		grid-column: 12/span 11
	}
	.grid-lg-11 {
		grid-column-start: span 11
	}
	.grid-start-lg-1.grid-lg-12 {
		grid-column: 1/span 12
	}
	.grid-start-lg-2.grid-lg-12 {
		grid-column: 2/span 12
	}
	.grid-start-lg-3.grid-lg-12 {
		grid-column: 3/span 12
	}
	.grid-start-lg-4.grid-lg-12 {
		grid-column: 4/span 12
	}
	.grid-start-lg-5.grid-lg-12 {
		grid-column: 5/span 12
	}
	.grid-start-lg-6.grid-lg-12 {
		grid-column: 6/span 12
	}
	.grid-start-lg-7.grid-lg-12 {
		grid-column: 7/span 12
	}
	.grid-start-lg-8.grid-lg-12 {
		grid-column: 8/span 12
	}
	.grid-start-lg-9.grid-lg-12 {
		grid-column: 9/span 12
	}
	.grid-start-lg-10.grid-lg-12 {
		grid-column: 10/span 12
	}
	.grid-start-lg-11.grid-lg-12 {
		grid-column: 11/span 12
	}
	.grid-start-lg-12.grid-lg-12 {
		grid-column: 12/span 12
	}
	.grid-lg-12 {
		grid-column-start: span 12
	}
}

@media (min-width:1920px) {
	.grid-start-xl-1.grid-xl-1 {
		grid-column: 1/span 1
	}
	.grid-start-xl-2.grid-xl-1 {
		grid-column: 2/span 1
	}
	.grid-start-xl-3.grid-xl-1 {
		grid-column: 3/span 1
	}
	.grid-start-xl-4.grid-xl-1 {
		grid-column: 4/span 1
	}
	.grid-start-xl-5.grid-xl-1 {
		grid-column: 5/span 1
	}
	.grid-start-xl-6.grid-xl-1 {
		grid-column: 6/span 1
	}
	.grid-start-xl-7.grid-xl-1 {
		grid-column: 7/span 1
	}
	.grid-start-xl-8.grid-xl-1 {
		grid-column: 8/span 1
	}
	.grid-start-xl-9.grid-xl-1 {
		grid-column: 9/span 1
	}
	.grid-start-xl-10.grid-xl-1 {
		grid-column: 10/span 1
	}
	.grid-start-xl-11.grid-xl-1 {
		grid-column: 11/span 1
	}
	.grid-start-xl-12.grid-xl-1 {
		grid-column: 12/span 1
	}
	.grid-xl-1 {
		grid-column-start: span 1
	}
	.grid-start-xl-1.grid-xl-2 {
		grid-column: 1/span 2
	}
	.grid-start-xl-2.grid-xl-2 {
		grid-column: 2/span 2
	}
	.grid-start-xl-3.grid-xl-2 {
		grid-column: 3/span 2
	}
	.grid-start-xl-4.grid-xl-2 {
		grid-column: 4/span 2
	}
	.grid-start-xl-5.grid-xl-2 {
		grid-column: 5/span 2
	}
	.grid-start-xl-6.grid-xl-2 {
		grid-column: 6/span 2
	}
	.grid-start-xl-7.grid-xl-2 {
		grid-column: 7/span 2
	}
	.grid-start-xl-8.grid-xl-2 {
		grid-column: 8/span 2
	}
	.grid-start-xl-9.grid-xl-2 {
		grid-column: 9/span 2
	}
	.grid-start-xl-10.grid-xl-2 {
		grid-column: 10/span 2
	}
	.grid-start-xl-11.grid-xl-2 {
		grid-column: 11/span 2
	}
	.grid-start-xl-12.grid-xl-2 {
		grid-column: 12/span 2
	}
	.grid-xl-2 {
		grid-column-start: span 2
	}
	.grid-start-xl-1.grid-xl-3 {
		grid-column: 1/span 3
	}
	.grid-start-xl-2.grid-xl-3 {
		grid-column: 2/span 3
	}
	.grid-start-xl-3.grid-xl-3 {
		grid-column: 3/span 3
	}
	.grid-start-xl-4.grid-xl-3 {
		grid-column: 4/span 3
	}
	.grid-start-xl-5.grid-xl-3 {
		grid-column: 5/span 3
	}
	.grid-start-xl-6.grid-xl-3 {
		grid-column: 6/span 3
	}
	.grid-start-xl-7.grid-xl-3 {
		grid-column: 7/span 3
	}
	.grid-start-xl-8.grid-xl-3 {
		grid-column: 8/span 3
	}
	.grid-start-xl-9.grid-xl-3 {
		grid-column: 9/span 3
	}
	.grid-start-xl-10.grid-xl-3 {
		grid-column: 10/span 3
	}
	.grid-start-xl-11.grid-xl-3 {
		grid-column: 11/span 3
	}
	.grid-start-xl-12.grid-xl-3 {
		grid-column: 12/span 3
	}
	.grid-xl-3 {
		grid-column-start: span 3
	}
	.grid-start-xl-1.grid-xl-4 {
		grid-column: 1/span 4
	}
	.grid-start-xl-2.grid-xl-4 {
		grid-column: 2/span 4
	}
	.grid-start-xl-3.grid-xl-4 {
		grid-column: 3/span 4
	}
	.grid-start-xl-4.grid-xl-4 {
		grid-column: 4/span 4
	}
	.grid-start-xl-5.grid-xl-4 {
		grid-column: 5/span 4
	}
	.grid-start-xl-6.grid-xl-4 {
		grid-column: 6/span 4
	}
	.grid-start-xl-7.grid-xl-4 {
		grid-column: 7/span 4
	}
	.grid-start-xl-8.grid-xl-4 {
		grid-column: 8/span 4
	}
	.grid-start-xl-9.grid-xl-4 {
		grid-column: 9/span 4
	}
	.grid-start-xl-10.grid-xl-4 {
		grid-column: 10/span 4
	}
	.grid-start-xl-11.grid-xl-4 {
		grid-column: 11/span 4
	}
	.grid-start-xl-12.grid-xl-4 {
		grid-column: 12/span 4
	}
	.grid-xl-4 {
		grid-column-start: span 4
	}
	.grid-start-xl-1.grid-xl-5 {
		grid-column: 1/span 5
	}
	.grid-start-xl-2.grid-xl-5 {
		grid-column: 2/span 5
	}
	.grid-start-xl-3.grid-xl-5 {
		grid-column: 3/span 5
	}
	.grid-start-xl-4.grid-xl-5 {
		grid-column: 4/span 5
	}
	.grid-start-xl-5.grid-xl-5 {
		grid-column: 5/span 5
	}
	.grid-start-xl-6.grid-xl-5 {
		grid-column: 6/span 5
	}
	.grid-start-xl-7.grid-xl-5 {
		grid-column: 7/span 5
	}
	.grid-start-xl-8.grid-xl-5 {
		grid-column: 8/span 5
	}
	.grid-start-xl-9.grid-xl-5 {
		grid-column: 9/span 5
	}
	.grid-start-xl-10.grid-xl-5 {
		grid-column: 10/span 5
	}
	.grid-start-xl-11.grid-xl-5 {
		grid-column: 11/span 5
	}
	.grid-start-xl-12.grid-xl-5 {
		grid-column: 12/span 5
	}
	.grid-xl-5 {
		grid-column-start: span 5
	}
	.grid-start-xl-1.grid-xl-6 {
		grid-column: 1/span 6
	}
	.grid-start-xl-2.grid-xl-6 {
		grid-column: 2/span 6
	}
	.grid-start-xl-3.grid-xl-6 {
		grid-column: 3/span 6
	}
	.grid-start-xl-4.grid-xl-6 {
		grid-column: 4/span 6
	}
	.grid-start-xl-5.grid-xl-6 {
		grid-column: 5/span 6
	}
	.grid-start-xl-6.grid-xl-6 {
		grid-column: 6/span 6
	}
	.grid-start-xl-7.grid-xl-6 {
		grid-column: 7/span 6
	}
	.grid-start-xl-8.grid-xl-6 {
		grid-column: 8/span 6
	}
	.grid-start-xl-9.grid-xl-6 {
		grid-column: 9/span 6
	}
	.grid-start-xl-10.grid-xl-6 {
		grid-column: 10/span 6
	}
	.grid-start-xl-11.grid-xl-6 {
		grid-column: 11/span 6
	}
	.grid-start-xl-12.grid-xl-6 {
		grid-column: 12/span 6
	}
	.grid-xl-6 {
		grid-column-start: span 6
	}
	.grid-start-xl-1.grid-xl-7 {
		grid-column: 1/span 7
	}
	.grid-start-xl-2.grid-xl-7 {
		grid-column: 2/span 7
	}
	.grid-start-xl-3.grid-xl-7 {
		grid-column: 3/span 7
	}
	.grid-start-xl-4.grid-xl-7 {
		grid-column: 4/span 7
	}
	.grid-start-xl-5.grid-xl-7 {
		grid-column: 5/span 7
	}
	.grid-start-xl-6.grid-xl-7 {
		grid-column: 6/span 7
	}
	.grid-start-xl-7.grid-xl-7 {
		grid-column: 7/span 7
	}
	.grid-start-xl-8.grid-xl-7 {
		grid-column: 8/span 7
	}
	.grid-start-xl-9.grid-xl-7 {
		grid-column: 9/span 7
	}
	.grid-start-xl-10.grid-xl-7 {
		grid-column: 10/span 7
	}
	.grid-start-xl-11.grid-xl-7 {
		grid-column: 11/span 7
	}
	.grid-start-xl-12.grid-xl-7 {
		grid-column: 12/span 7
	}
	.grid-xl-7 {
		grid-column-start: span 7
	}
	.grid-start-xl-1.grid-xl-8 {
		grid-column: 1/span 8
	}
	.grid-start-xl-2.grid-xl-8 {
		grid-column: 2/span 8
	}
	.grid-start-xl-3.grid-xl-8 {
		grid-column: 3/span 8
	}
	.grid-start-xl-4.grid-xl-8 {
		grid-column: 4/span 8
	}
	.grid-start-xl-5.grid-xl-8 {
		grid-column: 5/span 8
	}
	.grid-start-xl-6.grid-xl-8 {
		grid-column: 6/span 8
	}
	.grid-start-xl-7.grid-xl-8 {
		grid-column: 7/span 8
	}
	.grid-start-xl-8.grid-xl-8 {
		grid-column: 8/span 8
	}
	.grid-start-xl-9.grid-xl-8 {
		grid-column: 9/span 8
	}
	.grid-start-xl-10.grid-xl-8 {
		grid-column: 10/span 8
	}
	.grid-start-xl-11.grid-xl-8 {
		grid-column: 11/span 8
	}
	.grid-start-xl-12.grid-xl-8 {
		grid-column: 12/span 8
	}
	.grid-xl-8 {
		grid-column-start: span 8
	}
	.grid-start-xl-1.grid-xl-9 {
		grid-column: 1/span 9
	}
	.grid-start-xl-2.grid-xl-9 {
		grid-column: 2/span 9
	}
	.grid-start-xl-3.grid-xl-9 {
		grid-column: 3/span 9
	}
	.grid-start-xl-4.grid-xl-9 {
		grid-column: 4/span 9
	}
	.grid-start-xl-5.grid-xl-9 {
		grid-column: 5/span 9
	}
	.grid-start-xl-6.grid-xl-9 {
		grid-column: 6/span 9
	}
	.grid-start-xl-7.grid-xl-9 {
		grid-column: 7/span 9
	}
	.grid-start-xl-8.grid-xl-9 {
		grid-column: 8/span 9
	}
	.grid-start-xl-9.grid-xl-9 {
		grid-column: 9/span 9
	}
	.grid-start-xl-10.grid-xl-9 {
		grid-column: 10/span 9
	}
	.grid-start-xl-11.grid-xl-9 {
		grid-column: 11/span 9
	}
	.grid-start-xl-12.grid-xl-9 {
		grid-column: 12/span 9
	}
	.grid-xl-9 {
		grid-column-start: span 9
	}
	.grid-start-xl-1.grid-xl-10 {
		grid-column: 1/span 10
	}
	.grid-start-xl-2.grid-xl-10 {
		grid-column: 2/span 10
	}
	.grid-start-xl-3.grid-xl-10 {
		grid-column: 3/span 10
	}
	.grid-start-xl-4.grid-xl-10 {
		grid-column: 4/span 10
	}
	.grid-start-xl-5.grid-xl-10 {
		grid-column: 5/span 10
	}
	.grid-start-xl-6.grid-xl-10 {
		grid-column: 6/span 10
	}
	.grid-start-xl-7.grid-xl-10 {
		grid-column: 7/span 10
	}
	.grid-start-xl-8.grid-xl-10 {
		grid-column: 8/span 10
	}
	.grid-start-xl-9.grid-xl-10 {
		grid-column: 9/span 10
	}
	.grid-start-xl-10.grid-xl-10 {
		grid-column: 10/span 10
	}
	.grid-start-xl-11.grid-xl-10 {
		grid-column: 11/span 10
	}
	.grid-start-xl-12.grid-xl-10 {
		grid-column: 12/span 10
	}
	.grid-xl-10 {
		grid-column-start: span 10
	}
	.grid-start-xl-1.grid-xl-11 {
		grid-column: 1/span 11
	}
	.grid-start-xl-2.grid-xl-11 {
		grid-column: 2/span 11
	}
	.grid-start-xl-3.grid-xl-11 {
		grid-column: 3/span 11
	}
	.grid-start-xl-4.grid-xl-11 {
		grid-column: 4/span 11
	}
	.grid-start-xl-5.grid-xl-11 {
		grid-column: 5/span 11
	}
	.grid-start-xl-6.grid-xl-11 {
		grid-column: 6/span 11
	}
	.grid-start-xl-7.grid-xl-11 {
		grid-column: 7/span 11
	}
	.grid-start-xl-8.grid-xl-11 {
		grid-column: 8/span 11
	}
	.grid-start-xl-9.grid-xl-11 {
		grid-column: 9/span 11
	}
	.grid-start-xl-10.grid-xl-11 {
		grid-column: 10/span 11
	}
	.grid-start-xl-11.grid-xl-11 {
		grid-column: 11/span 11
	}
	.grid-start-xl-12.grid-xl-11 {
		grid-column: 12/span 11
	}
	.grid-xl-11 {
		grid-column-start: span 11
	}
	.grid-start-xl-1.grid-xl-12 {
		grid-column: 1/span 12
	}
	.grid-start-xl-2.grid-xl-12 {
		grid-column: 2/span 12
	}
	.grid-start-xl-3.grid-xl-12 {
		grid-column: 3/span 12
	}
	.grid-start-xl-4.grid-xl-12 {
		grid-column: 4/span 12
	}
	.grid-start-xl-5.grid-xl-12 {
		grid-column: 5/span 12
	}
	.grid-start-xl-6.grid-xl-12 {
		grid-column: 6/span 12
	}
	.grid-start-xl-7.grid-xl-12 {
		grid-column: 7/span 12
	}
	.grid-start-xl-8.grid-xl-12 {
		grid-column: 8/span 12
	}
	.grid-start-xl-9.grid-xl-12 {
		grid-column: 9/span 12
	}
	.grid-start-xl-10.grid-xl-12 {
		grid-column: 10/span 12
	}
	.grid-start-xl-11.grid-xl-12 {
		grid-column: 11/span 12
	}
	.grid-start-xl-12.grid-xl-12 {
		grid-column: 12/span 12
	}
	.grid-xl-12 {
		grid-column-start: span 12
	}
}

.width-10 {
	width: 10%
}

.width-20 {
	width: 20%
}

.width-30 {
	width: 30%
}

.width-40 {
	width: 40%
}

.width-50 {
	width: 50%
}

.width-60 {
	width: 60%
}

.width-70 {
	width: 70%
}

.width-80 {
	width: 80%
}

.width-90 {
	width: 90%
}

.button, .width-100 {
	width: 100%
}

.button {
	font-family: "WorkSans", sans-serif;
	font-size: 1rem;
	padding: .75rem 1.9rem;
	border: 0;
	cursor: pointer;
	font-weight: 700;
	line-height: 1;
	outline: 0;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .25);
	text-transform: uppercase;
	transition: .3s ease
}

@media (min-width:600px) {
	.button {
		width: auto
	}
}

.button.primary {
	background: #2a9da5;
	color: #fff
}

.button.primary:hover {
	background: #238289;
	transition: all .2s ease;
	text-decoration: none
}

.button.primary:focus {
	outline: 0;
	box-shadow: 0 0 0 .15rem rgba(42, 157, 165, .25);
	text-decoration: none
}

.button.primary:active {
	background: #20767c
}

.button.primary.disabled, .button.primary:disabled {
	opacity: .55;
	color: #666;
	background: #ccc;
	box-shadow: none;
	cursor: auto
}

.button.primary.disabled:hover, .button.primary.outline.disabled:hover, .button.primary.outline:active.disabled:hover, .button.primary.outline:active:disabled:hover, .button.primary.outline:disabled:hover, .button.primary.outline:hover.disabled:hover, .button.primary.outline:hover:disabled:hover, .button.primary:disabled:hover {
	background: #ccc
}

.button.primary.disabled:focus, .button.primary.outline.disabled:focus, .button.primary.outline:active.disabled:focus, .button.primary.outline:active:disabled:focus, .button.primary.outline:disabled:focus, .button.primary.outline:hover.disabled:focus, .button.primary.outline:hover:disabled:focus, .button.primary:disabled:focus {
	box-shadow: none
}

.button.primary.outline {
	background: 0 0;
	color: #2a9da5;
	border: .13rem solid #2a9da5;
	box-shadow: none
}

.button.primary.outline:hover {
	background: 0 0;
	transition: all .2s ease;
	text-decoration: none
}

.button.primary.outline:focus {
	outline: 0;
	box-shadow: 0 0 0 .15rem rgba(42, 157, 165, .25);
	text-decoration: none
}

.button.primary.outline:active {
	background: 0 0
}

.button.primary.outline.disabled, .button.primary.outline:disabled {
	opacity: .55;
	color: #666;
	background: #ccc;
	box-shadow: none;
	cursor: auto
}

.button.primary.outline:active, .button.primary.outline:hover {
	background: #2a9da5;
	color: #fff;
	box-shadow: none
}

.button.primary.outline:active:hover, .button.primary.outline:hover:hover {
	background: #238289;
	transition: all .2s ease;
	text-decoration: none
}

.button.primary.outline:active:focus, .button.primary.outline:hover:focus {
	outline: 0;
	box-shadow: 0 0 0 .15rem rgba(42, 157, 165, .25);
	text-decoration: none
}

.button.primary.outline:active:active, .button.primary.outline:hover:active {
	background: #20767c
}

.button.primary.outline:active.disabled, .button.primary.outline:active:disabled, .button.primary.outline:hover.disabled, .button.primary.outline:hover:disabled {
	opacity: .55;
	color: #666;
	background: #ccc;
	box-shadow: none;
	cursor: auto
}

.button.small {
	padding: .5rem 1rem;
	font-size: .75rem
}

.button.large {
	padding: 1rem 1.5rem;
	font-size: 1.25rem
}

.button.circle {
	border-radius: 50%;
	height: 2.5rem;
	width: 2.5rem;
	display: inline-flex;
	justify-content: center;
	font-size: 1.45rem;
	padding: inherit;
	align-items: center
}

.button.rounded {
	border-radius: 2.1rem
}

.button.block {
	display: block;
	width: 100%
}

.card {
	box-shadow: 1px 1px 1px #000;
	width: 100%;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, .2);
	transition: .3s ease
}

.card:hover {
	box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, .2)
}

.card-grid .card {
	padding: 3rem 1.125rem
}

.cards {
	background-color: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15)
}

.cards--header {
	padding: 1.5rem 1rem 0
}

.cards--header_avatar {
	padding: 1rem 1rem .5rem
}

.cards__content, .cards__footer {
	padding: 1rem
}

.cards__footer button {
	text-transform: inherit
}

.cards .text-bold {
	font-weight: 700
}

.collapsible__header {
	padding: 1rem 0
}

.collapsible__header--button.arrow {
	cursor: pointer;
	border: 0;
	width: 100%
}

.collapsible__header--button.arrow .ico-arrow_up {
	transform: rotate(180deg);
	transition: .3s ease
}

.collapsible__header--button.arrow.rotate .ico-arrow_up {
	transform: rotate(0deg)
}

.collapsible__header--button:focus, .collapsible__header--button:hover {
	text-decoration: none
}

.collapsible__header--button .text-button {
	margin-bottom: 0;
	width: 100%;
	text-align: left
}

.collapsible__content {
	overflow: hidden;
	transition: .3s ease;
	height: auto
}

.collapsible__content.closed {
	height: 0;
	transition: .3s ease
}

.modal, .modal__popup {
	transform: translate(-50%, -50%)
}

.modal {
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, .4);
	opacity: 0;
	visibility: hidden;
	transform: scale(1.1);
	pointer-events: none;
	z-index: 1011;
	transition: visibility 0s linear .25s, opacity .25s 0s, transform .25s;
	position: fixed;
	left: 0;
	top: 0
}

.modal__popup {
	position: absolute;
	top: 50%;
	left: 50%;
	background-color: #fff;
	width: calc(100% - 2rem);
	border-radius: .25rem;
	max-width: 480px;
	box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .3)
}

.modal__content {
	font-family: "WorkSans", sans-serif;
	padding: 3rem 1.5rem;
	color: #999;
	font-size: .9rem
}

.modal__buttons {
	display: flex;
	justify-content: center;
	width: auto;
	margin: 2rem 0 0
}

.modal__close {
	position: absolute;
	font-size: 1.3rem;
	color: #999;
	right: .7rem;
	top: .7rem;
	cursor: pointer
}

.modal__icon {
	font-size: 4rem;
	line-height: 1.2
}

.modal--active {
	opacity: 1;
	pointer-events: auto;
	visibility: visible;
	transform: scale(1);
	transition: visibility 0s linear 0s, opacity .25s 0s, transform .25s
}

.glide {
	position: relative;
	width: 100%;
	box-sizing: border-box
}

.glide__slides, .glide__track {
	overflow: hidden
}

.glide__track:after, .glide__track:before {
	content: "";
	height: 100%;
	width: 10%;
	position: absolute;
	top: 0;
	z-index: 1
}

@media (min-width:600px) {
	.glide__track:after, .glide__track:before {
		width: 15%
	}
}

.glide__track:before {
	background: #fff;
	background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, .3) 50%, rgba(255, 255, 255, 0) 100%);
	left: 0
}

.glide__track:after {
	background: #fff;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 50%, #fff 100%);
	right: 0
}

.glide__slides {
	position: relative;
	width: 100%;
	list-style: none;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	touch-action: pan-Y;
	padding: 0;
	white-space: nowrap;
	display: flex;
	flex-wrap: nowrap;
	will-change: transform
}

.glide__slide, .glide__slides--dragging {
	user-select: none
}

.glide__slide {
	margin: 2rem auto 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	transform: scale(.9);
	transition: all .2s linear;
	width: 100%;
	height: 100%;
	white-space: initial;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent
}

.glide__slide--active {
	transform: scale(1.08);
	transition: all .2s linear
}

.glide__slide--active .glide__inner>* {
	opacity: 1
}

.glide__slide--active .glide__info {
	box-shadow: 0 10px 15px rgba(39, 39, 39, .07)
}

.glide__inner, .glide__inner>* {
	position: relative;
	width: 100%;
	height: auto
}

.glide__inner {
	padding-top: initial
}

.glide__inner>* {
	opacity: .7;
	top: 0;
	left: 0
}

.glide__info {
	padding: 1.7rem 2rem;
	margin: 0 0 1rem;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .08)
}

.glide[data-adjust="aspect-16:9"] .glide__inner {
	padding-top: 56.25%;
	height: 0
}

.glide[data-adjust="aspect-16:9"] .glide__inner>* {
	position: absolute
}

.glide[data-adjust="aspect-4:3"] .glide__inner {
	padding-top: 75%;
	height: 0
}

.glide[data-adjust="aspect-4:3"] .glide__inner>* {
	position: absolute
}

.glide__bullet, .glide__bullets {
	position: relative;
	display: flex;
	justify-content: center
}

.glide__bullets {
	user-select: none;
	top: 1rem
}

.glide__bullet {
	margin: 0 .3rem;
	width: 13px;
	height: 13px;
	padding: 0;
	border-radius: 50%;
	background: #fff;
	border: 1px solid #2a9da5;
	align-items: center
}

.glide__bullet--active:before {
	content: "";
	width: 7px;
	height: 7px;
	background: #2a9da5;
	border-radius: 50%
}

.glide__bullet:focus {
	outline: 0
}

.glide__bullet:hover {
	cursor: pointer
}

.glide--swipeable {
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab
}

.glide--dragging {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing
}

.tabs {
	display: flex;
	flex-direction: column;
	background: #fff;
	margin-bottom: 1rem;
	position: relative
}

.tabs__header, .tabs__label {
	display: flex;
	justify-content: center;
	position: relative;
	overflow: hidden
}

.tabs__header {
	box-shadow: 0 1px 3px rgba(0, 0, 0, .09), 0 2px 3px rgba(0, 0, 0, .04);
	background: #fff;
	height: 50px
}

.tabs__label {
	font-family: "WorkSans", sans-serif;
	font-weight: 700;
	font-size: .9rem;
	letter-spacing: .5px;
	color: #999;
	width: 130px;
	text-align: center;
	cursor: pointer;
	flex-shrink: 0;
	align-items: center
}

.tabs__label.active {
	color: #2a9da5
}

.tabs__label.active:after {
	content: '';
	height: 2px;
	background: #2a9da5;
	width: 100%;
	position: absolute;
	bottom: 0
}

.tabs__label .cercle {
	width: 0;
	height: 0;
	position: absolute;
	background-color: #2a9da5
}

.tabs__container {
	background: #fff;
	top: 5px;
	position: relative;
	padding: 1rem
}

.tabs__panel {
	display: none;
	opacity: 0
}

.tabs__panel.active {
	display: block;
	opacity: 1
}

.tabs [class*=arrow] {
	display: none
}

.tabs.with-arrows [class*=arrow] {
	display: block;
	position: absolute;
	height: 50px;
	background: #fff;
	width: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer
}

.tabs.with-arrows .tabs__header {
	padding: 0 1.5rem;
	justify-content: flex-start
}

.tabs__arrow--left::after, .tabs__arrow--right::after {
	font-family: 'ds';
	content: "\e900";
	color: #999;
	font-size: 1.4rem
}

.tabs__arrow--left {
	left: 0;
	box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .05)
}

.tabs__arrow--left::after {
	transform: rotate(90deg)
}

.tabs__arrow--right {
	right: 0;
	box-shadow: -3px 0 5px 0 rgba(0, 0, 0, .05)
}

.tabs__arrow--right::after {
	transform: rotate(-90deg)
}

.animation-wave {
	opacity: .15;
	animation: touch 1.3s ease-out
}

.checkbox__label {
	display: block;
	position: relative;
	padding-left: 1.8rem;
	line-height: 1.2;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none
}

.checkbox__label [class*=span]:after {
	left: 8px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid #fff;
	border-width: 0 3px 3px 0;
	transform: rotate(45deg)
}

.checkbox__input {
	position: absolute;
	opacity: 0;
	left: 0;
	top: 0;
	cursor: pointer
}

.checkbox__input:checked~[class*=span] {
	background-color: #999;
	border: 0
}

.checkbox__input:checked~[class*=span]:after {
	display: block
}

.checkbox__input:disabled {
	background-color: #999;
	border-color: #999
}

.checkbox__span {
	position: absolute;
	top: 0;
	left: 0;
	height: 1.125rem;
	width: 1.125rem;
	border: 2px solid #ccc;
	border-radius: 3px
}

.checkbox__span:after {
	content: "";
	position: absolute;
	display: none
}

.checkbox__span .disabled {
	background-color: #333;
	border-color: #999
}

.checkbox__disabled {
	background-color: #ccc;
	border-color: #ccc
}

.button-group.primary-base [class*=input]:checked+label, .checkbox.primary-base [class*=input]:checked~[class*=span] {
	background-color: #2a9da5
}

.button-group.primary-light [class*=input]:checked+label, .checkbox.primary-light [class*=input]:checked~[class*=span] {
	background-color: #2bb7c1
}

.button-group.primary-dark [class*=input]:checked+label, .checkbox.primary-dark [class*=input]:checked~[class*=span] {
	background-color: #228389
}

::-ms-input-placeholder, ::placeholder {
	color: #999;
	opacity: 1
}

.input {
	position: relative;
	padding-top: 2rem;
	margin-bottom: .8rem
}

.input__input {
	width: 100%
}

.input__input, .search__input, .select__select {
	height: calc(1.8em + .75rem + 2px);
	padding: .375rem .75rem;
	font-family: "WorkSans", sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	color: #262626;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #999;
	border-radius: .25rem;
	position: relative
}

.search__input {
	width: 100%
}

.input__input:focus, .select__select:focus {
	border-color: #80bdff;
	outline: 0
}

.input__input:focus, .search__input:focus, .select__select:focus {
	box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.input__input:focus~[class*=error], .search__input:focus~[class*=error], .select__select:focus~[class*=error] {
	opacity: 1
}

.input__input:disabled, .input__input[class*=disabled], .search__input:disabled, .search__input[class*=disabled], .select__select:disabled, .select__select[class*=disabled] {
	opacity: .5;
	background: #eee
}

.input__input:disabled~[class*=label], .input__input[class*=disabled]~[class*=label], .search__input:disabled~[class*=label], .search__input[class*=disabled]~[class*=label], .select__select:disabled~[class*=label], .select__select[class*=disabled]~[class*=label] {
	opacity: .5;
	color: #333
}

.input__input[type=date], .input__input[type=time], .search__input[type=date], .search__input[type=time], .select__select[type=date], .select__select[type=time] {
	color: transparent
}

.input__input[type=date].focused, .input__input[type=date]:focus, .input__input[type=time].focused, .input__input[type=time]:focus, .search__input[type=date].focused, .search__input[type=date]:focus, .search__input[type=time].focused, .search__input[type=time]:focus, .select__select[type=date].focused, .select__select[type=date]:focus, .select__select[type=time].focused, .select__select[type=time]:focus {
	color: inherit
}

.input__input[type=date]::-webkit-calendar-picker-indicator, .input__input[type=date]::-webkit-clear-button, .input__input[type=time]::-webkit-calendar-picker-indicator, .input__input[type=time]::-webkit-clear-button, .search__input[type=date]::-webkit-calendar-picker-indicator, .search__input[type=date]::-webkit-clear-button, .search__input[type=time]::-webkit-calendar-picker-indicator, .search__input[type=time]::-webkit-clear-button, .select__select[type=date]::-webkit-calendar-picker-indicator, .select__select[type=date]::-webkit-clear-button, .select__select[type=time]::-webkit-calendar-picker-indicator, .select__select[type=time]::-webkit-clear-button {
	color: transparent;
	background: 0 0;
	z-index: 1
}

.input__input[type=date]::before, .input__input[type=time]::before, .search__input[type=date]::before, .search__input[type=time]::before, .select__select[type=date]::before, .select__select[type=time]::before {
	font-family: 'ds';
	position: absolute;
	top: .3rem;
	right: .5rem;
	color: #999;
	font-size: 1.4rem
}

.input__input[type=date]::before, .search__input[type=date]::before, .select__select[type=date]::before {
	content: '\e90e'
}

.input__input[type=time]::before, .search__input[type=time]::before, .select__select[type=time]::before {
	content: '\e90f'
}

.input__input[type=time]::-webkit-inner-spin-button, .search__input[type=time]::-webkit-inner-spin-button, .select__select[type=time]::-webkit-inner-spin-button {
	opacity: 0;
	z-index: 2
}

.input[class*="--"] [class*=__input]::placeholder {
	opacity: 0
}

.input[class*="--"] [class*=__input]:focus::placeholder {
	opacity: 1
}

.input__label, .select__label {
	position: absolute;
	font-size: 1rem;
	left: 0;
	transform: translateY(-2rem)
}

.input__error {
	position: absolute;
	opacity: 0;
	left: 0;
	bottom: -1.5rem;
	font-size: .85rem;
	color: #2a9da5;
	transition: all .1s linear
}

.input--underline [class*=__input] {
	border: 0;
	border-bottom: 1px solid #999;
	border-radius: 0;
	padding: .375rem .7rem .375rem 0
}

.input--underline [class*=__input]:focus {
	border-color: none;
	outline: 0;
	box-shadow: none
}

.input--underline [class*=__input].focused~[class*=label] {
	transform: translate(-.3rem, -1rem) scale(.8)
}

.input--underline [class*=__input]:disabled~[class*=label], .input--underline [class*=__input][class*=disabled]~[class*=label] {
	transform: translate(.7rem, .5rem)
}

.input--underline [class*=label] {
	position: absolute;
	transform: translateY(.5rem);
	transition: all .2s ease;
	pointer-events: none
}

.input--underline::after {
	content: "";
	position: absolute;
	background-color: #999;
	left: 0;
	bottom: 0;
	display: block;
	width: 100%;
	height: 2px;
	transform: scaleX(0);
	transition: transform .3s
}

.input--underline.highlight::after {
	transform: scaleX(1)
}

.input--outline [class*=__input]:focus, .input--outline [class*=__input]:hover {
	border-color: #999;
	box-shadow: inset 0 0 0 1px #999
}

.input--outline [class*=__input].focused~[class*=label] {
	transform: translateY(-.7rem) scale(.8);
	background-color: #fff;
	padding: 0 .3rem;
	left: 5px
}

.input--outline [class*=label] {
	position: absolute;
	left: .7rem;
	transform: translateY(.6rem);
	transition: all .2s ease;
	pointer-events: none
}

.input--underline.primary-base [class*=__input]:focus[type=date]::before {
	color: #2a9da5
}

.input--underline.primary-base::after {
	background-color: #2a9da5
}

.input--underline.primary-light [class*=__input]:focus[type=date]::before {
	color: #2bb7c1
}

.input--underline.primary-light::after {
	background-color: #2bb7c1
}

.input--underline.primary-dark [class*=__input]:focus[type=date]::before {
	color: #228389
}

.input--underline.primary-dark::after {
	background-color: #228389
}

.input--outline.primary-base [class*=__input]:focus, .input--outline.primary-base [class*=__input]:hover {
	border-color: #2a9da5;
	box-shadow: inset 0 0 0 1px #2a9da5
}

.input--outline.primary-base [class*=__input]:focus[type=date]::before, .input--outline.primary-base [class*=__input]:hover[type=date]::before {
	color: #2a9da5
}

.input--outline.primary-base [class*=__input]:disabled, .input--outline.primary-base [class*=__input][class*=disabled] {
	border: 1px solid #999;
	box-shadow: none
}

.input--outline.primary-light [class*=__input]:focus, .input--outline.primary-light [class*=__input]:hover {
	border-color: #2bb7c1;
	box-shadow: inset 0 0 0 1px #2bb7c1
}

.input--outline.primary-light [class*=__input]:focus[type=date]::before, .input--outline.primary-light [class*=__input]:hover[type=date]::before {
	color: #2bb7c1
}

.input--outline.primary-light [class*=__input]:disabled, .input--outline.primary-light [class*=__input][class*=disabled] {
	border: 1px solid #999;
	box-shadow: none
}

.input--outline.primary-dark [class*=__input]:focus, .input--outline.primary-dark [class*=__input]:hover {
	border-color: #228389;
	box-shadow: inset 0 0 0 1px #228389
}

.input--outline.primary-dark [class*=__input]:focus[type=date]::before, .input--outline.primary-dark [class*=__input]:hover[type=date]::before {
	color: #228389
}

.input--outline.primary-dark [class*=__input]:disabled, .input--outline.primary-dark [class*=__input][class*=disabled] {
	border: 1px solid #999;
	box-shadow: none
}

.toggle {
	display: flex;
	align-items: center
}

.toggle__slider {
	position: relative;
	display: inline-block;
	width: 2.5rem;
	height: .9rem;
	background: #ccc;
	border-radius: 25px;
	cursor: pointer;
	transition: all .2s ease
}

.toggle__slider:after {
	position: absolute;
	left: -.3rem;
	top: -.3rem;
	display: block;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background: #f2f2f2;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .45);
	content: '';
	transition: all .2s ease
}

.toggle__input {
	display: none
}

.toggle__input~[class*=label] {
	margin-left: 8px
}

.toggle__input:checked~[class*=slider] {
	background: #999
}

.toggle__input:checked~[class*=slider]:after {
	left: 1.2rem
}

.toggle__input:disabled~[class*=slider] {
	background: #eee;
	cursor: default
}

.toggle__input:disabled~[class*=slider]:after {
	background: #ccc
}

.toggle.primary-base [class*=input]:checked:not(:disabled)~[class*=slider] {
	background: #73d5db
}

.toggle.primary-base [class*=input]:checked:not(:disabled)~[class*=slider]:after {
	background: #2a9da5
}

.toggle.primary-light [class*=input]:checked:not(:disabled)~[class*=slider] {
	background: #87dee4
}

.toggle.primary-light [class*=input]:checked:not(:disabled)~[class*=slider]:after {
	background: #2bb7c1
}

.toggle.primary-dark [class*=input]:checked:not(:disabled)~[class*=slider] {
	background: #56ced5
}

.toggle.primary-dark [class*=input]:checked:not(:disabled)~[class*=slider]:after {
	background: #228389
}

.button-group {
	display: flex;
	border: 1px solid #999;
	border-radius: .25rem;
	overflow: hidden
}

.button-group__input, .select--outline select, .select--underline select {
	display: none
}

.button-group__input:checked+label {
	background-color: #999;
	color: #fff;
	font-weight: 700;
	border: 1px solid #999 left right
}

.button-group__input:checked+label:last-child {
	border-right: 0;
	border-left: 0;
	color: #fff
}

.button-group__label {
	padding: .875rem .75rem;
	background-color: #fff;
	font-weight: 700;
	color: #666;
	cursor: pointer;
	text-align: center;
	border-right: 1px solid #999
}

.button-group.primary-base [class*=label]:last-child, .button-group.primary-dark [class*=label]:last-child, .button-group.primary-light [class*=label]:last-child, .button-group__label:last-child {
	border-right: none
}

.button-group.primary-base {
	border: 1px solid #2a9da5
}

.button-group.primary-base [class*=label] {
	border-right: 1px solid #2a9da5
}

.button-group.primary-light {
	border: 1px solid #2bb7c1
}

.button-group.primary-light [class*=label] {
	border-right: 1px solid #2bb7c1
}

.button-group.primary-dark {
	border: 1px solid #228389
}

.button-group.primary-dark [class*=label] {
	border-right: 1px solid #228389
}

.radio {
	flex-direction: column
}

.radio, .radio__label {
	display: flex
}

.radio__input {
	vertical-align: middle;
	width: 1.125rem;
	height: 1.125rem;
	line-height: inherit;
	border: 1px solid #999;
	border-radius: 1rem;
	-webkit-appearance: initial;
	position: relative;
	transition: background .28s ease-in-out .07s;
	margin-right: .5rem;
	margin-left: 0;
	outline: 0;
	cursor: pointer
}

.radio__input:checked:before {
	content: "";
	background: #999;
	width: .5rem;
	height: .5rem;
	border-radius: 1rem;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	outline: 0
}

.radio__input:checked:after {
	animation: box-animate .26s ease-in-out
}

.radio__input:after {
	content: "";
	display: block;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background: #999;
	transition: background .28s ease-in-out .28s
}

.radio__input:disabled {
	background-color: #ccc;
	border-color: #ccc;
	cursor: default
}

.radio.primary-base [class*=input] {
	border: 1px solid #2a9da5
}

.radio.primary-base [class*=input]:checked:before, .switch.primary-base [class*=input]:checked+[class*=slider] {
	background: #2a9da5
}

.radio.primary-base [class*=input]:after {
	background: #2a9da5
}

.radio.primary-base [class*=input]:disabled, .radio.primary-dark [class*=input]:disabled, .radio.primary-light [class*=input]:disabled {
	border-color: #ccc
}

.radio.primary-light [class*=input] {
	border: 1px solid #2bb7c1
}

.radio.primary-light [class*=input]:checked:before, .switch.primary-light [class*=input]:checked+[class*=slider] {
	background: #2bb7c1
}

.radio.primary-light [class*=input]:after {
	background: #2bb7c1
}

.radio.primary-dark [class*=input] {
	border: 1px solid #228389
}

.radio.primary-dark [class*=input]:checked:before, .switch.primary-dark [class*=input]:checked+[class*=slider] {
	background: #228389
}

.radio.primary-dark [class*=input]:after {
	background: #228389
}

.select {
	position: relative;
	padding-top: 2rem;
	margin-bottom: .8rem
}

.header__brand img, .hero picture img, .select__select {
	width: 100%
}

.select--outline [class*=__selected] {
	border: 1px solid #999;
	border-radius: 5px
}

.select--outline [class*=__selected], .select--underline [class*=__selected] {
	position: relative;
	padding: .5rem;
	color: #262626;
	cursor: pointer
}

.select--outline [class*=__selected]::after, .select--underline [class*=__selected]::after {
	position: absolute;
	font-family: 'ds';
	content: '\e900';
	right: 0;
	font-size: 1.5rem;
	line-height: 1.7;
	top: 0;
	padding: 0 .3rem;
	transition: all ease .2s
}

.select--outline [class*=__selected]::before {
	content: '';
	height: 100%;
	position: absolute;
	right: 2.1rem;
	top: 0;
	border-left: 1px solid #999
}

.select--underline [class*=__selected]::before {
	right: 2.1rem;
	border-left: 1px solid #999
}

.select--outline [class*=__selected].active::after, .select--underline [class*=__selected].active::after {
	transform: rotate(-180deg)
}

.select--outline [class*=__selected].active~[class*=options], .select--underline [class*=__selected].active~[class*=options] {
	display: block
}

.select--outline [class*=__selected].active, .select--outline [class*=__selected]:focus, .select--outline [class*=__selected]:hover {
	border-color: #999;
	box-shadow: inset 0 0 0 1px #999
}

.select--outline [class*=__selected].active::before, .select--outline [class*=__selected]:focus::before, .select--outline [class*=__selected]:hover::before, .select--underline [class*=__selected].active::before, .select--underline [class*=__selected]:focus::before, .select--underline [class*=__selected]:hover::before {
	border-left: 2px solid #999
}

.select--outline [class*=options], .select--underline [class*=options] {
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	z-index: 999;
	margin: 0;
	padding: 0;
	border-radius: 3px;
	border: 1px solid #ccc;
	background: #fff
}

.select--outline [class*=options][class*=hide], .select--underline [class*=options][class*=hide] {
	display: none
}

.select--outline [class*=options] div, .select--underline [class*=options] div {
	color: #262626;
	border-bottom: 1px solid #ccc;
	padding: .4rem 1rem;
	cursor: pointer
}

.select--outline [class*=options] div:last-child, .select--underline [class*=options] div:last-child {
	border-bottom: none
}

.select--outline [class*=options] div:hover, .select--underline [class*=options] div:hover {
	color: #666;
	background: #eee
}

.select--underline [class*=__selected] {
	border: 0;
	border-radius: 0;
	border-bottom: 1px solid #999
}

.select--underline [class*=__selected].active, .select--underline [class*=__selected]:focus, .select--underline [class*=__selected]:hover {
	border-color: none;
	box-shadow: none
}

.select--underline [class*=__selected]::before {
	content: "";
	position: absolute;
	background-color: #999;
	left: 0;
	top: 100%;
	display: block;
	width: 100%;
	height: 2px;
	transform: scaleX(0);
	transition: transform .3s
}

.select--underline [class*=__selected].active::before {
	transform: scaleX(1)
}

.select--underline [class*=options] {
	top: calc(100% + 1px)
}

.primary-base.select--underline [class*=__selected].active, .primary-base.select--underline [class*=__selected]:focus, .primary-base.select--underline [class*=__selected]:hover, .select--outline.primary-base [class*=__selected].active, .select--outline.primary-base [class*=__selected]:focus, .select--outline.primary-base [class*=__selected]:hover {
	border-color: #2a9da5;
	box-shadow: inset 0 0 0 1px #2a9da5
}

.primary-base.select--underline [class*=__selected].active::before, .primary-base.select--underline [class*=__selected]:focus::before, .primary-base.select--underline [class*=__selected]:hover::before, .select--outline.primary-base [class*=__selected].active::before, .select--outline.primary-base [class*=__selected]:focus::before, .select--outline.primary-base [class*=__selected]:hover::before {
	border-left: 2px solid #2a9da5
}

.primary-base.select--underline [class*=__selected].active::after, .primary-base.select--underline [class*=__selected]:focus::after, .primary-base.select--underline [class*=__selected]:hover::after, .select--outline.primary-base [class*=__selected].active::after, .select--outline.primary-base [class*=__selected]:focus::after, .select--outline.primary-base [class*=__selected]:hover::after {
	color: #2a9da5
}

.primary-light.select--underline [class*=__selected].active, .primary-light.select--underline [class*=__selected]:focus, .primary-light.select--underline [class*=__selected]:hover, .select--outline.primary-light [class*=__selected].active, .select--outline.primary-light [class*=__selected]:focus, .select--outline.primary-light [class*=__selected]:hover {
	border-color: #2bb7c1;
	box-shadow: inset 0 0 0 1px #2bb7c1
}

.primary-light.select--underline [class*=__selected].active::before, .primary-light.select--underline [class*=__selected]:focus::before, .primary-light.select--underline [class*=__selected]:hover::before, .select--outline.primary-light [class*=__selected].active::before, .select--outline.primary-light [class*=__selected]:focus::before, .select--outline.primary-light [class*=__selected]:hover::before {
	border-left: 2px solid #2bb7c1
}

.primary-light.select--underline [class*=__selected].active::after, .primary-light.select--underline [class*=__selected]:focus::after, .primary-light.select--underline [class*=__selected]:hover::after, .select--outline.primary-light [class*=__selected].active::after, .select--outline.primary-light [class*=__selected]:focus::after, .select--outline.primary-light [class*=__selected]:hover::after {
	color: #2bb7c1
}

.primary-dark.select--underline [class*=__selected].active, .primary-dark.select--underline [class*=__selected]:focus, .primary-dark.select--underline [class*=__selected]:hover, .select--outline.primary-dark [class*=__selected].active, .select--outline.primary-dark [class*=__selected]:focus, .select--outline.primary-dark [class*=__selected]:hover {
	border-color: #228389;
	box-shadow: inset 0 0 0 1px #228389
}

.primary-dark.select--underline [class*=__selected].active::before, .primary-dark.select--underline [class*=__selected]:focus::before, .primary-dark.select--underline [class*=__selected]:hover::before, .select--outline.primary-dark [class*=__selected].active::before, .select--outline.primary-dark [class*=__selected]:focus::before, .select--outline.primary-dark [class*=__selected]:hover::before {
	border-left: 2px solid #228389
}

.primary-dark.select--underline [class*=__selected].active::after, .primary-dark.select--underline [class*=__selected]:focus::after, .primary-dark.select--underline [class*=__selected]:hover::after, .select--outline.primary-dark [class*=__selected].active::after, .select--outline.primary-dark [class*=__selected]:focus::after, .select--outline.primary-dark [class*=__selected]:hover::after {
	color: #228389
}

.select--underline.primary-base [class*=__selected].active, .select--underline.primary-base [class*=__selected]:focus, .select--underline.primary-base [class*=__selected]:hover, .select--underline.primary-dark [class*=__selected].active, .select--underline.primary-dark [class*=__selected]:focus, .select--underline.primary-dark [class*=__selected]:hover, .select--underline.primary-light [class*=__selected].active, .select--underline.primary-light [class*=__selected]:focus, .select--underline.primary-light [class*=__selected]:hover {
	box-shadow: none
}

.select--underline.primary-base [class*=__selected].active::after, .select--underline.primary-base [class*=__selected]:focus::after, .select--underline.primary-base [class*=__selected]:hover::after {
	color: #2a9da5
}

.select--underline.primary-base [class*=__selected]::before {
	background-color: #2a9da5
}

.select--underline.primary-light [class*=__selected].active::after, .select--underline.primary-light [class*=__selected]:focus::after, .select--underline.primary-light [class*=__selected]:hover::after {
	color: #2bb7c1
}

.select--underline.primary-light [class*=__selected]::before {
	background-color: #2bb7c1
}

.select--underline.primary-dark [class*=__selected].active::after, .select--underline.primary-dark [class*=__selected]:focus::after, .select--underline.primary-dark [class*=__selected]:hover::after {
	color: #228389
}

.select--underline.primary-dark [class*=__selected]::before {
	background-color: #228389
}

.switch {
	position: relative;
	display: inline-block;
	width: 4.7rem;
	height: 2.1rem
}

.switch__input {
	opacity: 0;
	width: 0;
	height: 0
}

.switch__input:checked+[class*=slider] {
	background: #999
}

.switch__input:checked+[class*=slider]::before {
	transform: translateX(40px)
}

.switch__input:checked~[class*=text].on {
	opacity: 0
}

.switch__input:checked~[class*=text].off {
	opacity: 1
}

.switch__input:disabled+[class*=slider] {
	cursor: default;
	background: #eee
}

.switch__slider {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: .3s;
	border-radius: 25px;
	cursor: pointer
}

.switch__slider::before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: #fff;
	transition: .4s;
	border-radius: 50%;
	z-index: 1
}

.switch__text {
	position: absolute;
	font-weight: 700;
	top: 5px;
	transition: .3s all linear
}

.switch__text.off {
	color: #fff;
	left: 1.2rem;
	opacity: 0
}

.switch__text.on {
	color: #333;
	right: .9rem;
	opacity: 1
}

.search {
	width: 100%;
	display: flex
}

.search__input {
	padding-right: 2.5rem
}

.search__input:focus {
	border-color: #2a9da5;
	outline: 0;
	box-shadow: none
}

.search__input:focus~[class*=button] {
	color: #2a9da5
}

.search__button {
	background-color: transparent;
	color: #999;
	border: 0;
	padding: 0;
	outline: 0;
	margin: 5px 0 0 -35px;
	height: auto;
	width: auto;
	font-size: 1.6rem;
	z-index: 1;
	cursor: pointer
}

.search__button:focus, .search__button:hover {
	text-decoration: none;
	color: #999
}

.progress {
	position: relative
}

.progress--circular {
	width: 2.125rem;
	height: 2.125rem;
	background: 0 0;
	display: inline-flex;
	border: 3px solid transparent;
	border-color:  #2a9da5;
	border-radius: 50%
}

.progress--circular::after, .progress--circular::before {
	content: '';
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	margin: auto;
	top: 0;
	left: -3px;
	right: 0;
	bottom: 0;
	background: 0 0;
	border: 3px solid transparent;
	border-bottom-color: #999;
	border-radius: 50%;
	box-sizing: content-box
}

.progress--circular::before {
	animation: spinner .85s linear infinite
}

.progress--circular::after {
	animation: spinner .85s cubic-bezier(.25, .46, .48, .9) infinite
}

.progress--linear {
	display: flex;
	width: 100%;
	height: .5rem;
	background: rgba(153, 153, 153, .5)
}

.progress__bar {
	position: absolute;
	background: #999;
	left: 0;
	top: 0;
	height: 100%;
	max-width: 100%;
	transition: width .6s ease-in-out
}

.progress--circular.primary-base {
	border-color: rgba(42, 157, 165, .5)
}

.progress--circular.primary-base::after, .progress--circular.primary-base::before {
	border-bottom-color: #2a9da5
}

.progress--linear.primary-base {
	background: rgba(42, 157, 165, .5)
}

.progress--linear-dummy [class*=bar] {
	animation: bar 2s ease-in-out forwards
}

.badge-box {
	position: relative;
	width: 2rem;
	height: 2rem;
	background: #eee;
	margin: 0 auto
}

.badge {
	position: absolute;
	display: inline-flex;
	align-items: center;
	background: red;
	color: #fff;
	font-size: .6rem;
	font-weight: 600;
	line-height: 1;
	border-radius: 8px;
	padding: 0 .3333rem;
	height: 1rem
}

.badge.primary-base, .progress--linear.primary-base [class*=__bar] {
	background: #2a9da5
}

.badge-top-left {
	top: -8px;
	left: -8px
}

.badge-bottom-left {
	bottom: -8px;
	left: -8px
}

.badge-top-right {
	top: -8px;
	right: -8px
}

.badge-bottom-right {
	bottom: -8px;
	right: -8px
}

.header {
	background-color: rgba(0, 0, 0, .8);
	display: flex;
	justify-content: space-between;
	border-bottom: 4px solid #2a9da5;
	position: fixed;
	width: 100%;
	padding: .5rem;
	z-index: 9999999
}

@media (min-width:600px) {
	.header {
		padding: 1rem
	}
}

.header .divider {
	border: 1px solid #4a4a4a
}

.header__brand {
	max-width: 50px
}

@media (min-width:960px) {
	.header__brand {
		max-width: 80px
	}
}

.header__nav {
	list-style: none
}

@media (min-width:960px) {
	.header__nav li {
		margin-right: 20px
	}
}

.header__nav li:nth-child(4n) {
	margin: 0
}

.header__nav li a {
	position: relative;
	color: #999;
	font-weight: 600
}

.header__mobile [class*=ico-] {
	font-size: 2rem;
	color: #fff
}

.header .sticky {
	position: sticky;
	background-color: #2a9da5;
	padding: 0 14px;
	font-size: 20px;
	z-index: 100;
	width: 48px;
	margin: auto 0;
	border-radius: 60px
}

.footer a, .footer p, .header .sticky [class*=ico] {
	color: #fff
}

.header .sticky .redes {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center
}

.header .sticky .redes p {
	font-size: 8px;
	transform: rotate(-90deg)
}

.footer {
	background-color: #262626;
	width: 100%;
	font-size: .75rem;
	padding-top: 2rem;
	padding-bottom: 30px;
}

.footer h4 {
	font-size: 1rem;
	color: #fff;
	border-bottom: 1px solid #999;
	margin-bottom: .8rem;
	padding-bottom: .4rem
}

.footer h3 {
	border-bottom: 1px solid #333;
	margin-bottom: .6rem;
	padding: .4rem 0
}

.footer__brand {
	align-self: flex-start
}

@media (max-width:599px) {
	.footer__brand {
		align-self: center;
		justify-self: center
	}
}

.footer__access {
	align-self: flex-start
}

.footer__access, .footer__access .list__accses {
	flex-direction: column
}

@media (max-width:599px) {
	.footer__access {
		align-self: center;
		justify-self: center;
		text-align: center
	}
}

.footer__links {
	align-self: flex-start
}

@media (max-width:599px) {
	.footer__links, .newsletter {
		align-self: center;
		justify-self: center;
		text-align: center
	}
}

.newsletter .input-container {
	flex-direction: row
}

@media (max-width:599px) {
	.newsletter .input-container {
		flex-direction: row;
		justify-content: center
	}
}

.newsletter .input-container .input-field {
	height: 30px;
	width: 193px;
	background-color: rgba(53, 53, 53, .459);
	border: 0;
	margin-top: 1rem;
	padding-left: .5rem
}

.newsletter .input-container .nl_icon {
	background: #2a9da5;
	height: 30px;
	width: 30px;
	margin-top: 1rem;
	align-content: center;
	display: grid;
	justify-items: center;
	color: #fff
}

.newsletter .input-container .nl_icon [class*=ico-] {
	color: #fff;
	font-size: 2rem
}

::placeholder {
	color: #999;
	font-size: .75rem
}

.app-footer {
	display: grid;
	min-height: 60px;
	background-color: #f2f2f2;
	justify-content: center;
	align-items: center;
	padding: 1rem
}

.app-footer .text-footer {
	color: #fff
}

.social {
	margin-top: 2rem;
	padding: 1rem 0;
	border-top: 1px solid #999
}

.social .redes {
	display: grid;
	justify-content: end
}

@media (max-width:599px) {
	.social .redes {
		justify-content: center
	}
}

.social .legal_text {
	color: #999
}

@media (max-width:599px) {
	.social {
		align-self: center;
		justify-self: center;
		text-align: center
	}
}

.social [class*=ico-] {
	color: #fff;
	font-size: 2rem
}

.hero {
	display: flex;
	align-items: center;
	position: relative;
	overflow: hidden
}

.hero:before, .reviews:before {
	content: '';
	display: block;
	background-color: #000;
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: .5;
	z-index: -1
}

@media (max-width:599px) {
	.hero {
		align-items: flex-end
	}
}

@media (min-width:1920px) {
	.hero {
	}
}

.hero picture, .reviews picture {
	position: absolute;
	z-index: -2;
	top: 0;
	left: 0
}

@media (min-width:1920px) {
	.hero picture img {
		width: 120%
	}
}

.hero .lead {
	max-width: 400px;
	margin-top: 4rem
}

@media (max-width:599px) {
	.hero .lead {
		margin-bottom: 4rem
	}
}

.hero .lead__title {
	font-size: 4rem;
	color: #fff;
	line-height: 1
}

@media (max-width:599px) {
	.hero .lead__title {
		font-size: 2.8rem;
		max-width: 280px
	}
}

.hero .lead__subtitle, .howorks__stepper .stepper li.active [class*=ico-], .howorks__stepper .stepper li:hover [class*=ico-], .services .aditionals__card:hover h3 {
	color: #fff
}

.hero .lead__divider {
	background-color: #fff;
	height: 50px;
	width: 1px
}

.reviews_box {
	text-align: center;
	color: #fff
}

.reviews {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 600px;
	position: relative;
	overflow: hidden;
	border-bottom: 6px solid #2a9da5;
	padding: 1rem 0
}

.howorks__img img, .reviews picture img {
	width: 100%
}

.reviews_box {
	display: grid;
	justify-items: center;
	grid-area: 1/3/6/11;
	padding: 2rem 0
}

.reviews_box .r_name {
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	font-size: .875rem
}

.reviews_box h1, .services .aditionals__card h3 {
	font-size: 1.5rem
}

@media (max-width:599px) {
	.reviews_box h1 {
		font-size: 1.25rem
	}
	.reviews_box {
		grid-area: 1/1/2/13;
		padding: 2.5rem;
		font-size: .75rem
	}
}

.r_photo {
	width: 160px;
	height: 160px;
	border-radius: 160px;
	border: 3px solid #2bb7c1;
	margin: 3rem 0
}

.r_photo .avatar {
	height: 140px;
	width: 140px;
	margin-top: 6px
}

.comilla__2, .comillas {
	font-size: 4rem;
	height: 24px;
	display: grid
}

.comillas {
	margin-bottom: .9rem;
	align-content: baseline
}

.comillas .open {
	justify-self: flex-start
}

.comilla__2 {
	align-content: center
}

.comilla__2 .close {
	justify-self: end
}

#corners:after, #corners:before, #corners>:last-child:after, #corners>:last-child:before {
	position: absolute;
	width: 60px;
	height: 50px;
	border-color: #fff;
	border-style: solid;
	content: ' '
}

#corners:before {
	top: 10px;
	left: 10px;
	border-width: 1px 0 0 1px
}

#corners:after {
	top: 10px;
	right: 10px;
	border-width: 1px 1px 0 0
}

#corners>:last-child:before {
	bottom: 10px;
	right: 10px;
	border-width: 0 1px 1px 0
}

#corners>:last-child:after {
	bottom: 10px;
	left: 10px;
	border-width: 0 0 1px 1px
}

.plans {
	position: relative;
	background-image: url("../img/como_img2.png");
	background-size: cover;
	background-color: #000;
	background-repeat: no-repeat;
	padding: 4rem 0 2rem;
	color: #999
}

.plan {
	border-top: 8px solid #666;
	background: #fff;
	padding: 16px;
	text-align: center;
	border-right: 1px solid #ccc;
	border-left: 1px solid #ccc;
	border-bottom: 1px solid #ccc
}

.plan .header_plan .h_line {
	border: solid 1px #eee;
	margin-top: 1rem;
	width: 100%
}

.list_box {
	text-align: left;
	list-style: none
}

.list_box .list_item {
	padding: .5rem 0;
	margin-left: 1rem;
	color: #666
}

.list_box [class*=ico-] {
	font-size: 20px;
	color: #666;
	margin-right: 4px
}

.line_price {
	border: solid 1px #eee;
	width: 100%;
	max-width: 120px
}

.sticky {
	background-color: #2a9da5;
	position: sticky;
	top: 180px;
	z-index: 100;
	border-radius: 60px;
	width: 40px;
	height: 250px;
	margin-top: -249px;
	left: 1920px;
	margin-right: 100px
}

@media (max-width:1919px) {
	.sticky {
		left: 1550px;
		margin-right: 10px
	}
}

@media (max-width:599px) {
	.sticky {
		left: 1440px;
		margin-right: 10px
	}
}

.sticky .redes, .sticky .redes p {
	display: flex;
	flex-direction: column
}

.sticky .redes {
	flex-wrap: wrap;
	justify-content: center
}

.sticky .redes p {
	font-size: 12px;
	transform: rotate(-90deg);
	color: #fff;
	width: 30px;
	font-weight: 700;
	text-transform: uppercase
}

.sticky .redes [class*=ico-] {
	color: #fff;
	font-size: 1.5rem
}

.sticky .icons-sticky, .sticky .icons-sticky-two {
	align-items: center;
	margin: 2rem .2rem;
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center
}

.sticky .icons-sticky a:hover {
	color: #2a9da5;
	outline: 0;
	transition: color .1s ease-out
}

.sticky .icons-sticky-two {
	margin: .5rem .2rem
}

.sticky .line_s {
	border-top: 1px solid #f2f2f2;
	transform: rotate(-90deg);
	padding: 0 1rem;
	margin-bottom: 3rem
}

.howorks {
	background-color: #000;
	position: relative;
	z-index: 1
}

.howorks::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url("../img/textura.png");
	background-size: cover;
	z-index: -2
}

.howorks__bg {
	position: relative;
	z-index: 3
}

.howorks__stepper .stepper {
	text-align: right
}

.howorks__stepper .stepper li {
	padding: 1.5rem;
	border-right: 8px solid #292929;
	transition: .3s ease
}

.howorks__stepper .stepper li [class*=ico-] {
	color: #666;
	font-size: 4rem
}

.howorks__stepper .stepper li.active, .howorks__stepper .stepper li:hover {
	border-right: 8px solid #2bb7c1
}

@media (max-width:959px) {
	.howorks__stepper .stepper {
		text-align: center
	}
	.howorks__stepper .stepper li {
		border: 0
	}
}

.leadHome {
	text-align: center;
	padding: 2rem 0;
	background-color: #228389;
	color: #fff
}

.leadHome .text-cont {
	max-width: 800px;
	margin: 0 auto
}

.leadHome .hr {
	width: 100%;
	height: 5px;
	background-color: #fff;
	max-width: 120px;
	margin: 1rem auto;
	margin-top: 0
}

.grid-lead {
	display: grid;
	grid-template-columns: repeat(4, 1fr)
}

@media (max-width:1279px) {
	.grid-lead {
		grid-template-columns: repeat(2, 1fr)
	}
}

.grid-lead img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	filter: grayscale(100%)
}

@media (max-width:599px) {
	.grid-lead img {
		filter: none
	}
}

.grid-lead .lead-link {
	position: relative
}

.grid-lead .lead-link:hover img {
	filter: grayscale(0)
}

.grid-lead .lead-link:hover p {
	background-color: #2a9da5;
	font-weight: 700;
	bottom: 50px;
	transition: .3s ease
}

.grid-lead .lead-link p, .services .aditionals {
	width: 100%;
	justify-content: center;
	align-items: center;
	transition: .3s ease
}

.grid-lead .lead-link p {
	position: absolute;
	display: flex;
	background-color: rgba(0, 0, 0, .5);
	bottom: 0;
	color: #fff;
	font-size: 2.5rem;
	text-align: center;
	line-height: 1.1;
	font-style: italic;
	z-index: 3;
	height: 120px
}

@media (max-width:599px) {
	.grid-lead .lead-link p {
		font-size: 1.25rem
	}
}

.border {
	border-left: 4px solid #2bb7c1;
	line-height: 1.3
}

@media (min-width:1280px) {
	.border {
		margin-bottom: 7rem
	}
}

.services {
	position: relative;
	z-index: 1;
	background-image: url("../img/services.png");
	background-size: cover;
	background-color: #000;
	background-repeat: no-repeat;
	background-position: top center
}

.services .aditionals {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	height: 100%;
	padding: 2rem
}

@media (max-width:599px) {
	.services .aditionals {
		grid-template-columns: 1fr;
		padding: 0
	}
}

.services .aditionals__card {
	text-decoration: none;
	background-color: rgba(77, 77, 77, .7);
	text-align: center;
	padding: 1rem;
	height: 100%;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	transition: .3s ease;
	color: #999
}

.services .aditionals__card [class*=ico-] {
	font-size: 6rem;
	color: #999;
	width: 100%;
	transition: .3s ease
}

.services .aditionals__card p {
	opacity: 0;
	display: none;
	transition: .3s ease;
	font-size: .875rem
}

.services .aditionals__card:hover {
	transition: .3s ease;
	background-color: #2a9da5
}

.services .aditionals__card:hover p {
	opacity: 1;
	display: block;
	transition: .3s ease;
	color: #fff
}

.services .aditionals__card:hover [class*=ico-] {
	color: #fff;
	font-size: 4rem
}

.bt_area_main_menu {
	opacity: 0;
}

.bt_area_main_menu:hover {
	opacity: 1;
}

.main_manu_div {
	display: none;
}

.bt_area_main_menu:hover .main_manu_div {
	display: grid;
}

.main_manu {
	position: fixed;
	width: 50%;
	height: auto;
	background: #fff;
	top: 110px;
	z-index: 9999999;
	left: 25%;
	display: flex;
	border-top: 4px solid #2a9da5;
	border-bottom: 4px solid #2a9da5;
}

.header {
	background: rgba(0, 0, 0, 1);
}

/* .index_manu_img {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 50%;
	height: 100%;
	background-image: url("../img/index_menu_img.jpg");
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
	margin-bottom: -2px;
} */



.bt_area_main_menu {
	position: absolute;
	left: -10px;
	top: -44px;
	width: calc(100% + 20px);
	height: 111px;
	background: transparent;
	border-bottom: 4px solid #2a9da5;
	margin-bottom: 4px;
}

.bt_option_main_menu {
	padding: 5px;
	padding-left: 20px;
	color: #969696;
	font-weight: 600;
	cursor: pointer;
}

.bt_option_main_menu:hover {
	padding: 5px;
	padding-left: 20px;
	color: #fff;
	font-weight: 600;
	background: #228389;
	width: fit-content;
	padding-right: 20px;
	-webkit-border-top-right-radius: 20px;
	-webkit-border-bottom-right-radius: 20px;
	-moz-border-radius-topright: 20px;
	-moz-border-radius-bottomright: 20px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	cursor: pointer;
}

.info_detail_home {
	color: #2a9da5;
	margin-left: 18px;
	line-height: 20px;
	margin-bottom: 10px;
}

.info_seccion {
	text-align: right;
	min-width: 700px;
	width: 70%;
	color: #fff;
	font-family: "WorkSans", sans-serif;
	transform: translate(-100%, 0px);
	margin-left: 100%;
}

.titulo_data_header {
	text-align: right;
	min-width: 700px;
	width: 70%;
	color: #fff;
	font-family: "WorkSans", sans-serif;
	transform: translate(-100%, 0px);
	margin-left: 100%;
}

.info_serccion_div {
	display: flex;
	align-items: center;
	position: relative;
	overflow: hidden;
	width: 100%;
	text-align: right;
	height: 100%;
	max-height: 400px;
	border-bottom: 8px solid #2a9da5;
}

.info_serccion_div:before {
	content: '';
	display: block;
	background-color: #000;
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: .5;
	z-index: -1;
}

.info_serccion_div picture {
	position: absolute;
	z-index: -2;
	top: -15px;
	left: 0;
	width: 100%;
	height: 100%;
}

.info_serccion_div img {
	width: 100%;
	height: calc(100% + 15px);
}

.info_serccion_div .lead__title {
	font-size: 3rem;
	color: #fff;
	line-height: 1;
}

.info_serccion_div .lead__subtitle {
	width: 100%;
	color: #fff;
}









.area_contenido_4 .container {
	display: flex;
}

.beneficios_area2_deco {
	width: 100%;
	height: 50px;
	border-left: 2px rgb(255 255 255 / 52%) solid;
	margin-left: 20px;
}

.beneficios_area2_deco2 {
	width: 100%;
	height: 50px;
}

.beneficios_area3_deco {
	width: 100%;
	height: 50px;
	border-left: 2px rgb(0 0 0 / 52%) solid;
	margin-left: 20px;
}

.contend_view_data_bg2 {
	position: absolute;
	top: 0px;
	left: -10px;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	padding-left: 129px;
	padding-top: 42px;
	background-image: url('../img/como_img2.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: right bottom;
	z-index: -1;
}

.index_area_img {
	width: 40%;
	min-height: 400px;
	position: relative;
}

.entrenamientos_lista {
	width: 20%;
	height: 440px;
}

.entrenamientos_area1 {
	width: 40%;
	min-height: 400px;
	position: relative;
}

.entrenamientos_area2 {
	width: 40%;
	min-height: 400px;
	position: relative;
}

.entrenamientos_area3 {
	width: 60%;
	min-height: 400px;
	position: relative;
}

.entrenamientos_area4 {
	width: 20%;
	min-height: 400px;
	position: relative;
}

.index_area1_img {
	width: 100%;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, -100%);
}

.entrenamientos_area1_img {
	width: 100%;
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -40px;
	transform: translate(-50%, -100%);
}

.servicio_area_img {
	height: 500px;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, -100%);
}

.area_contenido_1 .container {
	display: flex;
}

.area_contenido_2 .container {}

.selector_clase {
	position: relative;
	top: 10%;
	left: 50%;
	transform: translate(-50%, 0px);
	width: 100%;
	height: 80%;
}

.selector_clase_lista {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.selector_clase_lista_index {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.selector_clase_item_flecha {
	position: absolute;
	left: 25px;
	width: 24px;
	height: 24px;
	background-color: #ccc;
	border-radius: 40px;
	color: #fff;
	text-align: center;
	font-size: 16px;
}

.selector_clase_item {
	position: absolute;
	transform: translate(0px, -50%);
	left: 0px;
	width: 70px;
	height: 70px;
	background-color: #2A9DA5;
	border-radius: 40px;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.selector_clase_item_off {
	position: absolute;
	transform: translate(0px, -50%);
	left: 0px;
	width: 70px;
	height: 70px;
	background-color: rgb(0 0 0 / 40%);
	border-radius: 40px;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.selector_clase_item_movil {
	position: relative;
	top: 30px;
	margin-left: 5px;
	margin-right: 5px;
	width: 70px;
	height: 70px;
	background-color: #2A9DA5;
	border-radius: 40px;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.selector_clase_item_off_movil {
	position: relative;
	top: 30px;
	margin-left: 5px;
	margin-right: 5px;
	width: 70px;
	height: 70px;
	background-color: rgb(0 0 0 / 40%);
	border-radius: 40px;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.selector_clase_item_hover {
	position: absolute;
	left: calc(100% + 10px);
	top: 50%;
	transform: translate(0px, -50%);
	font-size: 13px;
	color: #999;
	opacity: 0;
	width: 150px;
	-webkit-transition-timing-function: ease-in-out;
	-webkit-transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
	-moz-transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
	-o-transition: wiwidth 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
	transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
}

.icon_arrow_titulo {
	margin-right: 5px;
	margin-left: 5px;
	color: #2a9da5;
}

.icon_arrow_titulo_detail_home {
	margin-right: 5px;
	margin-left: 5px;
	color: #2a9da5;
	position: absolute;
	left: 23px;
	margin-top: 7px;
	font-size: 10px !important;
}

.entrenamientos_area2_t1 {
	width: calc(100% + 100vw);
	font-family: "WorkSans", sans-serif;
	color: #BEBEBE;
	display: flex;
	display: inline-flex;
	margin-top: 80px;
}

.entrenamientos_area2_t1_text {
	white-space: nowrap;
	margin-right: 9px;
	font-size: 25px;
}

.entrenamientos_area2_t1_deco {
	border-top: 3px solid #2a9da5;
	height: 0px;
	width: -webkit-fill-available;
	margin-top: 18px;
}

.entrenamientos_area2_t2 {
	width: 100%;
	text-align: left;
	font-size: 40px;
	font-weight: bold;
	color: #535353;
	line-height: 33px;
}

.entrenamientos_area2_t3 {
	width: 100%;
	text-align: left;
	font-size: 15px;
	color: #535353;
	margin-top: 10px;
	line-height: 20px;
}

.entrenamientos_area2_t4 {
	padding: 10px;
	margin-top: 10px;
	padding-left: 40px;
	padding-right: 40px;
	background: #2a9da5;
	color: #fff;
	width: fit-content;
	border-radius: 40px;
	cursor: pointer;
}

.entrenamientos_area3_t1 {
	margin-left: 20px;
	font-size: 40px;
	font-weight: bold;
	color: #2a9da5;
}

.entrenamientos_area3_t2 {
	margin-left: 20px;
	margin-top: 20px;
	font-size: 15px;
	color: #535353;
	line-height: 20px;
}

.entrenamientos_area3_t3 {
	width: 100%;
	display: flex;
	display: inline-flex;
}

.entrenamientos_area3_t3_item {
	padding: 10px;
	text-align: -webkit-center;
	width: 200px;
	position: relative;
}

.entrenamientos_area3_t3_icono {
	width: 90px;
	background: #2a9da5;
	color: #fff;
	text-align: center;
	padding: 26px;
	padding-bottom: 14px;
	padding-top: 14px;
	border-radius: 70px;
	margin-bottom: 10px;
}

.entrenamientos_area3_t3_icono_text {
	font-size: 31px;
	font-weight: 600;
	margin-bottom: -13px;
}

.entrenamientos_area3_t3_icono_text2 {
	font-size: 40px;
	width: 100px;
	margin-left: -30px;
	text-align: center;
	font-weight: 600;
}

.entrenamientos_area3_t3_text {
	color: #C9C9C9;
}

.entrenamientos_area4_area {
	position: relative;
	width: 100%;
	height: 70px;
	margin-bottom: 20px;
}

.entrenamientos_area4_icon {
	background: #2a9da5;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
	top: 0px;
	width: 70px;
	height: 70px;
	position: absolute;
	left: 100%;
	margin-left: -70px;
	border-radius: 50px;
}

.entrenamientos_area4_test {
	position: absolute;
	top: 50%;
	transform: translate(0px, -50%);
	color: #C9C9C9;
	text-align: right;
	width: calc(100% - 75px);
}

.accordion_div {
	margin-top: 20px;
	margin-left: 20px;
}

.acordion_pregunta {
	background: #2a9da5;
	width: 80%;
	padding: 5px;
	padding-right: 30px;
	padding-left: 30px;
	color: #fff;
}

.acordion_respuesta {
	padding: 10px;
	width: 80%;
}

.menu_lineamientos {
	position: absolute;
	color: #fff;
	display: flex;
	left: 50%;
	top: 100%;
	transform: translate(-50%, -100%);
}

.menu_lineamientos_option {
	padding: 20px;
	margin-bottom: 4px;
	margin-left: 20px;
	margin-right: 20px;
	cursor: pointer;
}

.menu_lineamientos_option:hover {
	padding: 20px;
	margin-bottom: -1px;
	margin-left: 20px;
	margin-right: 20px;
	border-bottom: 5px solid #238289;
}

.menu_lineamientos_option_selected {
	padding: 20px;
	font-weight: 600;
	margin-bottom: -1px;
	margin-left: 20px;
	margin-right: 20px;
	cursor: pointer;
}

.lineamientos_area1 {
	width: 40%;
	position: relative;
	min-height: 500px;
}

.lineamientos_area2 {
	top: 0px;
	left: 0px;
	width: 60%;
	position: relative;
	min-height: 500px;
	display: block;
	/* Default of all elements, unless UA stylesheet overrides */
}

.lineamientos_area2_unselected {
	width: 60%;
	position: relative;
	min-height: 500px;
	display: none;
}

.beneficios_area2 {
	top: 0px;
	left: 0px;
	width: 50%;
	position: relative;
	color: #fff;
	font-size: 28px;
	font-family: "WorkSans", sans-serif;
	text-align: left;
	display: block;
	/* Default of all elements, unless UA stylesheet overrides */
}

.liniamientos_area1_img {
	height: 500px;
	position: absolute;
	top: 100%;
	left: 100%;
	margin-left: -20px;
	transform: translate(-100%, -100%);
}

.lineamientos_area2_area {
	border-left: 5px solid #238289;
	margin-bottom: 40px;
}

.index_area3_t2 {
	margin-left: 20px;
	margin-top: 20px;
	font-size: 15px;
	color: #535353;
	font-weight: 400;
	overflow-y: auto;
}

.liniamientos_area3_t2 {
	margin-left: 20px;
	margin-top: 20px;
	font-size: 15px;
	color: #535353;
	font-weight: 400;
	max-height: 290px;
	overflow-y: auto;
	height: 290px;
}

.liniamientos_bt {
	padding: 10px;
	margin-top: 10px;
	padding-left: 40px;
	padding-right: 40px;
	background: #2a9da5;
	color: #fff;
	width: fit-content;
	border-radius: 40px;
	cursor: pointer;
	margin-bottom: 20px;
	margin-left: 20px;
	font-size: 16px;
}

.selector_clase_item_index {
	background-image: url("../img/iconos/img5.png");
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	width: 90px;
	height: 90px;
	background-color: #2A9DA5;
	border-radius: 50px;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.index_area3_t1 {
	margin-left: 20px;
	font-size: 40px;
	font-weight: bold;
	color: #2a9da5;
	line-height: 37px;
}

.menu_movil_lineamientos {
	display: none;
}

.flecha_prev_movil {
	position: absolute;
	top: 13px;
	left: 10px;
	width: 24px;
	height: 24px;
	background-color: #ccc;
	border-radius: 40px;
	color: #fff;
	text-align: center;
	font-size: 16px;
}

.flecha_next_movil {
	position: absolute;
	top: 13px;
	left: 100%;
	margin-left: -34px;
	width: 24px;
	height: 24px;
	background-color: #ccc;
	border-radius: 40px;
	color: #fff;
	text-align: center;
	font-size: 16px;
}

.movil_area {
	display: none;
}

.center_menu {
	position: relative;
	left: 50%;
	transform: translate(-50%, 0px);
	display: flex;
}

.menu_moviles_option {
	position: relative;
	width: 100%;
	text-align: center;
	color: #939393;
	font-weight: 600;
	padding-right: 20px;
	padding-left: 20px;
}

.menu_moviles_option_selected {
	position: relative;
	width: 100%;
	text-align: center;
	color: #fff;
	font-weight: 600;
	padding-right: 20px;
	padding-left: 20px;
	background: #2a9da5;
}

.menu_moviles_option_data {
	position: relative;
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #D6D6D6;
}

.menu_movile_redes {
	position: absolute;
	top: 100%;
	left: 0px;
	height: 50px;
	width: calc(100% - 50px);
	background: #000;
	transform: translate(0px, -100%);
}

.hide_div {
	display: none;
}

.deco_index {
	position: absolute;
	bottom: 0px;
	width: 100%;
	height: 20px;
	left: 0px;
}

.deco_index_2 {
	position: absolute;
	top: 0px;
	transform: translate(0px, -100%);
	margin-top: 1px;
	width: 100%;
	height: 20px;
	left: 0px;
	z-index: 9;
}

.deco_index_3 {
	position: absolute;
	top: 100%;
	width: 100%;
	height: 20px;
	z-index: 9;
	left: 0px;
	margin-top: -1px;
}

.leadHome {
	position: relative;
}

.video_area {
	width: 100%;
	height: 100%;
	background: #000;
}

.fix_video {
	margin-top: 114px;
}

.tipo_entrenamiento_bt {
	position: relative;
	text-align: center;
	padding: 15px;
	margin-right: 15px;
	margin-left: 15px;
	padding-top: 5px;
	padding-bottom: 5px;
	width: 170px;
	-webkit-border-top-left-radius: 35px;
	-webkit-border-top-right-radius: 35px;
	-moz-border-radius-topleft: 35px;
	-moz-border-radius-topright: 35px;
	border-top-left-radius: 35px;
	border-top-right-radius: 35px;
	cursor: pointer;
}

.tipo_entrenamiento_bt_on {
	background: #2a9da5;
	z-index: 9;
	line-height: 15px;
	padding-top: 14px;
}

.tipo_entrenamiento_bt_off {
	background: #1E7980;
	z-index: 0;
	line-height: 15px;
	padding-top: 14px;
}

.beneficios_items {
	width: 100%;
	display: flex;
	margin-bottom: 60px;
}

.beneficios_items_item {
	width: calc(25% - 20px);
	margin-right: 10px;
	margin-left: 10px;
	text-align: center;
	padding: 10px;
	padding-bottom: 40px;
	border-bottom: 2px solid #EFEFEF;
}

.beneficios_number_icon {
	font-size: 70px;
	font-weight: bold;
	color: #19949D;
	font-family: "WorkSans", sans-serif;
}

.beneficios_area2_text {
	margin-left: 20px;
	line-height: 1.2;
}

.beneficios_area1 {
	width: 50%;
	position: relative;
	min-height: 450px;
}

.beneficios_area3 {
	top: 0px;
	left: 0px;
	width: 50%;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 40px;
	position: relative;
	min-height: 450px;
	display: block;
	/* Default of all elements, unless UA stylesheet overrides */
}

.beneficios_area3_t2 {
	margin-left: 20px;
	margin-top: 20px;
	font-size: 15px;
	color: #535353;
	font-weight: 400;
	overflow-y: auto;
}

.beneficios_area3_img {
	height: 550px;
	position: absolute;
	top: 100%;
	left: 100%;
	margin-left: -20px;
	transform: translate(-100%, -100%);
}

.beneficios_area2_text_titulo {
	font-weight: bold;
	font-family: "WorkSans", sans-serif;
	font-size: 3rem;
	margin-left: 20px;
	line-height: 1;
	margin-bottom: 50px;
}

.como_items_item {
	position: relative;
	width: 40%;
	margin-left: 10px;
	margin-right: 10px;
	background: #262626;
	color: #fff;
	font-family: "WorkSans", sans-serif;
	font-size: 16px;
	padding: 30px;
	padding-top: 60px;
}

.como_icon {
	position: absolute;
	left: 50%;
	top: 0px;
	transform: translate(-50%, -50%);
	width: 100px;
	height: 100px;
	background: #2A9CA4;
	border-radius: 50px;
}

.como_icon_img {
	width: 70%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.como_item_titulo {
	font-family: "WorkSans", sans-serif;
	font-size: 35px;
	font-weight: bold;
	text-align: center;
	line-height: 1;
	margin-bottom: 50px;
}

.bg_movil {
	display: none;
	position: absolute;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
}

.reviews {
	min-height: 750px;
}

.r_photo {
	border: 0px solid #2bb7c1;
}

.div_submenu_usuarios {
	display: flex;
	display: inline-flex;
	width: 100%;
	height: 500px;
	margin-top: 50px;
	margin-bottom: 50px;
}

.div_submenu_usuarios_item {
	position: relative;
	width: 34%;
	height: 100%;
	filter: grayscale(100%);
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.div_submenu_usuarios_item:hover {
	position: relative;
	width: 34%;
	height: 100%;
	filter: grayscale(0%);
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.div_submenu_usuarios_item_data {
	position: absolute;
	bottom: 0px;
	margin: 20px;
	font-size: 30px;
	font-weight: bold;
	font-family: "WorkSans", sans-serif;
	color: #fff;
}

.div_submenu_usuarios_item_data_text {
	margin-left: 20px;
}

.bt_slide_next {
	position: absolute;
	left: 100%;
	top: 50%;
	transform: translate(-100%, -50%);
	width: 50px;
	height: 50px;
	text-align: center;
	color: #fff;
	background: #1db0bf;
	font-size: 30px;
	padding: 3px;
	padding-left: 13px;
	-webkit-border-top-left-radius: 20px;
	-webkit-border-bottom-left-radius: 20px;
	-moz-border-radius-topleft: 20px;
	-moz-border-radius-bottomleft: 20px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.bt_slide_prev {
	position: absolute;
	left: 0%;
	top: 50%;
	transform: translate(0%, -50%);
	width: 50px;
	height: 50px;
	text-align: center;
	color: #fff;
	background: #1db0bf;
	font-size: 30px;
	padding: 3px;
	padding-right: 13px;
	-webkit-border-top-right-radius: 20px;
	-webkit-border-bottom-right-radius: 20px;
	-moz-border-radius-topright: 20px;
	-moz-border-radius-bottomright: 20px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}

@media (max-width: 1366px) {
	.main_manu {
		width: 70%;
		left: 15%;
	}
}

@media (max-width: 599px) {
	.movile_logo {
		position: relative;
		left: 50%;
		transform: translate(-50%, 0px);
	}
	.redes_sociales_movil {
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translate(0px, -50%);
	}
	.rounded_moviles {
		padding: 10px;
		font-size: 12px;
		padding-left: 13px;
		padding-right: 13px;
		border-radius: 20px;
		font-size: 15px;
	}
	.selector_clase_item_index {
		top: 20px;
	}
	.menu_movil {
		position: fixed;
		padding-top: 30px;
		top: 71px;
		left: -100%;
		width: 100%;
		height: calc(100% - 71px);
		z-index: 999999999999;
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
		background-color: rgba(255, 255, 255, 0.8);
		webkit-transition-timing-function: ease-in-out;
		-webkit-transition: width 1.0s ease, height 1.0s ease, top 1.0s ease, left 1.0s ease, opacity 0.5s;
		-moz-transition: width 1.0s ease, height 1.0s ease, top 1.0s ease, left 1.0s ease, opacity 0.5s;
		-o-transition: wiwidth 1.0s ease, height 1.0s ease, top 1.0s ease, left 1.0s ease, opacity 0.5s;
		transition: width 1.0s ease, height 1.0s ease, top 1.0s ease, left 1.0s ease, opacity 0.5s;
	}
	.menu_movil_selected {
		left: 0px;
	}
	.menu_moviles_option_data_sub {
		position: relative;
		width: 100%;
		height: 0px;
		overflow-y: hidden;
		webkit-transition-timing-function: ease-in-out;
		-webkit-transition: width 1.0s ease, height 1.0s ease, top 1.0s ease, left 1.0s ease, opacity 0.5s;
		-moz-transition: width 1.0s ease, height 1.0s ease, top 1.0s ease, left 1.0s ease, opacity 0.5s;
		-o-transition: wiwidth 1.0s ease, height 1.0s ease, top 1.0s ease, left 1.0s ease, opacity 0.5s;
		transition: width 1.0s ease, height 1.0s ease, top 1.0s ease, left 1.0s ease, opacity 0.5s;
	}
	.info_seccion {
		text-align: center;
		width: 100%;
		min-width: 100%;
		color: #fff;
		font-family: "WorkSans", sans-serif;
		transform: translate(0%, 0px);
		margin-left: 0%;
		margin-top: -260px;
	}
	.info_serccion_div .lead__title {
		font-size: 2.0rem;
	}
	.movil_fix {
		display: none;
	}
	.area_contenido_1 {
		padding: 0px;
	}
	.area_contenido_1 .container {
		display: initial;
		width: 100%;
		text-align: center;
	}
	.entrenamientos_lista {
		width: 100%;
		height: 150px;
	}
	.entrenamientos_area1 {
		width: 100%;
		min-width: auto;
		text-align: center;
	}
	.lineamientos_area2_area {
		border-left: 0px solid #238289;
	}
	.entrenamientos_area3_t1 {
		margin-left: 0px;
		font-size: 26px;
		text-align: center;
	}
	.index_area3_t1 {
		margin-left: 0px;
	}
	.liniamientos_area3_t2 {
		margin-left: 0px;
		padding: 20px;
		max-height: inherit;
		overflow-y: auto;
		height: auto;
	}
	.index_area3_t2 {
		margin-left: 0px;
		padding: 20px;
		max-height: inherit;
		overflow-y: auto;
		margin-top: 0px;
	}
	.entrenamientos_area2 {
		width: 100%;
		margin-top: -44px;
		min-height: 0px;
	}
	.index_area_img {
		width: 100%;
		margin-top: -44px;
	}
	.index_area1_img {
		height: 400px;
		width: auto;
		transform: translate(-50%, -94%);
	}
	.entrenamientos_area1_img {
		height: 400px;
		width: auto;
		margin-left: 0px;
		transform: translate(-50%, -100%);
	}
	.servicio_area_img {
		height: 400px;
		transform: translate(-50%, -94%);
		margin-top: 40px;
	}
	.info_serccion_div {
		height: 600px;
		display: grid;
	}
	.titulo_data_header {
		position: absolute;
		bottom: 0px;
		text-align: left;
		min-width: 100%;
		width: 100%;
		color: #fff;
		font-family: "WorkSans", sans-serif;
		transform: translate(0%, 0px);
		margin-left: 0%;
		padding: 10px;
		left: 0px;
	}
	.lineamientos_area2 {
		width: 100%;
		padding-top: 23px;
	}
	.lineamientos_area2_unselected {
		width: 100%;
		padding-top: 23px;
	}
	.liniamientos_bt {
		left: 50%;
		transform: translate(-50%, 0px);
		margin-left: 0px;
		position: relative;
	}
	.menu_movil_lineamientos {
		display: inherit;
		position: absolute;
		left: 0px;
		top: 17px;
		width: 100%;
		height: 50px;
	}
	.lineamientos_area1 {
		width: 100%;
	}
	.liniamientos_area1_img {
		transform: translate(-50%, -95%);
		left: 50%;
		margin-left: 0px;
	}
	.movil_area {
		display: flex;
	}
	.selector_clase_item {
		position: absolute;
		transform: translate(-50%, -50%);
		left: 0px;
		width: 65px;
		height: 65px;
		background-color: #2A9DA5;
		border-radius: 40px;
		background-position: center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-size: cover;
		-o-background-size: cover;
	}
	.selector_clase_item_off {
		position: absolute;
		transform: translate(-50%, -50%);
		left: 0px;
		width: 65px;
		height: 65px;
		background-color: rgb(0 0 0 / 40%);
		border-radius: 40px;
		background-position: center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-size: cover;
		-o-background-size: cover;
	}
	.selector_clase {
		position: absolute;
		top: 70px;
	}
	.entrenamientos_area2_t1 {
		width: 100%;
		margin-top: 0px;
	}
	.entrenamientos_area2_t1_deco {
		display: none;
	}
	.entrenamientos_area2_t1_text {
		left: 50%;
		position: relative;
		transform: translate(-50%, 0px);
		font-size: 19px;
	}
	.entrenamientos_area2_t2 {
		text-align: center;
		font-size: 35px;
	}
	.entrenamientos_area2_t3 {
		padding: 10px;
	}
	.entrenamientos_area2_t4 {
		left: 50%;
		position: relative;
		transform: translate(-50%, 0px);
	}
	.entrenamientos_area3_t2 {
		margin-left: 0px;
		padding: 10px;
	}
	.entrenamientos_area3_t3 {
		display: block;
	}
	.entrenamientos_area3_t3_item {
		left: 50%;
		transform: translate(-50%, 0px);
	}
	.entrenamientos_area3 {
		width: 100%;
	}
	.accordion_div {
		margin-left: 20%;
	}
	.info_detail_home {
		margin-left: 0px;
	}
	.icon_arrow_titulo_detail_home {
		position: relative;
		left: 0px;
	}
	.beneficios_items {
		display: initial;
	}
	.beneficios_items_item {
		width: 100%;
		margin: 0px;
	}
	.bg_movil {
		display: inherit;
	}
	.beneficios_area2 {
		width: 100%;
		margin-top: 370px;
		font-size: 16px;
		text-align: center;
	}
	.beneficios_area2_text {
		margin-left: 0px;
	}
	.beneficios_area2_deco {
		display: none;
	}
	.area_contenido_4 .container {
		display: inherit;
	}
	.beneficios_area3 {
		top: 0px;
		left: 0px;
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
		padding-top: 0px;
		position: relative;
		min-height: auto;
		display: block;
	}
	.beneficios_area3_t2 {
		margin-left: 0px;
		text-align: center;
	}
	.beneficios_area3_deco {
		display: none;
	}
	.beneficios_area1 {
		width: 100%;
		min-height: 360px;
	}
	.beneficios_area3_img {
		height: 400px;
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -20px;
		transform: translate(-50%, -100%);
	}
	.beneficios_area2_deco2 {
		display: none;
	}
	.beneficios_area2_text_titulo {
		font-weight: bold;
		font-family: "WorkSans", sans-serif;
		font-size: 30px;
		margin-left: 0px;
		line-height: 1;
		margin-bottom: 10px;
	}
	.como_items_item {
		width: 100%;
		margin-bottom: 100px;
	}
	.reviews {
		min-height: 938px;
	}
	.div_submenu_usuarios {
		display: block;
		width: 100%;
		height: auto;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.div_submenu_usuarios_item {
		width: 100%;
		height: 500px;
	}
	.bt_slide_next {
		display: none;
	}
	.bt_slide_prev {
		display: none;
	}
	.img_bg_fix {
		height: 938px;
		width: auto !important;
	}
}

.area_contenido_1 .container .swiper-container-horizontal>.swiper-pagination-bullets {
	top: 0
}