 
 @media (min-width: 100px) and (max-width:  920px) {
     .ordenarrecomendaciones{
         padding:0 3vw;
         border-bottom:none !important
     }
    .resumenContainer .containerResevar .btnMOB {
        font-size: calc(0.5rem + 1vw) !important;
        display: inline !important;
        width: auto !important;
        padding-right: 4vmin;
        margin-top: 3vmin;
        margin-bottom: 3vmin;
    }
    .resumenContainer .containerResevar .btnCalendar {
        width: 1.2rem !important;
    }
   
    .resumenContainer {
        min-height: 78vh;
    }
    .resumenContainer .sliderResumen .recom .recomendaciones {
        width: 80%;
        margin: auto
    }
    .recom .recomendaciones {
        font-size: calc(0.68em + 1vmin);
        color: #9DACBD;
        width: 100%;
    }
   
    .recom .recomendaciones .itemRecom img {
        width: 10%;
        float: left;
    }
   
    .recom .recomendaciones .itemRecom img ~ span {
        margin-left: 13%;
        display: table;
    }
    .ICONORESUMEN{

        border-radius:50%;
        width:20vw;
        background-color: #9EACED !important;
    }
    .resumenContainer .title {
        margin:2vh 0 1vh  0;
         justify-content: center;
         
        color: #394F65;
        font-weight: bold;
        text-align: center;
        font-size: calc(6vw);
       
       
        width: 100%;
       
    }
    .fechanowresultsresumen {
        text-align: left;
        font-family: WorkSans-Regular;
        
        font-size:3vw;
        margin-top:-5px;
   
        color: #9DACBD;
      
    }
 }
 
 @media (min-width: 921px)  {
    .resumenContainer .containerResevar  {
      
       position:fixed;
       background-color:white;
       bottom:0;
       z-index:98;
        border:1px solid white ;
    margin: 3vh 0  0 8%; 
    padding:1rem;
        box-shadow: 2px 1px 10px -3px #707070;
        height:10vh;
        width: 87% !important;
    }
    .resumenContainer .containerResevar .btnCalendar {
        width: 0.3rem !important;
    
    }
    .resumenContainer .containerResevar .btnCalendar_web {
        width: 1rem !important;
    
    }
   
    .resumenContainer .containerResevar .btnMOB {
        font-size: 0.9vw !important;
        display: inline !important;
     max-width:19vw !important;
    
     height:3.0rem !important;
      
  
    }
    .containergraficaresumen{
        display:flex;
        width:100%;
        margin-left:15% !important;
        margin-right:20% !important;

    }
    .recom .recomendaciones {
        font-size: 1rem;
        color: #9DACBD;
        width: 100%;
    }
   
    .recom .recomendaciones .itemRecom {
        width: 25%;
        margin:0 1%
       
    }
    .recom .recomendaciones .itemRecom img {
        width: 18%;
        padding:0 0.6vw;
        float: left;
    }
   
    .recom .recomendaciones .itemRecom img ~ span {
      
        display: table;
    }
    .recom .recomendacionesCompanie {
        font-size: 1rem;
        color: #9DACBD;
        width: 100%;
    }
   
    .recom .recomendacionesCompanie .itemRecom {
        width: 25%;
        margin:0 1%
       
    }
    .recom .recomendacionesCompanie .itemRecom img {
        width: 18%;
        padding:0 0.6vw;
        float: left;
    }
   
    .recom .recomendacionesCompanie .itemRecom img ~ span {
      
        display: table;
    }
    .resumenContainer .sliderResumen .recom .recomendaciones {
        width: 84%;
      
        margin:0 13% !important;
   
        margin: auto
    }
    .resumenContainer .sliderResumen .recom .recomendacionesCompanie {
        width: 90%;
      
        margin:0 5% !important;
   
        margin: auto
    }
   
    .ICONORESUMEN{
        margin:0 0 0 15% ;
        width:5rem;
        padding:0.5%;
        border-radius:50%;
        background-color: #9EACED !important;
    }
     .maxreservarResumen{
         margin-left:15%;   
         margin-top:2vh;
         margin-bottom:1vh;
     }
     .superiorMaxResumen{
         display:flex;
         width:100%;
         margin-bottom:1vh;
     }
     .SubTitle8resumen{
        width: 17%;
        font-size: 1.13vw !important;
        color: #9DACBD;
    min-width: 30px;
        
     }
     .lineareservatuclaseonlyresumen{
        border-bottom: 1px solid #9DACBD;
        opacity: 0.5;
       
        margin: 1.3vh 0;
        width: 87%;
     }
     .resumenContainer .title {
         
        color: #394F65;
        font-weight: bold;
        text-align: left;
        font-size: calc(2.3em);
        margin: 0vh auto 0 1vw;
        padding:0 2vw;
       
        width: 100%;
       
    }
    .titleresumensesion{
        color: #394F65;
        font-weight: bold;
        text-align: left;
        font-size: calc(1.5em);
        margin: 2vh auto 2vh 15%;

       
        width: 100%; 
    }
    .titleresumensesionCompanie{
        color: #394F65;
        font-weight: bold;
        text-align: left;
        font-size: calc(1.5em);
        margin: 2vh auto 2vh 5%;

       
        width: 100%; 
    }
    .titleresumensesion_tuse{
        color: #2A9DA5;
        font-weight: bold;
        text-align: left;
        font-size: calc(1.5em);
        margin: 2vh 15% 2vh 25%;

       
        width: 100%;   
    }
    .fechanowresultsresumen {
        text-align: left;
        font-weight:400;
        
        font-size: 0.45em;
        margin-top:-5px;
   
        color: #9DACBD;
      
    }
    .resumenContainer {
        overflow-x:hidden;
     overflow-y: scroll;
     height:80vh;
     
    }
    .resumenContainerCompanie {
        overflow-x:hidden;
     overflow-y: scroll;
     height:100vh;
     
    }
 }
 





 .resumenContainer .sliderResumen .subtitle {
     color: #333333;
     font-weight: bold;
     font-size: calc(1em + 1vw);
     margin: 1vh auto 0 auto;
     text-align: center;
 }

 .resumenContainer .sliderResumen .theData {
     text-align: center;
 }

 .resumenContainer .sliderResumen .time, .calori {
     margin-top: 3vh;
     color: #919191;
     text-transform: uppercase;
     line-height: 1;
 }

 .resumenContainer .sliderResumen .time div, .calori div {
     margin-top: 3vh;
 }

 .resumenContainer .sliderResumen .val {
     font-size: calc(4rem + 1vw);
     font-weight: bold;
     color: #6E6E6E;
     letter-spacing: normal;
 }

 .resumenContainer .sliderResumen .time img, .calori img {
     width: calc(2rem + 1vw);
 }



 .resumenContainer .sliderResumen .recom .subtitle {
     margin-bottom: 2vmin;
 }




