body{
    line-height:1.3 !important;
 
}







.swipersep {
    
    width: 5vw;
    margin-left:-0.7vw;
    margin-right:-2.0vw;
    background-color: transparent;
    
    
}

.stepsBullets .Bullet {
    display: inline-block;
    width: 30px;
    height: 28px;
    margin-left:1vw;
    border-radius: 15px;
    border: 3px solid #F2F2F2;
    color:#9DACBD;
    background-color: transparent;
    opacity: 1;
    cursor: pointer;
    z-index:2
}

.form-select:hover {
    color: #212529 !important ;   
}


  
  
    .MuiInput-underline::before {
        border-bottom:none !important
    }
    .MuiInput-underline:after {
        border-bottom: none !important;  
    } 
     .telefono .PhoneInputCountry{
        display:none !important
    }
   
.swipersep.active {
    
    display: inline-block;
    border-radius:1px !important;
 
    background-color: #7B86FA !important;
 
    color: none!important;
}
.swipersep.noactive {
    
    display: inline-block;
    border-radius:1px !important;
    border: 2px solid #F2F2F2!important;
  
    color: none!important;
}

.stepsBullets .active {
    background-color: #7B86FA;
    border: 2px solid #7B86FA;
    color: rgb(255, 255, 255) !important;
}

.stepsBullets {
    height: 5vh;
    margin-top: 3vh;
    color: white;
    font-weight: 500;

    margin-bottom: 2vh;
}


.CompletarPerfil .Separador {
    height: 1px;
    background-color: #F2F2F2;
    width: 60vw;
    margin: auto;
}

.photoProfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}




.labelPhotocamera {
    box-sizing: border-box;
    height: 18vh;
    margin-top:2vh;
    margin-bottom:-3vh !important;
    width: 18vh;
    border: 6px solid #EAEAEA;
  
    background-position: center 60%;
    background-repeat: no-repeat;
    background-size: 184%;
    position: relative;
}
.CompletarPerfil input::placeholder{
opacity:0.6;
}

.CompletarPerfil input, select {
    border: hidden !important;
    color:#9DACBD !important;
    font-weight:400;
    background: transparent;
    border-bottom: 1px solid #EAEAEA !important;
}




.dateBorn {
    color: #7B7B7B !important;
    text-transform: uppercase;
}





.changeView {
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.cls-1 {
    fill: #fff;
}

.dolor {
    width: 15px;
    height: 20px;
    /* border: 3px solid rgba(42, 157, 165, 1); */
    background-color: transparent;
    position: absolute;
}
.letracuerpo{
    margin-top:1.5vh;
    font-size:4vw
}
.letracuerpo1{
    margin-top:0vh;
    font-size:4vw
}

.titleautorizaciontitle{
    font-family: WorkSans-Bold !important;
    color: #2A9DA5;
    font-size: calc(1em + 1vw);
}



.preguntas {
    font-size: calc(0.5em + 1vw);
}




.tooltipPhoto {
    left: 20% !important;
    right: 5% !important;
position:absolute !important;
    border-radius: 20px !important;
    opacity: 1 !important;
    font-size: 3vmin !important;
}
.tooltipPhotoPagos {
    left: 20% !important;
    right: 5% !important;
  
position:absolute !important;
    border-radius: 20px !important;
    opacity: 1 !important;
    font-size: 3vmin !important;
}


.MuiDialogActions-root{
    justify-content: center !important;
}

.footerStep6 .text {
    color: white;
    font-weight: bold;
    justify-content: center !important;
}




@media (min-width: 100px) and (max-width:  920px) {
    .bievenidoa_reservas{display:none;}
    .CompletarPerfil .subtitle_text {
        color: #9DACBD;
        width: 70vw;
        font-size: 3.5vmin;
       
    }

   .pasarelateinvito .__react_component_tooltip {
        font-size:13px !important;
        width:190px;
        top:37px !important;
        left:-5vw !important;
    }
    .perfil_tooltip .__react_component_tooltip {
        font-size:13px !important;
        width:190px;
        top:48px !important;
        left:28vw !important;
    }
    .__react_component_tooltip {
        font-size:13px !important;
        width:190px;
        top:83px !important;
        left:35vw !important;
    }
    .btnInfocamera {
        left: 60%;
        top:5%;
        z-index: 1;
        width: 2rem;
        max-height:4rem !important;
        margin-top:1.4vh !important
    }
    .btnAction1guardar {
       
        display: flex;
        max-width: 50%;
        width: 55vw;
        height:5vh;
        font-size:1rem !important;
        display: inline-flex;
        justify-content: center;
    }

    .decirLesionTitulo{
        color:#19949D;
        text-align:left;
        padding-left:28px;
        font-weight:700;
        
        font-size: calc(.2rem + 1vw) !important;
      }
    .titleautorizacion{
        font-family: WorkSans-REGULAR !important;
     
        color: #BEBEBE;
        font-size: calc(0.5em + 1vw);
    }
    .marginswitch{
        margin-top:-2vh !important
    }
    .telefonobackground{
        background-color:white !important;
        border:none !important;
        margin-top:8px;
        padding:.375rem 0rem .375rem .3rem  !important
    }
    .bievenidoainicio{
        display:none
    }
    .posicionamientopaso1web{
display:row
    }
    .form-select {
        width:100% ;
    }
    .navFooter {
        border:1px solid rgba(240, 225, 255, 0.72);
        width: 100%;
        position: fixed !important;
        bottom: 0%;
        background-color: white;
        z-index: 1;
        padding: 0 !important;
        padding-bottom: 3px !important;
        padding-left: 2% !important;
        padding-right: 2% !important;
    
    }
    
    .footerStep6 {
        position: sticky;
        bottom:0;
        background-color: black;
        cursor: pointer;
        z-index:9999999999999999 !important;
        overflow: visible;
        
    }
  
    .infoterminos .infoplus{
        font-size: calc(.6rem + 0.5vw) !important;
        font-family:WorkSans-Regular !important;
    color: #9D9D9D !important;
    max-width: 80vw !important;
    margin-top: 0vh;
    margin-left: 6vh;
    overflow: scroll;
    height:45vh !important;
   margin-top:0vh;
    margin-left: 6vh;
    text-align: flex-end
}
    .formStepscompletar4{
        width:100% !important;

      
     }
    .paso3auto{
        margin-left:auto !important;
        margin-right:auto !important;
    }
 
    .step6 {
        width: 80vw;
    }
    .step6onlytriage {
        width:80vw;
        --bs-gutter-x:0 !important;
    }
    .letrasreal1{
        font-size:4vw !important;
      padding-left: 5vw !important
    }
    .letrasreal{
        font-size:4vw !important;
        max-width: 60vw;
       margin:auto !important
    }
    .containerCuerpo {
        width: 300px !important;
        height: 390px;
        position: relative;
        top: 0%;
        left: 0%;
        background: transparent linear-gradient(180deg,#D4DBFF 0%, #9EACED 100%) 0% 0% no-repeat padding-box;
        /* background: transparent linear-gradient(180deg,rgba(124, 210, 217, 1)  0%, rgba(42, 157, 165, 1) 100%) 0% 0% no-repeat padding-box; */
        font-size: 1.3rem;
    }
 
    
    .containerCuerpo .modalInfoContenido .icon img {
        height: 7rem;
    }
    .MuiInputBase-input{
        padding: 0.375rem 0.45rem !important
    }
    .MuiInputBase-root{
        position: fixed !important;
      background: URL("../assets/img/UserIcons/iconos/calendar.png")  no-repeat scroll  70vw 3px    ;
      background-size:18px;
      width:80% ;
      font-family:Work Sans !important;
    margin-left:-39%!important;
   
        border:none;
        border-bottom:-2px solid #BEBEBE!important  
    }
    
    .CompletarPerfil label {
        color: #394F65;
        font-weight:400;
        padding-top: 2vh;
    }
   
    .content-select select {
        position: relative !important;
        justify-content: flex-end;
        font-weight:400;
        background: URL("../assets/img/UserIcons/iconos/Icon material-arrow-drop-down.png") no-repeat scroll 70vw 15px ;
        background-size:18px;
        padding-right:5px;
        border:none;
        appearance: none !important !important;
        }
  
    .CompletarPerfil .subtitle {
        font-weight: 700;
        font-size: 5.5vmin;
        color: #394F65;
        margin-bottom: 2%;
    }
    .formStepscompletar{
        width:80vw 
     }
     .numberbluediv{
         display:none;
     }
     
     .formSteps {
        width: 80vw;
        color: #394F65;
     }
    .formSteps .objetivo, .objetivo_text {
        padding-bottom: 7%;
        display: grid;
        justify-items: center;
    }
    
    .formSteps .objetivo #icon {
        width: 30vmin;
        height: 30vmin;
        opacity:0.5;
        border: 5px solid #9EACED;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60% ;
    }
    
    .formSteps .objetivo.objetivos_small #icon {
        width: 18vw;
        height: 18vw;
        background-size: 50%;
        border: 5px solid #9EACED;
    }
    
    .formSteps .objetivo.objetivos_small #text {
        font-size: 3vmin;
    }
    
    .formSteps .objetivo.active.objetivos_small #icon {
        border: 5px solid #9EACED!important;
    }
    
    .formSteps .objetivo.transporte #icon {
        width: 18vmin;
        height: 18vmin;
      
        background-size: 130%;
    }
    
    .formSteps .objetivo #text {
        text-align: center !important;
        font-size: 3.5vmin;
        margin-top:1vh;
        height:15px;
        box-sizing: border-box;
        letter-spacing: 0px;
        color: #9EACED;
    }
    
    .formSteps .objetivo.active #icon {
        border: 5px solid #9EACED !important;
        opacity:1;
    }
    
    .formSteps .objetivo.active #text {
      font-weight:700;
        color: #394F65;
    }
    
    .formSteps .objetivo_text.objetivo_text_small #text {
        font-size: 2.8vmin;
        width: 17vmin;
        height: 17vmin;
        border: 4px solid #9EACED;
        opacity:0.5;
    }
    
    .formSteps .preg {
        font-size: calc(1rem + 1vmin);
    }
    
    .formSteps .objetivo_text.active.objetivo_text_small #text {
        border: 4px solid #9EACED !important;
        color:#394F65;
        opacity:1;
    }
    
    .formSteps .objetivo_text #text {
        width: 20vmin;
        height: 20vmin;
        border: 7px solid rgba(234, 234, 234, 1);
        padding-bottom: 0%;
        text-align: center;
        font-size: 3.5vmin;
        line-height: 1;
        color: #9EACED;
        padding-left: 3px;
        padding-right: 3px;
    }
    
    .formSteps .objetivo_text.active #text {
        border: 7px solid rgba(25, 148, 157, 1) !important;
        color: rgba(25, 148, 157, 1);
        font-weight: 700 !important;
    }
    .btnInfo {
        left: 55%;
        z-index: 1;
        width: 2rem;
        max-height:4rem !important;
        margin-top:1.4vh !important
    }
    .btnInfoPagos {
        left: 40%;
        z-index: 1;
        width: 1.5rem !important;
        max-height:4rem !important;
        margin-top:1.9vh !important
    }
    .slider_form {
        padding-top: 2vh;
       
    }
    .quitarmelo{
        display:none;
    }
    .labelPhoto {
        box-sizing: border-box;
        height: 20vh;
        margin-left:0 !important;
        margin-top:2vh;
        margin-bottom:-3vh !important;
        width: 20vh;
        border: 6px solid #9EACED;
        background-position: center 60%;
        background-repeat: no-repeat;
        background-size: 114%;
        position: relative;
    }
    .CompletarPerfil {
        background-color: white;
        min-height: 90vh;
    }
    .CompletarPerfil .titleregistro {
        font-weight: 700;
        font-size: 7vmin;
        color: #394F65;
        padding-top: 3vh;
    }
    .CompletarPerfil .subtitleweb {  display:none}

    .lineacompletarususario{display:none}
    .flexusuarioperfil{display:none

    }
    .imgabajo1{
        display:none
    }
    .imgarriba1{
        display:none
    }
    .CompletarPerfil .titleregistrodebajo{
        display:none
    }
    .centrado{
        margin-left:auto;
        margin-right:auto;
    }
 
    .CompletarFooter div {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .CompletarFooter {
        background-color: transparent;
        position: sticky !important;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-around ;
        width: 100%;
        color: white;
        height: 50px;
        padding: 0;
        border: 00 !important;
        cursor: pointer;
    }
    .CompletarPerfil .subtitlewebpaso1 {display:none}
    .niñafoto{display:none}
    .SubTitle8perfil{display:none}
    .decirLesion{
        display:none
    }
    .letrasrealweb{
   display:none  
    }
    .formSteps .paso5ubicar .objetivo #icon {

    background-size:10vmin;
  
    }
    .Swiperregistro .paso7{
      
        text-align:left;
      
    }
    .subtitletermin {
        text-align:center;
        font-family: WorkSans-Bold !important;
        font-size: 5.5vmin;
        color: #2A9DA5;
    }
    .siguiente1 {
        max-height:200vh;
        padding-top:0vh !important;
       margin-top:0vh !important;
       text-align: center;
       font-size: 5vw;
       font-family:WorkSans-Bold ;
     height:7vh;
       color: white;
       background-color: #000000;
   }
   .letrasabajowebpaso1{display:none}
   .PhoneInputInput{
    color: #9DACBD !important;
}
.telefonopsarela .PhoneInputInput{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
   border-bottom:1.3px solid #EAEAEA;
   color: #212529 !important;
  
}
.telefonopsarela .PhoneInputInput::placeholder{
    font-size:3.5vw !important;
    
  
}
.registrotelefono .PhoneInputInput{
    color: #9DACBD !important;
}
.tamañoletrassalud{
    font-weight:600 
}
}


.button, input, optgroup, select, textarea{
    line-height: normal !important ;
}
@media (min-width: 921px) {
    input::placeholder{
        font-size:1rem !important;
        color:#9DACBD !important;
    }
    .CompletarPerfil .subtitle_text {
        color: #9DACBD;
        width: 70vw;
        font-size: 3.5vmin;
        margin-bottom: 5%;
    }
    .content-selectweb select {
        font-size: 1vw !important;
        position: relative !important;
        justify-content: flex-end;
        background: URL("../assets/img/UserIcons/iconos/Icon material-arrow-drop-down.png") no-repeat scroll 14.5vw 14px ;
        width:100% ;
        background-size:18px;
       
        border:none;
      
        left:0px;
        top:0px;
        
        appearance: none !important !important;
        }
    .decirLesion_perfil{
        width:60%;
       position:absolute;
           margin-left: 40%;
         }
    .__react_component_tooltip {
        font-size:13px !important;
        width:250px;
        top:66px !important;
     
    }
    .btnInfocamera {
        left: 66%;
        top:5%;
        z-index: 1;
        width: 3rem;
        max-height:4rem !important;
        margin-top:1.4vh !important
    }
 
     .PhoneInputInput{
        color: #BEBEBE !important;
    }
    .tamañodeazul {
        width: 400px !important;
        height: 600px !important;
     
    }
    .decirLesionTitulo{
        color:#394F65;
        text-align:left;
      
        font-weight:700;
        
        font-size: calc(.2rem + 0.9vw) !important;
      }
      .btnAction1guardarweb {
       
        display: inline-flex;
       margin-left:10px !important;
        width: 100% !important;
        height:5vh;
        font-size:1rem !important;
        
    }
    .btnAction1guardar {
        display: flex;
      
        height:5vh;
        font-size:1rem !important;
        position: relative;
      margin-right:2.5vw !important;
      ;
        
    }
    .decirLesionTituloperfil{
        color:#394F65;
        text-align:left;
     
        font-weight:700;
        margin:1rem 0;
        font-size: calc(.4rem + 1.4vw) !important;
      }
    .anchorealizasactividad{
        margin-bottom:1vh !important;
        margin-top:1vh !important;
        margin-left:8vw !important;
        width:20% !important;
    }
    .titleautorizacion{
       
     
        color: #BEBEBE;
        font-size: calc(0.3em + 0.7vw);
    }
    .tamañoletrassalud{
        font-size:1rem !important
    }
    .marginswitch{
        margin-top:-2vh !important;
      
    }
    .formStepscompletar4{
        width:100% !important;
    position: relative !important;
     ;
        left: -10% !important;
      
     }
    .bievenidoainicio .title{
        color:#394F65 ;
    font-weight:700;
    font-size: 6vmin;
margin-bottom:2vh;
    margin-left: 4.5vw !important;
    line-height: 1;
    }
    .bievenidoainicio .title_triner{
        color: #394F65;
    font-weight:700;
    font-size: 5vmin;
margin-bottom:2vh;
    margin-left: 6vw !important;
    line-height: 1;
    }
    .bievenidoainicio .SubTitle8perfilcual {
        width: 8%;
        left: 0vw;
        margin-left: 1vw;
        text-align: left;
        position: relative !important;
      
        font-size: 0.9vw !important;
        color: #9DACBD;
        padding-top: 1px;
    
    }
    .bievenidoa_reservas .SubTitle8perfilcual {
        width: 8%;
        left: 0vw;
        margin-left: 1vw;
        text-align: left;
        position: relative !important;
      
        font-size: 0.9vw !important;
        color: #9DACBD;
        padding-top: 1px;
    
    }
    .bievenidoainicio .corrgircoso{
        left: 3.6vw;
    }
 
    
    .contenidototal{
margin-top:-50px;
    }
    .lineainiciousuario{
        border-bottom: 1px solid #BEBEBE;
      
        padding-right:1vw;
        opacity:0.5;
        margin-bottom:8px;
        z-index:1;
        width:100%;
        max-width:90vw;
        position:relative !important;
    }
    .bievenidoainicio .corrgircoso1{
        max-width:79vw;
        margin-left: 2.5vw ;
    }
 
    .lineainiciousuariosolita{
        border-bottom: 1px solid #BEBEBE;
        margin-left:14vw;
        padding-right:1vw;
        opacity:0.5;
        margin-bottom:0px;
        z-index:1;
        width:100%;
        max-width:90vw;
        position:relative !important;
    }
    .bievenidoainicio{
        display:row;
        width:100%;
        margin-top:1vh;
        margin-left:8vw;
    }
    .bievenidoa_reservas{
        display:row;
        width:100%;
        margin-top:1vh;
        margin-left:12.5vw;
    }
    .divcorreoelec{
        width:50%;
        margin:auto 0.8vw auto 0vw;
    }
    .divcorreoelecapelldio{
        width:50%;
        margin:auto 0vw auto 0.8vw;  
    }
    .divcorreoelec1{
        /* margin:auto 0vw auto 0px;
        padding:0 0vw 0 0px; */
        width:50%;
    }
    .divcorreoelec2{
        /* margin:auto 0vw auto 0px;
        padding:0 0px 0 10px; */
        width:50%;
    }
    .divcorreoelecmediorepetir{
        margin:auto 0px auto 0px;
        padding:0 0px 0 30px;   
    }
    .divcorreoelecmedio{
        margin:auto 40px auto 0px;
        padding:0 0px 0 0px;
    }
    .porcentajecorreo{
        width:15vw !important;
      
    }
    .porcentajecorreo1{
        width:90% !important
    }
    .telefonobackground{
        background-color:white !important;
        border:none !important;
        margin-top:8px;
        padding: 1vh 0px 1vh 0.5vw !important
    }
    .generoposiciona{
width:60% !important;
margin-bottom:20px;
margin-right: -40px !important;

    }
    .generoposicionaweb{
width:100% !important;
margin-bottom:20px;
margin-right: -40px !important;

    }
    .generoposicionaweb1{
width:80% !important;
margin-bottom:20px;
margin-right: -40px !important;

    }
    .generoposicionatipodoc{
width:60% !important;
margin-bottom:20px;
margin-right: -40px !important;

    }
    .swiper-container-autoheight .Paso5slider{
        
        height:calc(40vh - 100px);
        color:#394F65;
        overflow-y: scroll; 
        
    }
    .step6acomodar{
        margin-left:19.7vw;
      
    }
    .step6acomodaronly{
        margin-left:15vw;
      
    }
    .swiper-container-autoheight .Paso6slider{
        height:32vh;
        overflow-y: scroll;  
        overflow-x: hidden;
        width:84%;
        position:relative;
        left:0%;
       
    }
   
    .correrletrassuperior{
           
position:relative;


    
       
       
    }
    .cajapeso{
        padding-right:4vw;
    }
    .posicionamientopaso1web{
        display:flex;
            }
    .letrasabajowebpaso1{ 
             font-size: calc(.4rem + 0.4vw);
      
    
        text-align:left;

   display:flex;
   left:40%;
        width:50%;
     
    color:#9DACBD}
    .paso1subtitle{
        display:none
    }
    .content-selectcontinente select {
        position: relative !important;
        justify-content: flex-end;
        background: URL("../assets/img/UserIcons/iconos/Icon material-arrow-drop-down.png") no-repeat scroll 17vw 15px ;
        background-size:18px;
        padding-right:5px;
        border:none;
        appearance: none !important !important;
        }
   
    .form-select {
        width:100% ;
        
    }
    .paso2ubicartext{
        position:relative;
        text-align:left;
      margin:auto;
    
    }
    .paso3ubicartext{
        position:relative !important;
        text-align:center !important;
      margin:auto !important;
    
    }
    .paso2ubicar{
        position:relative;
        left:20% !important;
    
    }
    .direccionubicar{
        position:relative;
        
        width:17vw;
    }
    .navfooterdelete{
        display:none !important;
    }
    .siguiente1 {
        max-height:300vh;
        padding-top:0vh !important;
       margin-top:0vh !important;
       text-align: center;
       font-size: 2vw;
       font-family:WorkSans-Bold ;
     height:10vh;
       color: white;
       background-color: #394F65;
   }
    .footerStep6 {
      
        background-color: #394F65;
        cursor: pointer;
        z-index:9999999999999999 !important;
        overflow: visible;
        
    }

.ubicarfooter{
    position:relative;
 width:100%;
 height:10vh;
 top:81%;
 padding-left:8vw;
    
}
    .subtitletermin {
        font-weight: bold !important;
        font-size: 5.5vmin;
        color: #2A9DA5;
    }
    #tyc1{
        margin-top:4px;
       width:3vw; 
    }
     .TyC1 {
        font-size: calc(.5rem + 1vw);
      
    }
    .alturainifinity{
        height:auto !important
    }
    .Swiperregistro .paso7{
        position:relative;
        width:68vw !important;
        text-align:left;
        
        
      
        left:18vw;
        height:60vh !important;
    }
    .infoterminos .infoplus{
        overflow-y: scroll; 
        height:32vh;
        font-size: calc(.6rem + 0.5vw) !important;
      
    color: #9D9D9D !important;
    max-width: 83vw !important;
    margin-top: 4vh;
    margin-left: 0vh;}
    .swiper-wrapper{
        margin-top:0px;
      height:80% !important
    }
   
    .CompletarPerfil label {
        color: #394F65;
        font-size: 1vw !important;
         padding-top: 1vh
    }
     .preguntassalud{
        width:30vw;
    }
    .step6 {
        width:88.5%;
    }
    .step6onlytriage {
        width:100%;
    }
   
  .paso1completar{
margin-top:20px;
  
      
  }
  .paso2completar{
margin-top:80px;
  
      
  }
  .paso3completar{
      margin-top:80px;
    
    
  }
    .paso5subtitle{
   
     margin:auto;
     margin-bottom:20px;
     text-align:center;
    }
    .subtitlepaso4{
        display:none;
    }
    .CompletarPerfil .subtitle {
        margin-top:-2vh;
      
        font-weight: bold !important;
        font-size:30px;
        color: #394F65;
    }
 
    .formSteps .paso5ubicar .objetivo_text.objetivo_text_small #text{
        font-size: calc(.4rem + 0.7vw) !important;
        width: 12vmin !important;
    padding-bottom:0% !important;
        height: 12vmin !important;
    border: 5px solid rgb(158, 172, 237,0.5);
    }
    .formSteps .paso5ubicar .objetivo #icon {
        width: 12vmin !important;
    height: 12vmin !important;
    background-size:6vmin !important;
    border: 5px solid rgb(158,172,237,0.5);
    background-position: center;
    background-repeat: no-repeat;
    }
    .formSteps .paso5ubicar .objetivo #text{
        text-align: center;
        font-size: calc(.5rem + 0.7vw) !important;
    letter-spacing: 0px;
    color: rgb(158,172,237,1);;
    }

    .paso5ubicar{
    margin-top:-50px;
    
    
    position:relative;
    width:90.5%;
    }
    .marginimportantpaso5{
        margin: 0 10vw !important;
    }
 
    .switchpaso5{
        position:relative !important;
        left:27vw !important;
        right:24vw !important;
    }
    .sinoubicar{
        margin-top:1rem !important;
        margin-bottom:1rem;
        width:100%;
    }
    .letrasrealweb{
        font-size: calc(.6rem + 0.6vw) !important;
   position:relative !important;
   width:100% !important;
     left:29vw;
     right:28vw;
      
    }
    .letrasreal1{
     display:none
    }
    .letrasreal{
        font-size: calc(.6rem + 0.6vw) !important;
        max-width: 60vw;
       margin:auto !important
    }
 
    .btnMOB {
        font-weight:700;

        color: #FFFFFF;
        background: #CB3D82 0% 0% no-repeat padding-box;
        border-radius: 32px;
       position:relative;
  
        border: hidden;
        width: 70vw;
        max-width: 300px;
        display: flex-start;
        align-items: center;
        justify-content: center;
        margin-top:1vh !important;
        
    }

  .acomodarsilueta{
      display:flex !important;
      position:relative;
      left:12vw;
          
  }
  .acomodarsiluetaentrenador{
      display:flex !important;
      position:relative;
      left:88%;
          
  }
  .acomodarsiluetapre{
      display:flex !important;
      position:relative;;
      left:100%;
  
      width:100%;;
    

      
  }
  .acomodarsiluetaperfil{
      position:absolute;
      display:flex !important;
      position:relative;
      /* left:-3vw; */
     width:100vw;
      background-color:#F7F7F7;
   
    

      
  }
  .decirLesion09_categ{
    padding:10px 0;
    font-weight:700;
    font-size: 1.1vw !important;
    color:#394F65; 
  }

  .decirLesion09{
      margin-top:10px;
    font-weight:700;
    font-size: calc(.4rem + 0.6vw) !important;
    color:#394F65;
  
    position: relative;
    text-align:left;
    width: calc(100% - calc(229px * 0.30)); 
  }
  .decirLesion{
 width:60%;
position:absolute;
    margin-left: 17vw;
  }

  .decirLesion01{
   
    font-size: calc(.1rem + 0.6vw) !important;
    color:#9DACBD;
  
    position: relative;
    text-align:left;
    width: 19.5vw; 
  }
    .containerCuerpo {
        width: 253px !important;
        height: 330px;
        position: relative;
        top: 0%;
        left: 9%;
        margin-right:0.5vw;
        margin-left:0.5vw;
        background: transparent linear-gradient(180deg,#D4DBFF 0%, #9EACED 100%) 0% 0% no-repeat padding-box;
        font-size: 1.3rem;
    }
    .containerCuerpo_progres {
        width: 20vw !important;
        height: 25vw;
        max-width: 253px !important;
        max-height: 330px;
        position: relative;
        top: 0%;
      
        margin-right:0.5vw;
        margin-left:0.5vw;
        background: transparent linear-gradient(180deg,#D4DBFF 0%, #9EACED 100%) 0% 0% no-repeat padding-box;
        font-size: 1.3rem;
    }
    .containerCuerpoentrenador {
        width: 30vw !important;

        position: relative;
        top: 0%;
        left: 9%;
        margin-right:0.5vw;
        margin-left:0.5vw;
        background: transparent linear-gradient(180deg,#D4DBFF 0%, #9EACED 100%) 0% 0% no-repeat padding-box;
        font-size: 1.3rem;
    }
    .containerCuerpoperfil {
        width: 253px !important;
        height: 420px;
        position: relative;
        top: 0%;
        left: 0%;
        background: transparent linear-gradient(180deg,#D4DBFF 0%, #9EACED 100%) 0% 0% no-repeat padding-box;
        font-size: 1.3rem;
    }
    
  
   .paso3pos{
   position:relative;
  
   grid-gap:0.1vw;
 width:70vw;
 height:100px !important;
  margin-left:17vw !important;
  padding:2vw;
  margin-right:1vw !important;
  
   top:5vh;
  ;

      display: grid !important;
     
       grid-template-columns:repeat(6, 1fr);
      
 
}
.swiper-container-autoheight, .swiper-slide {
    height: auto;
}

    .relativopaso1{
        /* margin-top:3vh; */
        position:relative;
        top:0vh;
        left:0%;
        width:60%;
        height:100%
    }
   
    .flexusuarioregistro{
        margin-top: 0px;
   
        display:flex;
    }
    .numberbluediv{
        display:flex;
        position: fixed;
   left:0%;
    top: 83vh;
    z-index: 999999999;
    width: 14vw;
    height: 19;
  
    background-color: rgb(158,172,237,1);
    text-align:center;
    justify-content: center;
    }
    .numberblue{
        position:relative;
        top:-50px;
        left:1vw;
     
  font-weight:bold;
        font-size: 12vw !important;  
        color:  rgb(123,134,250,0.3)
    }
    .swiper-container {
     
    
        /* top:-5vh; */
        left:0vw;
    }
    .swiper-container .subirmasfil1 {
     
        margin:auto;
         top:-5vh;
        left:2%;
    }
    .CompletarPerfil {
        background-color: white;
      
        position: relative;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .CompletarFooter .anterior {
        background-color: #222222  ;
    }

    .CompletarFooter {
       position: fixed !important ;
       background-color: transparent;
    display: inline-flex;
        ;
      left:70vw;
      top:80%;
       align-items:center;
  
       width: calc(36% - calc(80px * 0.39));
        color: white;
        height: 8.4vh;
        padding: 0;
        margin:0;
        border: 00 !important;
        cursor: pointer;
        min-width:40vw;
    } 
    .CompletarFooter div {
        border-radius:55px;
        height: 100%;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .CompletarFooter .anterior {
        height: 100%;
        border-radius:0px !important;
        width: 40%;
        border-top-left-radius:50px !important;
        border-bottom-left-radius:50px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .telefono{
        display:table-row;
        
    }
    .borrandolo{
        display:none;
    }
    .agradarpaso2{
        display:flex;
        position:relative;
        margin-top: 4vh;
       
      
        justify-content: center;
        
        width: 100%;
        height: 100%;
    }
    .CompletarFooter .siguiente {
        height: 100%;
        border-radius:0px !important;

        
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .MuiInputBase-input{
        padding:12px 10px 10px 
    }
    .MuiInputBase-root{
        position: relative ;
        background: URL("../assets/img/UserIcons/iconos/calendar.png")  no-repeat   13vw 3px    ;
        background-size:18px;
        width:15vw ;
          border-bottom:-2px solid #BEBEBE!important   
    }
    .niñafoto{
        position: absolute;
        left: 100%;
        margin-top:0vh;
        width: calc(15vw - calc(9vw * 0.15));
      height:calc(100vh - 220px);
        top: 35px;
        transform: translate(-100%, 0px);
    }
    .Bullet{
        margin:12px !important
    }
    .swipersep {
        
        width: 0.1vw;
        height:3.2vh;
        margin-left: 26px;
       margin-top:-12px;
       margin-bottom:-15px;
       color: #9EACED;
        background-color: transparent;
    }
    .stepsBullets{
        display:flex;
   position: relative;
    ;
 
       top:5%;
       left:14vw;
       padding:2px;
        display:grid
    }
    .paso1margin{
        margin-left:10vw;
      
    }
    .CompletarPerfil .titleregistro {
       
        font-size: calc(2rem + 1vw) !important;
        color: #394F65;
        font-weight:bold;
        margin-top:0px;
        margin-left: 15vw;
       position:relative;
       text-align:left;
        
    }

    .btnInfo {
       
        left: 22vw;
        z-index: 2 !important;
        top:20px;
        width: 2rem;
        max-height:4rem !important;
        margin-top:1.4vh !important
    }
    .CompletarPerfil .titleregistrodebajo {

        font-size: calc(0.1rem + 1vw) !important;
        color: #9DACBD;
        margin-top:0px;
        margin-left: 15vw;
        margin-right: 35vw;
       position:relative;
       text-align:left;
        
    }
  
    .SubTitle8perfil{

        position:relative;
        white-space:pre;
        margin-top: 0px;
        font-size: calc(10px + 0.4rem) !important;
        color: #9DACBD;
        padding-top: 3vh;
     
        margin-left: 15vw;
        margin-right: 1vw;
    }
    .lineacompletarususario{
        border-bottom: 1px solid #BEBEBE;
        margin-bottom:8px;
        opacity:0.5;
        z-index:1;
        width:64vw;
    } 
     .imgarriba1{
        position: absolute;
        margin-top:6vh;
        margin-left:0vw;
         width:40%;
         z-index:0 !important
    }
    .imgabajo1{
        position: absolute;
        margin-top:30vh;
        margin-left:70vw !important;
         width:30%;
         z-index:0!important
     }
   
     .flexcontraseñas1{ display:flex !important;
        width:100% !important;}
        .estaturapaso1{
            width:43% !important;
        }
        
    .content-select select {
        font-size: 1vw !important;
        position: relative !important;
        justify-content: flex-end;
        background: URL("../assets/img/UserIcons/iconos/Icon material-arrow-drop-down.png") no-repeat scroll 18vw 15px ;
        width:70% ;
        background-size:18px;
       
        border:none;
      
        left:0px;
        top:0px;
        
        appearance: none !important !important;
        }
 
        .labelPhoto{
            width: 13vw !important;
            height:13vw !important;
        }
        .numeroposicion{
        
            width:35% ;
        }
        .numeroposicion1{
        
            width:90% !important;
        }
        .labelPhoto {
            box-sizing: border-box;
            height: 23vh;
          
         
            MARGIN-LEFT:17vw;
          

            margin-top:25px;
       
   
            border: 6px solid #9EACED;
          
            background-repeat: no-repeat;
            background-size: 100%;
            position: relative;
           left:-17%;
        }
     
    
     
 


  
   
    .generoynaci{
      grid-template-columns: repeat(2, minmax(0, 1fr));
        
    }
    .formStepscompletar{
        width:calc(100% - calc(26vw * 0.40));
     }
     
 
    .formSteps .objetivo, .objetivo_text {
        padding-bottom: 1%;
        display: grid;
        justify-items: center;
    }
    
 
    
    .formSteps .objetivo.objetivos_small #icon {
        width: 18vw;
        height: 18vw;
        background-size: 50%;
        border: 5px solid rgb(158,172,237,0.5);;
    }
    
    .formSteps .objetivo.objetivos_small #text {
        font-size: 3vmin;
    }
    
    .formSteps .objetivo.active.objetivos_small #icon {
        border: 5px solid rgb(158,172,237,1) !important;
    }
    
    .formSteps .objetivo.transporte #icon {
        width: 18vmin;
        height: 18vmin;
      
        background-size: 130%;
    }
    
    .formSteps .objetivo #text {
        text-align: center !important;
        font-size: 2vmin;
        margin-top:1vh;
        height:15px;
        box-sizing: border-box;
        letter-spacing: 0px;
        color: rgba(158, 172, 237,1);
    }
    
    .formSteps .objetivo.active #icon {
        border: 5px solid rgba(158, 172, 237, 1) !important;
    }
    
    .formSteps .objetivo.active #text {
        font-weight: bold !important;
        color: #394F65;
    }
    
    .formSteps .objetivo_text.objetivo_text_small #text {
        font-size: 2.8vmin;
        width: 17vmin;
        height: 17vmin;
        border: 4px solid rgba(158, 172, 237,0.5);
    }
    
    .formSteps .preg {
        font-size: calc(1rem + 1vmin);
    }
    
    .formSteps .objetivo_text.active.objetivo_text_small #text {
        border: 5px solid rgba(158, 172, 237,1) !important;
        color: #394F65;
        font-weight:bold;
    }
    
    .formSteps .objetivo_text #text {
        width: 20vmin;
        height: 20vmin;
        border: 7px solid rgb(158, 172, 237,0.5);
        padding-bottom: 0%;
        text-align: center;
        font-size: 3.5vmin;
        line-height: 1;
        color: #9EACED;
        padding-left: 3px;
        padding-right: 3px;
    }
    
    .formSteps .objetivo_text.active #text {
        border: 7px solid rgb(158, 172, 237,1) !important;
        color: rgb(158, 172, 237,1);
        
    }
  
 
    .slider_form {
        padding-top: 2vh;
        
    }
  
   
    .CompletarPerfil .Separador{
        display:none
    }
  
  
    .paso1modificar{
        position:relative !important;
        top:26px !important;
       
    }

 
    
 
  

    .CompletarPerfil .subtitleweb {
       position:relative;
       left:15%;
        font-weight: bold !important;
        font-size: calc(0.5rem + 1vw) !important;
        color: #2A9DA5;
    }
    .CompletarPerfil .subtitlewebpaso1 {

   display:flex;
   left:40%;
       font-weight:bold;
        font-size: calc(0.5rem + 1vw) !important;
        color: #394F65;
    }
  
    .formSteps .objetivo #icon {
     width:13vmin;
     height:13vmin;
        min-height:3vmin;
        min-width:3vw;
        border: 5px solid  rgb(158, 172, 237,0.5);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
    }
}

.FaltanItems {
    text-align: left !important;
}
.FaltanItemsmobster {
    text-align: center !important;
}



.CompletarFooter .anterior {
    background-color:#2A9DA5;
    border-radius:25px;
    font-weight:700;
}

.CompletarFooter .siguiente {
    color: #9DACBD;
    border-radius:25px;
    background-color: #D4E8FF;
    
}
.ubicarfont{
   padding-top:1vh !important;
    
}


.siguiente.canNext {
    color: #FFFFFF;
    background-color: #CB3D82;
    font-weight:700;
}