.CancelarReserva .estrellas {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin:1vh 10vw;
    padding:0 2vw ;
  

}
.cuadrar_textareae{
    margin: 0 10vw !important ,
}
.centrebolar{
    justify-content: center;
    text-align:center;
   
    margin: 0 5px !important;
}


.centrebolar input::placeholder{
    font-size:0.8vw !important;
    font-weight:bold
}

.widhtinfoperr{
    width:100% 
}
.CancelarReserva .msg1_cl{
    font-weight:400;
    font-style:italic;
    color: #9DACBD;
    text-align: center;
    line-height: 1;
    font-size: calc(0.5rem + 0.3vw);
    margin: 2vh 10vw 2vh 10vw;
}

.CancelarReserva .msg1{
    font-weight:bold;
    color: #394F65;
    text-align: center;
    line-height: 1;
    font-size: calc(0.5rem + 0.5vw);
    margin: 2vh 10vw 2vh 10vw;
}
.CancelarReserva .titleentre{
    color: #394F65;
    font-weight: bold;
    text-align: center;
    font-size:2rem !important
}
.widhtimg{
    width:50%;
  
}
.frecuencianumero{
font-size:3vw;
font-weight:bold;

}

.Iniciar button {
    background-color: #7886F5;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 2.5vmin;
    border-radius: 30px;
    padding: 0.5rem 1.5rem;
}

.Iniciar {
    display: flex;
    justify-content: center;
}

.entrenadorRutina .infoClase .name .time {
    color: #394F65;
    font-size: 1rem;
}

.entrenadorRutina .infoClase .name .rep {
    color: #394F65;
    font-size: 1rem;
}

.entrenadorRutina .infoClase .name {
    color: #394F65;
    font-size: 1.3rem;
  
    line-height: 1;
    margin-bottom: 0.5rem;
}

.entrenadorRutina {
    padding-top: 20px;
}

.entrenadorRutina .title {
    line-height: 1;
}

.nombreRutinaActual {
    font-size: calc(0.2rem + 1vw);
    text-align: left;
    margin-left: 1rem;
    font-weight: 500;
}

.nombreRutinaActual span {
    color: #CB3D82;
    font-weight: bold;
}