
@media (min-width: 100px) and (max-width: 322px) {
  .thumb {
  pointer-events: none;
  position: absolute;
  height: 15px;
  width: 80vw;
  outline: none;
  margin-top:3px;
 
}
.container {
  width:100vw;
  max-width:100vw;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom:2vh;
 padding-top:4vh;
  
}
.slider {
  position: relative;
  width: 100vw;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 17px;
  height: 15px;
}

.slider__track {
  background-color: white;
  width: 100%;
  border:1px solid #bebebe;
  z-index: 2;
}

.slider__range {
  background-color:#9EACED;
  z-index: 3;
}

.slider__left-value,
.slider__right-value {
  color: black;
  font-size: 12px;
  margin-top: -30px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */



.CompletarPerfil input{
  border-bottom:none !important
}

.thumb--left {
  z-index: 4;
  margin-bottom:0px;
}

.thumb--right {
  z-index: 5;
  margin-bottom:0px;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color:#9EACED;
  border: none;
  border-radius: 50%;
 
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 2vh;
  pointer-events: all;
  position: relative;
}


/* For Firefox browsers */



/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color:#9EACED;
  border: none;
  border-radius: 50%;
 
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 3vh;
  pointer-events: all;
  position: relative;


}
.thumb::-o-range-thumb {
  background-color:#9EACED;
  border: none;
  border-radius: 50%;
 
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 3vh;
  pointer-events: all;
  position: relative;


}

}
@media (min-width: 321px) and (max-width: 920px) {
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 15px;
    width: 80vw;
    outline: none;
    margin-top:0.3px;
   
  }
  .container {
  width:100vw;
  max-width:100vw;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom:2vh;
 padding-top:4vh;
  
}
.slider {
  position: relative;
  width: 100vw;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 17px;
  height: 15px;
}

.slider__track {
  background-color: white;
  width: 100%;
  border:1px solid #bebebe;
  z-index: 2;
}

.slider__range {
  background-color:#9EACED;
  z-index: 3;
}

.slider__left-value,
.slider__right-value {
  color:#394F65;
  font-size: 12px;
  margin-top: -30px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */



.CompletarPerfil input{
  border-bottom:none !important
}

.thumb--left {
  z-index: 4;
  margin-bottom:0px;
}

.thumb--right {
  z-index: 5;
  margin-bottom:0px;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #9EACED;
  border: none;
  border-radius: 50%;
 
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 2vh;
  pointer-events: all;
  position: relative;
}


/* For Firefox browsers */



/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color:#9EACED;
  border: none;
  border-radius: 50%;
 
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 3vh;
  pointer-events: all;
  position: relative;


}
.thumb::-o-range-thumb {
  background-color:#9EACED;
  border: none;
  border-radius: 50%;
 
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 3vh;
  pointer-events: all;
  position: relative;


}
/* For Firefox browsers */







}
@media (min-width: 921px) {
  .container {
    width:300px;
    
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-bottom:0px;
   padding-top:12px;
    
  }
  .slider__track,
.slider__range {
  border-radius: 25px;
  height: 8px;
}
.slider__left-value,
.slider__right-value {
  color: #394F65;
  font-size: 8px;
  margin-top: -15px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}
  .slider {
    position: relative;
    width: 200px;
  }
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 7px;
    width: 200px;
    outline: none;
    margin-top:-1.1vh;
   
  }
  .CompletarPerfil input{
    border-bottom:none !important
  }
  
  .thumb--left {
    z-index: 4;
    margin-bottom:0px;
  }
  
  .thumb--right {
    z-index: 5;
    margin-bottom:0px;
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color:#9EACED;
    border: none;
    border-radius: 50%;
   
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin-top: 2.1vh;
    pointer-events: all;
    position: relative;
  }
  
  
  .thumb::-moz-range-thumb {
    background-color:#9EACED;
    border: none;
    border-radius: 50%;
   
    cursor: pointer;
    height: 10px;
    width: 20px;
    margin-top: 3vh;
    pointer-events: all;
    position: relative;
  
  
  }
  .thumb::-o-range-thumb {
    background-color:#9EACED;
    border: none;
    border-radius: 50%;
   
    cursor: pointer;
    height: 16px;
    width: 20px;
    margin-top: 3vh;
    pointer-events: all;
    position: relative;
  
  
  }
  
  
  
  
  /* For Firefox browsers */
}

  
 
 