





.logo-imgsolis{
    display:flex;
    width: 18vw; 
  
    margin-left:0%
    
}





@media (min-width: 100px) and (max-width:  920px) {
    #navbarNavDropdown .nav-item {
        /* padding-top:-5vh !important; */
          position: relative;
          color: #394F65 !important;
          display: flex;
          align-items: center;
      }
    #profilexc {
   
        padding-top: 2vh !important;
    }
    #navbarNavDropdown .nav-ico {
    
        width: 6vw;
        margin-left: 7vw;
        margin-right: 5vw;
    }
    .logoContainerNaventrena {
        width: 13%;
        justify-content: center;
        margin:auto;
    }
    
.campana{
    right: 25%;
    width: 18px;
    position: absolute;
    top: 30%;

   
    top:30%;
/* display:flex; */
    ;
    /* margin-right:-7vw !important; */
    /* margin-left:2vw; */
}
    .contenedorFijo {
        min-height: calc(120vh - 1rem);
        margin: auto;
    }
    .gps{
        width: 30px;
        margin-right:0vw;
        margin-left:-1.4rem;
    }
    #profile {
        z-index: 3;
        padding-top: 2vh;
        background-image: url("../assets/img/fondo/bg_form.png");
        width: 60vw;
        position: fixed;
        right: 0px;
        border-bottom: 4px solid #CB3D82;
        background-size: cover;
        background-color: white;
    }
    #profilexc {
        z-index: 3;
        padding-top: 2vh;
        background-image: url("../assets/img/fondo/bg_form.png");
     height: 100% ;
     overflow: auto;
     
        position: fixed;
  
        border-bottom: 4px solid #2A9DA5;
        background-size: cover;
        background-color: white;
    }
    #navbarNavDropdown .casaweb img {
        display:none
    }
    #navbarNavDropdown .nav-icologo img{
        display:none
    }
    
.logoContainerNav {
    width: 18%;
}

    .letrasnavi{
        font-size: calc(0.6rem + 1vw);
        margin-left:5vw;
        font-weight:700 !important;
        
    }
 
    .goback {
        width: 9vw;
        max-height:5vh;
        padding-top:0.7vh;
        padding-bottom:0.7vh;
        margin-right:1vw !important;
        cursor: pointer;
    }
    .rayas{
        margin:auto;
        border:none;
        
        width: 30px;
    }
    .rayas_1{
        margin-right:0rem;
        margin-left:0rem;
        border:none;
        
        width: 30px;
    }
    .heart{
        width: 30px;
        margin-right:-6rem;
        margin-left:-2rem;
    }
    .line{
        display:flex;
        opacity:0.4;
        height:6vh !important;
        
        margin-right:-6vw;
     }
    .bieN{
   
        color: white;
        font-family: WorkSans;
        font-size: 2.0vmin;
        padding-top: 1vh;
    
        line-height: 1;
        text-transform:capitalize
    }
    .bie{
   
        display:flex;
        color: #BEBEBE;
        font-weight:700;
        font-size: 2.0vmin;
        padding-top: 1vh;
      
        line-height: 1;
    }
    .logo-img {  
        position:absolute;
      left:35%;
      top:30%;
        width: 100%;
            max-width:4rem !important;
            justify-content: center;
            /* margin-left:-4vw; */
            
           
        }
    .salirregistro{
 
        margin-left:5vw !important ;
        padding-left:5vw !important ;
    }
    .salirregistro .imagensalir{ width:20px;}
  
    .LogOutEnd{
        display:none 
    }
 
    .logo-imgnav{

    display:flex;
    justify-content: center;
    
}

.flechades{
    display:none
}

    .menu_superior_menu
{
    display:none
}
    /*Most of the Smartphones Mobiles*/
    .logo-mobile-nav {
        display: block !important;
    }
    .logo-desk-nav {
        display: none !important;
    }
    .avatar {
        width: 35px;
        display:flex;
      height:35px;
      position:relative;
      left:10px;
        background-color: #fff;
    margin-left:-20px;
        margin-right: 0%;
     
       
    }
    #navbarNavDropdown {
      
        height: calc(100rem);
        background-color: white;
    }
    .salirletrasregistro{
        font-size:3.5vw;
        color:#9DACBD;
        font-weight:bold;
    }
    #navbarNavDropdown .nav-ico img {
        width: 7vw !important;
        
        
    }
   
  
}


.logo-mobile-nav {
    display: none;
}

.logo-desk-nav {
    display: block;
}

.menu_superior {
    width: 100%;
    height: 72px;
    background: #000000;
    border-bottom: 4px solid #2A9DA5;
}

.menu_superior .logo {
    content: url('logo.png');
    height: 55px;
    opacity: 1;
}

.menu_superior a {
    color: white !important;
}



.menu_superior .redes_sociales {
    color: white;
    margin-left: 1rem;
}

.menu_superior .redes_sociales a {
    font-size: 1.2rem;
    display: inline-block;
}

.redes_sociales a {
    color: white;
    margin-right: 15%;
}

.btn-collapse-nav {
    width: 100%;
    padding: 1rem;
    margin: 0.1rem;
    background-color: rgba(0, 0, 0, 0.1);
    border: 0;
}
/* font-size: calc(0.7rem + 1vw) !important; */
@media (min-width: 921px) {
    .rayas_1{
        display:none;
    }
    #navbarNavDropdown .nav-item {
        /* padding-top:-5vh !important; */
          position: relative;
          color: #ffffff !important;
          display: flex;
          align-items: center;
      }
    #profilexc {
        width: 40vw !important;
        padding-top: 2vh !important;
        max-height:30rem;
        overflow-y: auto;
       
            transition: height 1s ease !important;
    }

    .menu_superior_menu
    {
        position:relative;
        left:24%;

      padding:1.8vh 0.3vw;
      border-left: 1px solid  rgbA(157, 172, 189, 0.3);;
        top:-9%;
 
        display:flex;
    
    }
    .menu_superior_menu_trainer
    {
        position:relative;
        left:41%;
        top:-6%;
vertical-align:middle;
    
      border-left: 1px solid #4A4A4A;
    
        display:flex;
    
    }

    #navbarNavDropdown .nav-ico {
    
        width: 10vw;
        margin-left: 7vw;
        margin-right: 5vw;
    }
    .logoContainerNaventrena {
        display:none;
    }
    .linenabvarn{
        margin-top:8vh;
        border-top:1px solid #CB3D82;
        opacity:0.4;
        margin-bottom:4vh;
        justify-content: center;
        margin-left:1vw;
        margin-right:1vw;
        
    }




    
.campana{
    width: 18px;
    position:absolute;
    right:23%;
    top:40%;

 
   
}
    .contenedorFijo {
        max-height: calc(100vh - 10rem);
      
    }
    .gps{
    display:none
    }
    .salirletrasregistro{
        font-size: calc(0.3rem + 1vw);
        font-weight:bold;
    }
    .salirregistro{ display:none !important}
    #profile {
        z-index: 3;
        padding-top: 2vh;
        background-image: url("../assets/img/fondo/bg_form.png");
        width: 60vw;
        position: fixed;
        right: 0vw;
        border-bottom: 4px solid #9EACED;
        background-size: cover;
        background-color: white;
    }
    #profilexc {
        z-index: 3;
        padding-top: 2vh;
        background-image: url("../assets/img/fondo/bg_form.png");
        width: 60vw;
        position: fixed;
        right: 0vw;
        border-bottom: 4px solid #9EACED;
        background-size: cover;
        background-color: white;
    }
    #navbarNavDropdown .nav-icologo img {
        position:relative;
        display:flex;
        margin-bottom: -3vh;
        width:4vw;
      margin:0 -0.8vw;

       justify-content: center;
       
        
    }

    #navbarNavDropdown .nav-icologo  {
        margin-left: 35% !important;
        margin-right: 2vw !important;
        margin-bottom:2vw
       
       
        
    }

.logoContainerNav {
    position:absolute;
    left:50%;
    margin-top:auto;
    margin-bottom:auto;
}
    .letrasnavi{
        font-size: 0.9vw;
     
        white-space: nowrap;
       
    }
    .goback {
    display:none;
    }
    
    .rayas{
       display:none;
    }
    .heart{
        display:none;
      
    }
 
    .line{
        display:none
    }
  
    .bieN{
   margin-bottom: 0 !important;
        color:#394F65;
        font-weight:bold;
        font-size:0.8vw;
        padding:0 0.2vw;
 
     
        text-transform:uppercase
    }
    .bie{
        margin-bottom: 0 !important;
        display:flex;
        padding:0 0.2vw;
        color: #394F65;
      
        font-size:0.8vw;
     
      
     
    }
    .avatar {
        width: 5vh;
        display:flex;
      height:5vh;
      position:relative;
      left:4vw;
        background-color: #fff;
   top:-2vh;
        margin-right: 0%;
     
       
    }
    .flechades{
        width: 0.8vw ;
        position:relative;
        left:1.4vw;
        top:1.9vh;
    }
    .logo-img {  
      
        width: 100px;
            
            
           
        }
    .logo-imgsolis1{
        width: 70px;
        position: relative;
        left: 55%;
        transform: translate(-50%, -9vh);
    }
    .rootSideBarMob {
        width: 110px;
        max-width:110px;
      
        /*The same in @media (min-width: 481px) #navbarNavDropdown */
        transition: all 0.5s ease;
    }
    .rootSideBarMob1 {
        width: 10vw;
  
        /*The same in @media (min-width: 481px) #navbarNavDropdown */
        transition: all 0.5s ease;
    }
    .salirregistro{
        display:flex;
     position: absolute;
     bottom:30%;
     right:5%;
       
        
    }
  
    .title{
margin-left:1px !important
    }
    .rootSideBarMob{
      
      position: static ;
        
       margin-top:66px !important
    }
    .rootSideBarMob1{
        width:7vw;
      display:block;
      position: static ;
        
       /* margin-top:66px !important */
    }
  

    .logo-imgsolis{
        
     display:none
    }
    .line
{display:none}

    .navHeader {
        /* position: fixed !important; */
        height:70px;
        top: 0;
        width:100% !important
       
    }
    #navbarNavDropdown{
      
        background-color:white!important
    }
    #navbarNavDropdown .nav-ico img {
        width: 2.5vw !important;
        padding:0.2vw;
        
    }
 
    #navbarNavDropdown .nav-ico {
        margin-left: 0% !important;
        margin-right: 70px !important;
        padding-left:2.2vw;
        width: 4vw !important;
    }
    .nav-separadorcompletar {
        top: 64vh;
        position: relative;
        margin: auto;
        opacity: 0.2;
        width: 0.6vw;
        background-color: #BEBEBE;
        min-width: 100px;
        border: 0.4px solid rgba(255, 255, 255, 0.359);
     
    }

   
    #profile {
        width: 20vw !important;
        padding-top: 2vh !important;
    }
 

    .LogOutEnd {
        flex-grow: 1;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
    }
    #navbarNavDropdown {
     height:100vh;
    }

}

.navHeader {
    border-bottom: 4px solid white;
    z-index: 2;
    
}
 .navbar-toggler{
    border-color:#F1F6F9 !important;
}
.navHeader .navbar-toggler {
    background: #F1F6F9;
    border-radius:10px solid #F1F6F9 !important;
}



#nvDropDown {
    z-index: 99999;
}

.sideBarMob {
    padding-top:calc(15vh - 100px) ;
    z-index: 99 !important;
    height: 100%;
    background-color: #A8B7FF;
    color: white;
    overflow: hidden !important;
    ;

    width: 100%;
    border-right: 4px solid #A8B7FF;
}
.sticky-top {
    z-index:99 !important
}
.sideBarMobTrainer {
    padding-top:10vh ;
    z-index: 99999999 !important;
    height:100vh;
    background-color: #A8B7FF;
    color: white;
    overflow: hidden !important;
    ;
    /* height: 100%; */
    width: 100%;
    border-right: 4px solid #A8B7FF;
}



.rootSideBarMob1:hover {
    width: 25vw;
}


#navbarNavDropDown .logout {
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    padding-bottom: 2vh;
}

#navbarNavDropdown ul {
    padding-top:1vh;
    height: calc(100% - 25vh);
}



#navbarNavDropdown .navbar-nav {
    display:flex;
   height:calc(100% - 20vh);
    justify-content:flex-end;
    ;
}






.LogOutEnd1 {
    flex-grow: 1;
   
    display: flex;
    flex-direction: column;
}


.inicionav{
    margin-top:3vh !important;
}
.nav-separador {
    width: 50vw;
    min-width: 200px;
    border: 1px solid rgba(255, 255, 255, 0.359);
    margin-left: 10vw;
}
.nav-separador1 {

    opacity:0.5;
    width: 23vw;
    min-width: 60px;
    border: 1px solid #394F65;
    margin-left: 10vw;
}


.navbar-brand {
    height: 100%;
    margin-right:3rem !important;
}










.profile {
    margin-right: 6vw;
    border: 0;
    background: none;
}



#profile a {
    color: #9DACBD !important;
    font-weight:bold;
    font-size:0.9rem;
    padding-left:1vw;
}
#profile a:hover{
    color: white !important;
    background-color: #CB3D82 !important;
    width:80%;
    border-top-right-radius: 50px !important;    border-bottom-right-radius: 50px !important;
}
#profile .cierre_session:hover{
    color: white !important;
    background-color: #CB3D82 !important;
    width:80%;
    border-top-right-radius: 50px !important;    border-bottom-right-radius: 50px !important;
}
#profilexc a {
    color: #969696 !important;
    font-weight:bold;
    font-size:0.9rem;
    padding-left:1vw;
}
#profilexc a:hover{
    color: white !important;
    background-color: #2A9DA5;
    width:80%;
    border-top-right-radius: 50px !important;    border-bottom-right-radius: 50px !important;
}



.Tawk {
    border: 0px none !important;
    padding: 0px !important;
    overflow: visible !important;
    min-width: 0px !important;
    min-height: 0px !important;
    max-width: none !important;
    max-height: none !important;
    width: auto !important;
    height: auto !important;
    position: fixed !important;
    display: block !important;
    margin: 0px !important;
}

.logprofile {
    border: none;
    background-color: #CB3D82;
    color: white;
    margin-right: 30px;
    padding: 5px 20px;
    border-radius: 10px;
}