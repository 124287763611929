


/* .moscou{
    margin-left:12vw
} */



.lbl{
    display:inline-block;
    width:65px;
    height:33px;
    background:#979797;
    border-radius:100px;
    cursor:pointer;
    position:relative;
    justify-content: center;
    
}

.lbl::after{
    content:'';
    display: block;
    width:25px;
    height:25px;
    background:white;
    border-radius:100px;
    position:absolute;
    top:4px;
    left:4px;
}
#switch4:checked+.lbl::after{
left:36px
}
/* #switch4{
    display:none
} */
/* prier boton */





/* 
#switch .letras1.active::after{
   left:unset;
    right: 0;
}
#switch2 .letras0.active::after{
   left:unset;
    right: 0;
} */





.rounded-circleer #icon {
    width:1vmin !important;
}




.donde{
    width:70px;
    margin-top:2px;
    margin:10px 10px;
    display:grid ;
    grid-template-columns: 130px 130px 130px;
    justify-content:space-between;
    align-items: baseline;

}










.CompletarPerfil1 input, select {
    border: hidden !important;
    background: transparent;
  
}
@media (min-width: 100px) and (max-width: 920px) {
    .slidersNetflixReservas2 .sliderNetFlix2 .content .iconNetflix2 {
        max-height: 3rem;
        max-width: 3rem;
    }
    .letrafilters{
        padding-top:2.5vh;
        
        color:black !important;
        
       
    }
    .Filtrar{
        display:flex;
        background-color: white;
        width:1vw !important;
        padding-right:1vw !important;
        border:none
    
    }
    .rounded-circle1{
        border: 5px solid white!important;
        color: rgba(25, 148, 157, 1);
    }
    
    
    
    
    
    .formSteps .objetivo.active #icon {
        border: 5px solid rgba(25, 148, 157, 1) !important;
    }
    
    .formSteps .objetivo.active #text {
        font-weight: 700 !important;
        color: black;
    }
    
    .formSteps .objetivo_text.objetivo_text_small #text {
        font-size: 2.8vmin;
        width: 17vmin;
        height: 17vmin;
        border: 4px solid rgba(234, 234, 234, 1);
    }
    
    .formSteps .preg {
        font-size: 20px;
    }
    
    .formSteps .objetivo_text.active.objetivo_text_small #text {
        border: 4px solid rgba(25, 148, 157, 1) !important;
        color: rgba(25, 148, 157, 1);
    }
    
    .formSteps .objetivo_text #text {
        width: 20vmin;
        height: 20vmin;
        border: 7px solid rgba(234, 234, 234, 1);
        padding-bottom: 7%;
        text-align: center;
        font-size: 3.5vmin;
        line-height: 1;
        color: rgba(124, 124, 124, 1);
        padding-left: 3px;
        padding-right: 3px;
    }
    
    .formSteps .objetivo_text.active #text {
        border: 7px solid rgba(25, 148, 157, 1) !important;
        color: rgba(25, 148, 157, 1);
        font-weight: 700 !important;
    }
    
    .containerCuerpo {
        width: 300px !important;
        height: 390px;
        position: relative;
        top: 0%;
        left: 0%;
        background: transparent linear-gradient(180deg, rgba(42, 157, 165, 1) 0%, rgba(124, 210, 217, 1) 100%) 0% 0% no-repeat padding-box;
        font-size: 1.3rem;
    }
    .rounded-circle1{
        border: 5px solid white!important;
        color: rgba(25, 148, 157, 1);
    }
    .stepsBullets .Bullet {
        display: inline-block;
        width: 30px;
        height: 28px;
        border-radius: 15px;
        border: 1px solid rgb(177, 166, 166);
        color: rgb(109, 109, 109);
        background-color: transparent;
        opacity: 1;
        cursor: pointer;
    }
    
    .stepsBullets .active {
        background-color: #2a9da5;
        color: rgb(255, 255, 255) !important;
    }
    .flecha{
        margin-top:1vh;
        padding-left:4vw !important;
        background-color: white;
        border:none;
        
    }
    .butonfil{
        width:30vw;
        font-size:4vw;
        margin-top:0vh !important;
        margin-bottom:3vh !important;
    }
    .basura{
        background-color: white;
       padding-right:1vw;
       padding-left:24vw;
       display:flex;
        border:none
    
    }
.barraw{
    box-shadow: 0px 10px 10px -6px gray;
    display:flex;
    width:100%;
    height:8vh
}
    #switch2 {
        margin-top:1vh;
        background:white;
        border-radius:100px;
        border:1px solid rgba(124, 124, 124, 1);
        width:72vw;
        height:5vh;
        position: relative;
    
        cursor: pointer;
        display:flex;
        outline:none;
        text-align: right;
        /* justify-content: flex-start; */
        justify-content: flex-end;
    }
    
    #switch2 .letras2{
        width:120px;
        height:0px;
        line-height:4vh;
        display:flex;
        padding-top:0.3vh;
        padding-left:10vw;
        padding-right:14vw;
     
        font-size: 3.5vmin;
        margin-right:4vw;
        justify-content: flex-end;
        color:#9DACBD;
    }
    
    
        #switch2 .letras0{
       
           
            font-size: 3.5vmin;
            padding-top:1.2vh;
            color:white;
            display:flex;
             width:40vw;
            height:4.8vh;
            position: absolute;
            justify-content: center;
            background:#9EACED;
            top:0;
            left:0;
            right: 0;
        border-radius:10vw;
        }
    .formSteps .objetivo #text {
        text-align: center;
        font-size: 3.5vmin;
        letter-spacing: 0px;
        color: rgba(124, 124, 124, 1);
    }
    .moscou{
 
  
        width: 100%;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        align-items: center;
       
    }
    .filtrarimagen{
        padding-top:2vh;
        width:9vw !important;
        display:flex;
        margin-right:2vw !important; 
        /* height:1px !important; */
        padding-left:0vw !important;
        padding-right:1vw !important;
    }
    .centrarfiltrar{
        margin-left:1.3vw !important;
        margin-right:1.3vw !important;
        width:100vw ;
     
    }
    .CompletarPerfil1 {
        background-color: white;
        min-height: 90vh;
    }
    .letrasrealfil{
        display:flex !important;
        font-size:4vw !important;
      margin-left: 0vw !important;
      white-space: nowrap;
    }
    
.letrasrealfil1{
    display:flex !important;
    font-size:4vw !important;
  margin-left: 0vw !important;
  padding-top: 1rem!important; 
   padding-bottom: 1rem!important;
  justify-content: flex-start !important;
  
  text-align:flex-start !important;
}
.basuraimagen{
    width:7vw !important;
    display:flex;
    /* height:1px !important; */
    padding-top:1.5vh;
     margin-left:0vw !important;
    margin-right:2vw !important; 
}
#switch {
    margin-top:1vh;
    background:white;
    border-radius:100px;
    border:1px solid rgba(157,172,189,.3);
    width:72vw;
    height:5vh;
    position: relative;
    cursor: pointer;
    display:flex;
    outline:none;
    text-align: right;
    /* justify-content: flex-start; */
    justify-content: flex-end;
}
#switch .letras1{
  
    font-size: 3.5vmin;
    padding-top:1.2vh;
    color:white;
    display:flex;
     width:40vw;
    height:4.8vh;
    position: absolute;
    justify-content: center;
    background:#9EACED;
    top:0;
    left:0;
    right: 0;
border-radius:10vw;
}
#switch .letras{
    width:120px;
    height:0px;
    line-height:4vh;
    display:flex;
    padding-top:0.3vh;
    padding-left:5vw;
    padding-right:8vw;
   
    font-size: 3.5vmin;
    margin-right:4vw;
    justify-content: flex-end;
    color:#9DACBD;
}
#switch2 {
    margin-top:1vh;
    background:white;
    border-radius:100px;
    border:1px solid rgba(157,172,189,.3);
    width:72vw;
    height:5vh;
    position: relative;

    cursor: pointer;
    display:flex;
    outline:none;
    text-align: right;
    /* justify-content: flex-start; */
    justify-content: flex-end;
}

#switch2 .letras2{
    width:120px;
    height:0px;
    line-height:4vh;
    display:flex;
    padding-top:0.3vh;
    padding-left:10vw;
    padding-right:14vw;
  
    font-size: 3.5vmin;
    margin-right:4vw;
    justify-content: flex-end;
    color:#9DACBD;
}


    #switch2 .letras0{
   
      
        font-size: 3.5vmin;
        padding-top:1.2vh;
        color:white;
        display:flex;
         width:40vw;
        height:4.8vh;
        position: absolute;
        justify-content: center;
        background:#9EACED;
        top:0;
        left:0;
        right: 0;
    border-radius:10vw;
    }
.flechaimagen{
    
  
    /* margin-right:1vw !important;  */
    display:flex;
    width:10vw !important; 
}
.objetivofiller{
     width: 33.3333333333%;
}
.mylgboom{
    margin-top: 0.3rem!important; 
margin-bottom: 0.3rem!important;
}
}

@media (min-width: 921px) and (max-width: 1500px) {
    .butonfil{
        display:none
    }
}


@media (min-width: 1501px) {
    .butonfil{
        width:100% !important;
        max-width:10rem !important;
        position: absolute !important;
font-size:0.8rem !important;
        margin:auto 30% !important;
        bottom: 2.5% !important ;
      
     
    }
}
@media (min-width: 921px) {
    .slidersNetflixReservas2 .sliderNetFlix2 .content .iconNetflix2 {
        max-height: 3rem;
        max-width: 3rem;
    }
    .letrafilters{
        padding-top:10px;
        margin-left:5px;
      
        color:black !important;
        
       
    }
    .Filtrar{
        display:flex;
        background-color: white;
        width:1vw !important;
       margin-left:10px;
     
        border:none
    
    }
    .stepsBullets .Bullet {
        display: inline-block;
        width: 30px;
        height: 28px;
        border-radius: 15px;
        border: 1px solid rgb(177, 166, 166);
        color: rgb(109, 109, 109);
        background-color: transparent;
        opacity: 1;
        cursor: pointer;
    }
    
    .stepsBullets .active {
        background-color: #2a9da5;
        color: rgb(255, 255, 255) !important;
    }
    .merg{
        z-index: 99999999999;
    }
    .flecha{
     
        padding-left:20px !important;
        padding-right:30px;
        background-color: white;
        border:none;
      
    z-index: 99999999 !important
        
    }

    .basura{
        background-color: white;
       padding-right:1px;
       padding-left:10px;
       display:flex;
       margin-left:24%;
        border:none
    
    } 
.barraw{
    box-shadow: 0px 10px 10px -6px gray;
    display:flex;
    width:100%;
    height:40px
}
    .formStepszoom .objetivo #text {
     
        font-size: calc(0.55rem);
  
        color: #9EACED;
    }
    .moscou{
        width: 100%;
        max-width:23vw;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-between;
        align-items: center;
       
    }
    .mylgboom{
            margin-top: 2.0rem!important; 
     margin-bottom: 0.5rem!important;
   }
    .objetivofiller{
        width: 17%;
        margin:0 0.3vw;
        
   }
    .centrarfiltrar .formStepszoom .objetivo #icon{
        width: 6vmin;
        height: 6vmin ;
        background-size:2.9vmin;
        border: 4px solid rgb(158,172,237,0.5);
        background-position: center;
        background-repeat: no-repeat;
        margin:0 auto;
    }
    .filtrarimagen{
   margin-top:10px;
        width:20px !important;
        display:flex;
       
    }
    .flechaimagen{

        /* margin-right:1vw !important;  */
        display:flex;
        width:2rem !important; 
    }
    #switch {
        margin-top:1vh;
        background:white;
        border-radius:100px;
        border:1px solid rgba(157,172,189,.3);
        width:12rem;
        height:1.7rem;
        position: relative;
    
        cursor: pointer;
        display:flex;
        outline:none;
        text-align: right;
     
        justify-content: flex-end;
    }
    #switch .letras1{
        font-weight:bold !important;
        font-size: calc(0.65rem );
        padding-top:7px;
        color:white;
        display:flex;
        width:6rem;
         height:1.7rem;
        position: absolute;
        justify-content: center;
        background:#9EACED;
        top:-1px;
        left:-2px;
        right: 0;
    border-radius:10vw;
    }
    #switch .letras{
        width:5rem;
        height:0px;
        line-height:10px;
        display:flex;
        padding-top:7px;
        padding-left:15px;
        padding-right:15px;
    
        font-size: calc(0.65rem );
        margin-right:8px;
        justify-content: flex-end;
        color:#9DACBD;
    }
    #switch2 {
        margin-top:1vh;
        background:white;
        border-radius:100px;
        border:1px solid rgba(157,172,189,.3);
        width:12rem;
        height:1.7rem;
        position: relative;
    
        cursor: pointer;
        display:flex;
        outline:none;
        text-align: right;
     
        justify-content: flex-end;
    }
    
    #switch2 .letras2{
        width:5rem;
        height:0px;
        line-height:10px;
        display:flex;
        padding-top:7px;
        padding-left:33px;
        padding-right:33px;
    
        font-size: calc(0.65rem );
        margin-right:8px;
        justify-content: flex-end;
        color:#9DACBD;
    }
    
    
        #switch2 .letras0{
            
            font-size: calc(0.65rem );
            padding-top:7px;
            color:white;
            display:flex;
            width:6rem;
             height:1.7rem;
            position: absolute;
            justify-content: center;
            background:#9EACED;
            top:-1px;
            left:-2px;
            right: 0;
        border-radius:10vw;
        }
    .basuraimagen{
        width:20px !important;
        display:flex;
        /* height:1px !important; */
        padding-top:7px;;
         margin-left:0vw !important;
        margin-right:0vw !important; 
    }
.letrasrealfil1{
    display:flex !important;
    font-size: calc(0.75rem);
  margin-left: 0vw !important;
padding-top:0.2rem !important;
padding-bottom:0.2rem !important;
  justify-content: flex-start !important;
  
  text-align:flex-start !important;
}
    .centrarfiltrar{
        margin-left:1.3vw !important;
        margin-right:10.3vw !important;
        height:120vh;
    }
    .letrasrealfil{
      margin-top:1.2vh !important;
            display:flex !important;
            font-size: calc(0.75rem);
          margin-left: 0vw !important;
          white-space: nowrap;
     }
    
    .CompletarPerfil1 {
        background-color: white;
        height: 100%;
        width:100%;
    }
    .formStepszoom{
      
        left:0% !important
    }
    .rounded-circle1{
        border: 5px solid white!important;
        color: rgba(25, 148, 157, 1);
    }
    
    
    
    
    
    .formStepszoom .objetivo.active #icon {
        border: 5px solid #9EACED !important;
    }
    
    .formStepszoom .objetivo.active #text {
        font-weight: bold !important;
        color: #394F65;
    }
    
    .formSteps .objetivo_text.objetivo_text_small #text {
        font-size: 2.8vmin;
        width: 17vmin;
        height: 17vmin;
        border: 4px solid rgba(234, 234, 234, 1);
    }
    
    .formSteps .preg {
        font-size: 20px;
    }
    
    .formSteps .objetivo_text.active.objetivo_text_small #text {
        border: 4px solid rgba(25, 148, 157, 1) !important;
        color: rgba(25, 148, 157, 1);
    }
    
    .formSteps .objetivo_text #text {
        width: 20vmin;
        height: 20vmin;
        border: 7px solid rgba(234, 234, 234, 1);
        padding-bottom: 7%;
        text-align: center;
        font-size: 3.5vmin;
        line-height: 1;
        color: rgba(124, 124, 124, 1);
        padding-left: 3px;
        padding-right: 3px;
    }
    
    .formSteps .objetivo_text.active #text {
        border: 7px solid rgba(25, 148, 157, 1) !important;
        color: rgba(25, 148, 157, 1);
      
    }
    
    .containerCuerpo {
        width: 300px !important;
        height: 390px;
        position: relative;
        top: 0%;
        left: 0%;
        background: transparent linear-gradient(180deg, rgba(42, 157, 165, 1) 0%, rgba(124, 210, 217, 1) 100%) 0% 0% no-repeat padding-box;
        font-size: 1.3rem;
    }
}



.photoProfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.slider_form {
    padding-top: 2vh;
}

.labelPhoto {
    height: 20vh;
    width: 20vh;
    border: 2px solid gray;
    background-position: center 60%;
    background-repeat: no-repeat;
    background-size: 184%;
    position: relative;
}





.formSteps {
    width: 80vw;
}

.dateBorn {
    color: #7B7B7B !important;
    text-transform: uppercase;
}

.formSteps .objetivo, .objetivo_text {
    padding-bottom: 7%;
    display: grid;
    justify-items: center;
}

.formSteps .objetivo #icon {
    width: 30vmin;
    height: 30vmin;
    border: 5px solid rgba(234, 234, 234, 1);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 180%;
}

.formSteps .objetivo.objetivos_small #icon {
    width: 18vw;
    height: 18vw;
    background-size: 60%;
    border: 5px solid rgba(234, 234, 234, 1);
}
.melo{}




.containerCuerpo .modalInfoContenido .icon img {
    height: 7rem;
}

.changeView {
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.cls-1 {
    fill: #fff;
}

.dolor {
    width: 20px;
    height: 20px;
    /* border: 3px solid rgba(42, 157, 165, 1); */
    background-color: transparent;
    position: absolute;
}

.step6 {
    width: 80vw;
}

.preguntas {
    font-size: calc(0.5em + 1vw);
}

.btnInfo {
    left: 55%;
    z-index: 1;
    width: 2rem;
}

.tooltipPhoto {
    left: 20% !important;
    right: 5% !important;
    border-radius: 20px !important;
    opacity: 1 !important;
    font-size: 3vmin !important;
}

.footerStep6 {
    background-color: black;
    cursor: pointer;
}

.footerStep6 .text {
    color: white;
    font-weight: bold;
    justify-content: center !important;
}
.slidersNetflixReservas .sliderNetFlix {
    min-height: 150px;
    max-width: 30vw !important;
    width: 100% !important;
    font-size: 0.7rem;
    text-transform: capitalize;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    word-wrap: break-word;
    cursor: pointer;
}



/* @media (min-width: 481px) {
    .slidersNetflixReservas2 .sliderNetFlix2 {
        min-height: 20vh;
        min-width:30vw !important;
        width: 100% !important;
        font-size: 1rem;
    }
} */

.slidersNetflixReservas2 .sliderNetFlix2 .content {
    position: absolute;
    left: 50%;
    bottom: 1vh;
    width: 87%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 1;
    font-weight: bold;
    color: white;
    opacity: 0.9;
}
.slidersNetflixReservas .sliderNetFlix .content {
    position: absolute;
    left: 0%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 1;
    font-weight: bold;
    color: white;
    opacity: 0.9;
}

.slidersNetflixReservas2 .sliderNetFlix2::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.2;
    border-radius:15px;
}
.slidersNetflixReservas .sliderNetFlix::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: black; */
    opacity: 0.5;
}

.slidersNetflixReservas2 .sliderNetFlix2:hover::before {
    opacity: 0;
}
.slidersNetflixReservas .sliderNetFlix:hover::before {
    opacity: 0;
}



.slidersNetflixReservas .sliderNetFlix .content .iconNetflix {
    max-height: 50px;
    max-width: 50px;
}

.slidersReservas .descr {
    border-left: 5px solid #268E95;
}

/* @media (min-width: 1023px) {
    .formSteps .objetivo #icon {
        width: 20vmin !important;
        height: 20vmin !important;
    }
} */

.FaltanItems {
    text-align: left !important;
}
