
@media (min-width: 100px) and (max-width:  920px) {

    .itemReserva .container_name1 {
        font-size: 1.4rem;
        width:35vw;


text-align:left;
        
        font-weight:700;
        text-transform: lowercase;
       
    }
    .itemReserva  .container_name1:first-letter { text-transform: uppercase }
    .posicionbotonentrenamientos1{
        display:none;
     
    
        
    }
    .newBtnReserva .btnNuevaReserva {
        width: max-content;
        background:#CB3D82;
        padding: 0.35rem 2rem;
        font-size: calc(0.7rem + 0.8vw) !important;
    }
    .bot .type img {
        height: 8vmin;
    }
    
    .bot .type {
        width: 20%;
        text-align: center;
        font-size: 75%;
        background-color:  rgba(123,134,250,0.6);
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    
    .bot .date {
        background-color:  rgba(158,172,237,0.6);
      
        width: 60%;
        text-transform: capitalize;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.88rem;
    }
    
    .bot .date .break {
        flex-basis: 100%;
        height: 0;
    }
    
    .bot .ver {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .sliderPublicidad .sliderimg img {
        width: 100%;
       
    }
    .Inicio .invertido:not(.btnMOB) {
        background-color: #2A9DA5;
     
    }
    .nuevosentrenamientos2{
        display:none
    }
    .nuevosentrenamientos{
        display:none
    }
    .imagenabajoblack{
        display:none
    }
    .imagenarribablack{
        display:none
    }
    .containerInicio .title {
        color: #394F65;
        font-weight: bold;
        font-size: 5vmin;
        text-align: center;
    }
 
    
    .containerInicio .title.creditos {
        font-size: calc(1rem + 3vw);
        text-align: center;
        
    }
    .listaReservas {
        margin-top: 1vh;
        margin-bottom: 2vh;
     
    }
    
    .listaReservas .itemReserva {
        margin-top: 2vh;
        height: 20vh;
        min-height: 150px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        font-size: 3.3vmin;
        line-height: 1;
        
    }
    
    .listaReservas .itemReserva .bot {
        height: 40%;
    }
    
    .listaReservas .itemReserva .bg {
        height: 60%;
        background-color: black;
        opacity: 0.2;
        
        
    }
    
    .listaReservas .itemReserva .bg .text {
        opacity: 1;
        position: relative;
        top: 30%;
        left: 5%;
        width: 90%;
        display: block;
        
    }
    
    .itemReserva .container_name {
        font-size: 1.6rem;
        font-weight:700;
        text-transform: capitalize;
       
    }
    
    .bot {
        background-color: transparent;
        display: flex;
        font-family:WorkSans-Bold !important;
    }
.containerInicio {
    min-height: 80vh;

    margin-bottom: 75px;
   
    
    width:100%;
    /*Tawk*/
}
.containerInicio .misCreditos {
    text-align: center;
    font-weight: bold;
    font-size: 15vw !important;
    color: #9DACBD;
    line-height: 1;
}

.containerInicio .vencenCreditos {
    text-align: center;
    font-size: 3.5vmin;
    font-weight: normal;
    color: #394F65;
    margin-bottom: 4%;
}
.pepitasnegras{
    display:none
}
.bulletsPublicidad .swiper-pagination-bullet {
    width: 3vmin;
    height: 3vmin;
    opacity:0.3;
    background-color: #9EACED;
}
.containerGraficasweb{
    display:none
}
.listaReservasimagenreservar{
    display:none
}
.bot .verlo {
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

}
.CancelarReserva .msg{
    color: #969696;
    text-align: left;
    font-weight:400;
    line-height: 1;
    font-size: calc(0.5rem + 2vw);
    margin: 2vh auto 2vh auto;
}

}
@media (min-width: 921px){

    .itemReserva .container_name1 {
        font-size: 1.7vw;
     margin:0 2vw 0 0 !important;
text-align:left;
        
        font-weight:700;
        text-transform: lowercase;
       
    }
  

    .CancelarReserva .msg{
        color: #394F65;
        text-align: left;
        font-weight:400;
        line-height: 1;
        font-size:0.9vw;
        width:60%;
        margin: 1vh auto 1vh auto;
    }
.CancelarReserva input::placeholder{
    font-weight:light
}
   
    .lineunitreser{
        border:1px solid white;
        margin:1vh auto;
    }
    .horasgrandes {
font-size:2.5vw !important;
    }
    .date .content span {
font-size:0.68vw;
    }
    .cajaazul{
        display:flex;
        padding:0.7vw;
    }
     .btnNuevaReserva {
        width: 10vw !important;
        padding: 0.35rem 2rem;
        font-size: calc(0.8rem ) !important;
    }
     .btnNuevaReservabot {
        width: 10vw !important;
        padding: 0.35rem 2rem;
        font-size: calc(0.8vw ) !important;
    }
    .listaReservasimagenreservar{
        cursor:pointer;
        margin-top:2vh !important;
        width:33.333% !important;
        height:40vh;
    }
    .containerGraficas .msg{
text-align: center;
margin: 10px 28.7vw;



    }
    .perfilgraf .containerGraficas .msg{
        text-align: left;
        margin: 10px 7vw;
        
        
        
            }
    .perfilgraf .containerGraficas .msginfo_genre{
        text-align: left;
       
        
        
        
            }
            .containerGraficas .semana1{
                width:100% !important;
                margin-top:1vh;
                padding: 1vh 29vw !important;
                
                
                
                    }

    .containerGraficas .semana{
width:100% !important;
margin-top:1vh;
padding: 1vh 6vw;



    }
    .letrasdatosentrea{
        text-align:center;
        font-size:5vw;
        font-weight:bold;
        color:#9DACBD;
    
    }
    .letrasdatosentrea1{
        font-size:0.7rem;
        text-align:center;
        font-weight:bold;
    text-transform:uppercase;
        color:#394F65;
    
    }
    .letrasdatosentrea2{
        text-align:center;
        margin-bottom:4px;
        font-size:0.7rem;
        font-weight:400;
    text-transform:capitalize;
        color:#6E6E6E;
    
    }
    .letrasdatosentrea3{
        justify-content: center;
              
    
    }
    .bot {
        background-color: #9EACED;
        display: row;
        font-weight:bold;
    
    }
    .bot .type img {
        margin:1vh 1vw 0.2vh 1vw;
        height: 5vh;
    }
    .bot .typein img {
        margin-top:10px;
        height: 5vh;
    }
   
    .bot .type {
      
        margin-left: 22%;
        margin-right: 2%;
        text-align: center;
        font-size: 75%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    .bot .typein {
      
    
        margin-right: 2%;
        text-align: center;
        font-size: 75%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    .bot .typeEntrenador {
      
        margin-left: 0% !important;
       
    }
    
    .bot .date {
       
        width: 62%;
        margin-left: 21%;
     
        text-transform: capitalize;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1vw;
    }
    
    .bot .date .break {
        margin-top:20px;
        flex-basis: 100%;
        height: 0;
    }
    
    .bot .ver {
     position: absolute !important;
        padding:2.5vh 0;
        top:32vh;
        text-align:center;
        justify-content: center;
     font-size:1.5vw;
        height:20%;
        background-color: #7B86FA;
        width: 100%;
        margin-left:-0.4vw;
   
    }
    .bot .verlo {
        width: 21%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
   
    }
    .tusClasesTitulo{
       display:none;
    }
    .cajaDeClases{

    }
    .sliderPublicidad  {
        margin:auto 6vw 0 14vw !important; 
     
     
    }
    .sliderPublicidad .sliderimg img {
        width: 100%;
        height:100%;
        max-height:16vh;
        background-size:cover !important;
    }
    .flexlista{
        display:flex;
    }
    .Inicio .invertido:not(.btnMOB) {
        background-color: white;
        position:relative;
      
        height:12vh;
               left:50%
    }
    .bulletsPublicidad .swiper-pagination-bullet {
        width: 1vmin !important;
        height: 1vmin !important;
    }
    .bulletsVideos .swiper-pagination-bullet {
        width: 2vmin !important;
        height: 2vmin !important;
        background-color:transparent;
        border:1px solid #CB3D82;
        opacity: 1;
    }
    .bulletsVideos{
        position: absolute;
        bottom:10% !important;
        left:50% !important;
        z-index:1;
    }
    .posicionbotonentrenamientos1{
        display:flex;
        margin-top:4vh;
        
        justify-content: flex-end;
       width:93%; 
     
    
        
    }
    .posicionbotonentrenamientos{
      
        position:absolute !important;
        z-index:99999;
      right:55%;
      height:auto;
      top:73vh;
      display:flex;
          max-width:20vw !important;
               font-weight: bold;
               font-size: 5vmin;
               text-align: center;
    }
    .contenedorGaleria{
        position:absolute;
        display:flex;
        font-weight:bold;
        top:30%;
        margin-left:17vw;
        margin-right:10vw;
        width:100%;
     height:0%;
        
       
        
        z-index:9999999;
        
       
        
    }
    .contenedorGaleria .iconNetflixnew1{
        position:relative;
        top:70%;
      left:42%;
      
        height: 6rem;
        width: 6rem;
       
    }
    .contenedorGaleria .iconNetflixnew2{
        position:relative;
        top:70%;
      left:42%;
      
      height: 6rem;
      width: 6rem;
       
    }
    .contenedorGaleria .iconNetflixnew3{
        position:relative;
        top:55%;
      left:42%;
      
        height: 3rem;
        width: 3rem;
       
    }
    .contenedorGaleria .iconNetflixnew4{
        position:relative;
        top:55%;
      left:42%;
      
        height: 3rem;
        width: 3rem;
       
    }
    .contenedorGaleria .img1{
        width:32%;
   
        height:70vh;
       font-size: 0.7rem;
       text-transform: capitalize;
       background-position: center;
       background-size: cover;
       background-repeat: no-repeat;
       word-wrap: break-word;
    
       filter: grayscale(70%);
     
   
    }
    .contenedorGaleria .img1:hover{
        filter: none;
    
        
 
    }
    .contenedorGaleria .img2{
       width:23%;
       height:70vh;
       font-size: 0.7rem;
       text-transform: capitalize;
       background-position: center;
       background-size: cover;
       background-repeat: no-repeat;
       word-wrap: break-word;
     
       filter: grayscale(100%);
      
    }
    .contenedorGaleria .img2:hover{
        filter: none;
 
    }
    .contenedorGaleria .img3{
       width:100%;
       padding:2px 0;
       height:35vh;
       font-size: 0.7rem;
       text-transform: capitalize;
       background-position: inherit;
       background-size: cover;
       background-repeat: no-repeat;
       word-wrap: break-word;
       
       filter: grayscale(100%);
    }
    .contenedorGaleria .img3:hover{
        filter: none;
 
    }
    .contenedorGaleria .imagenconte{
        width:20%
    }
    .contenedorGaleria .img4{
        font-size: 0.7rem;
       text-transform: capitalize;
       background-position: bottom;
       background-size: cover;
       background-repeat: no-repeat;
       word-wrap: break-word;
;
        padding:2px 0;
       width:100%;
       height:35vh;
       filter: grayscale(90%);
      
    }
    .contenedorGaleria .img4:hover{
        filter: none;
 
    }
    .contenedorGaleria .letraswhite1{
        font-size: 1.3vw;
        top: 65%;
        text-transform: uppercase;
      
        color: #fff;
        text-align: center;
        font-style: italic;
        padding: 1.5%;
        height: 8%;
        width: 100%;
       
    }
    .contenedorGaleria .letraswhite1 {
        background: rgb(158,172,237,0.7);
        position:relative;
       
       
        
    }
   


    .contenedorGaleria .letraswhite2{
        font-size:1.3vw;
        position:absolute;
        top:65%;
 
        text-transform:uppercase;
      
        background-color: black;
        color: white;
        text-align:center;
        font-style:italic;
        padding: 1.5% ;
        height: 8%;
        width:100%;
       opacity:0.5;
       
    }
    .contenedorGaleria .letraswhite2 {
        background-color: rgb(158,172,237,0.7);
        position:relative;
        opacity:0.7
    }
    .contenedorGaleria .letraswhite3 ,.letraswhite4{
        font-size:1.0vw;
        position:absolute;
        top:50%;
        text-transform:uppercase;
    
        text-align:center;
        font-style:italic;
        background-color: black;
        color: white;
        padding: 1.5% ;
        height: 10%;
        width:100%;
       opacity:0.5;
       
    }
   
    .contenedorGaleria .letraswhite3 ,.letraswhite4{
        background-color: rgb(158,172,237,0.7);
        position:relative;
        opacity:0.7
    }
    .contenedorGaleria img{
        ;
        width:200px;
   padding:5px;
        height:300px;
        margin-bottom:30px;
        object-fit:cover;
       
    }
  

    .nuevosentrenamientos .title{
     position:absolute;
     z-index:99999;
   left:17%;
   top:10%;
     
            color: #394F65;
            font-weight: bold;
            font-size: 5vmin;
            text-align: center;
    
    }

    .nuevosentrenamientos2 .title{
     position:relative;;
  

     
            color: #394F65;
            font-weight: bold;
            font-size: 4vmin;
           
            margin-left:15% !important;
    
    }
    .nuevosentrenamientos .titledebajo {
     position:absolute;
     z-index:99999;
   left:17%;
   top:20%;
   width:60%;
     
            color: white;
            font-weight: bold;
            font-size: 2vmin;
            text-align: left;
    
    }
    .rodarpues{
        overflow-y: scroll !important;
        height: calc(100vh - 70px);
        overflow-x: hidden;
        
    }
    .pepitasnegras{
    height:120vh;
        width:100vw;
        opacity:0.8;
   
        position:relative;;
        top:0px;;
    }
    .colorblack{
      
        
        display: inline-block;
     position: relative;
    
        width:100%;
        height:100%;
    }
    .imagenabajoblack{
    position:relative;
       top:-35px;
    }
    .imagenarribablack{
        position:relative;
        top:10px;
    }
    .listaReservas .itemReserva {
        height: 50vh !important;
    }
    .listaReservas .itemReserva .bg {
        height: 100% !important;
    }
    .listaReservas .itemReserva .bg .text {
        top: 65% !important;
    }
    .listaReservas .itemReserva .bot {
        position:relative;;
        top:-100%;
        left:70%;
     
        height: 100% !important;
        width:8vw;
    }
    .listaReservas .itemReserva .botija {
        position:relative;;
        top:0% !important;
        left:70%;
     
        height: 100% !important;
        width:8vw;
    }
    .containerInicio .misCreditos {
        text-align: center;
        font-weight: bold;
        font-size: 6vmin !important;
        color: #6E6E6E;
        line-height: 1;
    }
    
    .containerInicio .vencenCreditos {
        text-align: center;
        font-size: 1.5vmin;
        font-weight: normal;
        color: #2A9DA5;
        margin-bottom: 4%;
    }

    .containerInicio .totalacomode {
        position:relative;
        width:62%;
        display:flex;
        margin-left:14%;
     
    }
    .containerInicio .title {
        color: #2A9DA5;
        font-weight: bold;
        font-size: 2vmin;
        text-align: center;
    }
    .completarelpor{
        width: 12%!important
    }
    .containerInicio .titleladocreditos {
        color: #394F65;
        width:80%;
        font-weight: bold;
       
      margin-top:2vh;
      margin-bottom:2vh;
        font-size: 2.6vw;
        text-align: left;
    }
    
    .containerInicio .title.creditos {
        font-size: calc(2.4vmin);
        text-align: center;
        width: 10vw;
    }
    .listaReservas {
    
        position:relative;
        left:4%;
      
        width: 80%;
     
    }
    
    .listaReservas .itemReserva {
        margin-top: 2vh;
        background-repeat: no-repeat;
        height: 40vh !important;
        min-height: 180px;
        background-size: contain;
        
        color: white;
        font-size: 3.3vmin;
        line-height: 1;
        
    }
    
    .listaReservas .itemReserva .bot {
        height: 40%;
    
    }
  
    
    .listaReservas .itemReserva .bg {
        height: 100%;
        background-color: black;
        opacity: 0.2; 
        
        
    }
    .listaReservas .itemReserva .bginicio {
 
    
position:absolute;
width:92%;
        height: 40vh;
        background-color: black;
        opacity: 0.2; 
        
        
    }
    .listaReservas .itemReserva .bginicio:hover{
        
        opacity: 0.1 !important;
        
        
    }
    .listaReservas .itemReserva .bg:hover{
        
        opacity: 0.1 !important;
        
        
    }
    
    .listaReservas .itemReserva .bg .text {
        opacity: 1;
        position: relative;
        top: 30%;
        z-index:1 !important;
        left: 5%;
        width: 90%;
      
        
    }
    .listaReservas .itemReserva .bg .text1 {
        opacity: 1;
        position: absolute;
        top: 70%;
        text-align:left;
     font-size:1rem !important;
        left: 5%;
     
      
        
    }
    
    .itemReserva .container_name {
        font-size: 1rem;
        font-weight:700;
        text-transform:capitalize;
       
    }

    
 
    .containerInicio {
        min-height: 80vh;
        
        margin-bottom: 4vh;
       position:relative;
       left:10px;
        
        width:100% !important;
        /*Tawk*/
    }  

}




.bulletsPublicidad .swiper-pagination-bullet.active {
    background-color: #9EACED;
    opacity: 1;
}
.bulletsVideos .swiper-pagination-bullet.active {
    background-color: #CB3D82;
    opacity: 1;
}



.bulletsPublicidad {
    display: flex;
    justify-content: center;
    margin-top: 1vh;
    margin-bottom: 2vh;
}





.newBtnReserva .btnCalendar {
    width: 1.5rem;
}



.newBtnReserva {
   
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
}



@media (min-width: 481px) {
    .bot .type {
        
        font-size: 0.8vw !important;
        
    }
    .bot .typein {
        
        font-size: 0.8vw !important;
        
    }
    .bot .type {
        
        font-size: 0.8vw !important;
        
    }
}







.Inicio .invertido *:not(.btnMOB) {
    color: #9DACBD ;
}