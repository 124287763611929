.footer {
    background-color: #262626;
    width: 100%;
    font-size: 0.75rem;
    padding-top: 2rem;
    padding-bottom: 30px;
    color: #fff;
}

.footer h4 {
    font-size: 1rem;
    color: #fff;
    border-bottom: 1px solid #999;
    margin-bottom: 0.8rem;
    padding-bottom: 0.4rem;
}

.footer a {
    color: #fff;
}

.footer h5 {
    border-bottom: 1px solid #333;
    margin-bottom: 0.6rem;
    padding: 0.4rem 0;
}

.footer ul {
    list-style: none;
}

.navFooter {
    border:1px solid gray;
    width: 100%;
    position: sticky !important;
    bottom: 0;
    background-color: white;
    z-index: 2000000001;
    padding: 0 !important;
    padding-bottom: 3px !important;
    padding-left: 2% !important;
    padding-right: 2% !important;

}

/* @media (min-width: 1440px) {
    .navFooter {
        width: 100%;
    }
    .navbar {
        height: 10vh;
    }
    .navHeader {
        max-height: 70px;
    }
} */

.navFooter.entrenador div {
    justify-content: space-evenly;
}

.iconFooter {
    margin-top: 10px;
    width: 35px;
   
    
}


.navFooter a .text {
    color: #9DACBD;
    font-weight: bold;
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
}
.navFooter a .text_change_color {
    color: #D86DA1;
    font-weight: bold;
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
}