

.colornuevo::placeholder{

    font-size:1rem !important;
}
.telefonoroto ::placeholder{
    font-size:1rem
}





.perfil input:disabled {
    background-color: transparent !important;

   
}

.entrenadorPerfil .perfil .titulo {
    text-align: center;
    color: #394F65;
    font-weight: bold;
    font-size: 1.3rem;
    margin-top: 2vh;
    margin-bottom: 2vh;
}





.entrenadorPerfil .perfil .advertencia .imagen img {
    width: 2rem;
}







.accordion .accordion-item .accordion-header {
    border-bottom: 1px solid #E5E9ED;
}



.accordion .accordion-item .accordion-header .accordion-button:after {
    background-image: none !important;
}

.sesiones .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
    background-image: url("../assets/icons/expanded.png");
    transform: rotate(180deg) scale(1, -1);
    transition: transform .5s ease;
}


.sesiones .accordion .accordion-item .accordion-header .accordion-button:before {
    background-image: url("../assets/icons/collapse.png");
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
    content: "";
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .5s ease-in-out;
}


@media (min-width: 100px) and (max-width: 920px) {
    .verCuentaContainer .iconoContainer img {
        background-color: white;
    }
    .verCuentaContainer .subtitle {
        color:#394F65;
        font-weight: bold;
        line-height: 1;
        margin-top: 1rem;
     
    }
    
    .verCuentaContainer .orderList {
        font-size: calc(0.51rem + 1vw);
        text-align: left;
        margin-top: 1rem;
        list-style: decimal inside;
    }
    .itemSesionesTabla {
        line-height: 1.2;
   
        color: #394F65;
    }
    .stepsBulletssubircancel{
        margin-top:-3vh;
        margin-bottom:0vh !important;
    }
    .negritod{
        background-color: black;
        opacity: 0.2;
        display:inline-flex;
        height: 100%;
        position: absolute;
        width: 100%;
    }
    .negritod:hover{   opacity: 0.1}
    .contentDisciplina .content {
        color: white;
        text-transform: capitalize;
        font-weight: bold;
       position:relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .colornuevotrainerblack{
        color:#9DACBD !important;
     
    }
    .accordion .accordion-item .accordion-header .accordion-button {
        color: #394F65;
        font-weight: bold;

        padding: auto 1rem !important
       
      
    }
    .contentDisciplina .content .name {
        width: 50%;
        line-height: 0;
    }
    .contentDisciplina .content img {
        width: 5rem;
    }
    .entrenadorPerfil .perfil .advertencia .tel {
        font-weight: bold;
        color: #394F65;
    }
    .entrenadorPerfil .perfil .advertencia .texto {
        line-height: 1;
        font-size: 0.8rem;
        margin-bottom: 1rem;
        color: #9DACBD;
    }
    .entrenadorPerfil .perfil .advertencia .imagen {
        background-color:#CB3D82;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-bottom: 1rem;
    }
    .entrenadorPerfil .perfil .advertencia {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 90%;
    }
    .entrenadorPerfil, .verCuentaContainer {
        text-align: center;
        width: 95vw;
        min-height: calc(83vh);
        margin: auto;
    }
    #accordionSesiones .info {
        color: #262626;
        line-height: 1;
        font-size: 1rem;
        letter-spacing: 0;
    }
    .headerTable th:nth-child(odd):after {
        content: '';
        position: absolute;
        right: 0px;
        top: 25%;
        height: 50%;
        border-right: 3px solid white;
    }
    .headerTable th {
        color: white;
        font-weight: bolder;
        width: 50%;
        letter-spacing: 0;
        font-size: 1rem;
    }
    .disciplinasE .texto {
        line-height: 1;
        font-size: 0.8rem;
        padding:20px;
        color:#9DACBD
    }
    .entrenadorPerfil .title {
        color: #394F65;
        font-weight: bold;
        font-size: 1.4rem;
        margin-top: 1rem;
    }
    .disciplinasE .BtnGenerar {
        font-size: 0.8rem;
        padding: 12px 30px;
        width: 225px;
    }
    .disciplinasE .totalIngresos {
        color: #9DACBD;
        font-weight: bold;
        font-size: 3rem;
    }
    .disciplinasE .detail {
        color: #394F65;
        font-weight: bold;
        font-size: 0.8rem;
        margin: 1rem auto;
    }
    .bitacoraForm.Medida .containerCuerpo {
        width: 100% !important;
        height: 100% !important;
    }
    .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
        background-image: url("../assets/icons/expanded.png");
        transform: rotate(180deg) scale(1, -1);
        transition: transform .5s ease;
    }
    .glosario .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
        background-image: url("../assets/icons/expanded.png") !important;
        transform: rotate(180deg) scale(1, -1);
        transition: transform .5s ease;
    }

    .glosario .accordion .accordion-item .accordion-header .accordion-button:after {
        background-image: url("../assets/icons/collapse.png") !important;
        width: 1.25rem;
        height: 1.25rem;
        content: "";
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .5s ease-in-out;
    }
    .cuadroborder{
        border-top:none !important;
        border-left:none !important;
        border-right:none !important;
        border-bottom:1px solid #BEBEBE
    }
    .reservafull{
      
        margin-top: 0vh !important;
    

  
margin:none;
padding:none;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        font-size: 3.3vmin;
        line-height: 1;
        background-size:cover !important
    }
    .disciplinasE{
      
        
        /* height:100vh !important; */
    }
    .sesiones{
        height:auto !important;
    }
}




@media (min-width: 921px)  {
.iconoContainer{
display:flex;
}
    .verCuentaContainer .iconoContainer img {
        background-color: #3B3B3B;
        justify-content: center;
    
   
         align-items: center;
    }
    .verCuentaContainer .subtitle {
        color: black;
        font-weight: bold;
        line-height: 1;
        text-align:center;
        margin-top: 1rem;
    }
    
    .verCuentaContainer .orderList {
        font-size: calc(0.51rem + 0.5vw);
        text-align: left;
        margin-top: 1rem;
        list-style: decimal inside;
    }
 .tablaIngresosweb{
    margin: 0% 10% 2% 11%;
 }
    .negritod{
        background-color: black;
        opacity: 0.2;
     
        height: 100%;
        position: relative;
        width: 100%;
    }
    .negritod:hover{   opacity: 0.1}
    .itemSesionesTabla {
        line-height: 1.2;
       font-size:0.7vw;
        color: #394F65;
    }
    .colornuevotrainerblack{
        color:#9DACBD !important;
        font-size:1vw !important;
    }
    .letrasvideoarribaa1{
        font-size:0.7vw;
        width:30vw;
        font-weight:400;
         position: relative   !important;
        top:45vh;
           left:5vw;
        
       }
    .contentDisciplina .content {
        color: white;
        text-transform: capitalize;
        font-weight: bold;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .accordion .accordion-item .accordion-header .accordion-button {
        color: #394F65;
        font-weight: bold;
        font-size:0.8vw !important
    }
    .tamañograndeingresos{
        width:97.5vw;
    }
    .contentDisciplina .content .name {
        position:absolute;
      margin-top:20vh;
      width:20vw;
      text-align:center;
      margin-left:4vw;
      margin-right:4vw;
      font-size:1.5vw;
        line-height: 1;
    }
    .contentDisciplina .content img {
        width: 6vw;
        padding:5vh 0; 
        position:absolute;
    }
     .advertencia .tel {
        font-weight: bold;
        color: #394F65;
    }
    .advertencia .texto {
        line-height: 1;
        font-size: 0.8vw;
        margin-bottom: 1rem;
        color: #9DACBD;
        margin: 3.5vh 1vw 6vh 0vw;
    }
    .advertencia .imagen {
        background-color:#CB3D82;
        width: 4vw;
        height: 4vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2vh 1vw 1vh 7vw;
       
    }
    .advertencia .imagen img {
    width: 3.0vw !important;
       
    }
    .disciplinasE{
        overflow-y: auto;
        height:100%;
        max-height:calc(57vh - 9rem);
        width:100%
    }
    .disciplinasEhabi{
        overflow-y: auto;
        height: calc(85vh - 145px);
        width:100%
    }
    .advertencia {
        text-align: left;
     position:relative;
     left:0%;
     height:30vh;
        width: 100vw;
        margin:1vh 0 3vh 0 !important ;
    padding:0vh 10vw  3.7vh 10vw;
        background-color:#F7F7F7
    }
    .acomodartextinresos{
        margin: auto 0%;
        color:#9DACBD;
        padding: 0 20vw 0 14.5% !important;
        font-size:1vw;
    }
    .entrenadorPerfil, .verCuentaContainer {
        text-align: left;
    
        width: 100%;
        min-height: calc(10vh - 1rem);
        margin: auto;
    }
    .colordisciplinasE{
        background-color: #F7F7F7;
      
    }

    .imagenw1{
        width:10px;
        position:relative;
        left:-2.5vw;
        top:30%;
        padding:5px !important;
      
    display:none;
    }
    .infodet{
        font-size: 0.8vw !important;
        color: #9DACBD !important;
        max-width: 80vw !important;
        margin-top: 0vh;
        margin-right: 3vw;
      text-align:left;
    }
    #accordionSesiones  {
    cursor:pointer
    }
    #accordionSesiones .info {
        color: #262626;
        text-align:left;
        line-height: 1;
        font-size: 1rem;
        letter-spacing: 0;
    }
    #accordionSesiones .info9 {
        color: #394F65;
        text-align:left;
        line-height: 1;
        font-weight:bold;
        font-size: 1.0vw;
        letter-spacing: 0;
    }
  .unoporuno:hover{
      background-color:rgb(247, 247, 247)
  }
    .headerTable th:nth-child(odd):after {
        content: '';
        position: absolute;
        right: 0px;
        top: 25%;
        height: 50%;
        
    }
    .headerTable th {
        color: white;
        font-weight: bolder;
        width: 33.333%;
        letter-spacing: 0;
        font-size: 1.5rem;
    }
    .entrenadorPerfil .title {
        color: #394F65;
        font-weight: bold;
        font-size: 1.4rem;
        margin-top: 1rem;
        text-align:center;
        
    }
    .disciplinasE .BtnGenerar {

        font-size: 0.8rem;
        margin: 5vh 10vw !important;
        padding: 12px 30px;
        width: 340px;
    }
    .disciplinasE .totalIngresos {
        margin: 0.5vh 15vw;
        color: #9DACBD;
        font-weight: bold;
        font-size: 3rem;
        text-align:left;
    }
    .disciplinasE .detail {
        margin: auto 15vw !important;
        color: #394F65;
        font-weight: bold;
        font-size: 0.6rem;
        margin: 1rem auto;
    }
    .ingresoscolor{
        display:flex;
      
        background-color:#F7F7F7
    }
    .widthtelefono{
        width:95% !important;
    }
    .btnInfoEntre{
        left: 13vw;
        z-index: 2 !important;
        top: 0px;
        width: 3vw;
        max-height: 4rem !important;
        margin-top: 1.4vh !important;
    }
    .sesiones{
        width:80vw;
        margin:2vh 13vw;
    }
    .reservafull{

        margin-top: 2vh;
  height:550px !important;
  
margin:none;
padding:none;
        background-repeat: no-repeat;
        background-position: top;
        color: white;
        font-size: 3.3vmin;
        line-height: 1;
        background-size:cover !important
    }
  .containerweb7{
      width:50%;
  }
    .entrenadorPerfil .perfil {
        margin: 5vh 7vw 0vh 15vw;
    }
    .labelPhotoentre {
     
        box-sizing: border-box;
        height: 15vw;
   margin:10px;
        width: 15vw;
        border: 1px solid gray;
        background-repeat: no-repeat;

        background-size: 100%;
        position: relative;
     
    }
    .contetfototoal{

   
    border-radius: 100%;
    border: 10px solid #9EACED;
    }
    .cuadroborder{
        border-top:none !important;
        border-left:none !important;
        border-right:none !important;
        border-bottom:1px solid #BEBEBE
    }
    .bitacoraForm.Medida .containerCuerpo {
        width: 90% !important;
        height: 90% !important;
        
   
    }
    .bitacoraForm.Medida .containerCuerpo_ti {
      
        height: 100% !important;
   
    }
    .anchogenero{
        width:33.333% !important
    }
  
    .letrasvideoarribaa{
     
      position: absolute   !important;
        top:30% !important;
        left:60%;

        font-size:1rem !important;
    }
    .letrasvideoarribaa div{
     
  font-weight:bold;
      
        font-size:1rem !important;
    }
 
    .letrasvideoarribaa1 div{
     
  
        font-family:WorkSans-Regular !important;
        font-size:1rem !important;
    }
    .glosario .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
       
        transform: rotate(360deg) scale(1, -1);
        transition: transform .5s ease;
    }
    .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
      
        transform: rotate(180deg) scale(1, -1);
        transition: transform .5s ease;
    }
  
    .glosariopreguntas .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
        background-image: url("../assets/icons/expanded.png") !important;
        transform: rotate(180deg) scale(1, -1);
        transition: transform .5s ease;
    }
    .glosariopreguntas .accordion .accordion-item .accordion-header .accordion-button:after {
        background-image: url("../assets/icons/collapse_derecha.png") !important;
        width: 1.25rem;
        height: 1.25rem;
        content: "";
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .5s ease-in-out;
    }
    .glosarioVideo .accordion .accordion-item .accordion-header .accordion-button:after {
        display:none !important;
        background-image: url("../../Perfil/iconosinfo/felchaverde.png") !important;
        width: 1.2vw;
        height: 1.2vw;
        content:  attr(data-content);
        background-repeat: no-repeat;
        background-size: 0.7vw;
        transition: transform .5s ease-in-out;
    }
}

.tableStyledOdd .table-row:nth-child(2n+1) {
    background-color: rgb(229,233,237,0.2);
  
}
.tableStyledOddingresos .table-row:nth-child(4n+1) {
    background-color: rgb(199,199,199,0.2);
}



.itemSesionesTabla .date {
    font-weight: bold;
}





.contentDisciplina {
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 1rem !important;
}





.headerTable {
    background-size: cover;
    background-position: center;
}



.headerTable th:nth-child(odd) {
    position: relative;
}





.verCuentaContainer .iconoContainer {
    justify-content: center;
    
 
}




.verCuentaContainer .orderList .list-group-item {
    display: list-item;
    color: #9DACBD
}

