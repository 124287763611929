/* @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

body, h1, h2, h3, h4, h5, h6,label,div,u,span,input,button,textarea {
  font-family: "Work Sans",sans-serif !important;
}
body {

  line-height: 1.5 ;
  -webkit-text-size-adjust: 100%;
}

