@media (min-width: 921px){
    .infoClase .ejercicio {
        font-size: 0.9vw !important;
    }
    
    .infoClase .item {
        font-size: 0.9vw;
    }
    .colorcancelar{
        font-size: 1.2vw;
        color:#9DACBD !important;
        background-color: #D4E8FF !important   
    }
    .colorINICIAR{
        font-size: 1.2vw;
        color:white !important;
        font-weight:bold;
        background-color: #9EACED !important   
    }
    .colorchangr{
        color:#505050 !important
    }
    .darplus{
      
        padding: 0 3vw !important;
    }
    .darplus1{
        margin-top:1vw !important;
        padding: 0 2.4vw !important;
    }
    .darplus2{
        margin-top:0vh !important;
       
    }
    .entrenadorVerReserva{
        overflow: hidden !important;
        
    }
    .infoClase .content {
        overflow-y: scroll;
        overflow-x:hidden;
        max-height: 40vh;
        width:30%;
    }
    .infoClase .metodologia {
        display:flex;
    }
    .infoClase .metodologia .name {
        color: #394F65;
        font-weight: bold;
        width:30%;
        font-size: 0.9vw;
    }
    
    .infoClase .metodologia .time {
        color: #9DACBD;
        font-weight: bold;
        font-size: 0.9vw;
        margin:0 0vw;
        
    }
    .colorfondo{
        position:relative;
        background: #F7F7F7;
        box-shadow: 4px 0px 8px -4px rgb(0 0 0 / 99%);
     
        padding:1vw;
        width:20%;
    }
    .colorfondo1{
        background: #EDEDED;
        width:45%;
    }
    .colorfondo2{
        background: #F7F7F7;
        position: relative;
        width: 35%;
    }
    .marginresumentotal{
margin: 0 0 0 6%;
width:100%;
position: relative;
height:80vh;
      
    }
    .tamañoreal{
        width:100% !important;
    }
    .entrenadorVerReserva .info {
        text-align: left;
    
        width: 28vw;
  padding: 0 0vw;
        margin: 3vh auto 0 0vw;
    }

.entrenadorVerReserva .info .item .tit {
    color: #394F65;
    font-size: 0.9vw;
    font-weight: bolder;
}

.entrenadorVerReserva .info .item .dat {
    color: #9DACBD;
    width:70%;
    font-size: 0.9vw;
    font-weight:400;
    word-wrap: break-word;
    font-weight: 500;
}


.seva{
    position:relative;
    padding:0.5vh 2vw 0.5vh 2vw;
    text-align:left;
    font-size:0.9vw !important;
    width:80%;;
}

}
    @media (min-width: 100px) and (max-width:  920px) {
      
        .infoClase .ejercicio {
            font-size: 0.9rem !important;
        }
        
        .infoClase .item {
            font-size: 1.1rem;
        }
        .colorchangr{
            color:#394F65 !important
        }
        .seva{
            display:none;
        }
        .infoClase .content {
            overflow-y: scroll;
            max-height: 45vh;
           
        }
        .infoClase .metodologia .name {
            color: #394F65 ;
            font-weight: bold;
            width:100%;
            font-size: 1rem;
        }
        
        .infoClase .metodologia .time {
            color: #9DACBD;
            font-weight: bold;
            font-size: 1rem;
            width:100%;
        }
       
.entrenadorVerReserva .info .item {
    margin-bottom: 0.5rem;
}
        .entrenadorVerReserva .info .item .tit {
            color: #394F65;
            font-size:4vw;
            font-weight: bolder;
        }
        
        .entrenadorVerReserva .info .item .dat {
            color: #9DACBD;
            font-size:4vw;
            word-wrap: break-word;
            font-weight: 400;
        }
        .entrenadorVerReserva .info {
        text-align: left;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        
        margin: 30px auto 0 auto;
    }}
.entrenadorVerReserva .suptitle {
    color: #9DACBD;
    font-size: 1rem;
    font-weight: 400;
    text-transform: initial;
}

.entrenadorVerReserva .title {
    line-height: 1;
    text-transform: capitalize;
}






.entrenadorVerReserva .info .item:last-child {
    margin-bottom: 2rem;
}

.entrenadorVerReserva .stepsBullets .Bullet {
    width: 15px;
    height: 15px;
    border: none;
    background-color: #9EACED;
    opacity: 0.5;
}

.separador {
    border-bottom: 1px solid #959595;
    width: 80%;
    margin: auto;
    opacity:0.4
    
}

.entrenadorVerReserva .stepsBullets .active {
    opacity: 1;
}



.infoClase .content .infoD {
    margin-bottom: 1.5rem;
}



.infoClase .ejercicio .name {
    color: #394F65 ;
    font-weight: bold;
    width:100%;
}

.infoClase .ejercicio .time {
    color: #505050;
    font-weight: 500;
    width:100%;
}



.entrenador.stepsBullets {
    margin-bottom: 0 !important;
}

.entrenadorVerReserva .cancelar {
    background-color: #CB3D82;
    margin-top:0vh  !important;
    width:60vw !important;
    padding:1.4vh 0 ;
}

.entrenadorVerReserva .modalInfo textarea {
    pointer-events: none;
}

.resplandor {
 
    animation-duration: 0.2s;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.resplandor1 {
    background:#CB3D82 !important;
    animation-duration: 0.2s;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.resplandorButton {
 
    animation-duration: 0.2s;
    animation-name: changebuttonClass;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes changewidth {
    from {
        box-shadow: 0px 0px 30px #9EACED;
    }
    to {
        box-shadow: 0px 0px 0px #9EACED;
    }
}

@keyframes changebuttonClass {
    from {
        box-shadow: 0px 0px 5px 7px rgba(41,53,110,0.2);
    }
    to {
        box-shadow: 0px 0px 0px 0px #9EACED;
    }
}