.imagenresultsdis{
   display: flex;
   padding-top:3vh;
   margin:auto;
    width:20vw;
    justify-content: center;
    
}
.imagencompartir{
   display: flex;
   position:fixed;
 top:11%;
   margin-left:75%;
    width:12vw;
    z-index:1;
   
    
}
.fechanowresults{
    text-align:center;
    font-weight:400;
    font-size:4vw;
    color:#919191;
    padding-bottom:2vh
}

.borderresults{
 margin-left:11vw !important;
 margin-right:11vw !important;
    border-top :1px solid #BEBEBE;
    
}
.borderresults1{
 margin-left:11vw !important;
 margin-right:11vw !important;
    margin-top:-1vh;
    border-bottom :1px solid #BEBEBE
}
.borderresults19{
 margin-left:16vw !important;
 margin-right:16vw !important;
   
    border-bottom :1px solid #BEBEBE
}
 /* .rv-xy-plot__axis__title:first-child  {
    transform:translate(0, 6) rotate(-90) !important
} */
 .imagesestadiscardio1{
    width:7.5vw;
    display:flex;
    margin-left:35%;
    justify-content: center;
}
.letrasestadis11{
    text-align:center;
    font-weight:bold;
    font-size:1.5rem;
    color:#9DACBD
}

.btncompartir{
    width:7vw;
    padding:4px
}
.bitacoraTitleresu{
    margin-top: 1rem;
    margin-left:10vw;
    margin-right:10vw;
    padding-bottom:0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    font-weight: bold;
    color: #394F65;
    text-align: center;
}
.bitacoraTitleresu_te{
    margin-top: 1rem;
    margin-left:15%;
    margin-right:15%;
    padding-bottom:0rem;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 1.5rem;
    font-weight: bold;
    color: #394F65;
    text-align: center;
}
.letraspopfile{
    display: flex !important;
     font-size:4vw !important;
     margin-top:0 !important;
    
 }
 .rv-xy-plot__axis__tick__line {
    stroke: none;
}
.rv-xy-plot__axis__line {
    
    stroke: none;
}
 .letraspopfi20{

    width:70vw !important;
    height:6.4vh;
    max-width:100vw !important;
}
.bitacoraSlider100{
    height:190vh
}
.corrergrafico{
    margin-top:0vh !important;
    margin-left:1vw;
   
}

 .yletr{
    color:#6E6E6E !important
 }
