

@media (min-width: 100px) and (max-width: 920px) {
    .content-input input[type=radio] + i{
       
        height: 24px;
        width: 24px;
       position:absolute;
        border-radius: 100%;
        right: 12vw;
       
    }
    .contendor_grupales .content-input input[type=radio] + i{
       
        height: 24px;
        width: 24px;
       position:absolute;
       top:30%;
        border-radius: 100%;
        right: 6%;
       
    }
    .Reservando .titlecredit {
        margin-top:3vh;
        margin-bottom:2vh;
        color: #394F65;
        font-Weight:500;
      
        display: flex;
        justify-content: center;
        text-align:center;
        font-size: 3.5vmin;
       
        /* height: 5vh; */
    }
    .Reservando .title {
        color: #394F65;
        font-weight: bold;
        display: flex;
        justify-content: center;
        text-align:center;
        font-size: 5vmin;
       
        /* height: 5vh; */
    }

    
    .Reservando .title.confirmado {
        color: #fff;
        background-color: #9EACED;
        padding-top: 2vh;
        padding-bottom: 2vh;
        font-size: 6vmin;
    }
    
    .Reservando .subtitle {
        color: #394F65;
        display: flex;
        justify-content: center;
        font-weight: bold;
    }
    
    .Reservando .containerVolverHome .btnvolverHome {
        margin-left: 3vw;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        font-weight: bold;
    }
    .btnHomeVolver{
        margin-left:5px;
      
    }
    
    .Reservando .containerVolverHome .btnvolverHome.reserva {
        width: 50vw;
        border-radius:45px;
        margin-left:3vw !important;
        font-size:4vw;
    }
    
    .Reservando .containerVolverHome {
        margin-top: 5%;
    }
    
    .Reservando.confirmado {
        position: relative;
    }
    
    .Reservando .containerVolverHome .btnvolverHome img {
        width: 20px;
    }
    
    .Reservando .iconCalendar {
        display: flex;
        justify-content: center;
        height: 60px;
        align-items: center;
    }
    
    .Reservando .iconCalendar img {
        height: 40px;
    }
    .Entrenamiento.reserva * {
        transform: scaleX(-1);
        /* rever the text*/
    }
    .content-input input[type=radio]:checked+i:before{
        transform: scale(1);
        opacity: 1;
    }
    .hours {
 
        margin-right: 4vw;
        padding-left:1vw;
      max-width:100vw;
        max-height: 30vh;
        overflow: scroll;
        width: 100%;
        text-align: left;
    }
    .dateflex{
        display:flex;
        border-bottom: 1px solid #BCBCBC;
        width:100%;
        margin-bottom: 2vh;
    }
    
    .hours .hour {
    
        display:flex;
        padding-left: 9vw !important;
       
        padding-right: 10vw !important;
    
        font-size: 3.4vw !important;
        font-weight:bold;
    
        width: 100%;
        padding: 2vh;
        margin-top: 2vh;
       
        color: black;
        border-radius: 30px ;
        border:1px solid #BEBEBE!important;
        font-weight: bold;
        cursor: pointer;
    }
    
    .hours .hour.active {
        background-color: #2A9DA5;
        color: white;
    }
    
    .Reservando .containerResevar .btnReserva {
        margin-top: 0;
        font-weight: bold;
    }
    
    button:hover {
        text-decoration: none;
        /* color: white; */
    }
    .content-input{
        margin-bottom: 15px;
        cursor: pointer;
    }
    .content-input{
        position: relative;
        margin-bottom: 30px;
        padding:5px 0 5px 40px;
        display: block;
        
    }
    
    .rounded-circle.active{
       background-color:#9EACED;
       color:white !important;
       opacity: 1;
    }
    .selDays .rounded-circle.active{
       background-color:#CB3D82;
       color:white !important;
       opacity: 1;
    }
    
    
    .lineacenter{
        position:relative;
        width:90%;
       height:1px;
       opacity:0.4
        
    }
    .lineacenterhora{
        position:relative;
        display:flex;
        margin-top:2.6vh;
        width:100%;
       height:1px;
      
       opacity:0.4;
       text-align:center !important;
        
    }
    
    .days {
        overflow-x: scroll;
        scrollbar-width: none;
        overflow-y: hidden;
        height: 19vmin;
        display: flex;
        align-items: center;
    }
    
    .days .selDays {
        white-space: nowrap;
        text-align: center;
    }
    
    .days .selDays span {
        display: inline-flex;
        margin-left: 1vw;
        margin-right: 1vw;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        color: #9DACBD;
        cursor: pointer;
            width: 12vmin !important;
            height: 12vmin !important;    
    font-size:0.9rem;
    font-weight:bold;
            
    }
    
    /* .days .selDays span.active {
        background-color: #2A9DA5;
      
           color: white;
    } */
    
    .Reservando .date {
        text-align: flex-start;
        color: #394F65;
        justify-content: center;
        font-weight:400;
        text-transform: capitalize;
        font-weight: bold;
        font-size: 3vw;
        margin-top: 4.0vh;
        /* margin-bottom: 0.1vh; */
       padding-left:2vw;
        padding-bottom: 0.4vh;
        width: 100%; 
    }
    .Reservando {
        min-height: calc(110vh);;
    }
    .days {
        padding-top:3%;
        padding-bottom: 3%;
        
        height: auto !important;
    }
    .months .selMonth {
        width: 80vw;
        overflow: scroll;
        scrollbar-width: none;
        height: 9vh;
    
        color: black;
    }
    
    .months .selMonth div {
        display: inline;
        padding-left: 5vw;
        font-size:0.9rem !important;
        padding-right: 5vw;
        text-transform: capitalize;
        font-weight: bold;
        cursor: pointer;
    }
    
    .months .selMonth .active {
        background-color: #CB3D82;
        border-radius: 6px;
        color: white;
    }
    
    .months {
        display: flex;
        align-items: center;
    }
    
    .months div {
        display: flex;
        align-items: center;
    }
    
    .months .left, .months .right {
        width: 10vw;
        margin-left:8vw !important;
        margin-right:8vw !important;
        justify-content: center;
        color:#000000 !important;
        opacity:0.5
    }
    
    .Entrenamiento.reserva {
        margin-top: 0 !important;
        height: 20vh;
        transform: scaleX(-1);
    }
    .lineop{
        position:absolute;
        top:0vh !important;
        width:50% !important;
        margin-left:25vw;
        margin-right:0vw !important;
    border:1px solid #9EACED;
       
      
        margin-top: 0.5vh; 
    }
    /* .hours .hour {
        width: 100% !important;
        padding: 2vh !important;
        margin-top: 2vh !important;
       display:flex;
        padding-left: 7vw !important;
       
        padding-right: 8vw !important;
        color: black;
        border-radius: 30px !important;
        border:1px solid #BEBEBE!important;

        font-size: 3vw !important;
        font-weight:bold;
        font-weight: bold !important;
        cursor: pointer !important;
    } */
 


    .Entrenamiento.reserva {
        height: 25vh !important;
        background-position: center;
       
    }
    .colorcajanumerodia{
        background-color:#F1ECE9;
      
    }
    .hourspo{
        display:flex;
        margin-right:1vw !important;
        margin-left:1vw !important;
      
       
    }
    .hourspo1{
        display:flex !important;
    
    padding-top:1vh;
    padding-bottom:3vh;
    margin-left:-55vw !important;
    
    }
    .hourspo2{
        display:flex;
        justify-content: center;
        
    }
    
    .hours select {
     
        display:flex;
        background: URL("../assets/img/reservasimages/flecha_down_select.png") no-repeat scroll 89% 2.4vh ;
        background-size:3.5vw;
    margin-left:0vw !important;
        border:none;
       
        appearance: none  !important;
        }
        .months100{
            width:100%;
        }
        .lineop_1{
            position:absolute;
            
            width:60vw !important;
            margin:0.7vh 0vw 0.7vh 10vw  !important;
       
        border-top:1px solid #2A9DA5;
            opacity:1;
            width: 100%;
             
        }
  
   
}

@media (min-width: 921px) {
    .content-input input[type=radio] + i{
        height: 24px;
        width: 24px;
       position:absolute;
        border-radius: 100%;
        right: 2vw;
        top:16%;
       
    }
    .content-inputCompanies input[type=radio] + i{
        height: 24px;
        width: 24px;
        position: absolute;
        border-radius: 100%;
      
       
    }
    .content-inputCompanies input,
    {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    .lineaCom{
        border-top: 3px solid #9EACED;
  height: 2px;
  padding: 0;
  width:40vmin;
  margin: 1vmin auto 0 auto;
    }
    .caminito::before{
        content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.25;
   
    }
    .content-inputCompanies [type=radio]{display:none}
    
    
    .content-inputCompanies i,
    .content-inputCompanies i:before{
        transition: all 0.25s ease;
        border: solid 2px #CB3D82;
     
    }
    
  
    .content-inputCompanies input[type=radio] + i:before{
        content: '';
        display: block;
        height: 18px;
        width: 18px;
        border-radius: 100%;
        position: absolute;
        z-index: 1;
        top: 1px;
        left: 1px;
        background: #CB3D82;
        transition: all 0.25s ease;
        transform: scale(0);
        opacity: 0; /* Lo ocultamos*/
    }
    .content-inputCompanies input[type=radio]:checked+i:before{
        transform: scale(1);
        opacity: 1;
    }
    .dates1{
    padding:2vh ;
  
    font-size:0.8vw;  
 
}
    .seleeccionatusdias{
        margin-left:4vw;
    }
    .widthprofesores{
        width:38%;
    }
    .widthhorasydias{
     
        width:50%;
    }
    .scrollwidthhorasydias{
        overflow-y: auto;
        height:30vh;
    }
    .months100{
        width:15vw;
    }
    .containerReservas .titleseleccio {
        color: #394F65;
        font-weight:700;
        font-size: 2.5vmin;
        padding-top: 1vh;
        text-align:left;
    width:80%;
        line-height: 1;
    }
    .containerReservas .titleseleccio1 {
        color: #394F65;
        font-weight:700;
        font-size: 2.5vmin;
        padding-top: 1vh;
        text-align:left;
    width:50%;
        line-height: 1;
    }
    .Reservando .titlecredit {
        margin-top:3vh;
        margin-bottom:2vh;
        color: black;
        font-weight: bold;
        display: flex;
        justify-content: center;
        text-align:center;
        font-size: 3.5vmin;
       
        /* height: 5vh; */
    }
    .Reservando .title {
        color: #394F65;
        font-weight: bold;
        display: flex;
        justify-content: center;
        text-align:left;
        font-size: 5vmin;
       
        /* height: 5vh; */
    }
    
    .Reservando .title.confirmado {
        color: #fff;
        background-color: #2A9DA5;
        padding-top: 2vh;
        padding-bottom: 2vh;
        font-size: 6vmin;
    }
 
    
    .Reservando .subtitle {
        color: #000;
        display: flex;
        justify-content: center;
        font-weight: bold;
    }
    
    .Reservando .containerVolverHome .btnvolverHome {
        margin-left: 3vw;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        font-weight: bold;
    }
    .btnHomeVolver{
        margin-left:5px;
      
    }
    
    .Reservando .containerVolverHome .btnvolverHome.reserva {
        width: 50vw;
        border-radius:45px;
        margin-left:3vw !important;
        font-size:4vw;
    }
    
    .Reservando .containerVolverHome {
        margin-top: 5%;
    }
    
    .Reservando.confirmado {
        position: relative;
    }
    
    .Reservando .containerVolverHome .btnvolverHome img {
        width: 20px;
    }
    
    .Reservando .iconCalendar {
        display: flex;
        justify-content: center;
        height: 60px;
        align-items: center;
    }
    
    .Reservando .iconCalendar img {
        height: 40px;
    }
    
    .hours select {
     
        display:flex;
        background: URL("../assets/img/reservasimages/Grupo 5243.png") no-repeat scroll 82% 1.5vh ;
        background-size:0.8rem;
    margin-left:0vw !important;
        border:none;
       
        appearance: none  !important;
        }
    .content-input input[type=radio]:checked+i:before{
        transform: scale(1);
        opacity: 1;
    }
    .hourspo{
        display:flex;
        margin-right:1vw !important;
      
    
    }
    .hourspo1{
        display:flex !important;
    
    padding-top:1vh;
    padding-bottom:1.5vh;
    margin-left:-10vw !important;
    
    }
    .hourspo2{
        display:flex;
        justify-content: center;
        
    }
    .hours {
 
        margin-right: 1vw;
       
        padding-left:1vw;

    
        width: 100%;
        text-align: left;
    }
    .dateflex{
        display:flex;
        border-bottom: 1px solid #BCBCBC;
        width:100%;
        margin-bottom: 20px;
    }
    
    .hours .hour {
    
        display:flex;
        width:13vw;
       
        font-size: 0.8vw !important;
   
        padding: 1.0vh 1vw;
    
       position:relative;
       left:-4vw;
        color: #9DACBD;
        border-radius: 30px ;
        border:1px solid rgba(157,172,189,.3)!important;
        font-weight: 600;
        cursor: pointer;
    }
    
    .hours .hour.active {
        background-color: #2A9DA5;
        color: white;
    }
    
    .Reservando .containerResevar .btnReserva {
        margin-top: 0;
        font-weight: bold;
    }
    
    button:hover {
        text-decoration: none;
        /* color: white !important; */
    }
    .content-input{
        margin-bottom: 15px;
        cursor: pointer;
    }
    .content-input{
        position: relative;
        margin-bottom: 30px;
        padding:5px 0 5px 0px;
        display: block;
        font-size:1vw;
        
    }
    
    .rounded-circle.active{
       background-color:   #CB3D82;
       color:white !important
    }
    
    
    .lineacenter{
        position:relative;
        width:90%;
       height:1px;
       opacity:0.4
        
    }
    .lineacenterhora{
        position:relative;
        display:inline-flex;
        margin-top:24px;
        width:19vw;
       height:1px;
       margin:2vh 0  !important;
     z-index:999;
       opacity:0.4;
       text-align:center !important;
        
    }
  
    .colorcajanumerodia{
        background-color:#F1ECE9;
      
    }
    .colorcajadias{
        background-color:#E5E5E5;
      
    }
    .days {
        overflow-x: hidden;
        scrollbar-width: none;
        overflow-y: hidden;
        height: 4vmin;
        display: flex;
        align-items: center;
        width:15vw;
       
    }
    
    .days .selDays {
        white-space: nowrap;
        text-align: center;
    }
    .days .selDays .colornombredias{
        color: #2A9DA5;
        width: 2vw ;
        padding:0 5px;
    }
    
    .days .selDays span {
        display: inline-flex;
     
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        color: #9DACBD;
        
        cursor: pointer;
            width: 2vw ;
            min-width:16px;
            height: 2vw ;    
    font-size:0.8vw;
    
            
    }
    
    /* .days .selDays span.active {
        background-color: #2A9DA5;
      
           color: white;
    } */
    

    .Reservando .date {
        text-align: flex-start;
        color: black;
        opacity: 0.4 !important;
        justify-content: center;
        font-weight:400;
        text-transform: capitalize;
        font-weight: bold;
        font-size: 3vw;
        margin-top: 4.0vh;
        
        /* margin-bottom: 0.1vh; */
       padding-left:6vw;
       padding-right:1vw;
        padding-bottom: 0.4vh;
        width: 100%;
      
    }
    
    
    .Reservando {
        min-height: 85vh;
    }
    .days {
       
        
        height: auto !important;
    }
    .months .selMonth {
        width:15vmin;
        /* overflow:hidden; */
        scrollbar-width: none;
        height: 5vh;
    
        color: black;
    }
    .months .selMonth .noactive{
     display:none;
    }
    
    .months .selMonth div {
        display: inline-flex;
        width:100% !important;
        font-size:1vw !important;
        justify-content: center;
        
       text-align:center !important;
  
        text-transform: capitalize;
        font-weight: bold;
        cursor: pointer;
    }
    
    .months .selMonth .active {
        background-color: white;
        border-radius: 6px;
        color: #394F65;
       
    }
    
    .months {
        display: flex;
        align-items: center;
        background-color: white;
    }
    
    .months div {
        display: flex;
        align-items: center;
    
        width:13vmin !important;
    }
    
    .months .left, .months .right {
        cursor:pointer;
        width: 1vw;
        margin-left:0vw !important;
        margin-right:0vw !important;
        justify-content: center;
        color:#394F65 !important;
        opacity:0.5
    }
    
    .Entrenamiento.reserva {
        margin-top: 0 !important;
        height: 12vh;
        background-size:cover;
        background-position:center;
        transform: scaleX(1);
       
    }
    .lineop{
        position:absolute;
        top:0vh !important;
        width:21vw !important;
     margin-left:1vw;
    border-top:3px solid ##9EACED;
        opacity:1;
        width: 100%;
        margin-top: 1vh; 
    }
    .lineopnuevo_web{
        position:absolute;
        top:0vh !important;
        width:28vw !important;
     margin-left:1vw;
    border-top:2px solid #9EACED;
        opacity:1;
        width: 100%;
        margin-top: 1vh; 
    }
    .lineop_1{
        position:absolute;
        top:0vh !important;
        width:30.5vw !important;
        margin:1vh 0vw 1vh 1vw  !important;
   
    border-top:3px solid #9EACED;
        opacity:1;
        width: 100%;
         
    }
    .months .selMonth {
        scrollbar-width: initial !important;
    }
    .days .selDays {
        white-space: normal !important;
        text-align: left !important;
    }
    .Entrenamiento.reserva * {
        width:100%;
      
        /* rever the text*/
    }
    .ReservandoDeskt .title {
        font-size: 1.5vw;
    }
    .confirmativo .title {
        font-size: 1.5rem;
    }
    .confirmativo .title_detitles {
        font-size: 2rem;
    }
    .ReservandoDeskt  .noconfirmado {
        color: #fff;
   
        font-size: 2vmin;
    }
    
    .ReservandoDeskt .recomendacion {
        border-radius: 20px;
        background-color: #fff;
        padding: 4% 11%;
     
        color: #7B7B7B;
        font-size:0.6rem;
        font-weight:400;
        margin-top: 5%;
    }
    
    .ReservandoDeskt .information .content {
        width: 77%;
    }
    .confirmativo .information .content {
        width: 100%;
    }
    
    .ReservandoDeskt .recomendacion img {
        height: 40px;
        margin-bottom:10px;
    }
    .ReservandoDeskt .fondo {
        margin-right: 10%;
        width: 60%;
       
        position: absolute;
        top: 0;
        height: 100%;
    }
}








/* @media (min-width: 720px) {
    .Reservando .containerResevar .btnReserva {
        width: 15rem;
        margin-bottom: 2rem;
    }
} */

.ReservandoDeskt {
    display: flex;
    justify-content: center;
    align-items: center;
position:relative;
left:15%;



}



/* .ReservandoDeskt .fondo::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
} */

.ReservandoDeskt .InformationEntrenamiento {
    position: relative;
    left: 0%;
   width:100% !important;
    top: 78%;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1;
}

.ReservandoDeskt .InformationEntrenamiento .container_name {
    text-transform: capitalize;
    font-size: 2rem;
}

.ReservandoDeskt .inf {
    position: relative;
    z-index: 1;
    width:90%;
    color: white;
    left:0%;
  
}
.confirmativo .inf {
    position: relative;
    z-index: 1;
    width:90%;
    color: white;
    left:0%;
  
}

.ReservandoDeskt .information {
    display: flex;
    position:relative;

    width:100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
}
.confirmativo .information {
    display: flex;
    position:relative;
    left:0%;
    width:100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
}

.ReservandoDeskt .title.confirmado {
    color: #fff;
  ;
  
    margin-bottom: 5%;
}

.ReservandoDeskt .iconCalendar img {
    height: 30px;
    margin-top:30px;
    margin-bottom:10px;
    
}
.ReservandoDeskt .iconalertacamarada img {
    height: 40px;
    margin-top:10px;
    margin-bottom:10px;
    
}
.content-input input,
    {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    .content-input [type=radio]{display:none}
    
    
    .content-input i,
    .content-input i:before{
        transition: all 0.25s ease;
        border: solid 2px #CB3D82;
     
    }
    
  
    .content-input input[type=radio] + i:before{
        content: '';
        display: block;
        height: 18px;
        width: 18px;
        border-radius: 100%;
        position: absolute;
        z-index: 1;
        top: 1px;
        left: 1px;
        background: #CB3D82;
        transition: all 0.25s ease;
        transform: scale(0);
        opacity: 0; /* Lo ocultamos*/
    }

