
.sliderNetFlixvideo video{
    border-radius:15px;
}
.videoresponsive video{
    border-radius:15px;
}
.sliderNetFlixvideorozo video{
    border-radius:15px;
}


.lineorder{
    width:220px;
    height:1px
}
.buttonsorder{
    background-color:white;
    border:none;
    color: #394F65;
    font-weight:700;
}





.stepsTipos .Bullet {
    display: inline-block;
    height: 7vh;
    width: 7vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(100%);
    border-radius: 50px;
    opacity: 1 !important;
}

.stepsTipos .Bullet.active {
    background-color: #2A9DA5;
    filter: none !important;
    opacity: 1 !important;
}

.stepsTipos {
    margin-top: 1vh;
}





.bg_cover.reserva {
    height: 100%;
    /* Same as height of .Entrenamiento */
}
@media (min-width: 100px) and (max-width: 920px) {
    .glosario{
overflow-y: auto;
height:70vh;
    }
    .containerReservas > .debajosearchapple{
        height: calc(100vh - 95vmin);
              
                overflow-y:  auto;
    }
    .containerReservas > .debajosearchandroid{
        height: calc(100vh - 70vmin);
              
                overflow-y:  auto;
    }
    
    .pasarl .btnCalendar {
    width: 5vw;
}
.containerResevar .btnCalendar {
    width: 7vw;
}
    .slidersNetflixReservas .sliderNetFlix .content {
        position: absolute;
        left: 50%;
        width: 87%;
        bottom: 5%;
     
        text-align: center;
        line-height: 1;
        font-weight: bold;
        color: white;
        opacity: 0.9;
    }
    .slidersNetflixReservas .sliderNetFlixvideo .content {
        position: absolute;
        width: 80%;
        text-align: center;
        line-height: 1;
        font-weight: bold;
        color: white;
        opacity: 0.9;
    }
    .Cabecera {
        font-size: 2.5vmin ;
    }
    .modalMsgReserva {
        font-size: 4vw !important;
      overflow-y: scroll;
      
        max-height: 35vh;
    }
    .informacion .duracion span {
        font-size: 5vmin;
        font-weight: bold;
    }
    
    .informacion .calorias div div {
        font-size: 5vmin;
        font-weight: bold;
    }
    .containerReservas .title {
        color: #394F65;
        font-weight: bold;
        font-size: 6.0vmin;
        padding-top: 1vh;
        margin-left: -10px;
        line-height: 1;
        
    }
    .titleCategory {
        margin-left: 20px;
    color:#394F65;
        font-weight: bold;
    }
    .imgarribareservas{display:none}
    .imgabajoreservas{display:none}
    #root{
        overflow-y:scroll;
       
    }
    .containerResevar .btnReserva {
        margin-top: 0.32vh;
        margin-left: 3vw !important;
        width: 50%;
        display: flex;
        background:#CB3D82;
        justify-content: center;
        align-items: center;
    }
    .containerResevar .btnReservaHadval {
        margin-top: 0.32vh;
        margin-left: 3vw !important;
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
     .izquierbuton {
        
         font-size:5vw !important;
         margin-top:2vh !important;
        margin-left:2vh !important
    }
    .izquierbuton_1{
        padding:0 !important;
    }
    .Cabecera {
        position: absolute;
        top: 55%;
        font-family: WorkSans-Light;
        color: white;
        font-size: 4vw;
        line-height: 1;
        left: 7%;
        width: 87%
    }
    

    
    .Cabecera .container_name {
        font-size: 7vw !important;
        margin-top:1vh !important;
        font-weight:700;
        text-transform: capitalize;
        letter-spacing: 2px;
    }
    
    .Cabecera .lineup {
        position:absolute;
        top:0vh !important;
        width:65% !important;
        margin-left:50vw !important;
    border:1px solid #2A9DA5;
     
        width: 100%;
        margin-top: 0.5vh;
    
    }
    .Cabecera .lineop_1_p {
        position:absolute;
            
      
        margin:0.7vh 0vw 0.7vh 2vw  !important;
   
    border-top:1px solid #9EACED;
        opacity:1;
      
  
        top:0vh !important;
        width:49% !important;
        margin-left:50vw !important;
    
   
   
        margin-top: 0.5vh;
    
    }
    .informacion .step {
        width: 25%;
        height: 100%;
        text-align: center;
        font-size: calc(0.5em + 0.81vmin);
        font-weight: bold;
        padding: 2%;
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
    }
    .informacion {
        height: 10vh;
        color: white;
        position: relative;
        top: 0;
        left: 0;
    }
    .bg_cover {
        position: absolute;
        background-color: black;
        width: 100%;
        height: 35vh;
        /* Same as height of .Entrenamiento */
        opacity: 0.2;
    }
    .bg_cover:hover {
     
        /* Same as height of .Entrenamiento */
        opacity: 0.1;
    }
    .Entrenamiento {
        margin-top: 2vh;
        background-repeat: no-repeat;
        background-size: cover;
        height: 30vh;
        position: relative;
        top: 0;
        left: 0;
    }
    .maxreservar{
        display:none;
    }
    .holi{
        display: grid;
        grid-template-columns: auto auto auto ;
        grid-gap:0.6rem !important;
        margin-left:0.7rem;
        margin-right:0.7rem !important
    }
    .slidersNetflixReservas .sliderNetFlix {
        min-height: 23vh;
        position:relative;
    border-radius:15px;
       
        font-size: 0.7rem;
        text-transform: capitalize;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
    .slidersNetflixReservas .sliderNetFlixvideo {
        min-height: 23vh;
        position:relative;
        width: 100% !important;
       
        font-size: 0.7rem;
        text-transform: capitalize;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
 
    .slidersNetflixReservasNew .sliderNetFlixvideoNew {
        min-height: 23vh;
        position:relative;
        width: 100% !important;
       
        font-size: 0.7rem;
        text-transform: capitalize;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
 

    .cajacontrol{
        display:flex  !important;
        max-width:90vw  !important;
        max-height:20vh !important
    }
    .filters{
    
        border-radius:15px;
        border:1px solid #9DACBD;
        
        margin-left:1vw;
        background: white;
        font-size: 2.2vw;
        color:#394F65;
        width:14.5vw;
        display:column !important;
        height: 6vh;
        margin-right:1vw;
      
        
    }
    .reservatitulo1{
        display:none
    }
    .containerReservas {
        min-height: 80vh;
        margin-bottom: 75px; /*Tawk*/
    }
    .btnfiltrarW .imgfiltrarWf{
        width: calc(0.8rem + 0.6vw);
        height:calc(1.0rem + 0.9vw);
      
    }
    .btnfiltrarW .imgfiltrarWo{
        width: calc(0.9rem + 0.6vw);
      
    }
    .slidersNetflixReservas2 .sliderNetFlix2 {
        min-height: 22vh;
        min-width:5vw !important;
        width: 100% !important;
        font-size: 0.7rem;
        text-transform: capitalize;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
        border-radius:15px;
    }
    .filtrarreservasweb{
        display:none
    }
    .routerhe{
        height:100%;
        overflow-y:scroll !important;
       
    }
    .imgarribareservas{
        display:none
    }
    .Cabecera.reserva {
        position: absolute;
        top: 50%;
        color: white;
        font-size: 3.0vw !important;
        margin-top:1vh !important;
        left: 5%;
        width: 87%
    }
    .Cabecera.reserva_grupales {
        position: absolute;
        top: 40%;
        color: white;
        font-size: 3.0vw !important;
        margin-top:1vh !important;
        left: 5%;
        width: 87%
    }
    .preguntasfrecuentesweb{
    
        width:100% !important;
    }
    .slidersNetflixReservas .sliderNetFlixvideolo {
        min-height: 100px;
        width: 150px !important;
        font-size: 1rem;
        text-transform: capitalize;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
}

@media (min-width: 921px) and (max-width: 1500px) {
    .holi{
        display: grid;
        grid-template-columns: auto auto auto auto  ;
        grid-gap:1vw !important;
        margin-left:0vw;
        margin-right:7vw !important;
     
    }
}

@media (min-width: 1501px) {
    .holi{
        display: grid;
        grid-template-columns: auto auto auto auto auto ;
        grid-gap:1vw !important;
        margin-left:0vw;
        margin-right:7vw !important
    }
}


@media (min-width: 921px) {
    .slidersNetflixReservasNew .sliderNetFlixvideoNew video {
        object-fit: initial !important;
    }
    .sliderPublicidadnew  {
        margin:auto 0% !important; 
    }
     .sliderNetFlixvideolo .content{
        width: 250px;
        height: 325px;
    }
    .slidersNetflixReservas .sliderNetFlixvideolo {
        min-height: 350px;
        width: 250px !important;
        font-size: 1rem;
        text-transform: capitalize;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
     .sliderNetFlixvideoloco .content{
        width: 233px;
        height: 353px;
        /* width: 193px;
        height: 290px; */
    }
     .sliderNetFlixvideocomidas .content{
        width: 182px;
        height: 276px;
        /* width: 193px;
        height: 290px; */
    }
    .slidersNetflixReservas .sliderNetFlixvideocomidas {
        width: 182px;
        height: 276px;
        font-size: 1rem;
        text-transform: capitalize;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
    .slidersNetflixReservas .sliderNetFlixvideoloco {
        width: 233px;
        height: 353px;
        font-size: 1rem;
        text-transform: capitalize;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
 
    
    .swiper-button-prev-pin:after,.swiper-button-next-pin:after {
        font-size:15px !important;
        color:white;
            }
           .swiper-button-next-pin{
            position:relative !important;
            background:#9EACED !important;
            margin:auto 5vmin auto 0;
            width:4vmin !important;
            height:4vmin !important;
            top: 0 !important;
            border-radius:50% !important;
            border:1px solid #9EACED;
            }
            .swiper-button-prev-pin{
                position:relative !important;
                background:#9EACED !important;
                margin:auto 1vmin;
                width:4vmin !important;
                height:4vmin !important;
                top: 0 !important;
                border-radius:50% !important;
                border:1px solid #9EACED;
            }
            .swiper-button-prev-pin:after {
                content: 'prev';
                font-family: swiper-icons;
                font-size: 15px !important;
                font-variant: initial;
                height: 100%;
                justify-content: center;
                text-transform: none!important;
                letter-spacing: 0;
                display: flex;
                cursor: pointer;
                align-items: center;
            }
            .swiper-button-next-pin:after {
                content: 'next';
                font-family: swiper-icons;
                font-size: 15px !important;
                font-variant: initial;
                height: 100%;
                cursor: pointer;
                justify-content: center;
                text-transform: none!important;
                letter-spacing: 0;
                display: flex;
                align-items: center;
            }
    .swiper-button-prev-medicos:after,.swiper-button-next-medicos:after {
        font-size:15px !important;
        color:white;
            }
           .swiper-button-next-medicos{
            position:relative !important;
            background:#9EACED !important;
            margin:auto 5vmin auto 0;
            width:4vmin !important;
            height:4vmin !important;
            top: 0 !important;
            border-radius:50% !important;
            border:1px solid #9EACED;
            }
            .swiper-button-prev-medicos{
                position:relative !important;
                background:#9EACED !important;
                margin:auto 1vmin;
                width:4vmin !important;
                height:4vmin !important;
                top: 0 !important;
                border-radius:50% !important;
                border:1px solid #9EACED;
            }
            .swiper-button-prev-medicos:after {
                content: 'prev';
                font-family: swiper-icons;
                font-size: 15px !important;
                font-variant: initial;
                height: 100%;
                justify-content: center;
                text-transform: none!important;
                letter-spacing: 0;
                display: flex;
                cursor: pointer;
                align-items: center;
            }
            .swiper-button-next-medicos:after {
                content: 'next';
                font-family: swiper-icons;
                font-size: 15px !important;
                font-variant: initial;
                height: 100%;
                cursor: pointer;
                justify-content: center;
                text-transform: none!important;
                letter-spacing: 0;
                display: flex;
                align-items: center;
            }

            .swiper-button-prev-pindos:after,.swiper-button-next-pindos:after {
                font-size:15px !important;
                color:white;
                    }
                   .swiper-button-next-pindos{
                    position:relative !important;
                    background:#9EACED !important;
                    margin:auto 5vmin auto 0;
                    width:4vmin !important;
                    height:4vmin !important;
                    top: 0 !important;
                    border-radius:50% !important;
                    border:1px solid #9EACED;
                    }
                    .swiper-button-prev-pindos{
                        position:relative !important;
                        background:#9EACED !important;
                        margin:auto 1vmin;
                        width:4vmin !important;
                        height:4vmin !important;
                        top: 0 !important;
                        border-radius:50% !important;
                        border:1px solid #9EACED;
                    }
                    .swiper-button-prev-pindos:after {
                        content: 'prev';
                        font-family: swiper-icons;
                        font-size: 15px !important;
                        font-variant: initial;
                        height: 100%;
                        justify-content: center;
                        text-transform: none!important;
                        letter-spacing: 0;
                        display: flex;
                        cursor: pointer;
                        align-items: center;
                    }
                    .swiper-button-next-pindos:after {
                        content: 'next';
                        font-family: swiper-icons;
                        font-size: 15px !important;
                        font-variant: initial;
                        height: 100%;
                        cursor: pointer;
                        justify-content: center;
                        text-transform: none!important;
                        letter-spacing: 0;
                        display: flex;
                        align-items: center;
                    }

                    .swiper-button-prev-numbers:after,.swiper-button-next-numbers:after {
                        font-size:15px !important;
                        color:white;
                            }
                           .swiper-button-next-numbers{
                            position:relative !important;
                            background:#9EACED !important;
                            margin:auto 5vmin auto 0;
                            width:4vmin !important;
                            height:4vmin !important;
                            top: 0 !important;
                            border-radius:50% !important;
                            border:1px solid #9EACED;
                            }
                            .swiper-button-prev-numbers{
                                position:relative !important;
                                background:#9EACED !important;
                                margin:auto 1vmin;
                                width:4vmin !important;
                                height:4vmin !important;
                                top: 0 !important;
                                border-radius:50% !important;
                                border:1px solid #9EACED;
                            }
                            .swiper-button-prev-numbers:after {
                                content: 'prev';
                                font-family: swiper-icons;
                                font-size: 15px !important;
                                font-variant: initial;
                                height: 100%;
                                justify-content: center;
                                text-transform: none!important;
                                letter-spacing: 0;
                                display: flex;
                                cursor: pointer;
                                align-items: center;
                            }
                            .swiper-button-next-numbers:after {
                                content: 'next';
                                font-family: swiper-icons;
                                font-size: 15px !important;
                                font-variant: initial;
                                height: 100%;
                                cursor: pointer;
                                justify-content: center;
                                text-transform: none!important;
                                letter-spacing: 0;
                                display: flex;
                                align-items: center;
                            }




    .swiper-button-prev-comidas:after,.swiper-button-next-comidas:after {
        font-size:15px !important;
        color:white;
            }
           .swiper-button-next-comidas{
            position:relative !important;
            background:#9EACED !important;
            margin:auto 5vmin auto 0;
            width:4vmin !important;
            height:4vmin !important;
            top: 0 !important;
            border-radius:50% !important;
            border:1px solid #9EACED;
            }
            .swiper-button-prev-comidas{
                position:relative !important;
                background:#9EACED !important;
                margin:auto 1vmin;
                width:4vmin !important;
                height:4vmin !important;
                top: 0 !important;
                border-radius:50% !important;
                border:1px solid #9EACED;
            }
            .swiper-button-prev-comidas:after {
                content: 'prev';
                font-family: swiper-icons;
                font-size: 15px !important;
                font-variant: initial;
                height: 100%;
                justify-content: center;
                text-transform: none!important;
                letter-spacing: 0;
                display: flex;
                cursor: pointer;
                align-items: center;
            }
            .swiper-button-next-comidas:after {
                content: 'next';
                font-family: swiper-icons;
                font-size: 15px !important;
                font-variant: initial;
                height: 100%;
                cursor: pointer;
                justify-content: center;
                text-transform: none!important;
                letter-spacing: 0;
                display: flex;
                align-items: center;
            }
    
.containerResevar .btnCalendar {
    width: 2rem;
}
    .slidersNetflixReservas .sliderNetFlix .content {
        position: absolute;
        left: 50%;
        top: 50%;
        /* transform: translateX(-50%); */
        text-align: center;
        line-height: 1;
        font-weight: bold;
        color: white;
        opacity: 0.9;
    }
    .slidersNetflixReservas .sliderNetFlixvideo .content {
        position: absolute;
       
        /* transform: translateX(-50%); */
        text-align: center;
        line-height: 1;
        font-weight: bold;
        color: white;
        opacity: 0.9;
    }
  
    .Cabecera {
        font-size: 2.5vmin ;
    }
    .modalMsgReserva {
        font-size: 1rem !important;
      overflow-y: auto;
      
      
    }
    .informacion .duracion span {
        font-size: 3.5vmin;
        font-weight: bold;
    }
    
    .informacion .calorias div div {
        font-size: 3.5vmin;
        font-weight: bold;
    }
    .overflowteachers{
        overflow-y: auto;
        height:calc(40vh);
        color:#394F65
        
    }
 .scrollslidernetflix{
     overflow-y: auto;
     
     
     width:86vw;
     height:calc(93.3vh - 190px);
 }
   
    .glosario{
        overflow-y: auto;
        height: calc(97vh - 22vmin - 70px);
        /* calc(97vh - 22vmin - 70px); */
    }
    .glosarioVideo{
        overflow-y: hidden;
        height:50vh;
    }
    .preguntasfrecuentesweb{
       
        margin-left:15%;
        margin-right:1vw;
        width:76% !important;
    
        
    }
    .containerReservas .title {
        color: #394F65;
        font-weight:700;
        font-size: calc(5vmin + 0.2rem);
        
        margin-left: -10px;
        line-height: 1;
        
    }
    .containerReservas_lo_sentimos {
        color: #394F65;
        font-weight:700;
        font-size: calc(3vmin + 0.2rem);
        
        margin-left: -10px;
        line-height: 1;
        
    }
    .maxreservar1 .title {
        color: #394F65;
        font-weight:700;
        font-size: calc(5.0vmin + 0.2vw);
        
        margin-left: -10px;
        line-height: 1;
        
    }
    .titleCategory {
        margin-left: 20px;
  
        font-weight: bold;
    }
    .maxreservar{
       margin-top:-50px;
       margin-bottom:23.5px;
    }
    .maxreservar1{
  position:relative;
  top:-5vh;
left:17%;
    }

    #root{
        overflow:hidden;
      
    }
    .containerResevar .btnReserva {
        position:absolute !important;
        top:80%;
        right:0%;
       
        width: 16vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .maxreservaselec .btnReserva {
        position:absolute !important;
        top:80%;
        right:0%;
       
        width: 16vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
     .izquierbuton {
   
        margin-left:2vh !important;
        height:8vh;
        border-top-left-radius:50px !important;
           border-bottom-left-radius:50px !important;
        border-top-right-radius:0px !important;
           border-bottom-right-radius:0px !important;
    }
    
    .Cabecera {
        position: relative;
        top: 25%;
        color: white;
        font-size: calc(2em + 1vw);
        line-height: 1;
        left: 31vw;
        width: 49%
    }
    
    .Cabecera.reserva {
        position: absolute;
        top: 20%;
        color: white;
        font-size: calc(1em + 1vw);
        margin-top:1vh !important;
  
        width: 87%
    }
    
    .Cabecera .container_name {
        font-size: calc(2.5em + 3vw);
        margin-top:1vh !important;
        font-weight:700;
        text-transform: capitalize;
    
    }
    .tituloreservartuclase{
        text-align:left;
        margin-top:0vh;
        width:100%;
    }
    .tituloreservartuclasedebajo{
        text-align:left;
        margin-top:1vh;
        margin-bottom:1vh;
        width:50%;
    }
        .lineareservatuclase{
        border-bottom: 1px solid #BEBEBE;
      
  
        opacity:0.5;
        position:absolute !important;
     
     margin-left:6vw !important;
 
     top:14vh;
        width:calc(100% - 20vw);
  ;
    }
        .lineareservatuclasenoreser{
        border-bottom: 1px solid #BEBEBE;
      
  
        opacity:0.5;
        position:absolute !important;
     
     margin-left:6vw !important;
 
     top:11vh;
        width:calc(100% - 20vw);
  ;
    }
        .lineareservatuclaseonly{
        border-bottom: 1px solid #BEBEBE;
      
  
        opacity:0.5;
        position:absolute !important;
     
     margin-left:5.8vw !important;
 
     top:15.3vh;
        width:calc(100% - 20vw);
  ;
    }
    .imgarribareservas{
   
   position:absolute;
            float:left;
              margin-top: -25vh;
              margin-left: 0vh;
              width: 500px;
              z-index: -1 !important ;
             
    }
    .imgabajoreservas{
   
   position:fixed;
            float:right;
            top:40vh;
          left:70vw;
          margin-right:1vw;
    
              width: 30rem;
     
              
              z-index: -1 !important ;
             
    }
    .SubTitle8reservatuclase{
     
            width:30%;
            left: 0vw;
            margin-left: 0%;
            text-align:left;
            position:relative !important;
       
          
            font-size: calc(10px + 0.3rem) !important;
            color: #9DACBD;
            padding-top: 10vh;
           
          
        
    }
    
    .Cabecera .lineup {
        position:absolute;
        top:0% !important;
        width:54% !important;
      left:44%; 
    border-top:2px solid #9EACED;
        opacity:1;
      
        margin-top: 1vh;
    
    }
    .informacion .step {
        width: 25%;
        height: 100%;
        text-align: center;
        font-size: calc(0.7em);
        font-weight: bold;
        padding: 2%;
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .informacion {
        width:40%;
        height: 90px;
        color: white;
        position: relative;
        top: -90px;
        left: 44.5%;
     
        margin-right:-1vw;
    }
    .bg_cover {
        position: absolute;
        background-color: black;
        width: 100%;
        height: 50vh;
        opacity: 0.4;
    }
    .bg_cover:hover {
      
       
        opacity: 0.1;
    }
    .Entrenamiento {
        margin-top: 2vh;
        background-repeat: no-repeat;
        background-size: cover;
        height: 50vh;
        position: relative;
        width:60vw;
    
        margin-right:5vw;
        top: 0;
        
    }
    .Entrenamientoprofeyhora{
        width:70vw !important; 
    
    }
    .textodebajoreservas{
        font-size:0.9rem;
       
        color:#9DACBD;
    }
    .separadormin{
        display:none  
    }
    .disciplinasmin{
        display:none
    }
    .maximoletrasreservas{
        display:none
    }
    .MODALHEIGHFILT{
       overflow-x: hidden;
       overflow-y: hidden;
       ;
       
  
        
    }
    .modalInfofilling {
        background-color: rgba(0, 0, 0, .8);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height:100vh;
        transition: all 1s;
        opacity: 1;
        z-index:9;
    }
  
    .modalInfofilling .modalInfoContenido {
        background-color: white;
        width: 30%;
        margin: 50px 10px 0vh 7.0vw;
        padding: 3vh 0px 0vh 0;
        position: relative;
        border-radius: 0px;
        text-align: center;
    }
    .modalInfofilling3 .modalInfoContenido {
        background-color: white;
        width: 500px;
        margin: 0px 10px 0vh -90px;
        padding: 3vh 0px 0vh 0;
         position: absolute;
        ;
        border-radius: 0px;
       
       
        border-radius: 10px;
        text-align: center;
    }
 
    .zoomfiltros{
        margin-left:30vw;
        margin-right:50vw;
      
        width:100%;
        height:100%;
        margin-top:10vh;
        
     margin-bottom:10vh;
    }
    .filtrarreservasmobile{
        display:none
    }
   
    .slidersNetflixReservas2 .sliderNetFlix2 {

        min-height: 180px;
    width: 280px !important;
    border-radius:15px;
     
        font-size: 1rem;
        text-transform: capitalize;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
    .slidersNetflixReservas .sliderNetFlix {
        min-height: 180px;
        width: 280px !important;
        font-size: 1rem;
        border-radius:15px;
        text-transform: capitalize;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
    .slidersNetflixReservas .sliderNetFlixvideo {
        min-height: 180px;
        width: 280px !important;
        font-size: 1rem;
        text-transform: capitalize;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
    .slidersNetflixReservas .sliderNetFlixvideorozo {
       /* min-height:160px; */
        width: 240px !important;
        height:100% !important;
        font-size: 1rem;
        text-transform: capitalize;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
    }
    .sliderNetFlixvideoloco .content img:first-child:hover{
        width: 235px;
        height: 360px;
        font-size: 1rem;
        border-radius:20px;
        text-transform: capitalize;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        cursor: pointer;
        transition: 0.7s;
        box-shadow:0px 0px 15px -4px rgba(0,0,0,0.50);
        border:	0.5px solid rgba(0, 0, 0,  0.1);
        border-radius:	20px;
    }
    .sliderNetFlixvideocomidas .content img:last-child{
        pointer-events: none;
    }
    .sliderNetFlixvideocomidas .content img:hover{
        width: 185px;
        height: 284px;
        font-size: 1rem;
        border-radius:20px;
        text-transform: capitalize;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        word-wrap: break-word;
        z-index: 99999;
        cursor: pointer;
        transition: 0.8s;
       
     
        box-shadow:0px 0px 15px -4px rgba(0,0,0,0.50);
        border:	0.5px solid rgba(0, 0, 0,  0.3);
        border-radius:	20px;

    }
    .slidersNetflixReservas .sliderNetFlixvideorozo {
        /* opacity:0.7;
        background: linear-gradient(0deg,rgba(0,0,0,.8645833333333334),rgba(0,0,0,.6012780112044818) 35%,transparent); */
    }
  
    .slidersNetflixReservas .sliderNetFlixvideorozo .content img:hover {
        width: 245px !important;
        border:	1px solid rgba(158, 172, 237, 1);
        transition: 0.2s;
        border-radius:	20px;
       
    }
    .cajacontrol{
        display:flex  !important;
       
     
        max-height:20vh !important
    }
    .letrafiltrarW{
        font-size: calc(0.8rem + 0.1vw );
      
        font-family: WorkSans-Regular;
        margin:auto;
        color:#7C7C7C
    }
    .filtersweb{
        border-radius:15px;;
        border:2px solid #9DACBD;
        
        margin-left:1vw;
        background: white;
        font-size: 2.2vw;
        color:black;
        width:6rem;
        display:column !important;
        height: 6vh;
       
      
        
    }
    .btnfiltrarW .imgfiltrarWf{
        margin:0.3rem;
        width: calc(0.4em );
 
      
    }
    .btnfiltrarW .imgfiltrarWo{
     margin:0.2rem;
     
        width: calc(0.5em )
      
    }
    .btnfiltrarW{
        display:flex;
        justify-content: center;
        padding:1px;
        
    }
    .routerhe  {
        height:100%;
        overflow-y:scroll;
         
    }
    
 
   
    .containerReservas {

        margin-bottom: 75px; /*Tawk*/
        margin-left:14vw;
        margin-right:15vw;
    }
    .reservatitulo{
        display:none
    }
    .Cabecera {
        font-size: 1vw !important;
    }
    .Cabecera .container_name {
        font-size: 2.1vw !important;
    }
    /* .informacion .step {
        font-size: 2vmin !important;
    } 
    .informacion .more {
        font-size: 2.5vmin !important;
        padding: 0 !important;
    }*/
    .informacion .more img {
        padding-top: 2% !important;
        width: 25% !important;
        padding-bottom: 5% !important;
    }
}











.informacion .intensidad, .calorias,.ver {
    background-color: #7B86FA;
}

.informacion .intensidad img {
    width: calc(25% - 0.5vw);
}

.informacion .more img {
    width: 35%;
    margin-bottom:1vh;
}

.informacion .duracion, .more {
    background-color: #9EACED;
}

.informacion .duracion {
    line-height: 1;
}

.informacion .duracion img, .calorias img {
    width: 23%;
}



.informacion .calorias {
    line-height: 1;
    white-space: nowrap;
    font-weight: 600;
}

.informacion .more {
    cursor: pointer;
}



.letrasreservas{
    text-align:justify;
    
    margin-top:2vh;
    margin-left:2vh !important; 
    margin-right:2vh !important  
}







/* @media (min-width: 450px) {
    .slidersNetflixReservas .sliderNetFlix {
        min-height: 300px;
        width: 200px !important;
        font-size: 1rem;
    }
} */



.slidersNetflixReservas .sliderNetFlix::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.25;
    border-radius:15px;
}

.slidersNetflixReservas .sliderNetFlix:hover::before {
    opacity: 0;
}


.slidersNetflixReservas .sliderNetFlix .content .iconNetflix {
    max-height: 3rem;
    max-width: 3rem;
}

.slidersReservas .descr {
    border-left: 5px solid #268E95;
}
#mostrarOrdenar{
   
    display:none;
}
.buttonOrdenar{
    display:block;
    color:black;
    background-color:white;
    border:2px solid black;
    z-index:1;
    
}
.backDisciplinas span  {
    cursor: pointer;
   
}

.noDisciplian {
    text-align: center;
    padding-top:15%;
    padding-left: 3rem;
    padding-right: 3rem;
}

.noDisciplian img {
  
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
}

